export interface IAIScanConfig {
	apiUrl: string;
	apiKey: string;
	UIBaseUrl: string;
}

export interface IAIScanAttributes {
	[key: string]: string;
}

export interface IAIScanSecurityChecks {
	[key: string]: boolean;
}

export interface IAIScanCheck {
	antiTampering: {
		spoof: boolean;
		handwritten: boolean;
	};
	dateSubmitted: string;
	fileId: string;
	fileName: string;
	fileNameBack?: string;
	id: string;
	status: AISCAN_STATUS;
	type: AISCAN_CHECK_TYPE;
	result: {
		attributes?: IAIScanAttributes;
		securityChecks?: IAIScanSecurityChecks;
		similarityScore?: number;
		livenessScore?: number;
		error?: string;
		comment?: string;
	};
	updatedAt: string;
	createdAt: string;
	metadata: any;
}

export interface IAIScanSession {
	allowedDocumentCategory?: string | null;
	attempts: {
		idDocument: number;
		selfie: number;
	};
	availableChecks: AISCAN_CHECK_TYPE[];
	checks: IAIScanCheck[];
	maxAttemptsPerCheck: number;
	nextAction: AISCAN_ACTION;
	sessionId: string;
	similarityThreshold: number;
	passiveLivenessThreshold: number;
	status: AISCAN_STATUS;
}

export const AISCAN_SECURITY_CHECK_ERRORS_THRESHOLD = 3;

export enum AISCAN_STATUS {
	pending = 'Processing',
	complete = 'Completed',
	skipped = 'skipped',
	invalidated = 'invalidated',
	failed = 'Failed',
}

export const AISCAN_COMPLETED_STATUSES = [
	AISCAN_STATUS.complete,
	AISCAN_STATUS.failed,
];

export enum AISCANFLOWTYPES {
	disabled = 'disabled',
	idDocument = 'iddocument',
	idDocumentSelfie = 'iddocumentselfie',
	idDocumentSelfiePassiveLiveness = 'iddocumentselfiepassiveliveness',
	api = 'api',
}

export const AISCAN_UI_FLOWS = [
	AISCANFLOWTYPES.idDocument,
	AISCANFLOWTYPES.idDocumentSelfie,
	AISCANFLOWTYPES.idDocumentSelfiePassiveLiveness,
];

export enum AISCAN_CHECK_TYPE {
	idDocument = 'idDocument',
	idDocumentBackside = 'idDocumentBackside',
	selfie = 'selfie',
	passiveLiveness = 'passiveLiveness',
}

export enum AISCAN_ACTION {
	checkCompleted = 'checkCompleted',
	checkFailed = 'checkFailed',
	waitForSelfieCheck = 'waitForSelfieCheck',
	waitForDocumentCheck = 'waitForDocumentCheck',
	documentUpload = 'documentUpload',
	documentUploadBackside = 'documentUploadBackside',
	selfieUpload = 'selfieUpload',
	retrySelfieUpload = 'retrySelfieUpload',
	retryDocumentUpload = 'retryDocumentUpload',
	retryDocumentUploadDifferentType = 'retryDocumentUploadDifferentType',
}

export const AISCAN_WAITING_USER_ACTION = [
	AISCAN_ACTION.documentUpload,
	AISCAN_ACTION.selfieUpload,
	AISCAN_ACTION.retryDocumentUpload,
	AISCAN_ACTION.retrySelfieUpload,
];

export enum OCR_FIELDS {
	dateOfBirth = 'dateOfBirth',
	dateOfExpiry = 'dateOfExpiry',
	firstName = 'firstName',
	middleName = 'middleName',
	nameAtBirth = 'nameAtBirth',
	lastName = 'lastName',
	documentNumber = 'documentNumber',
	personalNumber = 'personalNumber',
	placeOfBirth = 'placeOfBirth',
	dateOfIssue = 'dateOfIssue',
	MRZ = 'MRZ',
	documentType = 'documentType',
	nationality = 'nationality',
	issuingCountry = 'issuingCountry',
}

export enum AISCAN_DEV_DATA_MODE {
	MOCK = 'mock',
	DEV = 'dev',
}

export enum AISCAN_DOCUMENT_SUPPORT_CATEGORY {
	FULL = 'FULL',
	PARTIAL = 'PARTIAL',
	LIMITED = 'LIMITED',
}
