import {
	GetAuthChallengeResponse,
	PostAuthChallengeRequestBody,
} from '../../../../common-ts/src/interface/auth-challenge';
import { apiClient } from '../../../../common-ts/src/services/network/api';

export const getChallenge = (did: string) => {
	return apiClient.get<GetAuthChallengeResponse>(
		`/api/v2/auth/challenge/${did}`
	);
};

export const sendSignature = (
	jwt: string,
	data: PostAuthChallengeRequestBody
) => {
	return apiClient.post<GetAuthChallengeResponse>(
		`/api/v2/auth/challenge`,
		data,
		{
			headers: {
				authorization: `Bearer ${jwt}`,
			},
		}
	);
};

export const getRedirectToken = () => {
	return apiClient.get<GetAuthChallengeResponse>(`/api/v2/auth/token`);
};
