import React from 'react';

import { SvgIcon } from '@material-ui/core';

export const SelfkeyIcon = (props: any) => {
	return (
		<SvgIcon {...props}>
			<path d="M10.9301 18.3282C11.7797 18.3282 12.4685 17.6339 12.4685 16.7773C12.4685 15.9208 11.7797 15.2264 10.9301 15.2264C10.0804 15.2264 9.3916 15.9208 9.3916 16.7773C9.3916 17.6339 10.0804 18.3282 10.9301 18.3282Z" fill="url(#paint0_linear)"/>
			<path d="M21.979 5.9919L11.4895 0L1 5.9919V17.9757L11.4895 23.9676L21.979 17.9757V5.9919ZM14.8399 5.18069C14.8395 5.55897 14.5344 5.86598 14.1568 5.86801H11.6161V7.3481H13.4902C13.6718 7.34849 13.8458 7.42113 13.9739 7.55002C14.102 7.67892 14.1737 7.85352 14.1734 8.03542C14.174 8.41405 13.8682 8.72155 13.4902 8.72237H11.6161V13.9916C11.6172 14.0159 11.6172 14.0402 11.6161 14.0646C12.9418 14.4156 13.8038 15.7003 13.6327 17.0683C13.4612 18.4363 12.3092 19.4654 10.9385 19.4747C9.56754 19.4838 8.40191 18.4705 8.21237 17.1048C8.02282 15.739 8.8675 14.4433 10.1888 14.0742C10.1856 14.0476 10.1839 14.0209 10.1837 13.9941V5.21646C10.1832 5.02563 10.2584 4.84244 10.3928 4.70723C10.5272 4.57202 10.7098 4.49588 10.9003 4.49559C10.9602 4.49559 11.0199 4.5037 11.078 4.51882C11.1343 4.50362 11.1923 4.49581 11.2506 4.49559H14.159C14.5366 4.49761 14.8417 4.80463 14.8421 5.1829L14.8399 5.18106V5.18069Z" fill="url(#paint1_linear)"/>
			<defs>
				<linearGradient id="paint0_linear" x1="10.9301" y1="18.2595" x2="10.9301" y2="15.2748" gradientUnits="userSpaceOnUse">
					<stop stopColor="#08BCCD"/>
					<stop offset="1" stopColor="#2DA1F8"/>
				</linearGradient>
				<linearGradient id="paint1_linear" x1="11.4895" y1="23.4365" x2="9.78107" y2="-3.90508" gradientUnits="userSpaceOnUse">
					<stop stopColor="#08BCCD"/>
					<stop offset="1" stopColor="#2DA1F8"/>
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};

