import { AxiosError } from 'axios';
import { IApplicationType } from './interface/application';
import {
	IMemberTemplateCondition,
	MemberOperation,
} from './interface/template';

export const isAxiosError = (error: Error | AxiosError): error is AxiosError =>
	!!(error as AxiosError).response?.status;

export const getErrorMesssage = (
	operation: MemberOperation,
	error: Error | AxiosError
) => {
	if (!isAxiosError(error)) {
		return error.message;
	}

	const status = error.response.status;

	const statuses = [403, 404, 422, 500];
	const message = statuses.includes(status) ? `${status}` : 'other';

	return `errors.${operation}-member-${message}`;
};

export const selectMemberTemplate = (
	memberConditions: IMemberTemplateCondition[], // the member template conditions in the parent template
	appType: IApplicationType, // the type of the parent application
	parentEntityType: string, // the legal entity type of the parent application
	memberEntityType: string, // the legal entity type of the member application (or individual)
	templateType: IApplicationType, // the type of the new member app to be added
	roles: string[] // the member role(s) of the new member app to be added
) => {
	// find suitable template based on entityType, roles, legalEntityTypes, and memberLegalEntityTypes

	// 1. filter by member application type
	let possibleTemplateConditions = memberConditions.filter(
		(entry: any) => entry.memberType === templateType
	);

	// 2. filter by parent entity type (if specified in the condition)
	if (appType === 'corporate') {
		possibleTemplateConditions = possibleTemplateConditions.filter(
			(condition) =>
				!condition.legalEntityTypes.length ||
				condition.legalEntityTypes.includes(parentEntityType)
		);
	}

	/* 3. filter by member entity type (if specified in the condition)
	 *
	 * (if the member type is "individual" then corporate templates
	 * will already be filtered out in step 1)
	 */
	if (templateType === IApplicationType.CORPORATE) {
		possibleTemplateConditions = possibleTemplateConditions.filter(
			(condition) =>
				!condition.memberLegalEntityTypes?.length ||
				condition.memberLegalEntityTypes?.includes(memberEntityType)
		);
	}

	// 4. filter by member role (if specified in the condition)
	possibleTemplateConditions = possibleTemplateConditions.filter(
		(condition) => {
			return (
				!condition.memberRoles.length ||
				condition.memberRoles.filter((r: string) => roles.includes(r))?.length >
					0
			);
		}
	);

	// 5. if we have multiple possible conditions, filter out the default
	if (possibleTemplateConditions.length > 1) {
		possibleTemplateConditions = possibleTemplateConditions.filter(
			(t) => !t.isDefault
		);
	}

	/* 6. if we still have multiple possible conditions,
	 * then select only those with the fewest number of memberEntityTypes.
	 *
	 * (if this would filter out all remaining templates, then take no action)
	 */
	if (possibleTemplateConditions.length > 1 && appType === 'corporate') {
		// get the fewest number of matching parentEntityTypes that aren't 0
		const fewestNumberOfMatchingTypes = Math.min(
			...possibleTemplateConditions
				.filter((c) => c.legalEntityTypes.length)
				.map((c) => c.legalEntityTypes.length)
		);
		// filter by that number of parentEntityTypes
		const filteredResult = possibleTemplateConditions.filter(
			(c) => c.legalEntityTypes.length === fewestNumberOfMatchingTypes
		);
		// update if the resulting array isn't empty
		possibleTemplateConditions = filteredResult.length
			? filteredResult
			: possibleTemplateConditions;
	}

	/* 7. if we still have multiple possible conditions,
	 * then select only those with the fewest number of memberEntityTypes.
	 *
	 * (if this would filter out all remaining templates, then take no action)
	 */
	if (possibleTemplateConditions.length > 1 && appType === 'corporate') {
		// get the fewest number of matching memberEntityTypes that aren't 0
		const fewestNumberOfMatchingTypes = Math.min(
			...possibleTemplateConditions
				.filter((c) => c.memberLegalEntityTypes?.length)
				.map((c) => c.memberLegalEntityTypes?.length)
		);
		// filter by that number of parentEntityTypes
		const filteredResult = possibleTemplateConditions.filter(
			(c) => c.memberLegalEntityTypes?.length === fewestNumberOfMatchingTypes
		);
		// update if the resulting array isn't empty
		possibleTemplateConditions = filteredResult.length
			? filteredResult
			: possibleTemplateConditions;
	}

	// 8. if we still have multiple possible conditions, sort them by member role specificity
	if (possibleTemplateConditions.length > 1) {
		possibleTemplateConditions.sort((a, b) => {
			// sort by member roles
			const aIntersection = roles.filter((r) => a.memberRoles.includes(r));
			const bIntersection = roles.filter((r) => b.memberRoles.includes(r));
			// sort so that the condition with the most matching roles comes first
			const diff = bIntersection.length - aIntersection.length;
			return diff !== 0 ? diff : a.memberRoles.length - b.memberRoles.length;
		});
	}
	return possibleTemplateConditions[0];
};
