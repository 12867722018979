import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	bold: {
		fontWeight: 600,
	},
	hiddenOnMobile: {
		'@media (max-width: 600px)': {
			display: 'none',
		},
		display: 'table-cell',
	},
	mobile: {
		'@media (max-width: 600px)': {
			display: 'table-cell',
			verticalAlign: 'baseline',
		},
		display: 'none',
	},
	mobileIconElement: {
		padding: '6px 0 6px 6px',
	},
	table: {
		marginBottom: '30px',
	},
	tableSubTitle: {
		marginBottom: '15px',
	},
	titleAndViewSelector: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '35px',
	},
});
