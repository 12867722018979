import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	fileItemWrap: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	menuItemIcon: {
		marginRight: '5px',
	},
	overflowProtection: {
		maxWidth: '210px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
});
