import { Button, Input } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './index';

interface ILoginFormProps {
	submitFunc: (payload: {
		credentials: { email: string; password: string };
	}) => any;
	loading: boolean;
	disabled?: boolean;
}

export const LoginForm = (props: ILoginFormProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const [_, setIsSubmitted] = useState(false);

	const [values, setValues] = useState({
		email: '',
		password: '',
		showPassword: false,
		validationError: false,
	});

	const handleChange =
		(prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
		};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event: React.MouseEvent) => {
		event.preventDefault();
	};

	const loginSubmit = (event: React.SyntheticEvent) => {
		event.preventDefault();
		setValues({ ...values, validationError: false });
		if (values.email.length <= 0 || values.password.length <= 0) {
			setValues({ ...values, validationError: true });
			return;
		}
		setIsSubmitted(true);
		const payload = {
			credentials: { email: values.email, password: values.password },
		};
		props.submitFunc(payload);
	};

	return (
		<>
			{props.loading && <LinearProgress />}
			<form onSubmit={loginSubmit} noValidate>
				<FormControl className={classes.input} fullWidth>
					<InputLabel shrink htmlFor="email">
						{t('login.email')}
					</InputLabel>
					<Input
						id="email"
						placeholder={t('login.email')}
						disabled={props.loading}
						type="email"
						error={values.email.length <= 0 && values.validationError}
						onChange={handleChange('email')}
						fullWidth
						required
					/>
					{values.email.length <= 0 && values.validationError ? (
						<Typography
							variant="subtitle1"
							color="error"
							className={classes.errorMessage}
						>
							{values.email.length <= 0 ? t('login.empty-email') : ''}
						</Typography>
					) : null}
				</FormControl>
				<FormControl className={classes.input} fullWidth>
					<InputLabel shrink htmlFor="password">
						{t('login.password')}
					</InputLabel>
					<Input
						id="password"
						disabled={props.loading}
						type={values.showPassword ? 'text' : 'password'}
						value={values.password}
						placeholder={t('login.password')}
						onChange={handleChange('password')}
						error={values.password.length <= 0 && values.validationError}
						required
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
								>
									{values.showPassword ? (
										<Visibility className={classes.visibilityIcon} />
									) : (
										<VisibilityOff className={classes.visibilityIcon} />
									)}
								</IconButton>
							</InputAdornment>
						}
					/>
					{values.password.length <= 0 && values.validationError ? (
						<Typography
							variant="subtitle1"
							color="error"
							className={classes.errorMessage}
						>
							{values.password.length <= 0 ? t('login.empty-password') : ''}
						</Typography>
					) : null}
				</FormControl>
				<Button
					disabled={props.loading || props.disabled}
					type="submit"
					className={classes.button}
					size="large"
					variant="contained"
					fullWidth
				>
					{t('login.sign-in')}
				</Button>
			</form>
		</>
	);
};
