import { ObjectFieldTemplateProps } from '@rjsf/core';
import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export const ObjectFieldTemplate = ({
	DescriptionField,
	description,
	TitleField,
	title,
	properties,
	required,
	uiSchema,
	idSchema,
}: ObjectFieldTemplateProps) => {
	return (
		<>
			{(uiSchema['ui:title'] || (title && uiSchema['ui:title'] !== '')) && (
				<TitleField
					id={`${uiSchema.uiId}-title`}
					title={title}
					required={required}
				/>
			)}
			{description && properties.length !== 1 && (
				<DescriptionField
					id={`${uiSchema.uiId}-description`}
					description={description}
				/>
			)}
			<Grid container={true} spacing={2}>
				{properties.map((element: any, index: number) => (
					<Grid item={true} xs={12} key={index}>
						{element.content}
						{description && properties.length === 1 && !uiSchema['ui:help'] && (
							<Typography
								id={`${uiSchema.uiId}-description`}
								variant="subtitle1"
							>
								{description}
							</Typography>
						)}
					</Grid>
				))}
			</Grid>
		</>
	);
};
