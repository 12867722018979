import { Attribute } from '../../../../../common-ts/src/customSchemas';
import {
	IApplication,
	IMember,
} from '../../../../../common-ts/src/interface/application';
import { apiClient } from '../../../../../common-ts/src/services/network/api';

export interface AddMemberApplicationData {
	application: {
		id: string;
		paths: string[];
		templateId: string;
		attributes: Attribute[];
		questions?: {
			id: string;
			data: string;
		}[];
	};
	memberRoles: string[];
	shares?: number;
}

export const addMemberApplication = (
	id: string,
	data: AddMemberApplicationData
) => {
	return apiClient.post<IApplication>(`/api/v2/applications/members`, data);
};

export const editMemberApplication = (
	id: string,
	memberApplicationId: string,
	data: { shares: number }
) => {
	return apiClient.patch<IApplication>(
		`/api/v2/applications/${id}/members/${memberApplicationId}`,
		data
	);
};

export const deleteMemberApplication = (
	parentApplicationId: string,
	applicationId: string
) => {
	return apiClient.delete<void>(
		`/api/v2/applications/${parentApplicationId}/members/${applicationId}`
	);
};

export interface AddMemberToExistingApplication {
	source: string;
	target: string;
	memberRoles?: string[];
	shares?: number;
}

export const addMemberToExistingApplication = (
	data: AddMemberToExistingApplication
) => {
	return apiClient.post<{ id: string; paths: string[]; members: IMember[] }[]>(
		`/api/v2/applications/members/links`,
		data
	);
};
