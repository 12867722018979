import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios';

// import { crash } from '@/services/crash';

export enum Header {
	AcceptLanguage = 'accept-language',
	Authorization = 'authorization',
}

export interface HttpPromise<T, C extends HttpRequestConfig = HttpRequestConfig>
	extends Promise<HttpResponse<T, C>> {}

export interface HttpInstance<C extends HttpRequestConfig = HttpRequestConfig>
	extends AxiosInstance {
	(config: C): HttpPromise<any, C>;
	(url: string, config?: C): HttpPromise<any, C>;
}

export interface HttpRequestConfig extends AxiosRequestConfig {
	sentry?: { disabled?: boolean; ignoreStatus?: number[] };
}

export interface HttpResponse<
	T,
	C extends HttpRequestConfig = HttpRequestConfig
> extends AxiosResponse<T> {
	config: C;
}

export interface HttpError<T, C extends HttpRequestConfig = HttpRequestConfig>
	extends AxiosError<T> {
	config: C;
	response?: HttpResponse<T, C>;
}

export class HttpClient<C extends HttpRequestConfig = HttpRequestConfig> {
	// TODO: Should be protected
	public client: AxiosInstance;
	protected APIURL: string;

	constructor(config?: HttpRequestConfig) {
		this.client = axios.create(config) as HttpInstance<C>;

		this.client.interceptors.request.use(undefined, this.report);
		this.client.interceptors.response.use(undefined, this.report);
	}

	public setAPIURL(url: string) {
		this.APIURL = url;
	}

	public request(config: any) {
		return this.client(config).then(this.unwrap);
	}

	// TODO: Fix any
	public get<T, P = object>(url: string, params?: P, config?: any) {
		return this.client.get<T>(url, { params, ...config }).then(this.unwrap);
	}

	// TODO: Fix any
	public post<T, D = object>(url: string, data?: D, config?: any) {
		return this.client.post<T>(url, data, config).then(this.unwrap);
	}

	// TODO: Fix any
	public put<T, D = object>(url: string, data?: D, config?: any) {
		return this.client.put<T>(url, data, config).then(this.unwrap);
	}

	// TODO: Fix any
	public patch<T, D = object>(url: string, data?: D, config?: any) {
		return this.client.patch<T>(url, data, config).then(this.unwrap);
	}

	// TODO: Fix any
	public delete<T>(url: string, config?: any) {
		return this.client.delete<T>(url, config).then(this.unwrap);
	}

	public getWithHeader<T>(
		url: string,
		params?: object,
		config?: HttpRequestConfig
	) {
		return this.client.get<T>(url, { params, ...config });
	}

	public postWithHeader<T>(
		url: string,
		data?: object,
		config?: HttpRequestConfig
	) {
		return this.client.post<T>(url, data, config);
	}

	protected unwrap = <T>(response: HttpResponse<T>) => response?.data;

	protected getReportOptions(error: HttpError<any>) {
		return error.isAxiosError
			? {
					config: {
						data: error.config.data,
						headers: error.config.headers,
						params: error.config.params,
					},
					response: error.response ? error.response.data : {},
			  }
			: {};
	}

	private shouldReport(sentry: C['sentry'], response?: HttpResponse<any>) {
		if (!sentry) return true;

		if (sentry.disabled || !response) return false;

		if (!sentry.ignoreStatus) return true;

		return !sentry.ignoreStatus.includes(response.status);
	}

	private report = (error: HttpError<any>) => {
		const shouldReport =
			this.shouldReport(
				(this.client.defaults as unknown as HttpRequestConfig).sentry,
				error.response
			) && this.shouldReport(error.config.sentry, error.response);

		// FIXME send error to sentry and throw error correctly
		if (error) {
			throw error;
		}

		// return crash.throw(error, this.getReportOptions(error));
	};
}
