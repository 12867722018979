import { getBase } from './path';

export const membersFilter = (
	parentApplicationId: string,
	memberApplicationId: string,
	memberApplicationPaths: string[]
) => {
	if (!memberApplicationId || !parentApplicationId) {
		return false;
	}

	if (memberApplicationId === parentApplicationId) {
		return true;
	}

	if (!memberApplicationPaths || memberApplicationPaths.length === 0) {
		return false;
	}

	return memberApplicationPaths.some((p) => getBase(p) === parentApplicationId);
};
