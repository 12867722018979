import { connect } from '@wagmi/core';

import { IWagmiContext } from '../App';

declare const __APP_CONFIG__: any;
const config = __APP_CONFIG__;

declare global {
	interface Window {
		GBG: any;
		ethereum: any;
	}
}

export enum WALLET_PROVIDERS {
	metamask = '1',
	walletConnect = '2',
	selfkey = '3',
}

function injectProviderMethods(provider: any) {
	// tslint:disable-next-line:variable-name
	const _global = (window || global) as any;
	_global.send = (e: any, t: any) => {
		return provider.send(e, t);
	};
}

export const restoreWalletConnection = async (
	provider: WALLET_PROVIDERS,
	wagmiContext: IWagmiContext
): Promise<{ account: string }> => {
	let accounts = null;

	if (provider === WALLET_PROVIDERS.metamask) {
		const connection = await connect(wagmiContext.config, {
			connector: wagmiContext.connectors.metamask,
		});
		accounts = connection.accounts;
	}

	if (provider === WALLET_PROVIDERS.walletConnect) {
		const connection = await connect(wagmiContext.config, {
			connector: wagmiContext.connectors.walletConnect,
		});
		accounts = connection.accounts;
	}

	/*
	if (provider === WALLET_PROVIDERS.selfkey) {
		const selfkeyProvider: any = new WalletConnectProvider({
			infuraId: config.app.infuraId,
			qrcode: false,
		});

		selfkeyProvider.connector.on('display_uri', (err: any, payload: any) => {
			const uri = payload.params[0];
			window.location.href = `selfkey://wc?uri=${uri}`;
		});

		await selfkeyProvider.enable();

		web3 = new Web3(selfkeyProvider as any);
		web3.eth.defaultAccount = selfkeyProvider.accounts[0];

		injectProviderMethods(selfkeyProvider);

		accounts = selfkeyProvider.accounts;
	}
	*/

	if (!accounts?.length) {
		return { account: null };
	}

	return { account: accounts[0] };
};

export const connectToWallet = async (
	provider: WALLET_PROVIDERS,
	wagmiContext: IWagmiContext
) => {
	const { account } = await restoreWalletConnection(provider, wagmiContext);

	if (!account) {
		throw new Error('Could not establish connection');
	}

	return { account, provider };
};
