import * as React from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { withTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';

import { Grid, Typography } from '@material-ui/core';
import { ISignatureInput } from './SignatureDialog';

interface IDrawSignatureProps extends ISignatureInput {
	classes: any;
	proportion?: number;
}

const styles = () =>
	createStyles({
		bold: {
			fontWeight: 600,
		},
		button: {
			'&:focus': {
				backgroundColor: '#064780',
			},
			'&:hover': {
				backgroundColor: '#043158',
			},
			backgroundColor: '#064780',
			borderRadius: '0 0 4px 4px',
			color: '#fff',
			marginTop: '-57px',
			position: 'relative',
			width: 'calc(100% + 4px)',
		},
		icon: {
			marginRight: '10px',
		},
		paper: {
			'& .sigCanvas': {
				height: 145,
				width: 376,
			},
			'@media (max-width: 800px)': {
				'& .sigCanvas': {
					height: 145,
					width: 291,
				},
				padding: '0 4px 0 0',
			},
			boxShadow: 'none',
			boxSizing: 'border-box',
			padding: '0 4px 0 0',
		},
		sigContainer: {
			backgroundColor: '#F8F8FC',
			border: '2px solid #D5D5E3',
			borderRadius: '4px',
			height: '180px',
			width: '100%',
		},
	});

class DrawSignature extends React.Component<IDrawSignatureProps, {}> {
	private canvasRef: React.RefObject<any>;

	constructor(props: IDrawSignatureProps) {
		super(props);
		this.canvasRef = React.createRef();
	}

	public componentDidUpdate(prevProps: IDrawSignatureProps) {
		if (
			this.props.signatureRequested &&
			this.props.signatureRequested !== prevProps.signatureRequested
		) {
			this.props.onSignatureUpdated(
				this.canvasRef.current.getCanvas().toDataURL()
			);
		}
	}

	public render() {
		const { classes, t } = this.props;
		return (
			<>
				<Paper classes={{ root: classes.paper }}>
					<Typography variant="body2" gutterBottom>
						{t('signatureDialog.drawMenuSubtitle')}
					</Typography>
					<div className={classes.sigContainer}>
						<SignatureCanvas
							ref={this.canvasRef}
							penColor="black"
							canvasProps={{
								className: 'sigCanvas',
							}}
						/>
					</div>
					<Button
						onClick={this.clearCanvas}
						className={classes.button}
						variant="contained"
						color="primary"
						fullWidth
					>
						<Grid container justify="center" alignItems="center">
							<ClearIcon className={classes.icon} />
							<Typography variant="body2" className={classes.bold}>
								{t('signatureDialog.draw.clear')}
							</Typography>
						</Grid>
					</Button>
				</Paper>
			</>
		);
	}

	private clearCanvas = () => {
		this.canvasRef.current.clear();
	};
}

export default withTranslation()(withStyles(styles)(DrawSignature));
