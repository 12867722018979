import identity from 'lodash/identity';
import React from 'react';

type State<T> = [T, React.Dispatch<React.SetStateAction<T>>];

export type Context = State<{ done: boolean }>;

export const IDScanStatusContext = React.createContext<Context>([
	undefined,
	identity,
]);
