import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMeasure } from 'react-use';

import { KycStatuses } from '../../../../common-ts/src/application/constants';
import { KnownAttribute } from '../../../../common-ts/src/customSchemas';
import {
	IApplication,
	IApplicationType,
} from '../../../../common-ts/src/interface/application';
import {
	DefaultDateFormat,
	IDateFormat,
} from '../../../../common-ts/src/interface/scope';
import { ILegalEntityType } from '../../../../common-ts/src/interface/template';
import { membersFilter } from '../../../../common-ts/src/util/membersFilter';
import { getBase } from '../../../../common-ts/src/util/path';
import { getAttribute } from '../../../../common/src/helpers/attributes';
import {
	HierarchyGraph,
	createHierarchyData,
} from '../../../../common/src/react/HierarchyGraph';
import { useOpen } from '../../../../common/src/react/hooks/useOpen';
import CustomerPortalTheme from '../../../../ui/src/theme/kyc-default-theme';
import { ApplicationSelect } from '../ApplicationSelect';
import { ApplicationInformation } from './ApplicationInformation';
import { useStyles } from './HierarchyViewTab.style';
import {
	LinkExistingApplicationModal,
	Props as LinkExistingApplicationModalProps,
} from './LinkExistingApplicationModal';

interface OnNodeClickOptions {
	selected: IApplication;
	parents: IApplication[];
}

export interface Props
	extends Pick<
		Partial<LinkExistingApplicationModalProps>,
		'onMemberLinkedSubmit'
	> {
	allApplications: IApplication[];
	currentApplication: IApplication;
	selected: IApplication;
	parents: IApplication[];
	legalEntity: ILegalEntityType;
	onNodeClick: (options: OnNodeClickOptions) => void;
	onAddMemberClick?: () => void;
	onEditMember?: (id: string) => void;
	onDeleteMember?: (id: string, parentId: string) => Promise<void>;
	dateFormat?: IDateFormat;
}

export const HierarchyViewTab: React.FC<Props> = ({
	allApplications,
	currentApplication,
	selected,
	parents,
	legalEntity,
	onNodeClick,
	onAddMemberClick,
	onDeleteMember,
	onMemberLinkedSubmit,
	dateFormat = DefaultDateFormat,
}) => {
	const styles = useStyles();
	const { t } = useTranslation();
	const { isOpen, open, close } = useOpen();
	const [parentApplicationId, setParentApplicationId] = React.useState('');

	const [outerRef, { width }] = useMeasure();

	const data = React.useMemo(
		() =>
			createHierarchyData(
				allApplications.filter((a) =>
					membersFilter(currentApplication.id, a.id, a.paths)
				)
			),
		[allApplications, currentApplication]
	);

	const getParent = React.useCallback(() => {
		return parents?.find((app) => app.id === parentApplicationId) ?? null;
	}, [parentApplicationId]);

	const attribute = getAttribute(
		selected.attributes,
		KnownAttribute.LegalEntityType,
		null
	);
	const hasLegalEntityType = attribute?.value;
	const deleteEnabled = [
		KycStatuses.invited,
		KycStatuses.terms_accepted,
	].includes(selected.currentStatus);

	return (
		<React.Fragment>
			<div ref={outerRef} className={styles.root}>
				<HierarchyGraph
					{...{ data, width }}
					height={350}
					onNodeClick={async (node) => {
						const selected = [
							currentApplication,
							...allApplications.filter(
								(app) => app.id !== currentApplication.id
							),
						].find((application) => application.id === node.id);

						const baseIds = selected.paths?.map(getBase).filter(Boolean) ?? [];

						const parents = allApplications.filter((application) =>
							baseIds.includes(application.id)
						);

						onNodeClick({ selected, parents });
						setParentApplicationId(parents[0]?.id ?? '');
					}}
				/>
			</div>

			<Divider className={styles.divider} />

			{isOpen && !!onMemberLinkedSubmit && (
				<CustomerPortalTheme>
					<LinkExistingApplicationModal
						applicationType={selected.type}
						applications={allApplications.filter(
							(application) => application.id !== selected.id
						)}
						{...{
							selected,
							isOpen,
							onClose: close,
							legalEntity,
							onMemberLinkedSubmit,
						}}
					/>
				</CustomerPortalTheme>
			)}

			<div className={styles.buttonsContainer}>
				{selected.type !== IApplicationType.INDIVIDUAL && !!onAddMemberClick && (
					<Tooltip
						title={
							hasLegalEntityType
								? t('membersTable.add_tooltip')
								: t('membersTable.add_disabled_tooltip')
						}
					>
						<span>
							<Button
								variant="contained"
								onClick={onAddMemberClick}
								disabled={!hasLegalEntityType}
								className={styles.button}
							>
								{t('membersTable.add')}
							</Button>
						</span>
					</Tooltip>
				)}

				{/* // TODO: Logic for can link existing */}
				{!!onMemberLinkedSubmit &&
					allApplications.length > 2 &&
					selected.type !== IApplicationType.INDIVIDUAL && (
						<Button
							variant="contained"
							onClick={open}
							className={styles.button}
						>
							Link existing
						</Button>
					)}

				{!!onDeleteMember &&
					deleteEnabled &&
					selected.paths &&
					!selected.members.filter((member) => !member.softDelete).length && (
						<Button
							variant="contained"
							onClick={() => onDeleteMember(selected.id, parentApplicationId)}
							className={styles.button}
						>
							Delete
						</Button>
					)}
			</div>

			{parents?.length > 1 && (
				<React.Fragment>
					<label
						htmlFor="application-select"
						className={styles.applicationsLabel}
					>
						Member of
					</label>

					<ApplicationSelect
						id="application-select"
						items={parents}
						value={parentApplicationId}
						onChange={setParentApplicationId}
						fullWidth
						className={styles.applicationsSelect}
					/>
				</React.Fragment>
			)}

			<ApplicationInformation
				parentApplication={getParent()}
				application={selected}
				dateFormat={dateFormat}
			/>
		</React.Fragment>
	);
};
