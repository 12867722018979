import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SizeMe } from 'react-sizeme';

import {
	IFilePreview,
	IFileSchema,
} from '../../../../../common-ts/src/interface/file';
import { IKycDocumentTemplate } from '../../../../../common-ts/src/interface/form';
import DynamicField, {
	IDynamicFieldProps,
} from '../../../../../common/src/react/on-system-fill/field/dynamic-field';
import { accent } from '../../../../../ui/src/colors';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import { setCurrentApplicationData } from '../../../redux/actions/application';
import { RootState } from '../../../redux/reducers';
import {
	acceptTerms,
	getFormPageUrl,
	getTermsDocument,
	isTermsAccepted,
} from '../../../services/api';
import { IKYCSectionProps, SectionNavigation } from './Section';

const useStyles = makeStyles({
	customCheckbox: {
		width: '32px',
		height: '32px',
		color: accent,
	},
	docArea: {
		boxShadow: 'none',
		left: 0,
		maxHeight: '85vh',
		'@media (max-width: 480px)': {
			maxHeight: '55vh',
		},
		overflowY: 'auto',
		padding: '0 10px',
		position: 'relative',
		display: 'none',
	},
	docAreaDisplay: {
		display: 'block',
	},
	docPage: {
		boxShadow:
			'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
		marginBottom: '10px;',
	},
	textAndCheckbox: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '25px',
	},
});

interface ITermsPageProps extends IKYCSectionProps {
	termsDocumentId: string;
}

export const TermsPage: React.FC<ITermsPageProps> = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(true);
	const [previewsLoaded, setPreviewsLoaded] = React.useState<{
		[id: number]: boolean;
	}>({});
	const [fieldValues, setFieldValues] = React.useState<{
		[id: string]: string | boolean;
	}>({});
	const [fields, setFields] = React.useState<IDynamicFieldProps[]>();
	const [previews, setPreviews] = React.useState<IFilePreview[]>([]);
	const [termsPageText, setTermsPageText] = React.useState({
		title: '',
	});
	const application = useSelector(
		(store: RootState) => store.applicationStore.currentApplication
	);
	const hasBeenAccepted = isTermsAccepted(application);
	const [submitDisabled, setSubmitDisabled] = React.useState(!hasBeenAccepted);
	const [error, setError] = React.useState(null);

	const acceptTermsClick = async () => {
		setError(null);
		if (isTermsAccepted(application)) {
			props.nextCallback();
			return;
		}
		setLoading(true);
		// check if there are signature requirements
		const formData =
			Object.keys(fieldValues).length > 0 ? { formData: fieldValues } : null;
		acceptTerms(application.id, formData)
			.then((updatedApplication) => {
				dispatch(setCurrentApplicationData(updatedApplication));
				props.nextCallback();
			})
			.catch((err) => {
				if (err.response.status === 422) {
					setError('errors.terms.signature-required');
				} else if (err.response.status === 500) {
					setError('errors.save-500');
				} else {
					setError('errors.save-other');
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onScroll = (event: any) => {
		const atBottomThreshold = 600; // Set a larger threshold just in case
		const atBottom =
			event.target.scrollHeight -
				event.target.clientHeight -
				event.target.scrollTop <=
			atBottomThreshold;
		if (atBottom && Object.keys(previewsLoaded).length === previews.length) {
			setSubmitDisabled(isDisabledNextButton());
		}
	};

	const previewLoaded = (preview: IFilePreview) => {
		const temp = { ...previewsLoaded };
		temp[preview.page] = true;
		setPreviewsLoaded(temp);
	};

	const checkRadioButtonFields = (
		field: IDynamicFieldProps,
		newValues: { [x: string]: string | boolean }
	) => {
		const currentField = fields.find(
			(f) => f.id === field.id && f.type === 'radio'
		);
		if (currentField) {
			newValues[field.id] = true;
			const otherRadioButtons = fields.filter(
				(f) =>
					f.id !== field.id &&
					f.type === 'radio' &&
					f.groupName === field.groupName
			);
			otherRadioButtons.forEach((radioBtn) => {
				newValues[radioBtn.id] = false;
			});
		}
		return newValues;
	};

	const renderPreviewImage = (preview: IFilePreview, index: number) => {
		if (fields.length === 0 || isTermsAccepted(application)) {
			return (
				<img
					onLoad={() => previewLoaded(preview)}
					className={previewsLoaded[preview.page] ? classes.docPage : null}
					src={getFormPageUrl(props.termsDocumentId, preview.page)}
					key={preview.page}
				/>
			);
		}

		const previewEntry = previews.find((p) => p.page === preview.page);

		return (
			<SizeMe key={preview.page}>
				{({ size }) => {
					const scale = size.width / previewEntry.originalPageWidth;
					return (
						<Paper
							className={previewsLoaded[preview.page] ? classes.docPage : null}
						>
							<img
								onLoad={() => previewLoaded(preview)}
								src={getFormPageUrl(props.termsDocumentId, preview.page)}
							/>
							{fields
								.filter((field) => (field.page === index + 1 ? field : false))
								.map((field) => {
									return (
										<DynamicField
											page={field.page}
											key={field.id}
											locationX={field.locationX}
											locationY={field.locationY}
											scale={scale}
											id={field.id}
											height={field.height}
											width={field.width}
											type={field.type}
											groupName={field.groupName}
											options={field.options}
											value={fieldValues[field.id]}
											onDataChange={(data: { value: string }) => {
												setFieldValues((prevValues) => {
													const newValues = { ...prevValues };
													newValues[field.id] = data.value;
													return checkRadioButtonFields(field, newValues);
												});
											}}
										/>
									);
								})}
						</Paper>
					);
				}}
			</SizeMe>
		);
	};

	React.useEffect(() => {
		setLoading(true);

		getTermsDocument(props.termsDocumentId).then(
			(data: IKycDocumentTemplate) => {
				const file = data.files[0] as IFileSchema;
				const totalPages = file.preview.length;
				setTermsPageText({ title: data.name });
				const processedBuild: IDynamicFieldProps[] = data.build
					.filter((build) => build.page <= totalPages)
					.map((build) => ({
						groupName: build.config.groupName,
						height: build.size.height,
						id: build._id,
						locationX: build.location.x,
						locationY: build.location.y,
						options: build.config.options,
						page: build.page,
						type: build.config.type,
						width: build.size.width,
					}));
				setFields(processedBuild);

				// select first option
				const selectValues: { [id: string]: string } = {};
				processedBuild.forEach((build) => {
					if (build.options?.length > 0) {
						selectValues[build.id] = build.options[0]?.value;
					}
				});

				setFieldValues(selectValues);

				setPreviews(
					file.preview.map((p: IFilePreview) => {
						p.originalPageWidth = p.originalSize.width;
						p.originalPageHeight = p.originalSize.height;
						return p;
					})
				);

				setLoading(false);
			}
		);
	}, []);

	const populateRadioGroups = (
		f: IDynamicFieldProps,
		radioButtonsGroups: {
			[name: string]: Array<{ id: string; value: boolean }>;
		}
	) => {
		if (f.groupName) {
			if (radioButtonsGroups[f.groupName]) {
				radioButtonsGroups[f.groupName].push({
					id: f.id,
					value: fieldValues[f.id] as boolean,
				});
			} else {
				radioButtonsGroups[f.groupName] = [
					{ id: f.id, value: fieldValues[f.id] as boolean },
				];
			}
		}
		return radioButtonsGroups;
	};

	React.useEffect(() => {
		if (fields?.length > 0) {
			setSubmitDisabled(isDisabledNextButton());
		}
	}, [fieldValues]);

	const isDisabledNextButton = () => {
		if (hasBeenAccepted) {
			return false;
		}

		let isSelecedtRadioBtn = false;
		let radioBtnsCount = 0;
		const nonRadioBtnFields: { [id: string]: string | boolean } = {};
		let radioButtonsGroups: {
			[name: string]: Array<{ id: string; value: boolean }>;
		} = {};
		fields.forEach((f) => {
			if (f.type === 'radio') {
				radioBtnsCount += 1;
				radioButtonsGroups = populateRadioGroups(f, radioButtonsGroups);
				if (fieldValues[f.id]) {
					isSelecedtRadioBtn = true;
				}
			} else {
				nonRadioBtnFields[f.id] = fieldValues[f.id];
			}
		});

		let invalidRadioBtnGroups = false;
		Object.keys(radioButtonsGroups).forEach((radioGrpName) => {
			const radioBtns = radioButtonsGroups[radioGrpName];
			const selectedRadioBtns = radioBtns.filter(
				(radioBtn) => radioBtn.value === true
			).length;
			if (selectedRadioBtns !== 1) {
				invalidRadioBtnGroups = true;
			}
		});

		if (invalidRadioBtnGroups) {
			return true;
		}

		if (isSelecedtRadioBtn && fields.length === radioBtnsCount) {
			return false;
		}

		// check missing non radio button fields
		const hasMissingFields =
			Object.keys(nonRadioBtnFields).some(
				(key: string) =>
					fields.find((f) => f.id === key).type !== 'checkbox' &&
					!nonRadioBtnFields[key]
			) ||
			fields.length - radioBtnsCount !== Object.keys(nonRadioBtnFields).length;

		return (radioBtnsCount > 0 && !isSelecedtRadioBtn) || hasMissingFields;
	};

	const hasSignatureField = fields?.some((s) => s.type === 'signature');
	const hasFields = fields?.length > 0;

	const showAllRenderedPreviews: boolean =
		Object.keys(previewsLoaded).length === previews.length;

	return (
		<CustomerPortalTheme>
			<Typography align="left" variant="h1" style={{ marginBottom: '10px' }}>
				{termsPageText.title}
			</Typography>

			{hasFields && isTermsAccepted(application) && (
				<div className={classes.textAndCheckbox}>
					<Typography align="left" variant="h1">
						{t(
							hasSignatureField
								? 'termsAndConditions.signedDocument'
								: 'termsAndConditions.filled'
						)}
					</Typography>
					<CheckIcon className={classes.customCheckbox} />
				</div>
			)}

			{error && (
				<Typography gutterBottom={true} variant="h3" color="error">
					{t(error)}
				</Typography>
			)}
			{loading || !showAllRenderedPreviews ? <LinearProgress /> : null}
			{previews.length > 0 ? (
				<Paper
					className={`${classes.docArea} ${
						showAllRenderedPreviews && !loading && classes.docAreaDisplay
					}`}
					onScroll={!hasBeenAccepted ? onScroll : null}
				>
					{previews
						.sort((a, b) => a.page - b.page)
						.map((preview, index) => renderPreviewImage(preview, index))}
				</Paper>
			) : null}
			<SectionNavigation
				loading={loading}
				prevCallback={props.prevCallback}
				nextCallback={acceptTermsClick}
				nextButtonProps={{ disabled: submitDisabled }}
				position="center"
				origin="terms"
			/>
		</CustomerPortalTheme>
	);
};
