import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomerPortalTheme from '../../../../ui/src/theme/kyc-default-theme';
import { UploadDialogEditContext } from '../UploadDocumentDialog';
import { getFileNameByContentId } from '../helpers';
import { BaseWidget, Props as BaseWidgetProps } from './BaseWidget';
import { useStyles } from './style';

interface IWidgetProps extends BaseWidgetProps {
	id: string;
	title: string;
	onChange: (file: any) => void;
	handleDelete: (id: string) => void;
}

export const ImageWidget = (props: BaseWidgetProps) => (
	<BaseWidget {...props}>
		<FileUploadWidget {...(props as any)} />
	</BaseWidget>
);

const FileUploadWidget = (props: IWidgetProps) => {
	const classes = useStyles();
	const [fileUploadContext] = React.useContext(UploadDialogEditContext);
	const uploadRef: React.RefObject<HTMLInputElement> = React.createRef();
	const { t } = useTranslation();
	const hasFile =
		fileUploadContext.files.length > 1 ||
		(fileUploadContext.files.length === 1 &&
			fileUploadContext.files[0].itemKey === 'image')
			? true
			: false;
	const hasError =
		props.error && props.error.keyword === 'required' && !hasFile
			? true
			: false;
	const inputClass = hasError ? classes.errorStyle : classes.noErrorStyle;

	return (
		<CustomerPortalTheme>
			<input
				ref={uploadRef}
				onChange={(event) => props.onChange(event.target.files[0])}
				id={`upload-button-${props.id}-${props.itemKey}`}
				className={classes.fileInput}
				type="file"
			/>
			<InputLabel
				shrink
				htmlFor={`upload-button-${props.id}-${props.itemKey}`}
				className={classes.titleSpace}
			>
				{t(`widgetLabels.${props.title.toLowerCase()}`)}{' '}
				{props.required && ' *'}
			</InputLabel>
			<Paper
				className={classes.bottomSpace}
				classes={{ elevation1: classes.noShadow }}
			>
				<Grid
					container
					wrap="nowrap"
					justify="space-between"
					className={inputClass}
				>
					<Grid item style={{ width: '100%' }}>
						{fileUploadContext.files
							.filter((f: any) => f.file && f.itemKey === props.itemKey)
							.map((file: any, index: number) => {
								const name = file.file.name ? (
									<Typography
										variant="body2"
										className={`${classes.overflowHandling} ${classes.bold}`}
									>
										{file.file.name}
									</Typography>
								) : (
									getFileNameByContentId(props.fileNames, file.file.content)
								);

								return (
									<Chip
										key={index}
										label={name}
										deleteIcon={<HighlightOffOutlinedIcon />}
										onDelete={() => props.handleDelete(file.id)}
										classes={{
											colorSecondary: classes.errorStyle,
											deletable: classes.deletable,
											label: classes.overflowHandling,
											root: classes.fileInputStyle,
										}}
									/>
								);
							})}
					</Grid>
					<Grid item className={classes.buttonWrap}>
						<Button
							className={classes.fileInputUpload}
							variant="contained"
							onClick={() => uploadRef.current.click()}
						>
							{t(
								fileUploadContext.files.filter(
									(f: any) => f.itemKey === props.itemKey
								).length
									? props.multiple
										? 'fileUploadDialog.add'
										: 'fileUploadDialog.replace'
									: 'fileUploadDialog.upload'
							)}
						</Button>
					</Grid>
				</Grid>
				{hasError ? (
					<Grid item style={{ marginTop: '5px' }}>
						<Typography gutterBottom={true} variant="subtitle1" color="error">
							{t('errors.required')}
						</Typography>
					</Grid>
				) : null}
			</Paper>
		</CustomerPortalTheme>
	);
};
