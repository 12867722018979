import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	buttonProgress: {
		left: '50%',
		marginLeft: -12,
		marginTop: -12,
		position: 'absolute',
		top: '50%',
	},
	closeIcon: {
		'& svg': {
			height: '30px',
			width: '30px',
		},
		'@media (max-width: 600px)': {
			marginRight: '-20px',
			marginTop: '-40px',
		},
		marginRight: '-60px',
		marginTop: '-90px',
		position: 'relative',
	},
	content: {
		marginBottom: '30px',
		marginTop: '40px',
		padding: 0,
	},
	dialog: {
		maxWidth: '840px',
	},
	hidden: {
		display: 'none',
	},
	iconWrap: {
		marginBottom: '-20px',
		marginTop: '10px',
		textAlign: 'right',
	},
	rightSpace: {
		marginRight: '10px',
	},
	wrapper: {
		float: 'left',
		position: 'relative',
	},
});
