import { InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { WidgetProps } from '@rjsf/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import CustomDatePicker from '../../../../common/src/react/DatePicker';
import { CustomDescriptionField } from './CustomRjsfTitle';

export const CustomDateTemplate = (props: WidgetProps) => {
	const { t } = useTranslation();

	return (
		<FormControl required={props.required} fullWidth>
			<InputLabel shrink htmlFor={props.id}>
				{props.label ?? props.schema.title}
			</InputLabel>
			{props.schema.description && props.schema.description.length && (
				<CustomDescriptionField description={props.schema.description} />
			)}
			<CustomDatePicker
				dateFormat="yyyy-MM-dd"
				placeholderText={t('questions.select_date')}
				selected={
					props.value
						? new Date(props.value as string).getTime() +
						  new Date(props.value as string).getTimezoneOffset() * 60000
						: ''
				}
				onChange={(selectedDate: Date) => {
					const d = new Date(
						selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
					)
						.toISOString()
						.split('T')[0];
					props.onChange(d);
				}}
				onBlur={props.onBlur}
			/>
		</FormControl>
	);
};
