// tslint:disable
// NOTE file copied from https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/SelectWidget/SelectWidget.tsx
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from '@material-ui/core/Select';
import { WidgetProps } from '@rjsf/core';
import { utils } from '@rjsf/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getAttrNameFromSchemaId } from '../../../../common-ts/src/attributes';
import { CustomDescriptionField } from './CustomRjsfTitle';

const { asNumber, guessType } = utils;

const nums = new Set(['number', 'integer']);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
const processValue = (schema: any, value: any) => {
	// "enum" is a reserved word, so only "type" and "items" can be destructured
	const { type, items } = schema;
	if (value === '') {
		return undefined;
	} else if (type === 'array' && items && nums.has(items.type)) {
		return value.map(asNumber);
	} else if (type === 'boolean') {
		return value === 'true';
	} else if (type === 'number') {
		return asNumber(value);
	}

	// If type is undefined, but an enum is present, try and infer the type from
	// the enum values
	if (schema.enum) {
		if (schema.enum.every((x: any) => guessType(x) === 'number')) {
			return asNumber(value);
		} else if (schema.enum.every((x: any) => guessType(x) === 'boolean')) {
			return value === 'true';
		}
	}

	return value;
};

export const CustomSelectWidget = ({
	schema,
	id,
	options,
	label,
	required,
	disabled,
	readonly,
	value,
	multiple,
	autofocus,
	onChange,
	onBlur,
	onFocus,
}: WidgetProps) => {
	const { t } = useTranslation();
	const { enumOptions, enumDisabled } = options;

	const emptyValue: any = multiple ? [] : '';

	const _onChange = ({
		target: { value },
	}: React.ChangeEvent<{ name?: string; value: unknown }>) =>
		onChange(processValue(schema, value));
	const _onBlur: any = ({
		target: { value },
	}: React.FocusEvent<HTMLInputElement>) =>
		onBlur(id, processValue(schema, value));
	const _onFocus: any = ({
		target: { value },
	}: React.FocusEvent<HTMLInputElement>) =>
		onFocus(id, processValue(schema, value));

	return (
		<FormControl
			fullWidth={true}
			//error={!!rawErrors}
			required={required}
		>
			<InputLabel shrink={true} htmlFor={id}>
				{label ?? schema.title}
			</InputLabel>
			{schema.description && schema.description.length && (
				<CustomDescriptionField description={schema.description} />
			)}
			{multiple ? (
				<Select
					multiple={typeof multiple === 'undefined' ? false : multiple}
					value={typeof value === 'undefined' ? emptyValue : value}
					required={required}
					disabled={disabled || readonly}
					autoFocus={autofocus}
					onChange={_onChange}
					onBlur={_onBlur}
					onFocus={_onFocus}
				>
					{(enumOptions as any).map(({ value, label }: any, i: number) => {
						const disabled: any =
							enumDisabled && (enumDisabled as any).indexOf(value) != -1;
						return (
							<MenuItem key={i} value={value} disabled={disabled}>
								{label}
							</MenuItem>
						);
					})}
				</Select>
			) : (
				<NativeSelect
					value={typeof value === 'undefined' ? emptyValue : value}
					required={required}
					disabled={disabled || readonly}
					autoFocus={autofocus}
					onChange={_onChange}
					onBlur={_onBlur}
					onFocus={_onFocus}
				>
					<option key={'empty'} value={emptyValue} disabled={disabled} />
					{(enumOptions as any).map(({ value, label }: any, i: number) => {
						const disabled: any =
							enumDisabled && (enumDisabled as any).indexOf(value) != -1;
						return (
							<option key={i} value={value} disabled={disabled}>
								{label}
							</option>
						);
					})}
				</NativeSelect>
			)}
		</FormControl>
	);
};
