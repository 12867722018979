import { Types } from 'mongoose';

import { AISCAN_DEV_DATA_MODE } from './aiscan';
import { IBilling } from './billing';
import { Feature } from './feature';
import { IStorageProviderConfiguration } from './storage';
import { EXTERNAL_VENDORS } from './vendors';

export enum Language {
	English = 'en',
	Chinese = 'zh',
	Chinese_TRADITIONAL = 'zh_traditional',
}

export const LANGUAGE_TRANSLATION = {
	[Language.English]: 'English',
	[Language.Chinese]: 'Chinese',
	[Language.Chinese_TRADITIONAL]: 'Traditional Chinese',
};

export interface IEmailContentConfig {
	enabled: boolean;
	subject: string;
	template: string;
}

export interface IEmailConfig {
	templatesFolder: string;
	fromEmailSender: string;
	emailSenderName: string;
	supportEmail: string;
	styles: {
		mainTextColor: string;
		secondaryTextColor: string;
		headerBackgroundColor: string;
		footerBackgroundColor: string;
		footerTextColor: string;
		footerLinkColor: string;
		actionButtonColor: string;
		actionButtonTextColor: string;
	};
	to: string;
	emailContent:
		| Map<string, IEmailContentConfig>
		| Record<string, IEmailContentConfig>;
}

export interface IEmailTemplateConfig extends IEmailConfig {
	managementUrl: string;
	portalUrl: string;
	apiUrl: string;
}

export interface IApplicationStatusWebhook {
	key: string;
	statuses: number[];
	url: string;
}

export interface IScopeWebhooks {
	defaultKey: string;
	endpoints: IApplicationStatusWebhook[];
}

export interface IRiskScoringConfig {
	enabled: boolean;
	corporateEnabled: boolean;
}

export interface IDatazooConfig {
	enabled: boolean;
	ignoreCountries: string[];
}

export interface IDataChecksOptions {
	[EXTERNAL_VENDORS.COMPLY_ADVANTAGE]?: {
		enabled: boolean;
		fuzziness: number;
		ignoreNationalities: string[];
		extraParams: string[];
	};
	[EXTERNAL_VENDORS.SCRAPER]?: {
		enabled: boolean;
		ignoreNationalities: string[];
		extraParams: string[];
	};
	[EXTERNAL_VENDORS.BREXIO]?: {
		enabled: boolean;
		ignoreNationalities: string[];
		documentOrderEnabled: boolean;
	};
}

export interface IScopeFeature {
	enabled?: boolean;
	individualEnabled?: boolean;
	corporateEnabled?: boolean;
}

export interface IScopeMessagesFeature extends IScopeFeature {
	greetingMessage?: string;
	greetingMessageEnabled?: boolean;
}

export interface IScopeDocVerFeature extends IScopeFeature {
	ignoreNationalities: string[];
	uiFlowEnabled: boolean;
	maxTriesAllowed: number;
	devDataMode?: AISCAN_DEV_DATA_MODE;
	backsideScanningEnabled?: boolean;
}

export enum ScopeStatus {
	Active = 'ACTIVE',
	PartiallyActive = 'PARTIALLY_ACTIVE',
	ViewOnly = 'VIEW_ONLY',
	Disabled = 'DISABLED',
}

export enum LogoType {
	APP_LOGO = 'appLogo',
	EMAIL_LOGO = 'emailLogo',
	FAVICON_LOGO = 'faviconLogo',
}

export enum DateTimeFormat {
	DEFAULT = 'DEFAULT',
	RFC2822 = 'RFC2822',
}
export interface IDateFormat {
	DATE_FORMAT: DateTimeFormat;
	DATE_MONTH_3_LETTERS_NO_COMMA: string;
	DATE_MONTH_3_LETTERS_COMMA: string;
	DATE_MONTH_2_NUMBERS_SLASH: string;
	DATE_NO_LEADING_ZEROS_MONTH_3_LETTERS_NO_COMMA: string;
	DATE_FULL_MONTH_NO_COMMA: string;
	DATE_ORDINAL_3_LETTERS_COMMAS: string;
}

export interface ITimeFormat {
	TIME_FORMAT: DateTimeFormat;
	TIME_HOUR_MIN: string;
	TIME_HOUR_MIN_SEC: string;
}
export const DefaultDateFormat: IDateFormat = {
	DATE_FORMAT: DateTimeFormat.DEFAULT,
	DATE_MONTH_3_LETTERS_NO_COMMA: 'MMM dd yyyy',
	DATE_MONTH_3_LETTERS_COMMA: 'MMM dd, yyyy',
	DATE_MONTH_2_NUMBERS_SLASH: 'MM/dd/yyyy',
	DATE_NO_LEADING_ZEROS_MONTH_3_LETTERS_NO_COMMA: 'MMM d yyyy',
	DATE_FULL_MONTH_NO_COMMA: 'MMMM dd yyyy',
	DATE_ORDINAL_3_LETTERS_COMMAS: 'MMM do, yyyy',
};
export const DefaultTimeFormat: ITimeFormat = {
	TIME_FORMAT: DateTimeFormat.DEFAULT,
	TIME_HOUR_MIN: 'hh:mm a',
	TIME_HOUR_MIN_SEC: 'hh:mm:ss a',
};
export const Rfc2822DateFormat: IDateFormat = {
	DATE_FORMAT: DateTimeFormat.RFC2822,
	DATE_MONTH_3_LETTERS_NO_COMMA: 'dd MMM yyyy',
	DATE_MONTH_3_LETTERS_COMMA: 'dd MMM, yyyy',
	DATE_MONTH_2_NUMBERS_SLASH: 'dd/MM/yyyy',
	DATE_NO_LEADING_ZEROS_MONTH_3_LETTERS_NO_COMMA: 'd MMM yyyy',
	DATE_FULL_MONTH_NO_COMMA: 'dd MMMM yyyy',
	DATE_ORDINAL_3_LETTERS_COMMAS: 'do MMM, yyyy',
};
export const Rfc2822TimeFormat: ITimeFormat = {
	TIME_FORMAT: DateTimeFormat.RFC2822,
	TIME_HOUR_MIN: 'HH:mm',
	TIME_HOUR_MIN_SEC: 'HH:mm:ss',
};
export const VALID_DATE_TIME_FORMATS = {
	DATE_DEFAULT: DefaultDateFormat,
	TIME_DEFAULT: DefaultTimeFormat,
	DATE_RFC2822: Rfc2822DateFormat,
	TIME_RFC2822: Rfc2822TimeFormat,
};

export interface IScope {
	_id: Types.ObjectId | string;
	apiUrl: string;
	applicationStatusWebhooks: IScopeWebhooks;
	billing: IBilling;
	contract: {
		active: boolean;
		startDate: Date;
	};
	createdAt: Date;
	corporateSignUpTemplate: Types.ObjectId;
	displayName: string;
	emailConfig: IEmailConfig;
	hosts: string[];
	path?: string;
	settings: {
		hidePoweredBySelfkey: boolean;
		logoUrl: string;
		faviconUrl: string;
		facebookLink: string;
		twitterLink: string;
		linkedInLink: string;
		emailLogoUrl: string;
		countryListUrl: string;
		walletConnectId: string;
		customerPortalAuthMethod: 'cookie' | 'jwt';
		customAttributeRepositories: string[];
		disableEmailAccessToken: boolean;
		disableApplicationListing: boolean;
		features: {
			[key: string]: any;
			[Feature.ComplyAdvantage]: IDataChecksOptions[EXTERNAL_VENDORS.COMPLY_ADVANTAGE];
			[Feature.BrexIO]: IDataChecksOptions[EXTERNAL_VENDORS.BREXIO];
			[Feature.Scraper]: IDataChecksOptions[EXTERNAL_VENDORS.SCRAPER];
			[Feature.Datazoo]: IDatazooConfig;
			[Feature.WalletScreening]: IScopeFeature;
			[Feature.XmasTree]: IScopeFeature;
			[Feature.ManagerFill]: IScopeFeature;
			[Feature.MultiScope]: IScopeFeature;
			[Feature.MultiApproval]: IScopeFeature;
			[Feature.Monitoring]: IScopeFeature;
			[Feature.KycRefresh]: IScopeFeature;
			[Feature.DocumentExpiryMonitoring]: IScopeFeature;
			[Feature.RiskScoring]: IScopeFeature;
			[Feature.TokenList]: IScopeFeature & {
				invitationExpiryStart: number;
				accessTokenExpiryStart: number;
			};
			[Feature.PasswordlessFlow]: IScopeFeature;
			[Feature.Reminders]: IScopeFeature & {
				ignoreAfterDays?: number; // maximum number of days since last application update
				limit?: number;
				rate?: number;
			};
			[Feature.Messages]: IScopeMessagesFeature;
			[Feature.IDScan]: IScopeDocVerFeature;
			[Feature.AIScan]: IScopeDocVerFeature & {
				devDataMode: AISCAN_DEV_DATA_MODE;
				backsideScanningEnabled: boolean;
			};
			[Feature.Languages]: { enabled: string[] };
			[Feature.ControlManagerActions]: IScopeFeature;
			[Feature.TwoFactorAuthRequired]: IScopeFeature;
			[Feature.GeneratePdfReports]: IScopeFeature;
		};
		loginWithWallet: boolean;
		loginWithEmail: boolean;
		loginRecaptcha: boolean;
		idScanIFrameRedirectUrl: string;
		idScanIFrameRedirectUrlFailure: string;
		maxDefaultUploads: number;
		formattingPreferences: {
			dateFormat: DateTimeFormat;
			timeFormat: DateTimeFormat;
		};
	};
	logoutUrl: string;
	managementUrl: string;
	name: string;
	portalUrl: string;
	externalPortalUrl: string;
	signUpTemplate: Types.ObjectId;
	status: ScopeStatus;
	storageConfig: IStorageProviderConfiguration;
	updatedAt: Date;
}
