import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { IApplication } from '../../../../../common-ts/src/interface/application';
import { getItemKeyFromSchema } from '../../../../../common/src/helpers/get-item-key-from-schema';
import { AssociatedFile } from '../../../../../common/src/models/associated-file';
import { uploadFiles } from '../../../../../common/src/models/files';
import {
	UploadDocumentDialog,
	getWidgetTypeMap,
} from '../../../../../common/src/react/UploadDocumentDialog';
import { FileUploadContext } from '../../../../../common/src/react/UploadDocumentDialog/FileUploadContext';
import {
	loadCurrentApplication,
	setCurrentApplicationData,
} from '../../../redux/actions/application';
import { RootState } from '../../../redux/reducers';
import { clearAttribute, updateAttribute } from '../../../services/api';
import { isStatusError } from '../../Helpers';
import { UploadPageItem } from './UploadPageItem';

export interface Requirement {
	properties: Record<string, any>;
	weight: number;
}

export interface Props {
	application: IApplication;
	isMemberApplication?: boolean;
	requirement: Requirement;
	fileNames: AssociatedFile[];
	onApplicationUpdate?: (app: IApplication) => void;
}

export const FileUpload: React.FC<Props> = (props) => {
	const { requirement } = props;

	const { t } = useTranslation();

	const widgetTypeMap = React.useMemo(() => getWidgetTypeMap(), []);

	const propertyId = Object.keys(requirement.properties)[0];
	const mainProperty = requirement.properties[propertyId];
	const properties = requirement.properties[propertyId].properties;
	const dispatch = useDispatch();

	const application = useSelector((root: RootState) =>
		props.isMemberApplication
			? props.application
			: root.applicationStore.currentApplication
	);
	const dispatchApplicationUpdate = props.isMemberApplication
		? props.onApplicationUpdate
		: (application: IApplication) => {
				dispatch(setCurrentApplicationData(application));
		  };

	const defaultContext: any = {
		fields: [],
		files: [],
	};

	const [state, setState] = useState(defaultContext);
	const [widgetDialogOpen, setWidgetDialogOpen] = useState(false);

	const onUploadDialogClose = (updatedApplication: IApplication = null) => {
		setWidgetDialogOpen(false);
		if (updatedApplication) {
			dispatchApplicationUpdate(updatedApplication);
		}
	};

	// populate context from existing data
	const refreshContext = () => {
		const context: any = {
			fields: [],
			files: [],
		};

		const value = mainProperty.value;

		if (value) {
			if (!Array.isArray(value)) {
				context.files = [].concat.apply(
					[],
					Object.keys(value)
						.filter(
							(key) =>
								typeof value[key] !== 'string' && !Array.isArray(value[key])
						)
						.map((key) => {
							const val: any =
								key !== 'selfie'
									? { file: value[key] }
									: { file: value[key].image };
							val.itemKey = key;
							val.schema = properties[key].$id;
							val.label = properties[key].title;
							return val;
						})
				);

				// multi uploads for a property
				context.files = [].concat(
					context.files,
					[].concat.apply(
						[],
						Object.keys(value)
							.filter(
								(key) =>
									typeof value[key] !== 'string' && Array.isArray(value[key])
							)
							.map((key) =>
								value[key].map((v: any) => ({
									file: v,
									itemKey: key,
									label: properties[key].title,
									schema: properties[key].$id,
								}))
							)
					)
				);
			} else {
				context.files = [].concat.apply(
					[],
					value.map((v) => ({
						file: v,
						itemKey: getItemKeyFromSchema(mainProperty.items.$id),
					}))
				);
			}

			context.fields = Object.keys(value)
				.filter((key) => typeof value[key] === 'string')
				.map((key) => ({
					itemKey: key,
					value: value[key],
				}));
		}
		setState(context);
	};

	const widgets =
		mainProperty.type !== 'array'
			? Object.keys(properties)
					// display only required filds; ignore others for now
					.filter((key) => mainProperty.required.includes(key))
					// sort uploads first
					.sort((a, b) => {
						const aProperty = properties[a];
						const bProperty = properties[b];
						const aSort = widgetTypeMap[aProperty.$id]
							? widgetTypeMap[aProperty.$id].sorting
							: 3;
						const bSort = widgetTypeMap[bProperty.$id]
							? widgetTypeMap[bProperty.$id].sorting
							: 3;
						return aSort - bSort;
					})
					.map((key) => {
						const property = properties[key];
						return {
							id: propertyId,
							itemKey: key,
							schema: property.$id,
							title: property.title,
						};
					})
			: [
					{
						id: propertyId,
						itemKey: getItemKeyFromSchema(mainProperty.items.$id),
						multiple: true,
						schema: mainProperty.items.$id,
						title: mainProperty.items.title,
					},
			  ];

	useEffect(() => {
		refreshContext();
	}, [props.requirement]);

	return (
		<FileUploadContext.Provider value={[state, setState]}>
			{widgetDialogOpen && (
				<UploadDocumentDialog
					applicationId={application.id}
					key={propertyId}
					propertyId={propertyId}
					onClose={onUploadDialogClose}
					widgets={widgets}
					open={widgetDialogOpen}
					title={mainProperty.title}
					description={mainProperty.description}
					mainProperty={mainProperty}
					fileNames={props.fileNames}
					onClearAttribute={clearAttribute}
					onUploadFiles={uploadFiles}
					onUpdateAttribute={updateAttribute}
					refreshApplication={(err) => {
						if (isStatusError(err)) {
							dispatch(loadCurrentApplication());
						}
					}}
				/>
			)}

			<UploadPageItem
				fileNames={props.fileNames}
				files={state.files}
				{...mainProperty}
			>
				<Button
					color="secondary"
					aria-label="menu"
					onClick={() => setWidgetDialogOpen(true)}
				>
					<Grid
						container
						justify="center"
						alignItems="center"
						direction="column"
					>
						<PublishIcon />

						<Typography variant="body2" className="bold" gutterBottom>
							{t(`fileUploadDialog.upload`)}
						</Typography>
					</Grid>
				</Button>
			</UploadPageItem>
		</FileUploadContext.Provider>
	);
};
