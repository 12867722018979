import { makeStyles } from '@material-ui/core/styles';

import { error, success } from '../../../../../../ui/src/colors';

export const useStyles = makeStyles((theme) => ({
	bold: {
		fontWeight: 'bold',
	},
	continueButton: {
		marginTop: '10px',
		textTransform: 'uppercase',
		letterSpacing: '1.5px',
	},
	failedColor: {
		color: error,
	},
	resultArea: {
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	resultDisplayText: {
		paddingLeft: theme.spacing(3),
	},
	resultIcon: {
		marginRight: '5px',
	},
	resultText: {
		fontWeight: 'bold',
	},
	successColor: {
		color: success,
	},
	topSpace: {
		marginTop: theme.spacing(4),
	},
	qrCodeContainer: {
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '15px',
		marginTop: '10px',
	},
	aiScanErrorList: {
		backgroundColor: theme.palette.background.paper,
		paddingLeft: '10px',
		'@media (max-width: 780px)': {
			paddingLeft: '0',
		},
	},
	aiScanErrorListItem: {
		marginBottom: 0,
	},
	aiScanErrorListItemText: {
		color: error,
	},
	aiScanErrorListTitle: {
		padding: '10px 25px',
		fontSize: '18px',
	},
}));
