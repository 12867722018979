import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { captureException } from '@sentry/react';
import { cloneDeep } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { IApplication } from '../../../../../common-ts/src/interface/application';
import { IFileSchema } from '../../../../../common-ts/src/interface/file';
import {
	IFormSubmission,
	IKycDocumentTemplate,
} from '../../../../../common-ts/src/interface/form';
import { triggerFileDownload } from '../../../../../common/src/helpers/triggerFileDownload';
import { IAttributeRequirement } from '../../../../../common/src/models/attribute-requirement';
import {
	IFile,
	downloadFile,
	uploadFiles,
} from '../../../../../common/src/models/files';
import { error, tertiary } from '../../../../../ui/src/colors';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import { setCurrentApplicationData } from '../../../redux/actions/application';
import { RootState } from '../../../redux/reducers';
import {
	AssociatedFile,
	getAssociatedFiles,
	submitOffSystemForm,
} from '../../../services/api';
import { FormDocument } from '../Forms';
import { SectionNavigation } from '../KYCLayout/Section';
import { FileUpload, Requirement } from './FileUpload';

const useStyles = makeStyles({
	bottomSpace: {
		marginBottom: '35px',
	},
	captionWrap: {
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
	documentsTable: {
		border: '2px solid #B9C2CC',
		borderRadius: '4px',
		boxSizing: 'border-box',
		padding: '20px',
	},
	errorColor: {
		color: error,
	},
	fileArea: {
		'@media (max-width: 600px)': {
			flexDirection: 'column',
		},
		display: 'flex',
		flexFlow: 'row wrap',
	},
	fileItem: {
		'@media (max-width: 600px)': {
			marginBottom: '10px',
			marginTop: '5px',
			width: '100%',
		},
		alignItems: 'center',
		backgroundColor: '#EDEDF4',
		borderRadius: '4px',
		boxSizing: 'border-box',
		display: 'flex',
		height: '37px',
		justifyContent: 'space-between',
		marginBottom: '10px',
		marginRight: '10px',
		padding: '8px 0 8px 12px',
		width: '266px',
	},
	fileItemIconButton: {
		padding: 10,
	},
	fileItemInput: {
		flex: 1,
		marginLeft: '8px',
	},
	fileItemRoot: {
		alignItems: 'center',
		display: 'flex',
		padding: '8px',
	},
	fileItemWrap: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	menuItemIcon: {
		marginRight: '5px',
	},
	overflowProtection: {
		maxWidth: '210px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	root: {
		marginBottom: '16px',
		padding: '16px',
	},
	semibold: {
		fontWeight: 500,
	},
	tableHeader: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	tableHeaderIcons: {
		'@media (max-width: 600px)': {
			minWidth: '100px',
		},
		alignItems: 'flex-start',
		display: 'flex',
		justifyContent: 'flex-end',
		minWidth: '250px',
	},
	tableWrap: {
		'& .documents-table-header': {
			marginBottom: '5px',
		},
		marginBottom: '20px',
	},
	tertiaryColor: {
		color: tertiary,
	},
});

export interface Props {
	title?: string;
	context?: any;
	isLastStep?: boolean;
	forms?: IFormSubmission[];
	requirements: IAttributeRequirement;
	isMemberApplication?: boolean;
	application?: IApplication;
	onApplicationUpdate?: (app: IApplication) => void;
	nextCallback?: (selectedNationality?: string) => void;
	prevCallback?: () => void;
	hasPrevSection?: () => boolean;
}

export const FileUploads: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const [loading, setLoading] = React.useState(false);
	const [requirements, setRequirements] = React.useState<Requirement[]>([]);
	const [errorMessage, setError] = React.useState(null);
	const [fileNames, setFileNames] = React.useState<AssociatedFile[]>([]);
	const dispatch = useDispatch();
	const application = useSelector((root: RootState) =>
		props.isMemberApplication
			? props.application
			: root.applicationStore.currentApplication
	);
	const dispatchApplication = props.isMemberApplication
		? props.onApplicationUpdate
		: (application: IApplication) => {
				dispatch(setCurrentApplicationData(application));
		  };

	const refresh = () => {
		// get files associated with this application in order to display file names
		getAssociatedFiles(application.id)
			.then((files) => {
				setFileNames(files);
				// break each upload into separate form
				const r = Object.entries(props.requirements.properties)
					.map(([key, value]) => ({
						properties: { [key]: value },
						weight: value.weight as number,
					}))
					.sort((a, b) => a.weight - b.weight);

				setRequirements(r);
			})
			.catch(() => {
				setError('errors.load-other');
			});
	};

	const downloadBlankForm = (form: IKycDocumentTemplate) => {
		const file = form.files[0] as IFileSchema;
		downloadFile(file._id as string).then((res) => {
			triggerFileDownload(res, file.contentType, file.fileName);
		});
	};

	/*
	const openFormFillDialog = (formRequirement: any) => {
		setFormFillOpen(true);
		setCurrentForm(formRequirement);
	};

	const onFormFillDialogClose = () => {
		setCurrentForm(null);
		setFormFillOpen(false);
	};
	 */

	const onFormUploadChange = async (
		formRequirement: IFormSubmission,
		file: File
	) => {
		setLoading(true);
		setError(null);
		try {
			const param = {
				file,
				name: (formRequirement.form as IKycDocumentTemplate).name,
			};
			const res = (await uploadFiles([param])) as IFile;
			const formRes = await submitOffSystemForm(
				application.id,
				formRequirement._id as string,
				res.id
			);

			// replace and update application
			const updatedApplication = cloneDeep(application);
			const existingIndex = (
				updatedApplication.forms as IFormSubmission[]
			).findIndex((s) => s._id === formRes._id);
			if (existingIndex > -1) {
				updatedApplication.forms[existingIndex] = formRes;
			} else {
				(updatedApplication.forms as IFormSubmission[]).push(formRes);
			}
			dispatchApplication(updatedApplication);
		} catch (err) {
			const e = err && err.response ? err.response : err;
			const ex = err && err.response ? new Error('onFormUploadChange') : err;
			captureException(ex, {
				contexts: {
					application: {
						id: application.id,
					},
					error: e,
					function: {
						name: 'onFormUploadChange',
					},
				},
				tags: {
					section: 'uploads',
				},
			});
			if (err.response.status === 422) {
				setError('errors.upload-422');
			} else if (err.response.status === 500) {
				setError('errors.upload-500');
			} else {
				setError('errors.upload-other');
			}
			return;
		}
		setLoading(false);
	};

	// TODO on system fill
	/*
	const submitForm = (fieldValues: {[id: string]: string}) => {
		console.log('fieldValues', fieldValues);
		const formData =
			Object.keys(fieldValues).length > 0 ? { formData: fieldValues } : null;
		console.log('formData', formData);
	};

	const formPageDownloadFunc = (preview: IPreview, abort: any): Promise<ArrayBuffer> =>
		getFormPage(currentForm.form._id, preview.page);
	*/

	React.useEffect(() => {
		refresh();
	}, [props.requirements]);

	return (
		<CustomerPortalTheme>
			<Typography align="left" variant="h1" className={classes.bottomSpace}>
				{t('sections.documents')}
			</Typography>

			{errorMessage && (
				<Typography gutterBottom={true} variant="subtitle1" color="error">
					{t(errorMessage)}
				</Typography>
			)}

			{/* props.forms.length && formFillOpen && (
				<FormFillDialog
					downloadFunc={formPageDownloadFunc}
					open={formFillOpen}
					title={"title"}
					description={"description"}
					onClose={onFormFillDialogClose}
					onSubmitClick={submitForm}
					formRequirement={currentForm}
				/>
			) */}

			{requirements.map((r, index) => (
				<FileUpload
					application={application}
					fileNames={fileNames}
					onApplicationUpdate={props.onApplicationUpdate}
					isMemberApplication={props.isMemberApplication}
					key={index}
					requirement={r}
				/>
			))}

			{props.forms
				.sort((a, b) => a.weight - b.weight)
				.map((formRequirement, index) => (
					<FormDocument
						key={formRequirement._id as string}
						formRequirement={formRequirement}
						downloadBlankForm={downloadBlankForm}
						// openFormFillDialog={openFormFillDialog}
						onFormUploadChange={onFormUploadChange}
					/>
				))}

			<SectionNavigation
				nextButtonProps={{ disabled: loading }}
				nextCallback={props.nextCallback}
				loading={loading}
				prevCallback={props.prevCallback}
				nextButtonText={props.isLastStep ? 'sections.submit' : null}
			/>
		</CustomerPortalTheme>
	);
};
