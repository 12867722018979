import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { tertiary } from '../../../../../ui/src/colors';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';

interface ITitleAreaProps {
	title: string;
	description: string;
	xtraClass?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	description: {
		color: tertiary,
		whiteSpace: 'pre-line',
	},
}));

export const TitleArea: React.FC<ITitleAreaProps> = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<CustomerPortalTheme>
			<Typography align="left" variant="h1" gutterBottom>
				{t(props.title)}
			</Typography>
			<Typography
				variant="subtitle1"
				className={`${classes.description} ${t(props.xtraClass)}`}
				gutterBottom
			>
				{t(props.description)}
			</Typography>
		</CustomerPortalTheme>
	);
};
