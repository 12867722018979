import { Reducer } from 'redux';
import { ITemplateSimple } from '../../../../common-ts/src/interface/template';
import { ITemplateActions, TemplateActions } from '../actions/templates';

export interface ITemplateState {
	templates: ITemplateSimple[];
	signupTemplate: ITemplateSimple;
	loading: boolean; // templates are currently loading
	loaded: boolean; // templates are initially loaded
}

const initialState: ITemplateState = {
	loaded: false,
	loading: false,
	signupTemplate: null,
	templates: [],
};

export const templateReducer: Reducer<ITemplateState, ITemplateActions> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case TemplateActions.FETCH_SIGNUP_TEMPLATE:
			return { ...state, loading: true };
		case TemplateActions.RECEIVE_SIGNUP_TEMPLATE:
			return {
				...state,
				loading: false,
				signupTemplate: action.signupTemplate,
			};
		case TemplateActions.FETCH_TEMPLATES:
			return { ...state, loading: true };
		case TemplateActions.RECEIVE_TEMPLATES:
			return {
				...state,
				loaded: true,
				loading: false,
				templates: action.templates,
			};
		case TemplateActions.API_ERROR:
			return { ...state, loading: false, error: action.error };
		default:
			return state;
	}
};
