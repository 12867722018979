import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	container: { position: 'relative' },
	overlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'white',
		zIndex: 10,
	},
});
