import * as React from 'react';

import Grid from '@material-ui/core/Grid';

import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import {
	BlockquoteButton,
	BoldButton,
	CodeBlockButton,
	CodeButton,
	ItalicButton,
	OrderedListButton,
	UnderlineButton,
	UnorderedListButton
} from 'draft-js-buttons';
import EditorComponent from 'draft-js-plugins-editor';

// No types available

// tslint:disable-next-line: no-var-requires
const { draftToMarkdown, markdownToDraft } = require('markdown-draft-js');

// tslint:disable-next-line: no-var-requires
const createInlineToolbarPlugin = require('draft-js-inline-toolbar-plugin')
	.default;
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';

// tslint:disable-next-line: no-var-requires
import 'draft-js/dist/Draft.css';

// tslint:disable-next-line: no-var-requires
const Separator = require('draft-js-inline-toolbar-plugin').Separator;

const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;

import { makeStyles, Theme } from '@material-ui/core/styles';

const fromMarkdown = (text: string) =>
	EditorState.createWithContent(convertFromRaw(markdownToDraft(text)));
const toMarkdown = (editorState: EditorState) =>
	draftToMarkdown(convertToRaw(editorState.getCurrentContent()));

export { toMarkdown, fromMarkdown, EditorState };

const editorStyles = makeStyles((theme: Theme) => ({
	counter: {
		alignSelf: 'end',
		marginTop: theme.spacing(-1),
		padding: theme.spacing()
	},
	editor: {
		'& button': {
			textAlign: 'center'
		},
		background: '#F8F8FC',
		border: '2px solid #D5D5E3',
		borderRadius: '2px',
		boxSizing: 'border-box' as any,
		cursor: 'text',
		flexGrow: 1,
		fontSize: '14px',
		height: '80px',
		lineHeight: '20px',
		maxWidth: '100%',
		overflow: 'auto',
		padding: '16px'
	},
	editorDisabled: {
		background: '#e4e4e4'
	},
	root: {
		alignItems: 'stretch' as any,
		display: 'flex',
		flexDirection: 'column' as any
	}
}));

interface IEditorProps {
	OnEditorState: (newState: EditorState) => void;
	editorState: EditorState;

	placeholderText?: string;
	loading?: boolean;
	readOnly?: boolean;
	disabled?: boolean;
}
const Editor = (props: IEditorProps) => {
	const classes = editorStyles();

	const {
		disabled,
		editorState,
		loading,
		OnEditorState,
		placeholderText,
		readOnly
	} = props;

	const plugins = [inlineToolbarPlugin].filter(Boolean);

	const editorRef = React.useRef(null);

	const focus = () => {
		editorRef.current.focus();
	};

	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			className={classes.root}
		>
			<Grid
				item
				className={`${classes.editor} ${
					disabled ? classes.editorDisabled : ''
				}`}
				onClick={focus}
			>
				<EditorComponent
					stripPastedStyles
					placeholder={placeholderText}
					editorState={editorState}
					onChange={newEditorState => OnEditorState(newEditorState)}
					plugins={plugins}
					ref={editorRef}
					readOnly={loading || readOnly || disabled}
					spellCheck
				/>
				<InlineToolbar>
					{(externalProps: any) => (
						<>
							<BoldButton {...externalProps} />
							<ItalicButton {...externalProps} />
							<UnderlineButton {...externalProps} />
							<CodeButton {...externalProps} />
							<Separator {...externalProps} />
							<UnorderedListButton {...externalProps} />
							<OrderedListButton {...externalProps} />
							<BlockquoteButton {...externalProps} />
							<CodeBlockButton {...externalProps} />
						</>
					)}
				</InlineToolbar>
			</Grid>
		</Grid>
	);
};

export default Editor;
