import { Link as MaterialLink } from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Typography from '@material-ui/core/Typography';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { goToPrivacyPolicy } from '../../../../../common/src/react/Login';
import { PasswordForm } from '../../../../../common/src/react/Login/setPassword';
import { tertiary } from '../../../../../ui/src/colors';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import { getMySession } from '../../../redux/actions/user';
import { setPassword } from '../../../services/api';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { TitleArea } from './TitleArea';

export const ChangePasswordForm: React.FC<{}> = (props) => {
	const { t } = useTranslation();
	const history = useHistory();

	const [displayError, setDisplayError] = useState(false);
	const [displaySuccess, setDispaySuccess] = useState(false);

	const onCancelClick = () => {
		history.goBack();
	};

	const onSubmit = (password: string) => {
		return setPassword(password)
			.then(() => setDispaySuccess(true))
			.catch(() => setDisplayError(true));
	};

	return (
		<CustomerPortalTheme>
			{displaySuccess ? (
				<Typography
					component="p"
					color="primary"
					align="left"
					variant="caption"
					gutterBottom={true}
				>
					{t('set-password.success')}
				</Typography>
			) : null}
			{displayError ? (
				<Typography
					component="p"
					color="error"
					align="left"
					variant="caption"
					gutterBottom={true}
				>
					{t('set-password.error')}
				</Typography>
			) : null}
			<PasswordForm
				displayCancel={true}
				displayRetype={true}
				onCancelClick={onCancelClick}
				submit={onSubmit}
				formTitle={'set-password.password'}
				setButtonText={'set-password.set-password'}
			/>
		</CustomerPortalTheme>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	privacyPolicy: {
		color: tertiary,
		whiteSpace: 'pre-line',
		marginTop: '1em',
	},
}));

export const SetPassword: React.FC<{}> = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();

	const [errorMessage, setErrorMessage] = useState('');

	const submit = (password: string) => {
		setErrorMessage('');
		return setPassword(password)
			.then(() => dispatch(getMySession()))
			.catch(() => setErrorMessage('set-password.error'));
	};

	return (
		<CustomerPortalTheme>
			<LanguageSwitcher />
			<TitleArea
				title={t('set-password.title')}
				description={t('set-password.description')}
			/>
			{errorMessage.length ? (
				<Typography
					component="p"
					color="error"
					align="left"
					variant="caption"
					gutterBottom={true}
				>
					{t(errorMessage)}
				</Typography>
			) : null}
			<PasswordForm
				displayCancel={false}
				submit={submit}
				setButtonProps={{ fullWidth: true, size: 'large' }}
				formTitle={'set-password.password'}
				setButtonText={'set-password.set-password'}
			/>
			<Typography
				className={classes.privacyPolicy}
				variant="subtitle2"
				gutterBottom
			>
				{t('login.privacy-notice')}
				<MaterialLink
					color="inherit"
					underline="hover"
					style={{ cursor: 'pointer', marginLeft: '4px' }}
					onClick={goToPrivacyPolicy}
				>
					{t('login.privacy-notice-link')}
				</MaterialLink>
				.
			</Typography>
			<BottomNavigation className="bottomNavigation">
				<BottomNavigationAction
					showLabel={true}
					component={Link}
					to="/auth/login"
					label={t('set-password.sign-in')}
				/>
			</BottomNavigation>
		</CustomerPortalTheme>
	);
};
