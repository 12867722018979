import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useState } from 'react';

import { IPreview } from '../../helpers/ImageLoader/ImageLoader';
import { FileStatusView } from '../Preview/file-status-view';
import FilePreview from './file-preview';
import { PDFPreviewV2 } from './pdf-preview-v2';

export interface IFilePreviewProps {
	content: string;
	mimeType: string;
	canDownload: boolean;
	useManagementAPI?: boolean;
	scopeId?: string;
	downloadFunc?: (preview: IPreview, abort: any) => Promise<ArrayBuffer>; // function to download image from API
}

const useStyles = makeStyles({
	previewImage: {
		width: '100%',
	},
});

export const FilePreviewV2 = (props: IFilePreviewProps) => {
	const classes = useStyles();
	const filePath =
		props.useManagementAPI && props.scopeId
			? `/integrations/v3/scope/${props.scopeId}/files`
			: '/api/v2/files';
	const [loading, setLoading] = useState(true);
	const [hasError, setHasError] = useState(false);

	const onLoad = () => {
		setLoading(false);
	};

	const onError = () => {
		setLoading(false);
		setHasError(true);
	};

	if (hasError) {
		return (
			<FileStatusView message={'fileStatus.genericError'} type={'error'} />
		);
	}

	return (
		<>
			{(() => {
				switch (props.mimeType) {
					case 'application/pdf':
						return (
							<PDFPreviewV2
								fileId={props.content}
								canDownload={props.canDownload}
							/>
						);
					case 'image/png':
					case 'image/jpg':
					case 'image/jpeg':
					default:
						return (
							<>
								{loading && !props.downloadFunc && (
									<Skeleton variant="rect" height="300px" width="100%" />
								)}
								{!props.downloadFunc ? (
									<img
										className={classes.previewImage}
										src={`${filePath}/${props.content}`}
										key={props.content}
										onLoad={onLoad}
										onError={onError}
									/>
								) : (
									<FilePreview
										downloadFunc={props.downloadFunc}
										file={{ _id: props.content, contentType: props.mimeType }}
										canDownload={props.canDownload}
									/>
								)}
							</>
						);
				}
			})()}
		</>
	);
};
