import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { ErrorMessage } from './../../../../../common/src/react/Errors';

interface IErrorSnackBarProps {
	message: string;
	handleClose?: () => void;
	open: boolean;
	variant: string;
}

const useStyles = makeStyles((theme) => ({
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	success: {
		backgroundColor: green[600],
	},
	warning: {
		backgroundColor: amber[700],
	},
}));

export const ErrorSnackBar = (props: IErrorSnackBarProps) => {
	const { t } = useTranslation();
	const classes = useStyles() as any;

	return (
		<Snackbar
			action={
				<IconButton
					size="small"
					aria-label="close"
					color="inherit"
					onClick={props.handleClose}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			key={'top,center'}
			open={props.open}
			onClose={props.handleClose ? props.handleClose : null}
			ContentProps={{
				'aria-describedby': 'message-id',
				className: classes[props.variant],
			}}
			message={<span id="message-id">{t(props.message)}</span>}
		/>
	);
};

export const NotFoundPage: React.FC<{ message: string; title?: string }> = ({
	message,
	title,
}) => {
	const { t } = useTranslation();
	const history = useHistory();

	const goToSignUpPage = () => {
		history.push('/auth/signup');
	};

	return (
		<>
			<ErrorMessage
				title={title ?? t('errors.not-found-title')}
				message={message}
			/>
			<Button
				onClick={goToSignUpPage}
				variant="contained"
				style={{ marginBottom: '20px' }}
			>
				{t('signup.go-to')}
			</Button>
		</>
	);
};
