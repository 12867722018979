import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationsView } from '../../../../common-ts/src/interface/applications-view';

import { useStyles } from './ApplicationsViewSelector.style';

export interface Props {
	value: ApplicationsView;
	onChange: (view: ApplicationsView) => void;
	className?: string;
}

const views = Object.values(ApplicationsView);
const separator = '|';

export const ApplicationsViewSelector: React.FC<Props> = ({
	value,
	onChange,
	className,
}) => {
	const t = useTranslation();
	const styles = useStyles();

	return (
		<div className={classnames(styles.root, className)}>
			{views.map((view, index) => (
				<React.Fragment key={view}>
					{index !== 0 && separator}

					<span
						className={classnames(styles.view, {
							[styles.selected]: value === view,
						})}
						onClick={() => onChange(view)}
					>
						{view}
					</span>
				</React.Fragment>
			))}
		</div>
	);
};
