import Hidden from '@material-ui/core/Hidden';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { greyLighter } from '../../../../../ui/src/colors';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';

interface KYCStep {
	// custom interface to support separate name and title
	name: string;
	title: string;
}

interface StepIndicatorContainerProps {
	activeStep: number;
	steps: KYCStep[];
}

const useStyles = makeStyles({
	activeStep: {
		fontWeight: 'bold !important' as 'bold',
	},
	label: {
		fontWeight: 'bold',
		'& span': {
			maxWidth: '220px',
			wordBreak: 'break-word',
			color: greyLighter,
			fontWeight: 'bold',
		},
	},
	stepper: {
		background: 'none',
		maxWidth: '230px',
	},
});

export const StepIndicatorContainer: React.FC<StepIndicatorContainerProps> = (
	props
) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<CustomerPortalTheme>
			<Hidden smUp>
				<div>
					<p>
						{t('step_indicator.current_step', {
							activeStep: props.activeStep + 1,
							total: props.steps.length,
						})}
					</p>
				</div>
			</Hidden>
			<Hidden only="xs">
				<Stepper
					className={classes.stepper}
					activeStep={props.activeStep}
					orientation="vertical"
				>
					{props.steps.map((step: KYCStep) => {
						return (
							<Step className="kyc-step" key={step.title}>
								<StepLabel
									classes={{
										active: classes.activeStep,
										labelContainer: classes.label,
									}}
								>
									{t(step.title)}
								</StepLabel>
							</Step>
						);
					})}
				</Stepper>
			</Hidden>
		</CustomerPortalTheme>
	);
};

export default StepIndicatorContainer;
