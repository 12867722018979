import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MetamaskIcon } from '../../../helpers/metamaskIcon';
import { WALLET_PROVIDERS } from '../../../helpers/wallets';

interface IWalletSignUpProps {
	buttonClass?: string;
	disabled?: boolean;
	onConnect: (provider: string) => void;
}

const useStyles = makeStyles({
	icon: {
		marginRight: '8px',
	},
	metamaskConnected: {
		marginTop: '25px',
	},
	metamaskIcon: {
		height: '30px',
		position: 'relative',
		top: '10px',
		width: '30px',
	},
});

export const ConnectMetaMask = (props: IWalletSignUpProps) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const handleClick = async (evt: React.MouseEvent<HTMLButtonElement>) => {
		evt.preventDefault();
		props.onConnect(WALLET_PROVIDERS.metamask);
	};

	return (
		<>
			<Button
				className={props.buttonClass}
				onClick={handleClick}
				type="submit"
				disabled={props.disabled || !window.ethereum}
				fullWidth
				size="large"
				variant="contained"
			>
				<MetamaskIcon className={classes.icon} />
				{t('signup.connect-metamask')}
			</Button>
			{!window.ethereum && (
				<Typography variant="subtitle2" align="center">
					<Link
						rel="noopener"
						target="_blank"
						href="https://metamask.io/download.html"
					>
						{t('signup.install-metamask')}
					</Link>
				</Typography>
			)}
		</>
	);
};
