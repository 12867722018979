import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toastify = () => 
	(<ToastContainer
		closeButton={false}
		hideProgressBar={true}
		closeOnClick={true}
		draggable={false}
	/>);

export default Toastify;
