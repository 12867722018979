import {
	IApplicationStatusLog,
	IStatusLogEntry,
} from '../interface/application';
import { KycStatuses } from './constants';

// backwards compatiblity helper
// statusLog to statusLog.event
export const normalizeStatusLog = (
	statuses: IStatusLogEntry[] | IApplicationStatusLog[]
): IApplicationStatusLog[] => {
	if ((statuses[0] as IApplicationStatusLog).event) {
		return statuses as IApplicationStatusLog[];
	}

	return (statuses as IStatusLogEntry[]).map((s) => {
		return {
			code: s.code,
			event: s,
			timestamp: s.timestamp,
		};
	});
};

export const findIndexOfStatus = (
	statuses: IApplicationStatusLog[],
	status: number
) => statuses.findIndex((s) => s.code === status);

export const getStatusSinceLastRefresh = (
	statuses: IApplicationStatusLog[]
) => {
	const refreshIndex = findIndexOfStatus(statuses, KycStatuses.refresh);
	return refreshIndex >= 0 ? statuses[refreshIndex] : statuses[0];
};

export const getStatusesSinceLastReopening = (
	statuses: IApplicationStatusLog[]
) => {
	const reopenedIndex = findIndexOfStatus(statuses, KycStatuses.reopened);
	return reopenedIndex >= 0 ? statuses.slice(0, reopenedIndex) : statuses;
};

export const getStatusesSinceLastReopeningOrRefresh = (
	statuses: IApplicationStatusLog[]
) => {
	const reopenedIndex = findIndexOfStatus(statuses, KycStatuses.reopened);
	const refreshIndex = findIndexOfStatus(statuses, KycStatuses.refresh);

	let index: number;
	if (reopenedIndex >= 0 && refreshIndex >= 0) {
		index = Math.min(reopenedIndex, refreshIndex);
	} else if (reopenedIndex >= 0) {
		index = reopenedIndex;
	} else if (refreshIndex >= 0) {
		index = refreshIndex;
	} else {
		index = -1;
	}
	return index >= 0 ? statuses.slice(0, index) : statuses;
};

export const getStatusesSinceLastSubmission = (
	statuses: IApplicationStatusLog[]
) => {
	const submittedIndex = findIndexOfStatus(statuses, KycStatuses.uploaded);
	return submittedIndex >= 0 ? statuses.slice(0, submittedIndex) : statuses;
};

export const managerHasAlreadyApprovedApplication = (
	statuses: IApplicationStatusLog[],
	userId: string
): boolean =>
	getStatusesSinceLastReopeningOrRefresh(statuses).some(
		(s) =>
			[KycStatuses.partially_approved, KycStatuses.approved].includes(s.code) &&
			(s.event as IStatusLogEntry).user?.toString() === userId
	);
