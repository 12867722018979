import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogAction from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import { makeStyles } from '@material-ui/styles';
import { withTheme } from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/material-ui';
import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IApplication } from '../../../../common-ts/src/interface/application';
import {
	IDatazooAttribute,
	IRepositoryEntry,
} from '../../../../common-ts/src/interface/datazoo';
import CustomerPortalTheme from '../../../../ui/src/theme/kyc-default-theme';
import { CustomAutocompleteWidget } from '../CustomFields/CustomAutocompleteWidget';
import { CustomCheckboxesWidget } from '../CustomFields/CustomCheckboxesWidget';
import { CustomDateTemplate } from '../CustomFields/CustomDateTemplate';
import { transformErrors } from '../CustomFields/CustomErrors';
import { FieldTemplate } from '../CustomFields/CustomFieldTemplate';
import { DefaultInputTemplate } from '../CustomFields/CustomInputTemplate';
import { ObjectFieldTemplate } from '../CustomFields/CustomObjectFieldTemplate';
import { CustomDescriptionField } from '../CustomFields/CustomRjsfTitle';
import { CustomSelectWidget } from '../CustomFields/CustomSelectWidget';

const useStyles = makeStyles({
	button: {
		fontWeight: 'normal',
		textTransform: 'uppercase',
	},
	form: {
		marginTop: '1em',
	},
});

const Form = withTheme(MuiTheme);

interface IDatazooModalProps {
	applicationId: string;
	country: string;
	datazooAttributesRepository: IRepositoryEntry[];
	open: boolean;
	modalCanBeClosed: boolean;
	translatableConsent?: string;

	onAddAttribute: (applicationId: string, data: any) => Promise<any>;
	onClose: (updatedApplication?: IApplication) => void;
}

const EmptyComponent = (): void => null;

export const DatazooModal = (props: IDatazooModalProps) => {
	if (!props.open) {
		return null;
	}

	const classes = useStyles();
	const { t } = useTranslation();
	const [selectedAttribute, setSelectedAttribute] = useState(null);
	const [possibleAttributes, setPossibleAttributes] =
		useState<IDatazooAttribute[]>(null);
	const [values, setValues] = useState({});
	const [loading, setLoading] = useState(true);

	const fields = {
		DescriptionField: CustomDescriptionField,
		TitleField: EmptyComponent,
	} as any;

	const widgets = {
		CheckboxesWidget: CustomCheckboxesWidget,
		DateWidget: CustomDateTemplate,
		EmailWidget: DefaultInputTemplate,
		SelectWidget: CustomSelectWidget,
		TextWidget: DefaultInputTemplate,
		UpDownWidget: CustomAutocompleteWidget,
	};

	useEffect(() => {
		// load each attribute
		const attributeUrlList = props.datazooAttributesRepository
			.filter((a) => a.country === props.country)
			.map((a) => a.json);
		Promise.all(
			attributeUrlList.map((url: string) => {
				return axios.get(url).then((r) => r.data);
			})
		).then((attributes) => {
			setPossibleAttributes(attributes);
			// update consent text
			if (
				props.translatableConsent &&
				attributes[0].properties?.consent?.title
			) {
				attributes[0].properties.consent.title = t(props.translatableConsent);
			}
			setSelectedAttribute(attributes[0]);
			setLoading(false);
		});
	}, []);

	const onSelectedAttributeChange = (value: string) => {
		const attr = possibleAttributes.find(
			(pa: IDatazooAttribute) => pa.$id === value
		);

		// update consent text
		if (props.translatableConsent && attr.properties?.consent?.title) {
			attr.properties.consent.title = t(props.translatableConsent);
		}

		setValues(null);
		setSelectedAttribute(attr);
	};

	const submit = async () => {
		setLoading(true);
		const updatedApplication = await props.onAddAttribute(props.applicationId, {
			schemaId: selectedAttribute.$id,
			value: values,
		});
		setLoading(false);
		props.onClose(updatedApplication);
	};

	const handleChange = (elem: any) => {
		setValues(elem.formData);
	};

	return (
		<CustomerPortalTheme>
			<Dialog
				fullWidth
				open={props.open}
				disableEscapeKeyDown={!props.modalCanBeClosed}
			>
				<DialogTitle>{t('datazoo.modal.title')}</DialogTitle>
				<DialogContent>
					{loading && <LinearProgress />}
					{!loading && possibleAttributes && possibleAttributes.length > 0 && (
						<NativeSelect
							onChange={(event) =>
								onSelectedAttributeChange(event.target.value)
							}
							value={selectedAttribute.$id}
						>
							{possibleAttributes.map((attr) => {
								return (
									<option key={attr.$id} value={attr.$id}>
										{attr.title}
									</option>
								);
							})}
						</NativeSelect>
					)}
					{selectedAttribute && (
						<Form
							className={classes.form}
							schema={selectedAttribute}
							FieldTemplate={FieldTemplate}
							ObjectFieldTemplate={ObjectFieldTemplate}
							onSubmit={submit}
							fields={fields}
							onChange={handleChange}
							formData={values}
							widgets={widgets}
							showErrorList={false}
							transformErrors={transformErrors}
							noHtml5Validate={true}
						>
							<DialogAction>
								<Button
									color="primary"
									className={classes.button}
									type="submit"
								>
									Submit
								</Button>

								{props.modalCanBeClosed && (
									<Button
										className={classes.button}
										onClick={() => props.onClose()}
									>
										Cancel
									</Button>
								)}
							</DialogAction>
						</Form>
					)}
				</DialogContent>
			</Dialog>
		</CustomerPortalTheme>
	);
};
