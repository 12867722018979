import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Theme, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { ExtraErrorData } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { metaMask, walletConnect } from '@wagmi/connectors';
import { createConfig, http } from '@wagmi/core';
import { Config, Connector } from '@wagmi/core';
import { mainnet, polygon, polygonAmoy, sepolia } from '@wagmi/core/chains';
import { get } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import { isStorageAvailable } from '../../common-ts/src/index';
import { ITemplateSimple } from '../../common-ts/src/interface/template';
import { apiClient } from '../../common-ts/src/services/network/api';
import {
	GetSettingsRequestBody,
	getSettings,
} from '../../common-ts/src/services/network/info';
import { ErrorMessage, InvalidScopePage } from '../../common/src/react/Errors';
import { UpdateFavicon } from '../../common/src/react/Favicon/updateFavicon';
import './App.css';
import { Main } from './react/Main';
import {
	AuthLayout,
	AuthenticationMainPage,
} from './react/components/AuthLayout';
import { PrivacyPolicyPage } from './react/components/PrivacyPolicy/PrivacyPolicy';
import Toastify from './react/components/migration/toastify';
import { persistor, store } from './redux/store';
import { getTemplates, purgeSessionStorage } from './services/api';
import './third-party-override.css';

declare const __APP_CONFIG__: any;
const config = __APP_CONFIG__;

declare const SENTRY_CONFIG: any;

if (SENTRY_CONFIG && SENTRY_CONFIG.enabled) {
	Sentry.init({
		dsn: SENTRY_CONFIG.dsn,
		integrations: [
			new Integrations.BrowserTracing(),
			new ExtraErrorData({
				depth: 10,
			}),
		],
		normalizeDepth: 10,
		release: SENTRY_CONFIG.release, // release tag
		tracesSampleRate: 1.0,
	});

	Sentry.setTag('instance', SENTRY_CONFIG.INSTANCE);
}

const kycTheme = createMuiTheme({});

const useStyles = makeStyles((theme: Theme) => ({
	logoutButton: {
		marginBottom: theme.spacing(2),
	},
}));

export const SettingsContext = React.createContext<
	GetSettingsRequestBody['info'] | null
>(null);
export const TemplatesContext = React.createContext({
	signUpTemplates: [],
	templates: [],
});
export interface IConnectors {
	metamask: Connector;
	walletConnect: Connector;
}
export interface IWagmiContext {
	config: Config;
	connectors: IConnectors;
}
export const WagmiContext = React.createContext<IWagmiContext>({
	config: null,
	connectors: {
		metamask: null,
		walletConnect: null,
	},
});

const App: React.FC = () => {
	const s = window.location.href.split('?');
	const query = s.length === 2 ? new URLSearchParams(s[1]) : null;

	const { t } = useTranslation();
	const [sessionExpired, setSessionExpired] = useState(false);
	const [loading, setLoading] = useState(true);
	const [settings, setSettings] = useState(null);
	const [applicationIdFromQuery] = useState(
		query ? query.get('applicationId') : null
	);
	const [templates, setTemplates] = useState({
		signUpTemplates: [],
		templates: [],
	});
	const [wagmiContext, setWagmiContext] = useState<IWagmiContext>({
		config: null,
		connectors: {
			metamask: null,
			walletConnect: null,
		},
	});
	const [hasSignUpTemplate, setHasSignUpTemplate] = useState(false);
	const [isInvalidScopeError, setIsInvalidScopeError] = useState(false);
	const classes = useStyles();
	const history = useHistory();

	const title = config.app.title;

	useEffect(() => {
		apiClient.client.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				const statusCode = get(error, 'response.status', null);
				const message = get(error, 'response.data.message', null);
				// error happended before it reached server
				if (!statusCode) {
					toast.error(t('errors.generic.network'), { toastId: 'network' });
				} else if (
					statusCode === 502 ||
					statusCode === 504 ||
					statusCode === 500
				) {
					// gateway error or api side error -> API might be down
					toast.error(
						t('errors.generic.5xx', {
							code: statusCode,
							email: settings?.supportEmail,
						}),
						{ toastId: '5xx' }
					);
				} else if (statusCode === 401 && message === 'no-session') {
					purgeSessionStorage(persistor);
					// expired session; handle in every case except when actually checking the session
					if (!history.location.pathname.includes('/auth/')) {
						setSessionExpired(true);
					}
				}

				return Promise.reject(error);
			}
		);

		getSettings()
			.then((res) => {
				setSettings(res.info);
				if (res.info.loginWithWallet && res.info.walletConnectId) {
					const metaMaskConnector = new metaMask({
						extensionOnly: true,
						infuraId: config.app.infuraId,
					});
					const walletConnectConnector = new walletConnect({
						projectId: res.info.walletConnectId,
						showQrModal: true,
						infuraId: config.app.infuraId,
					});
					const wagmiConfig = createConfig({
						chains: [mainnet, sepolia, polygon, polygonAmoy],
						connectors: [metaMaskConnector, walletConnectConnector],
						transports: {
							[mainnet.id]: http(),
							[sepolia.id]: http(),
							[polygon.id]: http(),
							[polygonAmoy.id]: http(),
						},
					});
					setWagmiContext({
						config: wagmiConfig,
						connectors: {
							metamask: metaMaskConnector,
							walletConnect: walletConnectConnector,
						},
					});
				}
				if (res.info.authMethod === 'jwt') {
					// useLocation not availabe at this point yet
					const queryToken = query ? query.get('token') : null;
					if (isStorageAvailable(window.sessionStorage) && !queryToken) {
						const token = window.sessionStorage.getItem('kycc_token');
						if (token) {
							apiClient.client.defaults.headers.common.authorization =
								'Bearer ' + token;
						}
					} else if (queryToken) {
						apiClient.client.defaults.headers.common.authorization =
							'Bearer ' + queryToken;
						query.delete('token');
					}
				}
			})
			.then(getTemplates)
			.then((templates: ITemplateSimple[]) => {
				const signUpTemplates = templates.filter(
					(tmpl) =>
						tmpl.isSignUpTemplate &&
						(tmpl.requirements.options.signUpMethodWallet ||
							tmpl.requirements.options.signUpMethodEmail)
				);
				setHasSignUpTemplate(signUpTemplates.length > 0);
				setTemplates({ signUpTemplates, templates });
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				if (err?.response?.data?.details) {
					setIsInvalidScopeError(true);
				} else {
					toast.error(t('signup.error-load-data'));
				}
			});
	}, []);

	const expiredBackToLogin = async () => {
		// logout and redirect
		window.location.href = `/api/v2/auth/logout`;
	};

	if (loading) {
		return <LinearProgress />;
	}

	if (window.location.pathname === '/privacy-policy') {
		return <PrivacyPolicyPage />;
	}

	return (
		<>
			{isInvalidScopeError ? (
				<InvalidScopePage
					message={t('errors.invalid-scope.message')}
					title={t('errors.invalid-scope.title')}
				/>
			) : (
				<TemplatesContext.Provider value={templates}>
					<SettingsContext.Provider value={settings}>
						<Sentry.ErrorBoundary fallback={'An error has occurred'}>
							<Toastify />

							<Provider {...{ store }}>
								<PersistGate {...{ persistor }}>
									<ThemeProvider theme={kycTheme}>
										<UpdateFavicon url={settings?.faviconUrl} />
										{sessionExpired ? (
											<AuthLayout
												hidePoweredBySelfkey={settings.hidePoweredBySelfkey}
												logoUrl={settings.logoUrl}
											>
												<ErrorMessage
													title={t('errors.generic.expired-session-title')}
													message={t('errors.generic.expired-session')}
												/>
												<Button
													className={classes.logoutButton}
													onClick={expiredBackToLogin}
													variant="contained"
												>
													{t('login.go-to')}
												</Button>
											</AuthLayout>
										) : (
											<>
												{sessionExpired ? (
													<AuthLayout
														hidePoweredBySelfkey={settings.hidePoweredBySelfkey}
														logoUrl={settings.logoUrl}
													>
														<ErrorMessage
															title={t('errors.generic.expired-session-title')}
															message={t('errors.generic.expired-session')}
														/>
														<Button
															className={classes.logoutButton}
															onClick={expiredBackToLogin}
															variant="contained"
														>
															{t('login.go-to')}
														</Button>
													</AuthLayout>
												) : (
													settings && (
														<Main
															urlApplicationId={applicationIdFromQuery}
															className="App"
															hasSignUp={hasSignUpTemplate}
															settings={settings}
															templates={templates.templates}
														>
															<Switch>
																<Route
																	render={() => (
																		<WagmiContext.Provider value={wagmiContext}>
																			<AuthenticationMainPage
																				settings={settings}
																				hasSignUp={hasSignUpTemplate}
																				templates={templates}
																				title={settings.title ?? title}
																			/>
																		</WagmiContext.Provider>
																	)}
																	path="/auth"
																/>
															</Switch>
														</Main>
													)
												)}
											</>
										)}
									</ThemeProvider>
								</PersistGate>
							</Provider>
						</Sentry.ErrorBoundary>
					</SettingsContext.Provider>
				</TemplatesContext.Provider>
			)}
		</>
	);
};

export default App;
