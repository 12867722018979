import * as React from 'react';

export interface IBaseFieldProps {
	id: any;
	style: any;
	value: any;
	disabled?: boolean;
	onDataChange: any;
}

class BaseField<Props extends IBaseFieldProps, State> extends React.Component<
	Props,
	State
> {
	constructor(props: Props) {
		super(props);
	}

	public render() {
		return <div>The Base Field!</div>;
	}

	protected onChange = async (data: any) => {
		this.props.onDataChange(data);
	};
}

export default BaseField;
