import React from 'react';
import { useTranslation } from 'react-i18next';

import { ChangePasswordForm } from '../AuthLayout/SetPassword';
import { TitleArea } from '../AuthLayout/TitleArea';

export const ChangePassword: React.FC = () => {
	const { t } = useTranslation();
	return (
		<>
			<TitleArea title={t('set-password.change-password')} description="" />
			<ChangePasswordForm />
		</>
	);
};
