import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WagmiContext } from '../../../App';
import { MetamaskIcon } from '../../../helpers/metamaskIcon';
import { SelfkeyIcon } from '../../../helpers/selfkeyIcon';
import { WalletConnectIcon } from '../../../helpers/walletConnectIcon';
import { WALLET_PROVIDERS, connectToWallet } from '../../../helpers/wallets';
import { ConnectMetaMask } from './ConnectMetamask';
import { ConnectWalletConnect } from './ConnectWalletConnect';

interface IWalletSignUpProps {
	buttonClass?: string;
	disabled?: boolean;
	onAccount: (account: string) => void;
	setOpenWallet?: (isWalletOpened: boolean) => void;
	refreshedAccount?: boolean;
}

const useStyles = makeStyles({
	button: {
		marginTop: '2em',
	},
	walletConnected: {
		marginTop: '25px',
	},
	walletIcon: {
		height: '30px',
		marginRight: '8px',
		position: 'relative',
		top: '10px',
		width: '30px',
	},
});

const WalletIconMap = {
	[WALLET_PROVIDERS.metamask]: MetamaskIcon,
	[WALLET_PROVIDERS.selfkey]: SelfkeyIcon,
	[WALLET_PROVIDERS.walletConnect]: WalletConnectIcon,
};

export const ConnectWallet = (props: IWalletSignUpProps) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [account, setAccount] = useState(null);
	const [provider, setProvider] = useState<WALLET_PROVIDERS>(null);
	const wagmiContext = useContext(WagmiContext);

	useEffect(() => {
		setAccount(null);
	}, [props.refreshedAccount]);

	const handleConnect = async (walletProvider: WALLET_PROVIDERS) => {
		try {
			const connection = await connectToWallet(walletProvider, wagmiContext);
			setProvider(walletProvider);
			setAccount(connection.account);
			props.onAccount(connection.account);
		} catch (error) {
			return;
		}
	};

	if (account) {
		const CurrentWalletIcon = WalletIconMap[provider];
		if (props.setOpenWallet) {
			props?.setOpenWallet(true);
		}

		return (
			<Typography variant="subtitle2" className={classes.walletConnected}>
				<CurrentWalletIcon className={classes.walletIcon} />
				{t('signup.metamask-connected')}
				<br />
				{props?.setOpenWallet
					? t('login.metamask-login')
					: t('signup.metamask-signup-info')}
			</Typography>
		);
	}

	return (
		<>
			<ConnectMetaMask
				buttonClass={classes.button}
				disabled={props.disabled}
				onConnect={handleConnect}
			/>

			<ConnectWalletConnect
				buttonClass={classes.button}
				disabled={props.disabled}
				onConnect={handleConnect}
			/>

			{/* <ConnectSelfkey
				buttonClass={classes.button}
				disabled={props.disabled}
				onConnect={handleConnect}
			/> */}
		</>
	);
};
