// TODO merge with API side
import { ObjectId } from 'bson';

import { Modify } from '../index';
import { IAttributeSchema } from './AttributeSchema';
import { AISCANFLOWTYPES } from './aiscan';
import { IClientData } from './clientData';
import {
	DataCheckVendor,
	IDataCheckEntry,
	IDocumentOrder,
	RESULT_CONFIRMATION_TYPE,
} from './dataCheck';
import { IDatazooCheckModel } from './datazoo';
import { IDocumentExpiryTask } from './documentExpiry';
import { IFormSubmission } from './form';
import {
	IDSCANFLOWSTRICTNESS,
	IDSCANFLOWTYPES,
	IDocumentVerificationCheckModel,
} from './idScan';
import { ILabel } from './label';
import { NoteContext } from './notes';
import { IPayment } from './payment';
import { IAttributeOverride, IQuestionRequirement } from './requirements';
import {
	IRiskScoringData,
	SCORE_CATEGORY,
	STATUS_MESSAGE,
} from './riskScoring';
import { IScope } from './scope';
import { IKYCTemplate } from './template';
import { IUserFields, USER_TYPE } from './user';
import { IDocumentVerificationVendor } from './vendors';
import { IWalletScreening } from './walletScreening';

export enum APPLICATION_MANAGER_ROLE {
	MANAGER = 'manager',
}

export enum IApplicationType {
	INDIVIDUAL = 'individual',
	CORPORATE = 'corporate',
}

export type APPLICATION_TYPE = 'individual' | 'corporate';

export type IAttributesMap = Map<string, IAttribute>;
export type IQuestionsMap = Map<string, IQuestion>;
export type IAttributesObject = Record<string, IAttribute>;
export type IQuestionsObject = Record<string, IQuestion>;

export interface INote {
	_id: ObjectId;
	external: boolean;
	content: string;
	createdAt: Date;
	author?: ObjectId | IUserFields;
	metaData: {
		context: NoteContext;
	};
	scope: ObjectId | string;
}

export interface IAttribute {
	addedByUser: boolean;
	description: string;
	idVerification: {
		dynamic: boolean;
		sendDocument: boolean;
		sendDocumentBackside: boolean;
		sendSelfieWithDocument: boolean;
		documentExpiryMonitoring: boolean;
		documentExpiryTasks: Array<IDocumentExpiryTask>;
	};
	isAdditional: boolean;
	label: string;
	schemaId: string;
	valid: boolean;
	value?: any;
	optional?: boolean;
	overrides: IAttributeOverride;
	pattern?: string;
	weight: number;
	$id?: string;
	_id?: string;
}

export interface IMember {
	_id?: string;
	application: ObjectId | string | IApplication;
	shares: number;
	memberRoles: string[];
	softDelete?: boolean;
}

export interface ISection {
	title: string;
	description: string;
	components: string[];
}

export interface IStatusLogMetaData {
	applicationIds?: string[];
	client?: IClientData;
	managerId?: string;
	reason?: string;
	requirementId?: string;
	source?: string;
	invalidatedIds?: string[];
	type?: string;
	resultConfirmationType?: RESULT_CONFIRMATION_TYPE;
	dataCheckResultIds?: string[];
	vendor?: DataCheckVendor;
	dataCheckId?: string;
	needsReview?: boolean;
	newManagers?: string[];

	// AML monitoring
	monitoringCheck?: boolean;
	addedCAHits?: string[];
	removedCAHits?: string[];
	updatedCAHits?: string[];
	newCheck?: string;
	previousCheck?: string;
	interval?: number;

	// IDSCAN / AISCAN
	idVerificationCheck?: string;
	attempt?: number;

	// user filling 1 or more requirements
	attributes?: [
		{
			data: any;
			previousData?: any;
			schemaId: string;
			id: string;
			attribute?: IAttribute;
		}
	];
	questions?: [{ data: any; previousData?: any; id: string }];
	forms?: [{ data: any; previousData?: any; id: string }];

	// user filling 1 form
	document?: string;
	formSubmissionId?: string;

	// user uploading 1 requirement
	attributeId?: string;
	data?: any;
}

export interface IDataSnapshot {
	attributes: { [key: string]: any };
	questions: { [key: string]: any };
	forms: Array<ObjectId | string | IFormSubmission>;
	riskScoringResult: IRiskScoringData | ObjectId | string | null;
	dataCheckResult: IDataCheckEntry | ObjectId | string | null;
	datazooCheck: ObjectId | string | null | IDatazooCheckModel;
	idVerificationCheck: IIDVerificationCheck | ObjectId | string | null;
	members: IMember[];
	walletScreening: ObjectId | string | null;
	[key: string]: any;
}

export interface IStatusLogEntry {
	_id?: ObjectId | string;
	code: number;
	dataSnapshot?: IDataSnapshot;
	timestamp?: Date;
	metaData?: IStatusLogMetaData;
	note?: ObjectId | string;
	user?: ObjectId | string;
	scope: ObjectId | string;
	userType?: USER_TYPE;
}

export interface IApplicationStatusLog {
	code: number;
	event: object | string | IStatusLogEntry;
	timestamp: Date;
}

export interface IApplicationRiskScoring {
	currentCategory: SCORE_CATEGORY;
	outOfDate: boolean;
	statusMessage: STATUS_MESSAGE;
	statusMetadata: any;
	results: IRiskScoringData[];
	scoreCard: ObjectId | string;
}

export interface IIDVerificationCheck {
	attributeId: string;
	check: ObjectId | IDocumentVerificationCheckModel;
	originalValue?: any;
	originalSchemaId?: string;
	checkedFiles?: string[];
	metadata?: Array<{
		attributeId: string;
		index: number;
	}>;
}

export interface IIDVerificationCheckPopulated
	extends Modify<
		IIDVerificationCheck,
		{
			check: IDocumentVerificationCheckModel;
		}
	> {}

export interface IApplication {
	additionalDisplayScopes: (string | ObjectId)[];
	// TODO this is either map or object; see questions property
	attributes: IAttributesObject;
	createdAt: string | Date;
	currentStatus?: number;
	lastApprovedDate?: Date;
	dataChecks: IDataCheckEntry[];
	documentOrders: string[] | IDocumentOrder[];
	datazooChecks: ObjectId[] | IDatazooCheckModel[];
	forms: ObjectId[] | IFormSubmission[];
	id: string;
	idScanFlowTries: number;
	idVerificationCheck: ObjectId | IDocumentVerificationCheckModel;
	idVerificationChecks: IIDVerificationCheck[];
	labels: ILabel[] | ObjectId[] | string[];
	managers: Array<{
		user: string;
		role: APPLICATION_MANAGER_ROLE;
	}>;
	members: IMember[];
	needsAMLReview?: boolean;
	nextMonitoringDate?: Date;
	notes: INote[] | string[] | ObjectId[];
	options: {
		askForMemberOwnership: boolean;
		datazooEnabled: boolean;
		idDocVerVendor: IDocumentVerificationVendor;
		idScanFlowStrictness: IDSCANFLOWSTRICTNESS;
		idScanFlowTriesLimit: number;
		idScanFlowType: IDSCANFLOWTYPES & AISCANFLOWTYPES;
		idScanShowPhotoUploadOption: boolean;
		idScanShowSelfieUploadOption: boolean;
		membersEnabled: boolean;
		backsideScanning: boolean;
		documentExpiryMonitoring: boolean;
		documentExpiryTasks: Array<IDocumentExpiryTask>;
	};
	owners: ObjectId[] | IUserFields[];
	paths?: string[];
	payments?: Array<ObjectId | IPayment>;
	questions: IQuestionsMap | IQuestionsObject;
	riskScoring: IApplicationRiskScoring;
	scope: IScope;
	statusName?: string;
	statusLog: IApplicationStatusLog[] | IStatusLogEntry[];
	type: IApplicationType;
	sections: ISection[];
	softDelete: boolean;
	template: string | ObjectId | IKYCTemplate;
	updatedAt: string | Date;
	walletScreenings: ObjectId[] | string[] | IWalletScreening[];
	expiryDates?: Date[];
}

export interface IQuestion extends IQuestionRequirement {
	value?: string[];
	valid: boolean;
	isAdditional: boolean;
	schema?: IAttributeSchema & any;
}
