import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory } from 'react-router-dom';

import { ITemplateSimple } from '../../../../../common-ts/src/interface/template';
import { primary, tertiary } from '../../../../../ui/src/colors';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { TitleArea } from './TitleArea';

const useStyles = makeStyles({
	bottomSpace: {
		marginBottom: '36px',
	},
	listItem: {
		'&:hover': {
			backgroundColor: '#F9F9FB',
			border: '2px solid #394C65',
		},
		border: '2px solid #B9C2CC',
		borderRadius: '4px',
		marginBottom: '20px',
		padding: '15px 20px',
	},
	listItemElement: {
		'&:hover': {
			backgroundColor: 'inherit',
		},
		borderBottom: 'none',
		borderRadius: '2px',
		flexDirection: 'column',
		padding: 0,
	},
	nav: {
		backgroundColor: 'none',
	},
	primary: {
		color: primary,
		fontSize: '14px',
		fontWeight: 'bold',
		lineHeight: '20px',
	},
	secondary: {
		color: tertiary,
		display: 'block',
		fontSize: '12px',
		lineHeight: '18px',
		marginBottom: '10px',
	},
	tableHeader: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	tableWrap: {
		marginBottom: '20px',
	},
});

interface ISignUpProps {
	passwordlessFlow?: boolean;
	templates: ITemplateSimple[];
}

export const ListTemplates: React.FC<ISignUpProps> = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();
	const signUpTemplates = props.templates.filter(
		(tmpl) => tmpl.isSignUpTemplate
	);

	if (signUpTemplates.length === 1) {
		return <Redirect to={`/auth/signup/${signUpTemplates[0].templateId}`} />;
	}

	return (
		<CustomerPortalTheme>
			<LanguageSwitcher />
			<TitleArea
				title={t('listTemplates.title')}
				description={t('listTemplates.description')}
				xtraClass={classes.bottomSpace}
			/>
			<div className={classes.nav}>
				{signUpTemplates.length > 0 &&
					signUpTemplates.map((template) => {
						return (
							<div key={template.templateId} className={classes.listItem}>
								<ListItem
									button
									divider={true}
									onClick={() =>
										history.push(`/auth/signup/${template.templateId}`)
									}
									alignItems="flex-start"
									className={classes.listItemElement}
								>
									<ListItemText
										style={{ margin: 0, padding: 0 }}
										primary={template.name}
										secondary={template.description}
										classes={{
											primary: classes.primary,
											secondary: classes.secondary,
										}}
									/>
								</ListItem>
							</div>
						);
					})}
			</div>
			<BottomNavigation className="bottomNavigation">
				<BottomNavigationAction
					showLabel={true}
					component={Link}
					to="/auth/login"
					label={t(
						`${props.passwordlessFlow ? 'passwordlessFlow' : 'signup'}.login`
					)}
				/>
			</BottomNavigation>
		</CustomerPortalTheme>
	);
};
