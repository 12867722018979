import { makeStyles } from '@material-ui/core/styles';

import { tertiary } from '../../../../ui/src/colors';

export const useStyles = makeStyles({
	cancelBtn: {
		marginRight: '10px',
	},
	closeIcon: {
		'& svg': {
			height: '30px',
			width: '30px',
		},
		'@media (max-width: 600px)': {
			marginRight: '-20px',
			marginTop: '-40px',
		},
		marginRight: '-60px',
		marginTop: '-90px',
		position: 'relative',
	},
	content: {
		overflowX: 'hidden',
		padding: 0,
	},
	defaultTopSpace: {
		marginTop: 0,
	},
	iconWrap: {
		marginBottom: '-20px',
		marginTop: '10px',
		textAlign: 'right',
	},
	subtitle: {
		color: tertiary,
		marginBottom: '36px',
		marginTop: '5px',
	},
});
