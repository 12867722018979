import React from 'react';

export const useOpen = (defaultIsOpen = false) => {
	const [isOpen, setIsOpen] = React.useState(defaultIsOpen);

	return React.useMemo(
		() => ({
			close: () => setIsOpen(false),
			isOpen,
			open: () => setIsOpen(true),
			set: setIsOpen,
			toggle: () => setIsOpen(!isOpen),
		}),
		[isOpen, setIsOpen]
	);
};
