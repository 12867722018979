import axios from 'axios';

import { AISCAN_CHECK_TYPE } from '../../../common-ts/src/interface/aiscan';

export const uploadFile = (
	apiUrl: string,
	session: string,
	file: any,
	type: AISCAN_CHECK_TYPE
) => {
	const form = new FormData();
	form.append(type, file);
	return axios
		.post(`${apiUrl}/session/${session}/files`, form)
		.then((res) => res.data);
};

export const getSession = (apiUrl: string, sessionId: string) => {
	return axios.get(`${apiUrl}/session/${sessionId}`).then((res) => res.data);
};
