import { makeStyles } from '@material-ui/core';

import { accent, primary, tertiary } from '../../../../../ui/src/colors';

export const useStyles = makeStyles({
	container: {
		textAlign: 'right',
	},
	menuItem: {
		'&:hover': {
			backgroundColor: accent,
			color: primary,
		},
		color: tertiary,
		padding: '5px 15px',
	},
});
