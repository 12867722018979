import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { KycStatuses } from '../../../../../common-ts/src/application/constants';
import { hasDatazooAttribute } from '../../../../../common-ts/src/datazoo/helpers';
import { AISCANFLOWTYPES } from '../../../../../common-ts/src/interface/aiscan';
import {
	IApplication,
	IAttribute,
	IStatusLogEntry,
} from '../../../../../common-ts/src/interface/application';
import { IRepositoryEntry } from '../../../../../common-ts/src/interface/datazoo';
import { IDSCANFLOWTYPES } from '../../../../../common-ts/src/interface/idScan';
import { ITemplateSimple } from '../../../../../common-ts/src/interface/template';
import { EXTERNAL_VENDORS } from '../../../../../common-ts/src/interface/vendors';
import { getAttribute } from '../../../../../common/src/helpers/attributes';
import { DatazooModal } from '../../../../../common/src/react/Datazoo/DatazooModal';
import { ErrorMessage } from '../../../../../common/src/react/Errors';
import { setCurrentApplicationData } from '../../../redux/actions/application';
import { RootState } from '../../../redux/reducers';
import {
	acceptTerms,
	addAttribute,
	getApplicationIDVerificationToken,
	getTemplateById,
	hasTerms,
	isTermsAccepted,
} from '../../../services/api';
import {
	SectionType,
	getRequirementsForSection,
	getSectionsForApplication,
	loadSchemas,
} from '../../Helpers/sections';
import { IDScanStatusContext } from '../../context/IDScanStatusContext';
import { getStatusPage } from '../KYCStatusText/KYCStatusText';
import MobileStepIndicatorContainer from '../StepIndicator/MobileStepIndicator';
import StepIndicator from '../StepIndicator/StepIndicator';
import { KYCRefreshModal } from './KYCRefreshModal';

const useStyles = makeStyles({
	button: {
		marginBottom: '30px',
	},
	container: {
		marginBottom: '16px',
	},
	fullWidth: {
		width: '100%',
	},
	mainContainer: {
		'@media (max-width: 960px)': {
			marginTop: '70px',
			maxWidth: 'initial',
		},
		'@media (min-width: 1025px) and (max-width: 1090px)': {
			maxWidth: '770px',
		},
		'@media (min-width: 961px) and (max-width: 1024px)': {
			maxWidth: '710px',
		},
		maxWidth: '840px',
		padding: '0 !important',
	},
	mobileSteps: {
		'@media (max-width: 960px)': {
			display: 'initial',
		},
		display: 'none',
	},
	overrideStepStyle: {
		paddingTop: '35px',
	},
	root: {
		'@media (max-width: 960px)': {
			border: 'none',
			boxShadow: 'none',
			padding: 0,
		},
		boxShadow: '0 20px 20px -18px rgba(192, 197, 218, 0.8)',
		marginBottom: '50px',
		marginLeft: 'auto',
		marginRight: 'auto',
		// padding: '48px 80px',
		padding: '32px',
	},
	steps: {
		'& div:nth-child(1)': {
			paddingLeft: 0,
		},
		'@media (max-width: 960px)': {
			display: 'none',
		},
	},
});

interface IKYCLayoutProps {
	application: IApplication;
	datazooAttributesRepository?: IRepositoryEntry[];
	onApplicationUpdate?: (updatedApplication: IApplication) => void;
	overrideStyle?: any;
	useRouter?: boolean;
	lastStepCallback?: () => void;
}

interface Section {
	component: React.ReactElement;
	name: SectionType;
	title: string;
}

interface SectionState {
	currentSection: number;
	sections: Section[];
}

export const KYCLayout = (props: IKYCLayoutProps) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [initialLoad, setInitialLoad] = React.useState(false);
	const [template, setTemplate] = React.useState<ITemplateSimple>(null);
	const [sectionState, setSections] = React.useState<SectionState>({
		currentSection: null,
		sections: [],
	});
	const [kycRefreshModalOpen, setKycRefreshModalOpen] = React.useState(false);
	const [datazooModalOpen, setDatazooModalOpen] = React.useState(false);
	// helper variable when filling datazoo enabled member applications
	const [submitHasBeenClicked, setSubmitHasBeenClicked] = React.useState(false);
	const history = useHistory();
	const location = useLocation();
	const [error, setError] = React.useState(null);
	const isMemberApplication = !!props.application.paths;
	const dispatch = useDispatch();
	const currentApplication = useSelector(
		(root: RootState) =>
			props.application ?? root.applicationStore.currentApplication
	);
	const updateApplication = isMemberApplication
		? props.onApplicationUpdate
		: (application: IApplication) => {
				dispatch(setCurrentApplicationData(application));
		  };

	// context for IDScan liveness status
	const [idScanStatus, setIDScanStatus] = React.useState<{ done: boolean }>({
		done: false,
	});

	const nationality = getAttribute(
		currentApplication.attributes as { [key: string]: IAttribute },
		'http://platform.selfkey.org/schema/attribute/nationality.json',
		null
	);
	const country = nationality?.value?.country;

	const onDatazooModalClose = (updatedApplication: IApplication) => {
		if (updatedApplication) {
			setDatazooModalOpen(false);
			updateApplication(updatedApplication);
		}
	};

	const nextSection = (selectedNationality?: string) => {
		setSections((prevSectionState) => {
			if (
				prevSectionState.currentSection >=
				prevSectionState.sections.length - 1
			) {
				// check if member application and datazoo enabled
				const datazooEnabled = template.metadata.datazooEnabled;
				const hasPossibleAttributes = selectedNationality
					? props.datazooAttributesRepository &&
					  props.datazooAttributesRepository.some(
							(a) => a.country === selectedNationality
					  )
					: null;
				if (
					datazooEnabled &&
					isMemberApplication &&
					hasPossibleAttributes &&
					!hasDatazooAttribute(currentApplication)
				) {
					setSubmitHasBeenClicked(true);
					return prevSectionState;
				}

				if (typeof props.lastStepCallback === 'function') {
					props.lastStepCallback();
				}
				return prevSectionState;
			}

			return {
				...prevSectionState,
				currentSection: prevSectionState.currentSection + 1,
			};
		});
	};

	const prevSection = () => {
		setSections((prevSectionState) => {
			if (prevSectionState.currentSection <= 0) {
				return prevSectionState;
			}
			return {
				...prevSectionState,
				currentSection: prevSectionState.currentSection - 1,
			};
		});
	};

	const setCurrentSection = (currentSection: string) => {
		setSections((prevSectionState) => {
			const newSection = prevSectionState.sections.indexOf(
				prevSectionState.sections.find((s) => currentSection === s.name)
			);
			if (newSection >= 0) {
				return {
					...prevSectionState,
					currentSection: newSection,
				};
			} else {
				return prevSectionState;
			}
		});
	};

	React.useEffect(() => {
		setInitialLoad(true);
		// check if refresh modal needs to be displayed
		const statuses = (currentApplication.statusLog as IStatusLogEntry[]).filter(
			(s) =>
				[
					KycStatuses.uploaded,
					KycStatuses.refresh,
					KycStatuses.reopened,
				].includes(s.code)
		);
		if (statuses.length > 0 && statuses[0].code === KycStatuses.refresh) {
			if (statuses.length === 1 || statuses[1].code !== KycStatuses.reopened) {
				setKycRefreshModalOpen(true);
			}
		}

		// load applications template
		getTemplateById(props.application.template as string)
			.then((loadedTemplate) => {
				setTemplate(loadedTemplate);
				// if terms page is not set in the kyc template accept terms automatically
				if (
					loadedTemplate &&
					!hasTerms(loadedTemplate.metadata) &&
					!isTermsAccepted(props.application) &&
					!isMemberApplication
				) {
					acceptTerms(props.application.id)
						.then((updatedApplication) => {
							dispatch(setCurrentApplicationData(updatedApplication));
						})
						.catch((_) => {
							setError('errors.load-other');
							setInitialLoad(false);
						});
				}
			})
			.catch((err) => {
				setError(
					`errors.${
						err.response.data.details === 'Template not found'
							? 'template-404'
							: 'load-other'
					}`
				);
				setInitialLoad(false);
			});
	}, []);

	React.useEffect(() => {
		const hasPrevSection = () => sectionState.currentSection > 0;
		const currentSectionName =
			sectionState.sections.length > 0 &&
			sectionState.currentSection !== null &&
			sectionState.sections[sectionState.currentSection]
				? sectionState.sections[sectionState.currentSection].name
				: null;
		// do not update the sections if we are currently on IDScan flow
		// IDScan flow triggers application update in the middle of the flow and causes premature
		// sections refresh
		if (
			!template ||
			(currentSectionName === SectionType.IDDocVerification &&
				!idScanStatus.done)
		) {
			return;
		}

		const datazooEnabled = template.metadata.datazooEnabled;
		const hasPossibleAttributes =
			props.datazooAttributesRepository &&
			props.datazooAttributesRepository.some((a) => a.country === country);
		if (
			datazooEnabled &&
			country &&
			hasPossibleAttributes &&
			!hasDatazooAttribute(currentApplication)
		) {
			setSubmitHasBeenClicked(false);
			setDatazooModalOpen(true);
			return;
		} else if (submitHasBeenClicked) {
			setSubmitHasBeenClicked(false);
			props.lastStepCallback();
			return;
		}

		loadSchemas(currentApplication)
			.then(async (schemas) => {
				// IDScan is enabled for application if both template and application.options have it enabled
				const idScanEnabledForApplication =
					!isMemberApplication &&
					currentApplication.options.idScanFlowType !==
						IDSCANFLOWTYPES.disabled &&
					template.metadata.idScanFlowType !== IDSCANFLOWTYPES.disabled;
				// get IDScan token for IDScan UI flow / liveness
				const idScanConfig =
					!idScanStatus.done &&
					currentApplication.options.idScanFlowType &&
					!(
						currentApplication.options.idDocVerVendor ===
							EXTERNAL_VENDORS.AISCAN &&
						currentApplication.options.idScanFlowType === AISCANFLOWTYPES.api
					) &&
					idScanEnabledForApplication
						? await getApplicationIDVerificationToken(currentApplication.id)
						: null;
				getSectionsForApplication(
					currentApplication,
					template,
					schemas,
					idScanConfig,
					props.datazooAttributesRepository,
					!isMemberApplication,
					nextSection,
					prevSection,
					hasPrevSection,
					setCurrentSection
				).then((sections) => {
					if (props.onApplicationUpdate && !initialLoad) {
						props.onApplicationUpdate(currentApplication);
					}

					setInitialLoad(false);

					setSections((prevSectionState) => {
						// check if section state needs adjustment in case a section was removed
						const sectionsCount = sections.length;
						const prevSectionsCount = prevSectionState.sections.length;
						const previousSectionName =
							prevSectionState.sections[prevSectionState.currentSection]?.name;
						// check if previous section is still present in the new section list
						const adjustedSectionIndex =
							sectionsCount < prevSectionsCount
								? sections.findIndex((s) => s.name === previousSectionName)
								: -1;
						const currentSection =
							(adjustedSectionIndex > -1
								? adjustedSectionIndex
								: prevSectionState.currentSection) || 0;

						// find first section with an invalid or new requirement
						const sectionWithRequests = sections.findIndex((section) => {
							const requirements = getRequirementsForSection(section);

							if (requirements) {
								return Object.values(requirements).some(
									(item: {
										isAdditional: boolean;
										valid: boolean;
										optional: boolean;
										value: any;
										document: any;
										form: any;
									}) => {
										const isForm = item?.form?._id;
										// in case requirement is a form then check if it has a document since form doesn't have value property
										if (isForm) {
											const formHasDocument = item?.document?._id;
											return (
												!item.valid ||
												(item.isAdditional &&
													!item.optional &&
													!formHasDocument)
											);
										}

										return (
											!item.valid ||
											(item.isAdditional && !item.optional && !item.value)
										);
									}
								);
							}
							return false;
						});
						return {
							currentSection:
								sectionWithRequests > -1 ? sectionWithRequests : currentSection,
							sections,
						};
					});
				});
			})
			.catch((_) => {
				setError('errors.load-other');
			});
	}, [
		template,
		props.application,
		currentApplication,
		idScanStatus,
		sectionState.currentSection,
	]);

	// changes state when section number changes
	React.useEffect(() => {
		if (!props.useRouter) {
			return;
		}

		if (
			sectionState.sections.length <= 0 ||
			sectionState.currentSection >= sectionState.sections.length
		) {
			return;
		}

		const name = sectionState.sections[sectionState.currentSection]?.name;

		if (sectionState.currentSection === 0) {
			history.replace(`/kyc/${currentApplication.id}/${name}`);
		} else {
			history.push(`/kyc/${currentApplication.id}/${name}`);
		}
	}, [sectionState.currentSection]);

	React.useEffect(() => {
		// get the section name from the end of the current url in the browser
		const sectionNameFromLocation =
			location.pathname.split('/')[location.pathname.split('/').length - 1];

		// if it doesn't match what we think, then the applicant might have
		// clicked the "back" button
		if (
			sectionNameFromLocation !==
			sectionState.sections[sectionState.currentSection]?.name
		) {
			const correctSectionIndex = sectionState.sections.findIndex(
				(s) => s.name === sectionNameFromLocation
			);
			if (correctSectionIndex >= 0) {
				setSections({
					...sectionState,
					currentSection: correctSectionIndex,
				});
			}
		}
	}, [location]);

	// check if application is in not editable or closed status
	const StatusPage = props.application
		? getStatusPage(props.application)
		: null;

	if (StatusPage) {
		const statusPageProps = {
			application: props.application,
			setCurrentSection,
		};
		return (
			<Paper
				className={
					props.overrideStyle
						? props.overrideStyle
						: `${classes.root} ${classes.mainContainer} ${classes.fullWidth}`
				}
			>
				<StatusPage {...statusPageProps} />
			</Paper>
		);
	}

	const hideStepIndicatorStates = props.application
		? [`/kyc/${props.application.id}/messages`]
		: [];
	const hideStepIndicator = hideStepIndicatorStates.includes(
		history.location.pathname
	);

	return (
		<>
			<KYCRefreshModal
				type={props.application.type}
				open={kycRefreshModalOpen}
				onClose={() => setKycRefreshModalOpen(false)}
			/>

			<DatazooModal
				applicationId={props.application.id}
				modalCanBeClosed={false}
				datazooAttributesRepository={props.datazooAttributesRepository}
				open={datazooModalOpen}
				country={country}
				onClose={onDatazooModalClose}
				onAddAttribute={addAttribute}
				translatableConsent={
					isMemberApplication ? 'datazoo.member_consent' : null
				}
			/>

			<Grid container justify="space-between">
				{!hideStepIndicator && (
					<Switch>
						<Route path={'/kyc/*'}>
							<Grid
								item
								className={`${classes.steps} ${
									props.overrideStyle ? classes.overrideStepStyle : ''
								}`}
							>
								<StepIndicator
									steps={sectionState.sections}
									activeStep={sectionState.currentSection}
								/>
							</Grid>
						</Route>
					</Switch>
				)}

				<Grid
					xs={12}
					sm={12}
					lg={9}
					xl={9}
					item
					className={classes.mainContainer}
				>
					{initialLoad && <LinearProgress />}

					{!initialLoad &&
						!error &&
						(props.useRouter ? (
							<Switch>
								{sectionState.sections.map((step, index) => (
									<Route
										key={step.name}
										path={`/kyc/${currentApplication.id}/${step.name}`}
									>
										<Paper
											className={
												props.overrideStyle ? props.overrideStyle : classes.root
											}
										>
											<IDScanStatusContext.Provider
												value={[idScanStatus, setIDScanStatus]}
											>
												{React.cloneElement(step.component, {
													key: `${step.name}-${currentApplication.id}`,
													isLastStep:
														sectionState.sections.length - 1 === index,
													application: currentApplication,
													onApplicationUpdate: props.onApplicationUpdate,
												})}
											</IDScanStatusContext.Provider>

											<MobileStepIndicatorContainer
												steps={sectionState.sections}
												activeStep={sectionState.currentSection}
											/>
										</Paper>
									</Route>
								))}
							</Switch>
						) : (
							sectionState.sections.map(
								(step, index) =>
									sectionState.currentSection === index && (
										<Paper
											key={step.name}
											className={
												props.overrideStyle ? props.overrideStyle : classes.root
											}
										>
											<IDScanStatusContext.Provider
												value={[idScanStatus, setIDScanStatus]}
											>
												{React.cloneElement(step.component, {
													isLastStep:
														sectionState.sections.length - 1 === index,
													application: currentApplication,
													onApplicationUpdate: props.onApplicationUpdate,
												})}
											</IDScanStatusContext.Provider>

											<MobileStepIndicatorContainer
												steps={sectionState.sections}
												activeStep={sectionState.currentSection}
											/>
										</Paper>
									)
							)
						))}

					{!!error && (
						<Paper
							className={
								props.overrideStyle ? props.overrideStyle : classes.root
							}
						>
							<ErrorMessage message={t(error)} title={t('errorPage.title')} />
						</Paper>
					)}
				</Grid>
			</Grid>
		</>
	);
};
