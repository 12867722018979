import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	warning: {
		backgroundColor: 'yellow',
		boxShadow: '0 1px 6px 1px rgba(0, 0, 0, 0.2)',
		margin: '0 auto',
		padding: '1em',
		zIndex: 1,
		textAlign: 'center',
	},
});

export const InactiveScopeWarning = (props: { contractActive: boolean }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		!props.contractActive && (
			<div className={classes.warning}>
				<h1>{t('warnings.contractInactive')}</h1>
			</div>
		)
	);
};
