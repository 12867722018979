// TODO merge with API side
import { ObjectId } from 'bson';

import { IApplication, IApplicationType, ISection } from './application';
import { IKycDocumentTemplate } from './form';
import { IRequirements } from './requirements';

export interface ITemplateHistory {
	event: string;
	timestamp?: Date;
	user: ObjectId;
}

export interface IMemberTemplateCondition {
	_id?: string;
	legalEntityTypes: string[];
	memberLegalEntityTypes: string[];
	memberRoles: string[];
	memberType: IApplicationType;
	template: ObjectId | string;
	isDefault: boolean;
}
export interface ILegalEntityType {
	name: string;
	value: string;
	memberRoles: string[];
}

export enum IKYCTemplateQueryResponseType {
	STANDARD = 'standard',
	FULL = 'full',
	EXPORT = 'export',
}

export interface IReviewDecision {
	_id?: ObjectId | string;
	statusCode: number;
	scope: ObjectId | string | IDisplayNameWithId;
	required: number;
}

export interface AddMemberOperationOptions {
	parentApplication: IApplication;
	memberType: IApplicationType;
	values: MemberValues;
	memberConditions: IMemberTemplateCondition[];
	parentEntityType: ILegalEntityType;
}

export interface AddMemberOptions extends AddMemberOperationOptions {
	rootApplication: IApplication;
}

export interface MemberValues {
	roles: string[];
	shares?: number;
	email: string;
	firstName: string;
	lastName: string;
	legalEntityType: string;
	name: string;
}

export type MemberOperation = 'delete' | 'edit' | 'add';

export interface IAttribute {
	description: string;
	id: string;
	required: boolean;
	schemaId: string;
	title: string;
}

export interface ITemplateSimple {
	attributes?: IAttribute[];
	templateId: string;
	name: string;
	description: string;
	metadata: any;
	isSignUpTemplate: boolean;
	requirements: IRequirements;
}

export interface IDisplayNameWithId {
	_id: ObjectId | string;
	displayName: string;
}

export interface IDisplayNameWithIdEditable extends IDisplayNameWithId {
	canEdit: boolean;
}

export interface IKYCTemplate {
	_id: ObjectId | string;
	active: boolean;
	reviewDecisions: IReviewDecision[];
	allowDynamicAttributes: boolean;
	createdAt: Date;
	defaultManager: string;
	description: string;
	history: ITemplateHistory[];
	isSignUpTemplate: boolean;
	legalLinks: [
		{
			link: string;
			name: string;
		}
	];
	memberTemplate: {
		company: ObjectId;
		individual: ObjectId;
	};
	memberTemplates: IMemberTemplateCondition[];
	name: string;
	removed: boolean;
	requirements: IRequirements;
	scope: ObjectId | string;
	scoreCard: ObjectId | string;
	submission: {
		documentTemplate: ObjectId | string | IKycDocumentTemplate;
	};
	type: IApplicationType;
	welcomeScreen: {
		disclaimerText: string;
		enabled: boolean;
	};
	sections: ISection[];
	updatedAt: Date;
}

export const LEGAL_ENTITY_TYPES: ILegalEntityType[] = [
	{
		memberRoles: [
			'director_ltd',
			'shareholder',
			'ubo',
			'observer',
			'authorizedSignatory',
			'other',
		],
		name: 'Company Limited by Shares (LTD)',
		value: 'ltd',
	},
	{
		memberRoles: [
			'manager',
			'member_llc',
			'ubo',
			'observer',
			'authorizedSignatory',
			'other_llc',
		],
		name: 'Limited Liability Company (LLC)',
		value: 'llc',
	},
	{
		memberRoles: [
			'grantor',
			'beneficiary_tst',
			'trustee',
			'protector',
			'ubo',
			'observer',
			'authorizedSignatory',
			'other',
		],
		name: 'Trust (TST)',
		value: 'tst',
	},
	{
		memberRoles: [
			'founder',
			'director_fnd',
			'supervisor',
			'beneficiary_fnd',
			'ubo',
			'observer',
			'authorizedSignatory',
			'other',
		],
		name: 'Foundation (FND)',
		value: 'fnd',
	},
	{
		memberRoles: [
			'generalPartner',
			'limitedPartner',
			'ubo',
			'observer',
			'authorizedSignatory',
			'other',
		],
		name: 'Limited Partnership (LLP)',
		value: 'llp',
	},
	{
		memberRoles: ['member', 'ubo', 'observer', 'authorizedSignatory'],
		name: 'Other',
		value: 'other',
	},
];

export const INDIVIDUAL_ENTITY = {
	memberRoles: ['other', 'authorizedSignatory', 'observer'],
	name: 'Individual',
	value: 'individual',
};

export const MEMBER_ROLES = Array.from(
	new Set<string>(
		Object.keys(LEGAL_ENTITY_TYPES)
			.map((type: any) => LEGAL_ENTITY_TYPES[type].memberRoles)
			.reduce((flattened, curr) => [...flattened, ...curr], [])
	)
);
