import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	bottomSpace: {
		marginBottom: '10px',
	},
	closeIcon: {
		'& svg': {
			height: '30px',
			width: '30px',
		},
		'@media (max-width: 600px)': {
			marginRight: '-20px',
			marginTop: '-40px',
		},
		marginRight: '-60px',
		marginTop: '-90px',
		position: 'relative',
	},
	dialogContent: {
		boxShadow: 'none !important',
		overflow: 'hidden',
		overflowY: 'auto',
		padding: 0,
	},
	dialogPaper: {
		maxWidth: '1000px',
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'start',
		marginBottom: '20px',
	},
	hiddenOnMobile: {
		'@media (max-width: 600px)': {
			display: 'none  !important',
		},
		display: 'table-cell',
	},
	iconWrap: {
		marginBottom: '-20px',
		marginTop: '10px',
		textAlign: 'right',
	},
	maxWidth: {
		maxWidth: '120px !important',
	},
	memberIcon: {
		marginRight: '17px',
	},
	memberMobileContent: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	membersTableButton: {
		marginLeft: '32px',
	},
	membersTableHeader: {
		display: 'flex',
	},
	missingInfo: {
		backgroundColor: '#FCF8F8',
		border: '2px solid #FF3500 !important',
	},
	mobile: {
		'@media (max-width: 600px)': {
			alignItems: 'flex-start',
			display: 'flex',
			justifyContent: 'space-between',
			verticalAlign: 'baseline',
		},
		display: 'none',
	},
	overflowProtection: {
		maxWidth: '130px',
		overflow: 'hidden',
	},
	popupStyle: {
		'& h1': {
			marginBottom: '10px',
		},
		boxShadow: 'none',
	},
	tableContent: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	tableData: {
		flexGrow: 1,
	},
	tableDataName: {
		'& p': {
			marginRight: '5px',
		},
		alignItems: 'baseline',
		display: 'flex',
	},
	tableWrap: {
		'& .documents-table-header': {
			marginBottom: '5px',
		},
		border: '2px solid #B9C2CC',
		borderRadius: '4px',
		boxSizing: 'border-box',
		marginBottom: '20px',
		padding: '16px 20px',
	},
	title: {
		marginBottom: '35px',
	},
	topSpace: {
		marginTop: '10px',
	},
});
