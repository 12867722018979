import * as React from 'react';

import { convertToRaw, EditorState } from 'draft-js';

// tslint:disable-next-line: no-var-requires
const { draftToMarkdown } = require('markdown-draft-js');

import 'draft-js-inline-toolbar-plugin/lib/plugin.css';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import RawEditor from './RawEditorComponent';

const editorStyles = makeStyles((theme: Theme) => ({
	buttonAlign: {
		'@media (max-width: 600px)': {
			maxWidth: '100%',
			textAlign: 'right' as any,
		},
		textAlign: 'right' as any,
	},
	container: {
		'@media (max-width: 600px)': {
			flexDirection: 'column' as any,
		},
		alignItems: 'flex-end',
		flexDirection: 'row' as any,
	},
	editor: {
		'@media (max-width: 600px)': {
			marginBottom: theme.spacing(1),
			width: '100%',
		},
	},
	item: {
		flexBasis: theme.spacing(),
	},
	root: {
		display: 'flex',
	},
	sendButton: {
		'@media (max-width: 600px)': {
			marginLeft: 0,
		},
		marginLeft: '20px',
	},
}));

interface IEditorProps {
	onSend: (data: string) => Promise<void>;

	placeholderText?: string;
	readOnly?: boolean;
	onChange?: (data: string) => void;
}
const Editor = (props: IEditorProps) => {
	const classes = editorStyles();

	const { readOnly, placeholderText } = props;

	const [loading, setLoading] = React.useState(false);

	const [editorState, setEditorState] = React.useState(
		EditorState.createEmpty()
	);

	const handleSaveClick = () => {
		if (loading) {
			return;
		}
		setLoading(true);
		props
			.onSend(draftToMarkdown(convertToRaw(editorState.getCurrentContent())))
			.then(() => {
				setEditorState(EditorState.createEmpty());
			})
			.catch((error) => {
				// console.log(error);
			})
			.then(() => {
				setLoading(false);
			});
	};

	return (
		<Grid
			container
			justify="flex-start"
			className={`${classes.container} editorContainer`}
		>
			<Grid item xs className={classes.editor}>
				<RawEditor
					placeholderText={placeholderText}
					readOnly={readOnly}
					editorState={editorState}
					OnEditorState={setEditorState}
					loading={loading}
				/>
			</Grid>
			<Grid item xs={2} className={classes.buttonAlign}>
				<Button
					variant="contained"
					onClick={handleSaveClick}
					className={classes.sendButton}
				>
					Send
				</Button>
			</Grid>
		</Grid>
	);
};

export default Editor;
