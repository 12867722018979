import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import QRCode from 'qrcode';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useOpen } from '../../../../../common/src/react/hooks/useOpen';
import { useCheckUserSession } from '../../../helpers';
import { setCurrentApplicationById } from '../../../redux/actions/application';
import { userLogout } from '../../../redux/actions/user';
import { RootState } from '../../../redux/reducers';
import {
	buildUrlApplicationWithAccessToken,
	getChatCount,
	getURLWithRedirectToken,
} from '../../../services/api';
import { DropDownMenu, DropDownMenuLink } from '../DropDownMenu/DropDownMenu';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const useStyles = makeStyles({
	chipRoot: {
		marginLeft: '5px',
	},
	closeButton: {
		position: 'absolute',
		right: 0,
		top: 0,
	},
	dialogContainer: {
		textAlign: 'center',
	},
	message: {
		alignItems: 'center',
		display: 'flex',
		gap: '10px',
	},
	primaryColor: {
		backgroundColor: '#F3BA2F',
	},
	toolbar: {
		'@media (max-width: 960px)': {
			backgroundColor: '#fff',
			boxShadow: '0 1px 6px 1px rgba(0, 0, 0, 0.2)',
			flexWrap: 'nowrap',
			height: '44px',
			left: 0,
			margin: '0 auto',
			padding: '0 16px',
			width: '100%',
			zIndex: 1,
		},
		height: 'initial',
		margin: '0 auto 30px',
		maxWidth: '1080px',
		paddingTop: '30px',
	},
	toolbarLogo: {
		'@media (max-width: 960px)': {
			height: '40px',
			width: 'auto',
		},
		width: '80px',
	},
});

export const TopBar = (props: {
	applicationId: string;
	applicationIsOpen: boolean;
	displaySwitcher: boolean;
	passwordlessFlow: boolean;
	messagesEnabled: boolean;
	logoUrl: string;
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();
	const user = useSelector((store: RootState) => store.userStore.user);
	const [messagesCount, setMessagesCount] = useState(0);
	const { isOpen, open, close } = useOpen();
	const [qrCodeLink, setQrCodeLink] = React.useState('');
	const [intervalId, setIntervalId] = useState(null);

	useEffect(() => {
		if (props.messagesEnabled) {
			getChatCount(props.applicationId).then((count) => {
				setMessagesCount(count);
			});
		}
	}, []);

	const menuItems = [
		!props.passwordlessFlow
			? {
					click: () => {
						history.push('/change-password');
					},
					icon: 'account_box',
					title: 'profile_menu.change_password',
			  }
			: null,
	]
		.concat(
			props.displaySwitcher
				? [
						{
							click: async () => {
								dispatch(setCurrentApplicationById(null));
								history.push('/auth/applications');
							},
							icon: 'exit_to_app',
							title: 'profile_menu.switch_application',
						},
				  ]
				: [],
			[
				{
					click: () => {
						dispatch(userLogout());
						history.push('/auth/sign-in');
					},
					icon: 'exit_to_app',
					title: 'profile_menu.signout',
				},
			]
		)
		.filter((i) => i);

	const openChat = () => {
		history.push(`/kyc/${props.applicationId}/messages`);
	};

	const openContinueOnMobileDialog = () => {
		open();

		const newIntervalId = setInterval(() => {
			useCheckUserSession(history, dispatch);
		}, 30000);
		setIntervalId(newIntervalId);

		getURLWithRedirectToken().then((accessTokenResponse) => {
			const { jwt: accessToken } = accessTokenResponse;
			const URL = buildUrlApplicationWithAccessToken(
				accessToken,
				props.applicationId
			);
			QRCode.toDataURL(URL).then(setQrCodeLink);
		});
	};

	const closeContinueOnMobileDialog = () => {
		close();
		useCheckUserSession(history, dispatch);
		if (!!intervalId) {
			clearInterval(intervalId);
			setIntervalId(null);
		}
	};

	return (
		<Grid
			container
			spacing={2}
			alignItems="center"
			justify="space-between"
			className={classes.toolbar}
		>
			<div className="logo">
				<img alt="logo" className={classes.toolbarLogo} src={props.logoUrl} />
			</div>
			<div className={classes.message}>
				{!isMobile && props.applicationIsOpen && (
					<div>
						<Button
							variant="contained"
							onClick={openContinueOnMobileDialog}
							style={{ letterSpacing: '1px' }}
						>
							{t('idverification.qrCode.continueOnMobile')}
						</Button>
					</div>
				)}

				{isOpen && (
					<Dialog
						open={isOpen}
						fullWidth
						onClose={closeContinueOnMobileDialog}
						maxWidth="sm"
						className={classes.dialogContainer}
						PaperProps={{
							style: {
								position: 'relative',
							},
						}}
					>
						<DialogTitle disableTypography={true}>
							<Typography variant="h2">
								{t('idverification.qrCode.continueOnMobile')}
							</Typography>
						</DialogTitle>

						<IconButton
							onClick={closeContinueOnMobileDialog}
							className={classes.closeButton}
							color="inherit"
						>
							<CloseIcon />
						</IconButton>

						<DialogContent>
							<Typography>{t('idverification.qrCode.modalText')}</Typography>

							<img src={qrCodeLink} />
						</DialogContent>
					</Dialog>
				)}

				{props.messagesEnabled && props.applicationIsOpen ? (
					<DropDownMenuLink
						handleOpenClick={openChat}
						icon={'forum'}
						title={
							<div style={{ alignItems: 'center', display: 'flex' }}>
								{t('chat.messages_link')}
								<Chip
									label={messagesCount}
									color="primary"
									size="small"
									classes={{
										colorPrimary: classes.primaryColor,
										root: classes.chipRoot,
									}}
								/>
							</div>
						}
						showArrowIcon={false}
					/>
				) : null}
				<DropDownMenu title={user.name} menuItems={menuItems} />
				<LanguageSwitcher />
			</div>
		</Grid>
	);
};
