import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';

import { IApplication } from '../../../../../common-ts/src/interface/application';
import { IRepositoryEntry } from '../../../../../common-ts/src/interface/datazoo';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import { RootState } from '../../../redux/reducers';
import { isApplicationEditable } from '../../../services/api';
import Messages from '../Messages/Messages';
import { ChangePassword } from './ChangePassword';
import { InactiveScopeWarning } from './InactiveScopeWarning';
import { TopBar } from './TopBar';
import { KYCLayout } from './index';

const useStyles = makeStyles({
	container: {
		'@media (max-width: 960px)': {
			position: 'relative',
			top: '65px',
		},
		marginBottom: '26px',
	},
	overrideStepStyle: {
		paddingTop: '35px',
	},
	root: {
		'@media (max-width: 960px)': {
			boxShadow: 'none',
			padding: '0 0 2em',
			width: '100%',
		},
		boxShadow: '0px 20px 20px -18px #C0C5DA',
		boxSizing: 'border-box',
		overflow: 'hidden',
		padding: '4em 5em',
	},
	wrap: {
		margin: '0 auto',
		maxWidth: '1080px',
	},
});

interface IMainLayoutProps {
	displaySwitcher: boolean;
	passwordlessFlow: boolean;
	datazooAttributesRepository: IRepositoryEntry[];
	messagesEnabled: boolean;
	logoUrl: string;
	contractActive: boolean;
}

export const MainLayout = (props: IMainLayoutProps) => {
	const history = useHistory();
	const { t } = useTranslation();
	const classes = useStyles();
	// states which hides step indicator
	const application: IApplication = useSelector(
		(store: RootState) => store.applicationStore.currentApplication
	);
	const hideStepIndicatorStates = [
		'/change-password',
		`/kyc/${application.id}/messages`,
	];
	const hideStepIndicator = hideStepIndicatorStates.includes(
		history.location.pathname
	);
	const user = useSelector((store: RootState) => store.userStore.user);
	const applicationIsOpen = isApplicationEditable(application);

	return (
		<CustomerPortalTheme>
			<InactiveScopeWarning contractActive={props.contractActive} />
			<TopBar
				applicationIsOpen={applicationIsOpen}
				applicationId={application.id}
				displaySwitcher={props.displaySwitcher}
				passwordlessFlow={props.passwordlessFlow}
				messagesEnabled={props.messagesEnabled}
				logoUrl={props.logoUrl}
			/>
			<Container maxWidth="lg">
				<Grid container className={classes.wrap}>
					{hideStepIndicator && (
						<Grid container className={classes.container}>
							<Grid item>
								<Button
									color="primary"
									variant="contained"
									onClick={() => history.goBack()}
								>
									{t('layout.back')}
								</Button>
							</Grid>
						</Grid>
					)}

					<KYCLayout
						key={`kyclayout-main-${application.id}`}
						application={application}
						datazooAttributesRepository={props.datazooAttributesRepository}
						useRouter={true}
					/>

					<Switch>
						<Route key={'change-password'} path={`/change-password`}>
							<Grid xs={12} lg={12} sm={12} xl={12} item>
								<Paper className={classes.root}>
									<ChangePassword />
								</Paper>
							</Grid>
						</Route>

						<Route key={'chat'} path={`/kyc/${application.id}/messages`}>
							<Grid xs={12} lg={12} sm={12} xl={12} item>
								<Paper className={classes.root}>
									<Messages
										applicationId={application.id}
										userId={user.id}
										onClickBack={() => history.goBack()}
									/>
								</Paper>
							</Grid>
						</Route>
					</Switch>
				</Grid>
			</Container>
		</CustomerPortalTheme>
	);
};
