import { IApplication } from '../../../../../common-ts/src/interface/application';
import { isApplicationEditable } from '../../../services/api';
import { StatusPages } from './StatusPages';

export interface KYCStatusInputProps {
	application: IApplication;
	setCurrentSection: (section: string) => any;
}

export const getStatusPage = (application: IApplication) => {
	if (!isApplicationEditable(application)) {
		return (StatusPages as any)[application.currentStatus];
	}
	return null;
};
