import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { jurisdictionDisplay } from '../../../../common-ts/src/countries';
import {
	getTextValue,
	hasNoValue,
	isObjectValue,
} from '../../../../common-ts/src/formatters';
import {
	IAttribute,
	IQuestion,
} from '../../../../common-ts/src/interface/application';
import {
	DefaultDateFormat,
	IDateFormat,
} from '../../../../common-ts/src/interface/scope';
import '../../i18n';

interface IRequirementFormattingFields {
	value: any;
	valid: boolean;
	schemaId?: string;
	uiType?: string;
}

interface IFormatAnswerProps {
	requirement: IRequirementFormattingFields;
	dateFormat: IDateFormat;
	typographyProps?: any;
}

const FormatArrayAnswer = (props: IFormatAnswerProps) => {
	return props.requirement.value
		.filter((val: any) => val)
		.map((val: any, index: number) => {
			const text = getTextValue(
				props.requirement.schemaId,
				val,
				props.dateFormat,
				(props.requirement as IQuestion).uiType
			);
			return (
				<Typography
					key={`${index}-${props.requirement.schemaId}`}
					{...props.typographyProps}
				>
					{text}
				</Typography>
			);
		});
};

const FormatObjectAnswer = (props: IFormatAnswerProps) => {
	const { t } = useTranslation();
	if (
		(props.requirement as IAttribute).schemaId ===
			'http://platform.selfkey.org/schema/attribute/physical-address.json' ||
		(props.requirement as any).uiType === 'addressInput'
	) {
		return (
			<div>
				{[
					props.requirement.value.address_line_1,
					props.requirement.value.address_line_2,
					props.requirement.value.city,
					props.requirement.value.province,
					props.requirement.value.postalcode,
				]
					.filter((i) => i)
					.map((i, index) => {
						return (
							<Typography
								key={`objectanswer-${index}`}
								{...props.typographyProps}
							>
								{i + ' '}
							</Typography>
						);
					})}
			</div>
		);
	}

	return (
		<>
			{Object.keys(props.requirement.value)
				.filter((k: string) => k !== 'denonym')
				.map(
					// TODO remove denonym removal hack
					(key: any, index: number) => {
						const isCountry = key === 'country' || key === 'issuing_country';
						const value = isCountry
							? jurisdictionDisplay(props.requirement.value[key])
							: props.requirement.value[key];
						if (typeof value === 'boolean') {
							return (
								<Typography {...props.typographyProps} key={index}>
									{t(`attributeKeys.${key}`) + ':'} {value ? 'YES' : 'NO'}
								</Typography>
							);
						}
						if (typeof value === 'string') {
							return (
								<Typography {...props.typographyProps} key={index}>
									{!isCountry ? t(`attributeKeys.${key}`) + ':' : null} {value}
								</Typography>
							);
						}
						return '';
					}
				)}
		</>
	);
};

export const ValueFormatter = (props: {
	typographyProps?: any;
	requirement: IRequirementFormattingFields;
	emptyRequirementText?: string;
	dateFormat?: IDateFormat;
}) => {
	const dateFormat = props.dateFormat ? props.dateFormat : DefaultDateFormat;
	if (
		hasNoValue({
			value: props.requirement.value,
			valid: props.requirement.valid,
		})
	) {
		return (
			<Typography {...props.typographyProps}>
				{props.emptyRequirementText || 'Waiting for answers'}
			</Typography>
		);
	}

	if (props.requirement.valid && Array.isArray(props.requirement.value)) {
		return (
			<FormatArrayAnswer
				requirement={props.requirement}
				typographyProps={props.typographyProps}
				dateFormat={dateFormat}
			/>
		);
	}

	if (isObjectValue(props.requirement)) {
		return (
			<FormatObjectAnswer
				requirement={props.requirement}
				typographyProps={props.typographyProps}
				dateFormat={dateFormat}
			/>
		);
	}

	const val = getTextValue(
		props.requirement.schemaId,
		props.requirement.value,
		dateFormat,
		props.requirement.uiType
	);
	return <Typography {...props.typographyProps}>{val}</Typography>;
};
