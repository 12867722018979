// NOTE this file is copied here https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/CheckboxesWidget/CheckboxesWidget.tsx

// tslint:disable

import * as React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { InputLabel } from '@material-ui/core';
import { WidgetProps } from '@rjsf/core';
import { makeStyles } from '@material-ui/styles';

import { CustomDescriptionField } from './CustomRjsfTitle';

const selectValue = (value: any, selected: any, all: any) => {
	const at = all.indexOf(value);
	const updated = selected.slice(0, at).concat(value, selected.slice(at));

	// As inserting values at predefined index positions doesn't work with empty
	// arrays, we need to reorder the updated selection to match the initial order
	return updated.sort((a: any, b: any) => all.indexOf(a) > all.indexOf(b));
};

const deselectValue = (value: any, selected: any) => {
	return selected.filter((v: any) => v !== value);
};

const useStyles = makeStyles({
	kycCustomCheckboxControl: {
		marginTop: '15px',
		position: 'relative',
		width: '100%',
	},
	kycCustomCheckboxLabel: {
		paddingLeft: '25px',
	},
	kycCustomCheckbox: {
		position: 'absolute',
		top: '5px',
	},
});

export const CustomCheckboxesWidget = ({
	schema,
	label,
	id,
	disabled,
	options,
	value,
	autofocus,
	readonly,
	required,
	onChange,
	onBlur,
	onFocus,
}: WidgetProps) => {
	const { enumOptions, enumDisabled } = options;
	const classes = useStyles();

	const _onChange =
		(option: any) =>
		({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
			const all = (enumOptions as any).map(({ value }: any) => value);

			if (checked) {
				onChange(selectValue(option.value, value, all));
			} else {
				onChange(deselectValue(option.value, value));
			}
		};

	const _onBlur = ({
		target: { value },
	}: React.FocusEvent<HTMLButtonElement>) => onBlur(id, value);
	const _onFocus = ({
		target: { value },
	}: React.FocusEvent<HTMLButtonElement>) => onFocus(id, value);

	return (
		<FormControl fullWidth={true} required={required}>
			<InputLabel shrink htmlFor={id}>
				{label || schema.title}
			</InputLabel>
			{schema.description && schema.description.length && (
				<CustomDescriptionField description={schema.description} />
			)}
			<FormGroup>
				{(enumOptions as any).map((option: any, index: number) => {
					const checked = value.indexOf(option.value) !== -1;
					const itemDisabled =
						enumDisabled && (enumDisabled as any).indexOf(option.value) !== -1;
					const checkbox = (
						<Checkbox
							id={`${id}_${index}`}
							checked={checked}
							className={classes.kycCustomCheckbox}
							disabled={disabled || itemDisabled || readonly}
							autoFocus={autofocus && index === 0}
							onChange={_onChange(option)}
							onBlur={_onBlur}
							onFocus={_onFocus}
						/>
					);
					return (
						<FormControlLabel
							className={classes.kycCustomCheckboxControl}
							control={checkbox}
							key={index}
							label={
								<span className={classes.kycCustomCheckboxLabel}>
									{option.label}
								</span>
							}
						/>
					);
				})}
			</FormGroup>
		</FormControl>
	);
};
