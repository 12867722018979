import { Input, InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/styles';
import { WidgetProps } from '@rjsf/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getAttrNameFromSchemaId } from '../../../../common-ts/src/attributes';
import { CustomDescriptionField } from './CustomRjsfTitle';

const useStyles = makeStyles({
	defaultTopSpace: {
		marginTop: 0,
	},
});

export const DefaultInputTemplate = (props: WidgetProps) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const onChange = ({
		target: { value },
	}: React.ChangeEvent<HTMLInputElement>) =>
		props.onChange(value === '' ? props.options.emptyValue : value);
	const onBlur: any = ({
		target: { value },
	}: React.FocusEvent<HTMLInputElement>) => props.onBlur(props.id, value);
	const onFocus: any = ({
		target: { value },
	}: React.FocusEvent<HTMLInputElement>) => props.onFocus(props.id, value);

	return (
		<FormControl fullWidth>
			<InputLabel
				shrink={true}
				htmlFor={props.id}
				className={classes.defaultTopSpace}
			>
				{props.label || props.schema.title} {props.required && '*'}
			</InputLabel>
			{props.schema.description && props.schema.description.length && (
				<CustomDescriptionField description={props.schema.description} />
			)}
			<Input
				id={props.id}
				placeholder={t('questions.input_text')}
				type={props.schema.type as string}
				disabled={props.disabled || props.readonly}
				disableUnderline
				fullWidth
				required={props.required}
				value={props.value ? props.value : ''}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
			/>
		</FormControl>
	);
};
