import React from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { UploadDialogEditContext } from '../UploadDocumentDialog';

export const TextInputWidget = (props: any) => {
	const [fileUploadContext, dispatch] = React.useContext(
		UploadDialogEditContext
	);
	const { t } = useTranslation();
	const hasError =
		props.error && props.error.keyword === 'required' ? true : false;

	const field = fileUploadContext.fields.find(
		(f: any) => f.itemKey === props.itemKey
	);
	const val = field ? field.value : '';

	const onChange = (newValue: string) => {
		const newState = { ...fileUploadContext };
		const newFieldsArray = newState.fields.filter(
			(f: any) => f.itemKey !== props.itemKey
		);
		newFieldsArray.push({
			itemKey: props.itemKey,
			value: newValue,
		});
		newState.fields = newFieldsArray;

		dispatch(newState);
	};

	return (
		<FormControl margin="normal" fullWidth>
			<FormLabel>{props.title}</FormLabel>
			<TextField
				onChange={(event) => onChange(event.target.value)}
				value={val}
			/>
			{hasError ? (
				<Grid item style={{ marginTop: '5px' }}>
					<Typography gutterBottom={true} variant="subtitle1" color="error">
						{t('errors.required')}
					</Typography>
				</Grid>
			) : null}
		</FormControl>
	);
};
