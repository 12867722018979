import React from 'react';

import { MenuItem } from '@material-ui/core';

import Select, { SelectProps } from '@material-ui/core/Select';

import { IApplication } from '../../../common-ts/src/interface/application';
import { fullNameFromApplication } from '../../../common/src/helpers/attributes';

export interface Props extends Omit<SelectProps, 'value' | 'onChange'> {
	value?: string;
	items: IApplication[];
	onChange: (id: string) => void;
}

export const ApplicationSelect: React.FC<Props> = ({
	items,
	onChange,
	...rest
}) => {
	return (
		<Select
			onChange={(event) => {
				onChange(event.target.value as string);
			}}
			{...rest}
		>
			{items.map((item) => (
				<MenuItem key={item.id} value={item.id}>
					{fullNameFromApplication(item)}
				</MenuItem>
			))}
		</Select>
	);
};
