export type Path = string[];

/**
 * Parse a string representing a path
 * @param path path represented as a string. eg. ",id1,id2,id3,"
 * @returns an array of strings representing the parsed path. eg. ["id1", "id2", "id3"]
 */
export const parse = (path: string): Path => {
	return path?.slice(1, -1).split(',') ?? [];
};

/**
 * Formats a path represented as an array of strings
 * @param path an array representing the path. eg. ["id1", "id2", "id3"]
 * @returns path represented as a string. eg. ",id1,id2,id3,"
 */
export const format = (path: Path) => {
	return `,${path.join(',')},`;
};

/**
 * Append ids to a path represented as a string
 * @param path path represented as a string. eg. ",id1,id2,id3,"
 * @param ids array of string ids to be appended. eg. ["id4", "id5"]
 * @returns path represented as a string, with the id appended in the provided order. eg. ",id1,id2,id3,id4,id5"
 */
export const append = (path: string, ...ids: Path) => {
	return `${path}${format(ids).slice(1)}`;
};

/**
 * Concatenate two paths represented as strings
 * @param one path represented as a string. eg. ",id1,id2"
 * @param other path represented as a string. eg. ",id3,id4,"
 * @returns concatenated path represented as a string. eg. ",id1,id2,id3,id4,"
 */
export const concat = (one: string, other: string) => {
	return `${one}${other.slice(1)}`;
};

/**
 * Returns the id from the provided level in the path.
 * @param path path represented as a string. eg. ",id1,id2,id3,id4,"
 * @param level integer string representing the level in path, starting from 0. negative values count levels starting
 *  from the end of the path. eg. "1", "-1"
 * @returns the id from the provided level
 * - eg. getFromLevel(",id1,id2,id3,id4,", "1") returns id2
 * - eg. getFromLevel(",id1,id2,id3,id4,", "-1") returns id4
 */
export const getFromLevel = (path: string, level: string) => {
	const index = parseInt(level, 10);

	const parsed = parse(path);

	if (index < 0) {
		return parsed[parsed.length + index];
	}

	return parse(path)[index];
};

export const getBase = (path: string) => {
	return getFromLevel(path, '-1');
};

export const getDepth = (path: string) => {
	return parse(path).length;
};

export const pathsBaseApplicationsContainsId = (paths: string[], id: string) =>
	!!paths?.find((path) => getBase(path) === id);

const validPathPattern = /^,(\w+,)*\w+,$/;

export const validate = (paths: string[]) =>
	paths.every((path) => validPathPattern.test(path));

export default {
	validate,
	parse,
	format,
	append,
	concat,
	getFromLevel,
	getBase,
	getDepth,
	pathsBaseApplicationsContainId: pathsBaseApplicationsContainsId,
};
