import { ApiInfoSettings } from '../../interface/settings';
import { apiClient } from './api';

export interface GetSettingsRequestBody {
	info: ApiInfoSettings;
}

export const getSettings = () => {
	return apiClient.get<GetSettingsRequestBody>(`/api/v2/info`);
};
