import { LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import uniqWith from 'lodash/uniqWith';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { allRequirementsFulfilled } from '../../../../../common-ts/src/application/allRequirementsFulfilled';
import {
	KycProcessClosedStatuses,
	KycStatuses,
} from '../../../../../common-ts/src/application/constants';
import { KnownAttribute } from '../../../../../common-ts/src/customSchemas';
import { IApplication } from '../../../../../common-ts/src/interface/application';
import { IRepositoryEntry } from '../../../../../common-ts/src/interface/datazoo';
import { membersFilter } from '../../../../../common-ts/src/util/membersFilter';
import { fullNameFromApplication } from '../../../../../common/src/helpers/attributes';
import { MemberTypeIcon } from '../../../../../common/src/react/MemberTypeIcon';
import { getAttribute } from '../../../../../common/src/react/helpers';
import { tertiary } from '../../../../../ui/src/colors';
import { Label } from '../../../../../ui/src/materialui/label';
import { CustomerPortalTheme } from '../../../../../ui/src/theme/kyc-default-theme';
import { receiveApplicationsList } from '../../../redux/actions/application';
import { RootState } from '../../../redux/reducers';
import {
	getApplicationById,
	getApplicationStatusText,
	isApplicationEditable,
} from '../../../services/api';
import { SectionNavigation } from '../KYCLayout/Section';
import { MemberDataFillForm } from './MemberDataFillForm';
import { useStyles } from './MembersTable.style';

const getLabelColor = (application: IApplication) => {
	switch (application.currentStatus) {
		case KycStatuses.approved:
		case KycStatuses.uploaded:
			return 'default';
		default:
			if (!allRequirementsFulfilled(application)) {
				return 'secondary';
			}
			return 'default';
	}
};

const getLabelIcon = (application: IApplication) => {
	switch (application.currentStatus) {
		case KycStatuses.approved:
		case KycStatuses.uploaded:
			return <DoneOutlinedIcon />;
		case KycStatuses.cancelled:
		case KycStatuses.rejected:
			return <ClearOutlinedIcon />;
		default:
			if (!allRequirementsFulfilled(application)) {
				return <InfoOutlinedIcon />;
			}
			return <DoneOutlinedIcon />;
	}
};

interface IMembersTableProps {
	application: IApplication;
	datazooAttributesRepository: IRepositoryEntry[];
	nextCallback?: () => void;
	prevCallback?: () => void;
}

export const MembersTable = (props: IMembersTableProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();
	const [loading] = React.useState(false);
	const [currentMemberApplicationId, setCurrentMemberApplicationId] =
		React.useState(null);
	const applicationsInitial = useSelector(
		(store: RootState) => store.applicationStore.applications
	);

	const applications = applicationsInitial
		? applicationsInitial.filter((a) =>
				membersFilter(props.application.id, a.id, a.paths)
		  )
		: [];

	const detailsClick = (memberApplicationId: string) => {
		setCurrentMemberApplicationId(memberApplicationId);
	};

	const onDetailsClose = (
		updatedMemberApplication: IApplication,
		hardRefresh: boolean
	) => {
		// update applications list
		if (updatedMemberApplication && !hardRefresh) {
			const newApplications = [...applications];
			const index = newApplications.findIndex(
				(a) => a.id === updatedMemberApplication.id
			);
			newApplications.splice(index, 1);
			newApplications.push(updatedMemberApplication);
			dispatch(receiveApplicationsList(newApplications));
		} else if (updatedMemberApplication && hardRefresh) {
			getApplicationById(updatedMemberApplication.id).then((app) => {
				const newApplications = [...applications];
				const index = newApplications.findIndex((a) => a.id === app.id);
				newApplications.splice(index, 1);
				newApplications.push(app);

				dispatch(receiveApplicationsList(newApplications));
			});
		}

		setCurrentMemberApplicationId(null);
	};

	const applicationsMap = applications.reduce<Record<string, IApplication>>(
		(obj, current) => {
			obj[current.id] = current;
			return obj;
		},
		{}
	);

	// comparison function to determine if members are unique
	const f = (a: any, b: any) =>
		a.application && b.application && a.application.id === b.application.id;

	// combination of member roles from all applications in the tree
	const memberRoleMap: { [key: string]: string[] } = {};

	const members = uniqWith(
		applications.flatMap((application) => {
			const mem = application.members
				.filter((m) => !m.softDelete)
				.map((m) => ({
					...m,
					application:
						applicationsMap[m.application as string] || ({} as IApplication),
				}));

			// populate member roles
			for (const m of mem) {
				if (m.application && m.application.id) {
					if (!memberRoleMap[m.application.id]) {
						memberRoleMap[m.application.id] = [];
					}
					memberRoleMap[m.application.id] = [
						...memberRoleMap[m.application.id],
						...m.memberRoles,
					];
				}
			}
			return mem;
		}),
		f
	);

	// override roles for each member with combined roles
	for (const m of members) {
		m.memberRoles = memberRoleMap[m.application.id];
	}

	return (
		<CustomerPortalTheme>
			<MemberDataFillForm
				applicationId={currentMemberApplicationId}
				datazooAttributesRepository={props.datazooAttributesRepository}
				onClose={onDetailsClose}
				open={currentMemberApplicationId ? true : false}
			/>

			<Typography variant="h1" className={classes.title}>
				{t('sections.members')}
			</Typography>

			<>
				{members
					.sort((a, b) =>
						(a.application?.id || '').localeCompare(b.application?.id || '')
					)
					.map((memberApplicationAggregate) => {
						const { application, ...memberApplicationEntry } =
							memberApplicationAggregate;

						const email = getAttribute(
							application.attributes,
							KnownAttribute.Email
						).value;

						const errorInfo = !allRequirementsFulfilled(application)
							? classes.missingInfo
							: '';

						const statusText = KycProcessClosedStatuses.includes(
							application.currentStatus
						)
							? getApplicationStatusText(application)
							: !allRequirementsFulfilled(application)
							? getApplicationStatusText(application)
							: 'applicationStatus.information_filled';

						return (
							<div
								className={`${errorInfo} ${classes.tableWrap}`}
								key={`${application.id}-${memberApplicationEntry._id}`}
							>
								<div className={classes.mobile}>
									<div className={classes.memberIcon}>
										<MemberTypeIcon size="default" type={application.type} />
									</div>

									<div className={classes.memberMobileContent}>
										<Typography
											variant="body1"
											color="textPrimary"
											className="semibold"
										>
											{fullNameFromApplication(application)}{' '}
										</Typography>

										<Typography variant="subtitle1" style={{ color: tertiary }}>
											{email}
										</Typography>

										<Typography
											variant="body2"
											color="textPrimary"
											className={classes.bottomSpace}
										>
											{memberApplicationEntry.memberRoles
												.map((r: string) => t(`roles.${r}`))
												.join(', ')}
										</Typography>

										<div>
											<Label
												text={t(statusText)}
												status={getLabelColor(application)}
												icon={getLabelIcon(application)}
											/>
										</div>

										<div>
											<Button
												onClick={() => detailsClick(application.id)}
												color="primary"
												variant="contained"
												className={classes.topSpace}
											>
												{t('membersTable.add_details')}
											</Button>
										</div>
									</div>
								</div>

								<div
									className={`${classes.tableContent} ${classes.hiddenOnMobile}`}
								>
									<div className={classes.memberIcon}>
										<MemberTypeIcon size="default" type={application.type} />
									</div>

									<div className={classes.tableData}>
										<div className={classes.tableDataName}>
											<Typography
												variant="body1"
												color="textPrimary"
												className={`${classes.overflowProtection} ${classes.maxWidth}`}
											>
												{fullNameFromApplication(application)}{' '}
											</Typography>

											<Typography
												variant="subtitle1"
												style={{ color: tertiary }}
											>
												{' '}
												-{' '}
												{memberApplicationEntry.memberRoles
													.map((r: string) => t(`roles.${r}`))
													.join(', ')}
											</Typography>
										</div>

										<div>
											<Typography
												variant="subtitle1"
												style={{ color: tertiary }}
											>
												{email}
											</Typography>
										</div>
									</div>

									<div className="members-table-status">
										<Label
											text={t(statusText)}
											status={getLabelColor(application)}
											icon={getLabelIcon(application)}
										/>
									</div>

									{isApplicationEditable(application) && (
										<div className={classes.membersTableButton}>
											<Button
												onClick={() => detailsClick(application.id)}
												color="primary"
												variant="contained"
											>
												{t('membersTable.add_details')}
											</Button>
										</div>
									)}
								</div>
							</div>
						);
					})}
			</>

			<SectionNavigation
				nextButtonProps={{ disabled: loading }}
				nextCallback={props.nextCallback}
				loading={loading}
				prevCallback={props.prevCallback}
				position="center"
			/>
		</CustomerPortalTheme>
	);
};
