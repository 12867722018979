import { Config, signMessage } from '@wagmi/core';
import jwtDecode from 'jwt-decode';

import { isStorageAvailable } from '../../../../common-ts/src/index';
import { apiClient } from '../../../../common-ts/src/services/network/api';
import { SESSION_ID } from '../../constants';
import {
	IAccessTokenResponse,
	IInvitationData,
	ILoginPayload,
} from '../../models';
import { getChallenge, sendSignature } from '../../services/api/wallet';

export const getMyUser = (jwt?: string) => {
	const fields = ['id', 'email', 'roles', 'name'].join(',');
	const headers = jwt ? { authorization: `Bearer ${jwt}` } : {};

	// TODO: Replace any with type
	return apiClient.get<any>(`/api/v2/users/me?fields=${fields}`, undefined, {
		headers,
	});
};

export const saveSessionId = async (sessionId: string) => {
	if (isStorageAvailable(window.sessionStorage)) {
		sessionStorage.setItem(SESSION_ID, sessionId);
	}
};

export const login = (payload: ILoginPayload, loginMethod?: string) => {
	const url =
		loginMethod === 'jwt' ? '/api/v2/auth/login-jwt' : '/api/v2/auth/login';

	// TODO: Replace any with type
	return apiClient.post<any>(url, payload).then((data) => {
		if (isStorageAvailable(window.sessionStorage) && data.jwt) {
			window.sessionStorage.setItem('kycc_token', data.jwt);
			apiClient.client.defaults.headers.common.authorization =
				'Bearer ' + data.jwt;
		}
		return data;
	});
};

export const purgeSessionStorage = (persistor: any) => {
	if (isStorageAvailable(window.sessionStorage)) {
		window.sessionStorage.removeItem('kycc_token');
		window.sessionStorage.removeItem(SESSION_ID);
	}
	return persistor.purge();
};

export const sendPasswordReset = (email: string) => {
	return apiClient.post<void>('/api/v2/users/access_token', { email });
};

export const getURLWithRedirectToken = () => {
	return apiClient.get<IAccessTokenResponse>(`/api/v2/users/me/access_token`);
};

export const buildUrlApplicationWithAccessToken = (
	accessToken: string,
	applicationId: string
) => {
	const prefix = process.env.BASE_URL || window.location.origin;
	const sessionId = sessionStorage.getItem(SESSION_ID);
	return `${prefix}/api/v2/applications/${applicationId}?access_token=${accessToken}&sessionId=${sessionId}`;
};

export const setPassword = (password: string) => {
	return apiClient.post<void>('/api/v2/users/me/credentials', { password });
};

export const signUp = (data: IInvitationData, jwt?: string) => {
	const headers = jwt ? { authorization: `Bearer ${jwt}` } : {};

	return apiClient.post<void>('/api/v2/invitations', data, { headers });
};

// TODO: Replace any with type
export const createUser = (jwt: string, data: any) => {
	return apiClient.post<void>(`/api/v2/users`, data, {
		headers: { authorization: `Bearer ${jwt}` },
	});
};

export const authWallet = async (account: `0x${string}`, config: Config) => {
	const did = `did:eth:${account}`;
	const challenge = await getChallenge(did);
	const authJwt = challenge.jwt;
	const parsedAuthJwt: any = jwtDecode(authJwt);
	const signature = await signMessage(config, {
		account,
		message: parsedAuthJwt.nonce,
	});
	const keyId = `${did}#keys-1`;
	const signatureRes = await sendSignature(authJwt, {
		signature: { value: signature, keyId },
	});
	return signatureRes.jwt;
};

export const verifyRecaptcha = (recaptcha?: string) => {
	return apiClient.post<void>(`/api/v2/auth/recaptcha`, { recaptcha });
};
