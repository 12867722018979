import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { KYCStatusInputProps } from './KYCStatusText';

import { KycStatuses } from '../../../../../common-ts/src/application/constants';
import { userLogout } from '../../../redux/actions/user';

import { SettingsContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}));

const APPROVED: React.FC<KYCStatusInputProps> = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const logout = () => {
		dispatch(userLogout());
		history.push('/auth/sign-in');
	};
	const classes = useStyles();
	const settings = React.useContext(SettingsContext);
	return (
		<Paper className={classes.root}>
			<Grid
				spacing={2}
				container
				justify="center"
				direction="column"
				alignItems="center"
			>
				<Grid item xs={12}>
					<Typography align="center" variant="h2" gutterBottom={true}>
						{t('kycStatuses.approved')}
					</Typography>
					<Typography align="center" variant="body1" gutterBottom={true}>
						{t('statusPage.approved.description', {
							supportEmail: settings.supportEmail,
						})}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button onClick={logout} variant="contained">
						Sign Out
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};

const REJECTED: React.FC<KYCStatusInputProps> = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const logout = () => {
		dispatch(userLogout());
		history.push('/auth/sign-in');
	};
	const classes = useStyles();
	const settings = React.useContext(SettingsContext);
	return (
		<Paper className={classes.root}>
			<Grid
				spacing={2}
				container
				justify="center"
				direction="column"
				alignItems="center"
			>
				<Grid item xs={12}>
					<Typography align="center" variant="h2" gutterBottom={true}>
						{t('kycStatuses.rejected')}
					</Typography>
					<Typography align="center" variant="body1" gutterBottom={true}>
						{t('statusPage.rejected.description', {
							supportEmail: settings.supportEmail,
						})}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button onClick={logout} variant="contained">
						Sign Out
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};

const CANCELLED: React.FC<KYCStatusInputProps> = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const logout = () => {
		dispatch(userLogout());
		history.push('/auth/sign-in');
	};
	const classes = useStyles();
	const settings = React.useContext(SettingsContext);
	return (
		<Paper className={classes.root}>
			<Grid
				spacing={2}
				container
				justify="center"
				direction="column"
				alignItems="center"
			>
				<Grid item xs={12}>
					<Typography align="center" variant="h2" gutterBottom={true}>
						{t('kycStatuses.cancelled')}
					</Typography>
					<Typography align="center" variant="body1" gutterBottom={true}>
						{t('statusPage.cancelled.description', {
							supportEmail: settings.supportEmail,
						})}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button onClick={logout} variant="contained">
						Sign Out
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};

const USER_DECLINED: React.FC<KYCStatusInputProps> = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const logout = () => {
		dispatch(userLogout());
		history.push('/auth/sign-in');
	};
	const classes = useStyles();
	const settings = React.useContext(SettingsContext);
	return (
		<Paper className={classes.root}>
			<Grid
				spacing={2}
				container
				justify="center"
				direction="column"
				alignItems="center"
			>
				<Grid item xs={12}>
					<Typography align="center" variant="h2" gutterBottom={true}>
						{t('kycStatuses.userDeclined')}
					</Typography>
					<Typography align="center" variant="body1" gutterBottom={true}>
						{t('statusPage.userDeclined.description', {
							supportEmail: settings.supportEmail,
						})}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button onClick={logout} variant="contained">
						Sign Out
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};

const UPLOADED: React.FC<KYCStatusInputProps> = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const logout = () => {
		dispatch(userLogout());
		history.push('/auth/sign-in');
	};
	const classes = useStyles();
	const settings = React.useContext(SettingsContext);
	return (
		<Paper className={classes.root}>
			<Grid
				spacing={2}
				container
				justify="center"
				direction="column"
				alignItems="center"
			>
				<Grid item xs={12}>
					<Typography align="center" variant="h2" gutterBottom={true}>
						{t('kycStatuses.uploaded')}
					</Typography>
					<Typography variant="body1" gutterBottom={true}>
						{t('statusPage.uploaded.description', {
							supportEmail: settings.supportEmail,
						})}
					</Typography>
					<Typography variant="body1" gutterBottom={true}>
						{t('statusPage.uploaded.editNotAllowedDescription', {
							supportEmail: settings.supportEmail,
						})}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button onClick={logout} variant="contained">
						{t(`statusPage.uploaded.signOut`)}
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};

export const StatusPages: any = {};

StatusPages[KycStatuses.approved] = APPROVED;
StatusPages[KycStatuses.rejected] = REJECTED;
StatusPages[KycStatuses.cancelled] = CANCELLED;
StatusPages[KycStatuses.user_declined] = USER_DECLINED;
StatusPages[KycStatuses.uploaded] = UPLOADED;

StatusPages[KycStatuses.manager_assigned] = UPLOADED;
StatusPages[KycStatuses.partially_approved] = UPLOADED;
StatusPages[KycStatuses.data_check] = UPLOADED;
StatusPages[KycStatuses.reopened] = UPLOADED;
