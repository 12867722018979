import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DoneAll from '@material-ui/icons/DoneAll';
import HourGlass from '@material-ui/icons/HourglassEmpty';
import PlayCircle from '@material-ui/icons/PlayCircleFilled';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	KycProcessApplicantActionRequiredStatuses,
	KycProcessClosedStatuses,
	KycStatuses,
	getNameForStatus,
} from '../../../../../common-ts/src/application/constants';
import { IApplication } from '../../../../../common-ts/src/interface/application';
import { ITemplateSimple } from '../../../../../common-ts/src/interface/template';
import { primary, tertiary } from '../../../../../ui/src/colors';
import { Label } from '../../../../../ui/src/materialui/label';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import { setCurrentApplication } from '../../../redux/actions/application';
import { RootState } from '../../../redux/reducers';
import { selectableApplicationFilter } from '../../Main';
import { TitleArea } from './TitleArea';

const useStyles = makeStyles({
	bold: {
		fontWeight: 600,
	},
	bottomSpace: {
		marginBottom: '36px',
	},
	listItem: {
		'&:hover': {
			backgroundColor: '#F9F9FB',
			border: '2px solid #394C65',
		},
		border: '2px solid #B9C2CC',
		borderRadius: '4px',
		marginBottom: '20px',
		padding: '15px 20px',
	},
	listItemElement: {
		'&:hover': {
			backgroundColor: 'inherit',
		},
		borderBottom: 'none',
		borderRadius: '2px',
		flexDirection: 'column',
		padding: 0,
	},
	nav: {
		backgroundColor: 'none',
	},
	primary: {
		color: primary,
		fontSize: '14px',
		fontWeight: 'bold',
		lineHeight: '20px',
	},
	secondary: {
		color: tertiary,
		display: 'block',
		fontSize: '12px',
		lineHeight: '18px',
		marginBottom: '10px',
	},
	tableHeader: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	tableWrap: {
		marginBottom: '20px',
	},
});

export const getSimpleStatusTranslation = (
	status: number,
	t: TFunction<string>
) => {
	const text = getNameForStatus(status);

	if (!KycProcessClosedStatuses.includes(status)) {
		const waitingForInput = !!KycProcessApplicantActionRequiredStatuses.find(
			(s) => status === s
		);
		return {
			color: 'primary',
			icon: <HourGlass />,
			text: waitingForInput
				? t('kycStatuses.inProgress')
				: t('kycStatuses.underReview'),
		};
	}

	if (KycProcessClosedStatuses.includes(status)) {
		return {
			color: status === KycStatuses.approved ? 'default' : 'error',
			icon: status === KycStatuses.approved ? <DoneAll /> : <CloseIcon />,
			text,
		};
	}

	return {
		color: 'secondary',
		icon: <PlayCircle />,
		text: 'not-started',
	};
};

export const ListApplications: React.FC<{ templates: ITemplateSimple[] }> = (
	props
) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { templates } = props;

	const applications: IApplication[] = useSelector(
		(store: RootState) => store.applicationStore.applications
	).filter((a) => selectableApplicationFilter(a, templates));

	if (applications.length === 1) {
		dispatch(setCurrentApplication(applications[0].id));
	}

	return (
		<CustomerPortalTheme>
			<TitleArea
				title={t(
					`listApplications.${applications.length ? 'title' : 'title-empty'}`
				)}
				description={t(
					`listApplications.${
						applications.length ? 'description' : 'description-empty'
					}`
				)}
				xtraClass={classes.bottomSpace}
			/>
			<div className={classes.nav}>
				{applications.map((application) => {
					const template = templates.find(
						(tmpl) => tmpl.templateId === application.template
					);
					const status = getSimpleStatusTranslation(
						application.currentStatus,
						t
					);
					return (
						<div key={application.id} className={classes.listItem}>
							<ListItem
								button
								divider={true}
								onClick={() => dispatch(setCurrentApplication(application.id))}
								alignItems="flex-start"
								className={classes.listItemElement}
							>
								<ListItemText
									style={{ margin: 0, padding: 0 }}
									primary={
										template
											? template.name
											: t('listApplications.inactive-template')
									}
									secondary={template ? template.description : ''}
									classes={{
										primary: classes.primary,
										secondary: classes.secondary,
									}}
								/>
								<Label
									icon={status.icon}
									text={status.text}
									status={status.color as any}
								/>
							</ListItem>
						</div>
					);
				})}
			</div>
		</CustomerPortalTheme>
	);
};
