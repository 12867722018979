import { KycStatuses } from '../../../../../common-ts/src/application/constants';
import { IApplication } from '../../../../../common-ts/src/interface/application';
import { apiClient } from '../../../../../common-ts/src/services/network/api';

export const acceptTerms = (
	id: string,
	data: { formData: { [id: string]: string | boolean } } = null
) => {
	return apiClient.put<IApplication>(`/api/v2/applications/${id}/terms`, data);
};

export const isTermsAccepted = (application: IApplication) => {
	return application.statusLog.some((log) =>
		[
			KycStatuses.terms_accepted,
			KycStatuses.user_processing_requirement,
			KycStatuses.uploaded,
		].includes(log.code)
	);
};
