import { apiClient } from '../../../../../common-ts/src/services/network/api';

export const addOrSkipIDJourneyId = (id: string, journeyId: string) => {
	// TODO: Replace any with type
	return apiClient.post<any>(`/api/v2/applications/${id}/id-verification`, {
		journeyId,
		skip: journeyId ? false : true,
	});
};

export const createAIScanSession = (id: string) => {
	return apiClient.post<any>(`/api/v2/applications/${id}/id-verification`, {
		skip: false,
	});
};
