import * as React from 'react';
import BaseField, { IBaseFieldProps } from './base-field';

export interface ISelectFieldOption {
	value: string;
	key: string;
}

interface ISelectFieldProps extends IBaseFieldProps {
	options: ISelectFieldOption[];
}

class SelectField extends BaseField<ISelectFieldProps, {}> {
	public render() {
		return (
			<select
				style={this.props.style}
				value={this.props.value || ''}
				onChange={(event: any) => {
					this.onChange({
						value: event.target.value,
					});
				}}
				disabled={this.props.disabled}
			>
				{this.props.options?.map((item, index) => {
					return (
						<option key={index} value={item.value}>
							{item.key}
						</option>
					);
				})}
			</select>
		);
	}
}

export default SelectField;
