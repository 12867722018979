import {
	ITemplateSimple,
	IKYCTemplateQueryResponseType,
} from '../../../../common-ts/src/interface/template';

import { apiClient } from '../../../../common-ts/src/services/network/api';

export const hasTerms = (
	templateMetadata: ITemplateSimple['metadata']
): boolean => {
	return templateMetadata.terms && templateMetadata.termsActive;
};

export const getTemplates = () => {
	return apiClient.get<ITemplateSimple[]>('/api/v2/templates', {
		active: true,
	});
};

export const getTemplateById = (
	id: string,
	format: IKYCTemplateQueryResponseType = IKYCTemplateQueryResponseType.FULL
) => {
	return apiClient.get<ITemplateSimple>(`/api/v2/templates/${id}`, {
		format,
	});
};
