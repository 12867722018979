import * as React from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import { withTranslation } from 'react-i18next';

import { Grid, InputLabel, Typography } from '@material-ui/core';
import { ISignatureInput } from './SignatureDialog';

interface ITypeSignatureProps extends ISignatureInput {
	defaultInitials?: string;
	classes: any;
	proportion?: number;
}

interface ITypeSignatureState {
	initials: string;
	fontSize: number;
}

const styles = () =>
	createStyles({
		bold: {
			fontWeight: 600,
		},
		button: {
			'&:focus': {
				backgroundColor: '#064780',
			},
			'&:hover': {
				backgroundColor: '#043158',
			},
			backgroundColor: '#064780',
			borderRadius: '0 0 4px 4px',
			color: '#fff',
			marginTop: '-60px',
			position: 'relative',
			width: '100%',
		},
		fontSize: {
			'@media (max-width: 800px)': {
				'& div': {
					minWidth: 0,
				},
				minWidth: 0,
			},
		},
		icon: {
			marginRight: '10px',
		},
		inputRoot: {
			marginBottom: '8px',
		},
		paper: {
			'& canvas': {
				backgroundColor: '#F8F8FC',
				border: '2px solid #D5D5E3',
				borderRadius: '4px',
				height: '180px',
				width: '100%',
			},
			'@media (max-width: 800px)': {
				'& canvas': {
					height: '140px',
					width: '100%',
				},
			},
			boxShadow: 'none',
			boxSizing: 'border-box',
			marginBottom: '20px',
			padding: '0 4px 0 0',
		},
		titleWrap: {
			marginBottom: '20px',
		},
		wrap: {
			'@media (max-width: 800px)': {
				width: '100%',
			},
			width: '380px',
		},
	});

class TypeSignature extends React.Component<
	ITypeSignatureProps,
	ITypeSignatureState
> {
	private canvasRef: React.RefObject<HTMLCanvasElement>;

	constructor(props: ITypeSignatureProps) {
		super(props);
		this.state = {
			fontSize: 24,
			initials: this.props.defaultInitials ? this.props.defaultInitials : '',
		};

		this.canvasRef = React.createRef();
	}

	public componentDidMount() {
		if (this.props.defaultInitials !== '') {
			this.drawCanvas();
		}
	}

	public componentDidUpdate(
		prevProps: ITypeSignatureProps,
		prevState: ITypeSignatureState
	) {
		if (
			prevState.initials !== this.state.initials ||
			this.state.initials === this.props.defaultInitials ||
			prevState.fontSize !== this.state.fontSize
		) {
			this.drawCanvas();
		}

		if (
			this.props.signatureRequested &&
			this.props.signatureRequested !== prevProps.signatureRequested
		) {
			this.props.onSignatureUpdated(this.canvasRef.current.toDataURL());
		}
	}

	public render() {
		const { classes, t } = this.props;

		return (
			<div className={classes.wrap}>
				<div className={classes.titleWrap}>
					<InputLabel shrink classes={{ root: classes.inputRoot }}>
						{t('signatureDialog.type.textField')}
					</InputLabel>
					<TextField
						id="initials"
						value={this.state.initials}
						onChange={this.handleChange('initials')}
						placeholder={t('signatureDialog.type.textField')}
						fullWidth
					/>
				</div>
				<Paper classes={{ root: classes.paper }}>
					<canvas ref={this.canvasRef} />
				</Paper>
				<Button
					onClick={this.clearCanvas}
					className={classes.button}
					variant="contained"
					color="primary"
					fullWidth
				>
					<Grid container justify="center" alignItems="center">
						<ClearIcon className={classes.icon} />
						<Typography variant="body2" className={classes.bold}>
							{t('signatureDialog.type.clear')}
						</Typography>
					</Grid>
				</Button>
			</div>
		);
	}

	private handleChange =
		(name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			this.setState({ [name]: event.target.value } as Pick<
				ITypeSignatureState,
				any
			>);
		};

	private clearCanvas = () => {
		this.setState({ initials: '', fontSize: 24 });
	};

	private drawCanvas = () => {
		const ctx = this.canvasRef.current.getContext('2d');
		const { width, height } = this.canvasRef.current;
		ctx.clearRect(0, 0, width, height);
		ctx.font = `${this.state.fontSize}px verdana`;
		ctx.textAlign = 'center';

		ctx.fillText(
			this.state.initials,
			width / 2,
			height / 2 + this.state.fontSize / 3
		);
	};
}

export default withStyles(styles)(withTranslation()(TypeSignature));
