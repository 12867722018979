import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { ITemplateSimple } from '../../../../common-ts/src/interface/template';
import { getTemplateById, getTemplates } from '../../services/api';

export enum TemplateActions {
	FETCH_SIGNUP_TEMPLATE = 'FETCH_SIGNUP_TEMPLATE',
	RECEIVE_SIGNUP_TEMPLATE = 'RECEIVE_SIGNUP_TEMPLATE',
	FETCH_TEMPLATES = 'FETCH_TEMPLATES',
	RECEIVE_TEMPLATES = 'RECEIVE_TEMPLATES',
	API_ERROR = 'API_ERROR',
}

interface ITemplateActionFetchSignUp
	extends Action<TemplateActions.FETCH_SIGNUP_TEMPLATE> {}

interface ITemplatesActionFetch
	extends Action<TemplateActions.FETCH_TEMPLATES> {}

interface ITemplatesActionReceive
	extends Action<TemplateActions.RECEIVE_TEMPLATES> {
	templates: ITemplateSimple[];
}

interface ITemplateActionReceiveSignup
	extends Action<TemplateActions.RECEIVE_SIGNUP_TEMPLATE> {
	signupTemplate: ITemplateSimple;
}

interface ITemplatesActionApiError extends Action<TemplateActions.API_ERROR> {
	error: any;
}

export type ITemplateActions =
	| ITemplateActionFetchSignUp
	| ITemplatesActionFetch
	| ITemplateActionReceiveSignup
	| ITemplatesActionReceive
	| ITemplatesActionApiError;

export const loadSignupTemplate = (): ITemplateActionFetchSignUp => ({
	type: TemplateActions.FETCH_SIGNUP_TEMPLATE,
});

export const loadTemplatesList = (): ITemplatesActionFetch => ({
	type: TemplateActions.FETCH_TEMPLATES,
});

export const apiError = (error: any): ITemplatesActionApiError => ({
	type: TemplateActions.API_ERROR,
	error,
});

export const receiveSignupTemplate = (
	signupTemplate: ITemplateSimple
): ITemplateActionReceiveSignup => ({
	signupTemplate,
	type: TemplateActions.RECEIVE_SIGNUP_TEMPLATE,
});

export const receiveTemplatesList = (
	templates: ITemplateSimple[]
): ITemplatesActionReceive => ({
	templates,
	type: TemplateActions.RECEIVE_TEMPLATES,
});

export const loadTemplates = () => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(loadTemplatesList());
		return getTemplates()
			.then((templates: ITemplateSimple[]) => {
				dispatch(receiveTemplatesList(templates));
			})
			.catch((err) => {
				dispatch(apiError(err.response));
			});
	};
};

export const loadSignupTemplateById = (id: string) => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(loadSignupTemplate());
		return getTemplateById(id)
			.then((template: ITemplateSimple) => {
				dispatch(receiveSignupTemplate(template));
			})
			.catch((err) => {
				dispatch(apiError(err.response));
			});
	};
};
