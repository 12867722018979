import { apiClient } from '../../../../common-ts/src/services/network/api';

export const getTermsDocument = async (id: string) => {
	// TODO: Replace any with type
	return apiClient.get<any>(`/api/v2/forms/${id}`);
};

export const getFormPage = async (id: string, page: number) => {
	// TODO: Replace any with type
	return apiClient.get<any>(`/api/v2/forms/${id}/pages/${page}`, undefined, {
		responseType: 'arraybuffer',
	});
};

export const getFormPageUrl = (id: string, page: number) => {
	return `/api/v2/forms/${id}/pages/${page}`;
};
