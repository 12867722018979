import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export interface IFileStatusViewProps {
	type: 'error' | 'primary';
	message: string;
	tryAgainFunc?: () => any;
}

export const FileStatusView = (props: IFileStatusViewProps) => {
	const { t } = useTranslation();
	return (
		<>
			<Typography
				color={props.type}
				align="center"
				variant="body1"
				gutterBottom={true}
			>
				{t(props.message)}
			</Typography>
			{props.tryAgainFunc && (
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						props.tryAgainFunc();
					}}
				>
					Try Again
				</Button>
			)}
		</>
	);
};
