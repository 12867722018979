import { IUserFields } from '../../../common-ts/src/interface/user';

export const getAttribute = (
	attributes: any,
	schemaId: string,
	fallback?: any
): any => {
	const attributeId = Object.keys(attributes).find((attrId) => {
		const attribute = attributes[attrId];
		return attribute.schemaId === schemaId;
	});
	return attributes[attributeId] ? { ...attributes[attributeId] } : fallback;
};

export const fullName = (user: IUserFields): string =>
	user?.name ?? user?.email ?? '';

export const getFileNameByContentId = (files: any[], id: string) => {
	const foundFile = files.find((f) => f._id === id);
	return foundFile ? foundFile.fileNameToShow : id;
};
