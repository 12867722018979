import { IAttributesObject } from '../../../common-ts/src/interface/application';

export const getAttributeIdBySchemaId = (
	attributes: IAttributesObject,
	schemaId: string
) => {
	return Array.from(Object.keys(attributes)).find(
		(key) => attributes[key].schemaId === schemaId
	);
};
