import * as React from 'react';

import { ErrorMessage } from './ErrorMessage';
import { IErrorPage } from './index';

export const InvalidScopePage: React.FC<IErrorPage> = ({ message, title }) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: '50px',
			}}
		>
			<ErrorMessage message={message} title={title} />
		</div>
	);
};
