import * as React from 'react';

export const PrivacyPolicyPage = () => {
	const file = 'https://cdn.kyc-chain.com/KYCC-Services-Privacy-Notice.pdf';
	return (
		<div
			style={{ width: '100%', height: '100vw', border: '1px solid #d3d3d3' }}
		>
			<embed type="application/pdf" src={file} width="100%" height="100%" />
		</div>
	);
};
