import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { dataURItoFile } from '../../util/dataUriToFile';
import { KYCWebcam } from './KYCWebcam';
import './WebcamPopup.scss';

const useStyles = makeStyles({
	closeIcon: {
		'& svg': {
			height: '30px',
			width: '30px',
		},
		'@media (max-width: 600px)': {
			marginRight: '-20px',
			marginTop: '-40px',
		},
		marginRight: '-60px',
		marginTop: '-90px',
		position: 'relative',
	},
	content: {
		padding: 0,
	},
	dialog: {
		'& [role="dialog"]': {
			maxWidth: '520px',
		},
	},
	iconWrap: {
		marginBottom: '-20px',
		marginTop: '10px',
		textAlign: 'right',
	},
});

interface IWebcamPopupProps {
	onImageCapture: any;
	onClose: any;
	isOpen: boolean;
}

export const WebcamPopup = (props: IWebcamPopupProps) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const onImageCapture = (p: any) => {
		if (p && props.onImageCapture) {
			if (p.base64) {
				props.onImageCapture(dataURItoFile(p.base64));
			} else if (p.file) {
				props.onImageCapture(p.file);
			}
		}
	};

	return (
		<Dialog
			open={props.isOpen}
			aria-labelledby="form-dialog-title"
			fullWidth={true}
			maxWidth="md"
			scroll="paper"
			className={classes.dialog}
		>
			<div className={classes.iconWrap}>
				<IconButton
					color="inherit"
					onClick={() => props.onClose()}
					className={classes.closeIcon}
				>
					<CloseIcon />
				</IconButton>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'start',
					marginBottom: '20px',
				}}
			>
				<Typography variant="h1" color="textPrimary" id="form-dialog-title">
					{t('webcam.title')}
				</Typography>
			</div>
			<DialogContent classes={{ root: classes.content }}>
				<KYCWebcam onImageCapture={onImageCapture} />
			</DialogContent>
		</Dialog>
	);
};
