import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { downloadFile } from '../../models/files';
import { FileStatusView } from './file-status-view';

export interface IPDFPreviewProps {
	fileId: string;
	canDownload: boolean;
}

export const PDFPreviewV2 = (props: IPDFPreviewProps) => {
	const [loading, setLoading] = useState(false);
	const [loadedFileBlob, setLoadedFileBlob] = useState(null);
	const [hasError, setError] = useState(false);

	const height =
		(window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight) * 0.85;

	const loadFile = () => {
		setLoading(true);
		setError(false);
		downloadFile(props.fileId)
			.then((res) => {
				const fileURL = window.URL.createObjectURL(
					new Blob([res], { type: 'application/pdf' })
				);
				setLoadedFileBlob(fileURL);
				setLoading(false);
			})
			.catch(() => {
				setError(true);
			});
	};

	useEffect(() => {
		loadFile();
	}, []);

	return (
		<>
			{hasError && (
				<FileStatusView
					message={'fileStatus.genericError'}
					type={'error'}
					tryAgainFunc={loadFile}
				/>
			)}
			{loading && !hasError && <LinearProgress variant="query" />}
			{!hasError && (
				<>
					{!(window as any).safari ? (
						<Button
							target="_blank"
							href={`${loadedFileBlob}${props.canDownload ? '' : '#toolbar=0'}`}
						>
							Open in a new tab
						</Button>
					) : null}
					<object
						height={height}
						width="100%"
						type="application/pdf"
						data={`${loadedFileBlob}${props.canDownload ? '' : '#toolbar=0'}`}
					/>
				</>
			)}
		</>
	);
};
