import { makeStyles } from '@material-ui/core/styles';

import { accent } from '../../../../ui/src/colors';

export const useStyles = makeStyles({
	button: {
		'&$disabled': {
			backgroundColor: accent,
			opacity: '0.3',
		},
		'&:hover': {
			backgroundColor: accent,
			boxShadow: 'none',
		},
		backgroundColor: accent,
		marginBottom: '1em',
		marginTop: '2em',
	},
	buttonWrapper: {
		margin: 0,
	},
	dividerText: {
		textAlign: 'center',
	},
	errorMessage: {
		fontSize: '12px',
		textAlign: 'left',
	},
	input: {
		marginTop: '1.5em',
	},
	visibilityIcon: {
		fontSize: '17px',
	},
});

export const goToPrivacyPolicy = () => {
	window.open(`/privacy-policy`, '_blank');
};
