import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LoadingButton from '../../../../../common/src/react/LoadingButton';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';

const useStyles = makeStyles({
	backBtn: {
		'@media (max-width: 960px)': {
			left: '16px',
			position: 'absolute',
		},
		marginRight: '10px',
	},
	nextBtn: {
		'@media (max-width: 960px)': {
			position: 'absolute',
			right: '16px',
		},
	},
	sectionButton: {
		margin: '10px',
	},
	topSpace: {
		marginTop: '28px',
		position: 'relative',
	},
	topSpaceRight: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	topSpaceCenter: {
		display: 'flex',
		justifyContent: 'center',
	},
	wrapper: {
		display: 'inline-block',
		position: 'relative',
	},
	termsMobileCta: {
		'@media (max-width: 960px)': {
			position: 'fixed',
			bottom: 30,
			right: 0,
			width: '100%',
			padding: '10px 0',
			marginTop: 0,
			background: 'white',

			'& button': {
				position: 'relative',
				right: '0',
			},
		},
	},
});

export interface ISectionNavigationProps {
	nextCallback?: (metadata?: any) => void;
	prevCallback?: () => void;
	hasPrevSection?: boolean;
	nextButtonProps?: any;
	prevButtonProps?: any;
	prevButtonText?: string;
	nextButtonText?: string;
	loading?: boolean;
	position?: string;
	origin?: string;
}

export interface IKYCSectionProps extends ISectionNavigationProps {
	title?: string;
}

export const SectionNavigation: React.FC<ISectionNavigationProps> = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const displayBackButton = props.hasPrevSection
		? props.prevCallback
		: props.hasPrevSection === undefined && props.prevCallback;
	const displayNextButton =
		typeof props.nextCallback === 'function' ||
		(props.nextButtonProps && props.nextButtonProps.type === 'submit');
	return (
		<CustomerPortalTheme>
			<div
				className={`${classes.topSpace} ${
					props.position === 'right' && classes.topSpaceRight
				} ${props.position === 'center' && classes.topSpaceCenter} ${
					props.origin === 'terms' && classes.termsMobileCta
				}`}
			>
				{displayBackButton ? (
					<Button
						color="primary"
						variant="contained"
						onClick={props.prevCallback}
						disabled={props.loading}
						className={classes.backBtn}
						{...props.prevButtonProps}
					>
						←{' '}
						{props.prevButtonText
							? t(props.prevButtonText)
							: t('sections.back')}
					</Button>
				) : null}
				{displayNextButton ? (
					<LoadingButton
						variant="contained"
						className={classes.nextBtn}
						onClick={props.nextCallback}
						disabled={props.loading}
						loading={props.loading}
						{...props.nextButtonProps}
					>
						{props.nextButtonText
							? t(props.nextButtonText)
							: `${t('sections.next')} →`}
					</LoadingButton>
				) : null}
			</div>
		</CustomerPortalTheme>
	);
};
