import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';
import { get, isArray, isEmpty, isEqual } from 'lodash';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IApplication } from '../../../../common-ts/src/interface/application';
import CustomerPortalTheme from '../../../../ui/src/theme/kyc-default-theme';
import { IFile } from '../../models/files';
import { DatePickerWidget } from '../Widgets/DatePickerWidget';
import { ImageWidget } from '../Widgets/ImageWidget';
import { SelfieWidget } from '../Widgets/SelfieWidget';
import { TextInputWidget } from '../Widgets/TextInputWidget';
import { FileUploadContext } from './FileUploadContext';
import { useStyles } from './UploadDocumentDialog.style';

interface IUploadDialogProps {
	applicationId: string;
	mainProperty: any;
	open: boolean;
	onClose: (updatedApplication?: IApplication) => void;
	propertyId: string;
	title: string;
	description: string;
	widgets: any[];
	fileNames: any[];
	onClearAttribute: (
		applicationId: string,
		propertyId: string
	) => Promise<IApplication>;
	onUploadFiles: (
		files: Array<{
			file: {
				content?: string;
				name: string;
			};
			name: string;
		}>
	) => Promise<IFile | IFile[]>;
	onUpdateAttribute: (
		applicationId: string,
		propertyId: string,
		requestParams: any
	) => Promise<IApplication>;
	onSubmit?: () => void | Promise<void>;
	refreshApplication?: (err: AxiosError) => void;
	imageUploadInsteadOfSelfie?: boolean;
}

export interface IWidgetType {
	multiple: boolean;
	sorting: number;
	widget: any;
}

interface IUpload {
	schema: string;
	file: {
		content?: string;
		name: string;
	};
	label: string;
	itemKey: string;
}

interface GetWidgetTypeMapOptions {
	imageUploadInsteadOfSelfie: boolean;
}

export const getWidgetTypeMap = (options?: GetWidgetTypeMapOptions) => {
	const { imageUploadInsteadOfSelfie = false } = options ?? {};

	const widgetTypeMap: { [key: string]: IWidgetType } = {
		'http://platform.selfkey.org/schema/file/image.json': {
			multiple: false,
			sorting: 0,
			widget: ImageWidget,
		},
		'http://platform.selfkey.org/schema/file/multi-image.json': {
			multiple: true,
			sorting: 0,
			widget: ImageWidget,
		},
		'http://platform.selfkey.org/schema/misc/expiry-date.json': {
			multiple: false,
			sorting: 3,
			widget: DatePickerWidget,
		},
		'http://platform.selfkey.org/schema/misc/extra-images.json': {
			multiple: true,
			sorting: 0,
			widget: ImageWidget,
		},
		'http://platform.selfkey.org/schema/misc/issue-date.json': {
			multiple: false,
			sorting: 3,
			widget: DatePickerWidget,
		},
		'http://platform.selfkey.org/schema/misc/selfie.json': {
			multiple: false,
			sorting: 1,
			widget: imageUploadInsteadOfSelfie ? ImageWidget : SelfieWidget,
		},
	};

	return widgetTypeMap;
};

const InputWidget: IWidgetType = {
	multiple: false,
	sorting: 3,
	widget: TextInputWidget,
};

const formatArrayUpload = (uploadResponse: IFile | IFile[], files: IUpload[]) =>
	files
		.filter((f) => !f.file.content)
		.map((file) => {
			const uploadedFile = Array.isArray(uploadResponse)
				? uploadResponse.find((r: IFile) => r.originalname === file.file.name)
				: uploadResponse;
			const content = uploadedFile.id;
			return { content };
		});

interface IFileAttributeUpdate {
	content: string;
	file?: IUpload;
}

const formatFileAttributeUpdate = (
	uploadResponse: IFile | IFile[],
	files: IUpload[]
) => {
	const multiple = [
		'http://platform.selfkey.org/schema/file/extra-images.json',
		'http://platform.selfkey.org/schema/misc/extra-images.json',
		'http://platform.selfkey.org/schema/file/multi-image.json',
	];
	const data:
		| { [key: string]: IFileAttributeUpdate }
		| { [key: string]: { image: IFileAttributeUpdate } }
		| { [key: string]: IFileAttributeUpdate[] } = {};

	for (const file of files.filter((f) => !f.file.content)) {
		const uploadedFile = Array.isArray(uploadResponse)
			? uploadResponse.find((r: IFile) => r.originalname === file.file.name)
			: uploadResponse;

		if (file.schema === 'http://platform.selfkey.org/schema/misc/selfie.json') {
			data[file.itemKey] = {
				image: {
					content: uploadedFile.id,
				},
			};
		} else if (multiple.includes(file.schema)) {
			if (!data[file.itemKey]) {
				data[file.itemKey] = [];
			}
			(data[file.itemKey] as IFileAttributeUpdate[]).push({
				content: uploadedFile.id,
			});
		} else {
			data[file.itemKey] = {
				content: uploadedFile.id,
			};
		}
	}
	return data;
};

interface IEditContext {
	fields: any[];
	files: IUpload[];
}

type Context = [any, any];

export const UploadDialogEditContext = React.createContext<Context>({} as any);

export const UploadDocumentDialog = ({
	imageUploadInsteadOfSelfie,
	...props
}: IUploadDialogProps) => {
	if (!props.open) {
		return null;
	}

	const widgetTypeMap = React.useMemo(
		() => getWidgetTypeMap({ imageUploadInsteadOfSelfie }),
		[imageUploadInsteadOfSelfie]
	);

	const [state] = useContext(FileUploadContext);

	const classes = useStyles();
	const { t } = useTranslation();
	// temporary copy for editing
	const editContext: IEditContext = {
		fields: [...state.fields],
		files: [
			...state.files.map((f: IFile) => ({
				...f,
				id: Math.random().toString(36).substring(7),
			})),
		],
	};
	const [tempState, dispatch] = useState<IEditContext>(editContext);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [widgetErrors, setWidgetErrors] = useState(null);

	const getAddedFiles = (files: IUpload[]) => {
		return files.filter((f) => f.file && !f.file.content);
	};

	const getRemovedFiles = (files: IUpload[], originalFiles: IUpload[]) => {
		const contentIds = files
			.filter((f) => f.file && f.file.content)
			.map((f) => f.file.content);
		return originalFiles.filter((f) => !contentIds.includes(f.file.content));
	};

	const getEditedFields = (fields: any[], originalFields: any[]) => {
		return fields.filter((field: any) => {
			const currentField = originalFields.find(
				(f: any) => f.itemKey === field.itemKey
			);
			return !currentField || !isEqual(field.value, currentField.value);
		});
	};

	const areFilesCleared = (files: IUpload[]) => {
		return files.every((f) => !f.file);
	};

	const handleAPIErrors = (
		err: Error | AxiosError,
		functionName: string,
		tPrefix: string
	) => {
		const e = (err as AxiosError)?.response ?? err;
		const ex = (err as AxiosError)?.response
			? new Error('uploadFilesError')
			: err;
		const status = get(err, 'response.status', null);
		const details = get(err, 'response.data.details');
		let messages: string[] = [];
		let keywords: string[] = [];
		if (isArray(details)) {
			messages = details
				.filter((d) => d.message)
				.map((d) => d.message.toLowerCase());
			keywords = details
				.filter((d) => d.keyword)
				.map((d) => d.keyword.toLowerCase());
		} else if (details && isArray(details.errors)) {
			messages = details.errors
				.filter((d: any) => d.message)
				.map((d: any) => d.message.toLowerCase());
			keywords = details.errors
				.filter((d: any) => d.keyword)
				.map((d: any) => d.keyword.toLowerCase());
		} else if (details) {
			if (details.message) {
				messages.push(details.message.toLowerCase());
			}
			if (details.keyword) {
				keywords.push(details.keyword.toLowerCase());
			}
			if (typeof details === 'string' && details !== '') {
				messages.push(details.toLowerCase());
			}
		}
		captureException(ex, {
			contexts: {
				application: {
					id: props.applicationId,
				},
				error: e as any,
				function: {
					name: functionName,
				},
			},
			tags: {
				section: 'uploadDialog',
			},
		});

		if (status === 422) {
			if (messages.includes('no files attached')) {
				return `errors.${tPrefix}-422-no-files`;
			} else if (messages.includes('upload-limit')) {
				return `errors.${tPrefix}-422-upload-limit`;
			} else if (messages.includes('image-resolution-high')) {
				return `errors.${tPrefix}-422-image-resolution-high`;
			} else if (keywords.includes('required')) {
				return `errors.${tPrefix}-422-required`;
			} else if (messages.includes('duplicate')) {
				return `errors.${tPrefix}-422-duplicate`;
			} else {
				return `errors.${tPrefix}-422`;
			}
		} else if (status === 413) {
			return `errors.${tPrefix}-413`;
		} else if (status === 409) {
			return `errors.${tPrefix}-409`;
		} else if (status === 500) {
			return `errors.${tPrefix}-500`;
		} else {
			return `errors.${tPrefix}-other`;
		}
	};

	useEffect(() => {
		const updatedEditContext: any = {
			fields: [...state.fields],
			files: [
				...state.files.map((f: IFile) => ({
					...f,
					id: Math.random().toString(36).substring(7),
				})),
			],
		};
		dispatch(updatedEditContext);
	}, [state]);

	const onSaveClick = async () => {
		setLoading(true);
		setWidgetErrors(null);
		const requestParams: any = {};

		if (
			areFilesCleared(tempState.files) &&
			state.files.some((f: IUpload) => f.file)
		) {
			try {
				// all files are cleared; delete attribute data
				const updatedApplication = await props.onClearAttribute(
					props.applicationId,
					props.propertyId
				);
				props.onClose(updatedApplication);
			} catch (err) {
				if (props.refreshApplication) {
					props.refreshApplication(err as AxiosError);
				}
				const translatedMessage = handleAPIErrors(
					err,
					'updateAttribute',
					'save'
				);
				setError(translatedMessage);
			}
			setLoading(false);
			return;
		} else {
			// upload all files except those which already have "content" property
			const uploadParams = getAddedFiles(tempState.files).map((f: IUpload) => ({
				file: f.file,
				name: f.itemKey,
			}));
			const removedFiles = getRemovedFiles(tempState.files, state.files);
			const editedFields = getEditedFields(tempState.fields, state.fields);

			// format data for API
			try {
				if (props.mainProperty.type === 'array') {
					const tempStateFileData = tempState.files
						.filter((f) => f.file && f.file.content)
						.map((f) => f.file);
					if (uploadParams.length) {
						const res = await props.onUploadFiles(uploadParams);
						requestParams.data = [].concat(
							formatArrayUpload(res, tempState.files),
							tempStateFileData
						);
					} else if (removedFiles.length) {
						requestParams.data = tempStateFileData;
					}
				} else {
					let data: any = {};
					editedFields.forEach((field: any) => {
						data[field.itemKey] = field.value;
					});

					const tempStateFileData: { [key: string]: any } = {};
					tempState.files
						.filter((f) => !f.file || f.file.content)
						.forEach((f) => {
							if (props.mainProperty.properties[f.itemKey].type === 'array') {
								if (tempStateFileData[f.itemKey]) {
									tempStateFileData[f.itemKey].push(f.file);
								} else {
									tempStateFileData[f.itemKey] = [f.file];
								}
							} else if (!f.file) {
								tempStateFileData[f.itemKey] = f.file;
							}
						});
					// uploads
					if (uploadParams.length) {
						// here
						const res = await props.onUploadFiles(uploadParams);
						const uploads = formatFileAttributeUpdate(res, tempState.files);
						if (!isEmpty(tempStateFileData)) {
							Object.keys(tempStateFileData).forEach((key) => {
								if (uploads[key] && isArray(uploads[key])) {
									data[key] = [].concat(tempStateFileData[key], uploads[key]);
									delete uploads[key];
								}
							});
							if (!isEmpty(uploads)) {
								data = { ...data, ...uploads };
							}
						} else {
							data = { ...data, ...uploads };
						}
					} else {
						data = { ...data, ...tempStateFileData };
					}

					// make sure data does not have any removed files
					// tslint:disable-next-line:prefer-for-of
					for (let i = 0; i < removedFiles.length; i++) {
						const key = removedFiles[i].itemKey;
						if (data[key] && isArray(data[key])) {
							data[key] = data[key].filter(
								(d: any) => d.content !== removedFiles[i].file.content
							);
						} else if (
							data[key] &&
							data[key].content === removedFiles[i].file.content
						) {
							delete data[key];
						}
					}

					requestParams.data = data;
				}
			} catch (err) {
				if (props.refreshApplication) {
					props.refreshApplication(err as AxiosError);
				}
				const translatedMessage = handleAPIErrors(err, 'uploadFiles', 'upload');
				setError(translatedMessage);
				setLoading(false);
				return;
			}
		}

		try {
			// here
			const updatedApplication = await props.onUpdateAttribute(
				props.applicationId,
				props.propertyId,
				requestParams
			);
			setLoading(false);
			props.onClose(updatedApplication);
		} catch (err) {
			if (props.refreshApplication) {
				props.refreshApplication(err as AxiosError);
			}
			const translatedMessage = handleAPIErrors(err, 'updateAttribute', 'save');
			const apiError = get(err, 'response.data.error', '');
			const apiErrorDetails = get(err, 'response.data.details.errors', null);
			setError(translatedMessage);
			if (apiError === 'INVALID_ATTRIBUTE_ERROR' && apiErrorDetails) {
				setWidgetErrors(apiErrorDetails);
			}
			setLoading(false);
			return;
		}
	};

	const removed = getRemovedFiles(tempState.files, state.files);
	const edited = getEditedFields(tempState.fields, state.fields);
	const uploaded = getAddedFiles(tempState.files);
	const filesCleared = areFilesCleared(tempState.files);
	const hasOriginalFiles = state.files.some((f: IUpload) => f.file);
	const originalMultiFilesCount = state.files.filter(
		(f: IUpload) =>
			f.schema === 'http://platform.selfkey.org/schema/file/multi-image.json' ||
			f.schema === 'http://platform.selfkey.org/schema/misc/extra-images.json'
	).length;
	const tempStateMultiFileCount = tempState.files.filter(
		(f: IUpload) =>
			f.schema === 'http://platform.selfkey.org/schema/file/multi-image.json' ||
			f.schema === 'http://platform.selfkey.org/schema/misc/extra-images.json'
	).length;
	const hasSomethingInputted =
		// multifile upload has one or more removed
		(props.mainProperty.type === 'array' && removed.length > 0) ||
		// has one multifile removed
		originalMultiFilesCount > tempStateMultiFileCount ||
		// has something edited
		edited.length ||
		// has something uploaded
		uploaded.length ||
		// has all files cleared
		(hasOriginalFiles && filesCleared && removed.length === state.files.length);

	return (
		<CustomerPortalTheme>
			<Dialog
				disableEscapeKeyDown={true}
				fullWidth
				open={props.open}
				onClose={(_, reason) =>
					!loading && reason !== 'backdropClick' && props.onClose()
				}
				aria-labelledby="form-dialog-title"
				classes={{ paperWidthSm: classes.dialog }}
				scroll="body"
			>
				<div className={classes.iconWrap}>
					<IconButton
						color="inherit"
						onClick={() => !loading && props.onClose()}
						className={classes.closeIcon}
					>
						<CloseIcon />
					</IconButton>
				</div>

				<Typography variant="h1">
					{t('fileUploadDialog.title', { docName: props.title })}
				</Typography>

				{props.description && (
					<Typography variant="h4">{props.description}</Typography>
				)}

				{error && (
					<Typography gutterBottom={true} variant="subtitle1" color="error">
						{t(error)}
					</Typography>
				)}

				<DialogContent className={classes.content}>
					<UploadDialogEditContext.Provider value={[tempState, dispatch]}>
						{props.widgets.map((widgetProps, index) => {
							const Widget = widgetTypeMap[widgetProps.schema]
								? widgetTypeMap[widgetProps.schema]
								: InputWidget;
							// find errors for the widget if any
							const widgetError = widgetErrors
								? widgetErrors.find((e: any) => {
										return (
											e.params &&
											e.params.missingProperty === widgetProps.itemKey
										);
								  })
								: null;
							const wProps = {
								...{
									error: widgetError,
									fileNames: props.fileNames,
									multiple: Widget.multiple,
									required: true, // required variable hard coded for now
								},
								...widgetProps,
							};
							return <Widget.widget key={index} {...wProps} />;
						})}
					</UploadDialogEditContext.Provider>
				</DialogContent>

				<div>
					<div className={classes.wrapper}>
						<Button
							variant="contained"
							onClick={() =>
								onSaveClick().then(() => props.onSubmit && props.onSubmit())
							}
							disabled={loading || !hasSomethingInputted}
							className={classes.rightSpace}
						>
							{t('fileUploadDialog.save')}
						</Button>

						{loading && (
							<CircularProgress size={24} className={classes.buttonProgress} />
						)}
					</div>

					<Button
						variant="contained"
						color="primary"
						disabled={loading}
						onClick={() => props.onClose()}
					>
						{t('fileUploadDialog.cancel')}
					</Button>
				</div>
			</Dialog>
		</CustomerPortalTheme>
	);
};
