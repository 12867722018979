import * as React from 'react';
import BaseField, { IBaseFieldProps } from './base-field';

class DateField extends BaseField<IBaseFieldProps, {}> {
	public render() {
		return (
			<input
				type="date"
				style={this.props.style}
				value={this.props.value || ''}
				onChange={(event: any) => {
					this.onChange({ value: event.target.value });
				}}
				disabled={this.props.disabled}
			/>
		);
	}
}

export default DateField;
