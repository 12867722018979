import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import { accent, primary, tertiary } from '../../../../../ui/src/colors';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';

interface MenuItem {
	icon: string; // icon id
	click: () => void; // function to be called when menu is clicked
	title: string;
}

interface MenuInputProps {
	title: string;
	menuItems: MenuItem[]; // array of menu items
}

const useStyles = makeStyles({
	button: {
		'& span:first-child': {
			marginTop: 0,
		},
		'&:hover': {
			backgroundColor: accent,
			color: primary,
		},
		'@media (max-width: 600px)': {
			minWidth: '60px',
			padding: 0,
		},
		color: tertiary,
		padding: '6px 16px',
	},
	icon: {
		'@media (max-width: 600px)': {
			marginRight: 0,
		},
		fontSize: '21px',
		marginRight: '7px',
		marginTop: '-3px',
	},
	menuItem: {
		'&:hover': {
			backgroundColor: accent,
			color: primary,
		},
		color: tertiary,
		padding: '5px 15px',
	},
	overflowProtection: {
		'@media (max-width: 600px)': {
			display: 'none',
		},
		display: 'initial',
		maxWidth: '250px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
});

interface IDropDownMenuLinkProps {
	anchorEl?: any;
	handleOpenClick: (event: any) => any;
	icon: any;
	showArrowIcon: boolean;
	title: any;
}

export const DropDownMenuLink = (props: IDropDownMenuLinkProps) => {
	const classes = useStyles();
	return (
		<Button
			aria-owns={props.anchorEl ? 'simple-menu' : null}
			aria-haspopup="true"
			onClick={props.handleOpenClick}
			className={classes.button}
		>
			<Icon className={classes.icon}>{props.icon}</Icon>
			<Typography variant="subtitle2" className={classes.overflowProtection}>
				{props.title}
			</Typography>
			{props.showArrowIcon ? <Icon>arrow_drop_down</Icon> : null}
		</Button>
	);
};

export const DropDownMenu: React.FC<MenuInputProps> = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOpenClick = (event: React.MouseEvent<HTMLInputElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuClick = (menuClickFunc: () => void) => {
		// close menu when menu is clicked
		setAnchorEl(null);
		menuClickFunc();
	};

	return (
		<CustomerPortalTheme>
			<DropDownMenuLink
				anchorEl={anchorEl}
				handleOpenClick={handleOpenClick}
				icon={'person'}
				showArrowIcon={true}
				title={t(props.title)}
			/>
			<Menu
				id="user-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{props.menuItems.map((item: MenuItem, index: number) => (
					<MenuItem
						key={index}
						onClick={() => handleMenuClick(item.click)}
						className={classes.menuItem}
					>
						<Typography variant="subtitle2">{t(item.title)}</Typography>
					</MenuItem>
				))}
			</Menu>
		</CustomerPortalTheme>
	);
};
