import axios from 'axios';

const URL = 'https://cdn.kyc-chain.com/common/supported-documents.json';

export const loadDocumentSupport = async (url: string = URL) => {
	return axios({
		method: 'get',
		url,
	}).then((res) => res.data);
};
