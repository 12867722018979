import * as React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Tab, Tabs, Typography } from '@material-ui/core';

import DrawSignature from './DrawSignature';
import TypeSignature from './TypeSignature';
import UploadSignature from './UploadSignature';

const styles = (theme: Theme) =>
	createStyles({
		actionsRoot: {
			'@media (max-width: 800px)': {
				flexDirection: 'row-reverse',
				justifyContent: 'space-between',
				paddingLeft: 0,
				paddingRight: 0,
			},
			justifyContent: 'flex-start',
			paddingLeft: 0,
		},
		cancel: {
			'@media (max-width: 800px)': {
				marginLeft: '0 !important',
			},
			marginLeft: '10px !important',
		},
		closeIcon: {
			'& svg': {
				height: '30px',
				width: '30px',
			},
			'@media (max-width: 800px)': {
				marginRight: '-20px',
				marginTop: '-40px',
			},
			marginRight: '-60px',
			marginTop: '-90px',
			position: 'relative',
		},
		content: {
			margin: theme.spacing(),
		},
		dialogRoot: {
			'@media (max-width: 800px)': {
				overflow: 'initial',
			},
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
			padding: 0,
		},
		dialogTitle: {
			display: 'flex',
			justifyContent: 'start',
			marginBottom: '20px',
		},
		iconWrap: {
			marginBottom: '-20px',
			marginTop: '10px',
			textAlign: 'right',
		},
		listItems: {
			display: 'flex',
			flexDirection: 'column',
			marginBottom: '22px',
			minWidth: '210px',
		},
		pageContent: {
			maxWidth: '380px',
			width: '100%',
		},
	});

interface ISignatureDialogProps extends WithTranslation {
	closeFunc: () => void;
	defaultInitials?: string;
	isOpen: boolean; // dialog is open or closed
	onSignatureReady: (image: string) => void;
	proportion?: number;
	classes: any;
}

enum Tabs2 {
	draw,
	type,
	upload,
}

interface ISignatureDialogState {
	signatureRequested: boolean;
	selectedTab: Tabs2;
}

export interface ISignatureInput extends WithTranslation {
	signatureRequested: boolean;
	onSignatureUpdated: (image: string) => void;
}

class SignatureDialog extends React.Component<
	ISignatureDialogProps,
	ISignatureDialogState
> {
	public constructor(props: ISignatureDialogProps) {
		super(props);
		this.state = {
			selectedTab: Tabs2.draw,
			signatureRequested: false,
		};
	}

	public componentDidUpdate(prevProps: ISignatureDialogProps) {
		if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
			this.setState({ signatureRequested: false });
		}
	}

	public render() {
		const { classes, t } = this.props;
		let pageContent = null;
		switch (this.state.selectedTab) {
			case Tabs2.draw:
				pageContent = (
					<DrawSignature
						proportion={this.props.proportion}
						signatureRequested={this.state.signatureRequested}
						onSignatureUpdated={this.signatureUpdated}
					/>
				);
				break;
			case Tabs2.type:
				pageContent = (
					<TypeSignature
						proportion={this.props.proportion}
						defaultInitials={this.props.defaultInitials}
						onSignatureUpdated={this.signatureUpdated}
						signatureRequested={this.state.signatureRequested}
					/>
				);
				break;
			case Tabs2.upload:
				pageContent = (
					<UploadSignature
						proportion={this.props.proportion}
						onSignatureUpdated={this.signatureUpdated}
						signatureRequested={this.state.signatureRequested}
					/>
				);
				break;
			default:
				break;
		}

		return (
			<Dialog
				open={this.props.isOpen}
				aria-labelledby="form-dialog-title"
				maxWidth="sm"
				scroll="paper"
			>
				<div className={classes.iconWrap}>
					<IconButton
						color="inherit"
						onClick={this.props.closeFunc}
						className={classes.closeIcon}
						aria-label={t('signatureDialog.close')}
					>
						<CloseIcon />
					</IconButton>
				</div>
				<div className={classes.dialogTitle}>
					<Typography variant="h1" color="textPrimary" id="form-dialog-title">
						{t('signatureDialog.title')}
					</Typography>
				</div>
				<DialogContent classes={{ root: classes.dialogRoot }}>
					<div className={classes.listItems}>
						<Tabs
							value={this.state.selectedTab}
							onChange={(evt, value) => this.setState({ selectedTab: value })}
						>
							<Tab
								id="draw"
								value={Tabs2.draw}
								label={
									<Typography variant="body1">
										{t('signatureDialog.drawMenu')}
									</Typography>
								}
								onClick={() => this.setState({ selectedTab: Tabs2.draw })}
								selected={this.state.selectedTab === Tabs2.draw}
							/>
							<Tab
								id="type"
								value={Tabs2.type}
								label={
									<Typography variant="body1">
										{t('signatureDialog.typeMenu')}
									</Typography>
								}
								onClick={() => this.setState({ selectedTab: Tabs2.type })}
								selected={this.state.selectedTab === Tabs2.type}
							/>
							<Tab
								id="upload"
								value={Tabs2.upload}
								label={
									<Typography variant="body1">
										{t('signatureDialog.uploadMenu')}
									</Typography>
								}
								onClick={() => this.setState({ selectedTab: Tabs2.upload })}
								selected={this.state.selectedTab === Tabs2.upload}
							/>
						</Tabs>
					</div>
					<div className={classes.pageContent}>{pageContent}</div>
				</DialogContent>
				<DialogActions classes={{ root: classes.actionsRoot }}>
					<Button
						onClick={this.handleSignatureInsert}
						type="submit"
						variant="contained"
					>
						{t('signatureDialog.insert')}
					</Button>
					<Button
						onClick={this.props.closeFunc}
						variant="contained"
						color="primary"
						className={classes.cancel}
					>
						{t('signatureDialog.close')}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	private handleSignatureInsert = () => {
		this.setState({ signatureRequested: true });
	};

	private signatureUpdated = (base64Signature: string) => {
		this.props.onSignatureReady(base64Signature);
		this.props.closeFunc();
	};
}

const translatedDialog = withTranslation()(SignatureDialog);
export default withStyles(styles)(translatedDialog);
