import * as Joi from '@hapi/joi';

import { IJsonSchema } from './interface/requirements';

export const validFormats = [
	'date',
	'time',
	'date-time',
	'uri',
	'uri-reference',
	'uri-template',
	'email',
	'hostname',
	'ipv4',
	'ipv6',
	'regex',
	'uuid',
];

export const MAIN_ATTRIBUTES = [
	'http://platform.selfkey.org/schema/attribute/date-of-birth.json',
	'http://platform.selfkey.org/schema/attribute/nationality.json',
	'http://platform.selfkey.org/schema/attribute/physical-address.json',
	'http://platform.selfkey.org/schema/attribute/company-name.json',
	'http://platform.selfkey.org/schema/attribute/legal-entity-type.json',
	'http://platform.selfkey.org/schema/attribute/legal-jurisdiction.json',
];

export enum KnownAttribute {
	BankStatement = 'http://platform.selfkey.org/schema/attribute/bank-statement.json',
	BusinessTypes = 'http://platform.selfkey.org/schema/attribute/business-types.json',
	DateOfBirth = 'http://platform.selfkey.org/schema/attribute/date-of-birth.json',
	IncorporationDate = 'http://platform.selfkey.org/schema/attribute/incorporation-date.json',
	CountryOfResidency = 'http://platform.selfkey.org/schema/attribute/country-of-residency.json',
	Domicile = 'http://platform.selfkey.org/schema/attribute/domicile.json',
	DriversLicense = 'http://platform.selfkey.org/schema/attribute/drivers-license.json',
	Email = 'http://platform.selfkey.org/schema/attribute/email.json',
	Fingerprint = 'http://platform.selfkey.org/schema/attribute/fingerprint.json',
	FirstName = 'http://platform.selfkey.org/schema/attribute/first-name.json',
	LastName = 'http://platform.selfkey.org/schema/attribute/last-name.json',
	LegalEntityType = 'http://platform.selfkey.org/schema/attribute/legal-entity-type.json',
	LegalJurisdiction = 'http://platform.selfkey.org/schema/attribute/legal-jurisdiction.json',
	MiddleName = 'http://platform.selfkey.org/schema/attribute/middle-name.json',
	NationalId = 'http://platform.selfkey.org/schema/attribute/national-id.json',
	NationalIdNumber = 'http://platform.selfkey.org/schema/attribute/national-id-number.json',
	Nationality = 'http://platform.selfkey.org/schema/attribute/nationality.json',
	Passport = 'http://platform.selfkey.org/schema/attribute/passport.json',
	PhoneNumber = 'http://platform.selfkey.org/schema/attribute/phone-number.json',
	PhysicalAddress = 'http://platform.selfkey.org/schema/attribute/physical-address.json',
	TaxCertificate = 'http://platform.selfkey.org/schema/attribute/tax-certificate.json',
	TaxIdNumber = 'http://platform.selfkey.org/schema/attribute/tax-id-number.json',
	UtilityBill = 'http://platform.selfkey.org/schema/attribute/utility-bill.json',
	VoiceId = 'http://platform.selfkey.org/schema/attribute/voice-id.json',
	WorkPlace = 'http://platform.selfkey.org/schema/attribute/work-place.json',
	BtcAddress = 'http://platform.selfkey.org/schema/attribute/btc-address.json',
	EthAddress = 'http://platform.selfkey.org/schema/attribute/eth-address.json',
	DashAddress = 'http://platform.selfkey.org/schema/attribute/dash-address.json',
	CertificateOfIncorporation = 'http://platform.selfkey.org/schema/attribute/certificate-of-incorporation.json',
	RegistrationCertificate = 'http://platform.selfkey.org/schema/attribute/registration-certificate.json',
	ExternalDocument = 'http://platform.selfkey.org/schema/attribute/external-document.json',
	CompanyName = 'http://platform.selfkey.org/schema/attribute/company-name.json',
	BirthCertificate = 'http://platform.selfkey.org/schema/attribute/birth-certificate.json',
	MemberRegister = 'http://platform.selfkey.org/schema/attribute/member-register.json',
	Cv = 'http://platform.selfkey.org/schema/attribute/cv.json',
	ProfessionalReferenceLetter = 'http://platform.selfkey.org/schema/attribute/professional-reference-letter.json',
	CorporateStructure = 'http://platform.selfkey.org/schema/attribute/corporate-structure.json',
	CompanyRegistrationNumber = 'https://cdn.kyc-chain.com/kyc-chain/company-registration-number.json',
}

export const AttributeToCAParam: { [key: string]: string } = {
	[KnownAttribute.MiddleName]: 'middleName',
	[KnownAttribute.DateOfBirth]: 'dateOfBirth',
	[KnownAttribute.Nationality]: 'countryCodes',
	[KnownAttribute.CountryOfResidency]: 'countryCodes',
};

export const fieldToKnownAttributeMap: Record<string, KnownAttribute> = {
	email: KnownAttribute.Email,
	firstName: KnownAttribute.FirstName,
	lastName: KnownAttribute.LastName,
	legalEntityType: KnownAttribute.LegalEntityType,
	name: KnownAttribute.CompanyName,
};

// TODO: Add type for each attribute
export interface KnownAttributeType {
	[KnownAttribute.BankStatement]: any;
	[KnownAttribute.DateOfBirth]: any;
	[KnownAttribute.IncorporationDate]: any;
	[KnownAttribute.CountryOfResidency]: any;
	[KnownAttribute.Domicile]: any;
	[KnownAttribute.DriversLicense]: any;
	[KnownAttribute.Email]: any;
	[KnownAttribute.Fingerprint]: any;
	[KnownAttribute.FirstName]: any;
	[KnownAttribute.LastName]: any;
	[KnownAttribute.LegalEntityType]: any;
	[KnownAttribute.LegalJurisdiction]: any;
	[KnownAttribute.MiddleName]: any;
	[KnownAttribute.NationalId]: any;
	[KnownAttribute.Nationality]: any;
	[KnownAttribute.Passport]: any;
	[KnownAttribute.PhoneNumber]: any;
	[KnownAttribute.PhysicalAddress]: any;
	[KnownAttribute.TaxCertificate]: any;
	[KnownAttribute.TaxIdNumber]: any;
	[KnownAttribute.UtilityBill]: any;
	[KnownAttribute.VoiceId]: any;
	[KnownAttribute.WorkPlace]: any;
	[KnownAttribute.BtcAddress]: any;
	[KnownAttribute.EthAddress]: any;
	[KnownAttribute.DashAddress]: any;
	[KnownAttribute.CertificateOfIncorporation]: any;
	[KnownAttribute.RegistrationCertificate]: any;
	[KnownAttribute.ExternalDocument]: any;
	[KnownAttribute.CompanyName]: any;
	[KnownAttribute.BirthCertificate]: any;
	[KnownAttribute.MemberRegister]: any;
	[KnownAttribute.Cv]: any;
	[KnownAttribute.ProfessionalReferenceLetter]: any;
	[KnownAttribute.CorporateStructure]: any;
}

export interface Attribute<
	T extends keyof KnownAttributeType = keyof KnownAttributeType
> {
	id: string;
	schemaId: T;
	data: KnownAttributeType[T];
}

export const createAttribute = <T extends keyof KnownAttributeType>(
	attr: Attribute<T>
) => attr;

export const knownAttributes = Object.values(KnownAttribute);

export const validationSchema = Joi.object({
	jsonSchema: Joi.object({
		description: Joi.string().allow('').label('Description'),
		enum: Joi.array().items(Joi.string().min(1)),
		items: Joi.object().keys({
			enum: Joi.array().items(Joi.string().min(1)),
		}),
		title: Joi.string().min(2).label('Title').required(),
	}).required(),
	optional: Joi.boolean().default(false),
	schemaId: Joi.string()
		.allow(null)
		.allow(...knownAttributes),
	uiType: Joi.string(),
	weight: Joi.number().min(0),
});

export const validationSchemaWithNotes = validationSchema.concat(
	Joi.object().keys({
		externalNote: Joi.string().min(1).label('External Note'),
		internalNote: Joi.string().min(1).label('Internal Note'),
	})
);

export const CustomSchemas: { [key: string]: IJsonSchema } = {
	addressInput: {
		description: '',
		properties: {
			address_line_1: {
				description: 'Street name, number',
				title: 'Physical Address 1',
				type: 'string',
			},
			address_line_2: {
				description: 'Building, apartment, etc.',
				title: 'Physical Address 2',
				type: 'string',
			},
			city: {
				description: 'City or Town',
				title: 'City',
				type: 'string',
			},
			province: {
				description: 'Province or State',
				title: 'Province',
				type: 'string',
			},
			// tslint:disable:object-literal-sort-keys
			postalcode: {
				description: 'Postal code or Zip code',
				title: 'Postal Code',
				type: 'string',
			},
		},
		required: ['address_line_1'],
		title: '',
		type: 'object',
	},
	blockchainAddress: {
		description:
			'This address does not have any pattern restrictions; it supports any blockchain address',
		minLength: 2,
		title: '',
		type: 'string',
	},
	btc: {
		description: '',
		minLength: 1,
		pattern: '^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$',
		title: '',
		type: 'string',
	},
	checkboxes: {
		description: '',
		items: {
			enum: [],
			type: 'string',
		},
		title: '',
		type: 'array',
		uniqueItems: true,
	},
	country: {
		description: '',
		properties: {
			country: {
				$ref: 'https://platform.selfkey.org/schema/misc/country.json',
			},
		},
		required: ['country'],
		title: '',
		type: 'object',
	},
	date: {
		description: '',
		format: 'date',
		title: '',
		type: 'string',
	},
	email: {
		description: '',
		format: 'email',
		minLength: 1,
		title: '',
		type: 'string',
	},
	eth: {
		description: '',
		minLength: 1,
		pattern: '^0x[a-fA-F0-9]{40}$',
		title: '',
		type: 'string',
	},
	input: {
		description: '',
		minLength: 1,
		title: '',
		type: 'string',
	},
	phone: {
		description: '',
		minLength: 1,
		pattern: '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$',
		title: '',
		type: 'string',
	},
	select: {
		description: '',
		enum: [],
		title: '',
		type: 'string',
	},
};
