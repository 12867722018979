import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import {
	IApplication,
	IApplicationType,
} from '../../../../../../../common-ts/src/interface/application';

import { SharesRegistryMemberRowItem } from './SharesRegistryMemberRowItem';

import { useStyles } from './ListViewTab.style';

export interface Props {
	application: IApplication;
	memberApplications: IApplication[];
	onEditMemberClick: (id: string) => void;
	onDeleteMember: (id: string) => Promise<void>;
	onAddMemberClick: () => void;
}

export const ListViewTab: React.FC<Props> = ({
	application,
	memberApplications,
	onEditMemberClick,
	onDeleteMember,
	onAddMemberClick,
}) => {
	const styles = useStyles();
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<TableContainer component="div" className={styles.table}>
				<Table size="small" aria-label="members">
					<TableHead>
						<TableRow>
							<TableCell
								className={`${styles.mobile} ${styles.mobileIconElement}`}
							>
								<Typography
									variant="body2"
									color="textPrimary"
									className={styles.bold}
								>
									{' '}
								</Typography>
							</TableCell>

							<TableCell>
								<Typography
									variant="body2"
									color="textPrimary"
									className={styles.bold}
								>
									{t('membersTable.name')}
								</Typography>
							</TableCell>

							<TableCell className={styles.hiddenOnMobile}>
								<Typography
									variant="body2"
									color="textPrimary"
									className={styles.bold}
								>
									{t('membersTable.position')}
								</Typography>
							</TableCell>

							{application.type === IApplicationType.CORPORATE && (
								<>
									<TableCell align="center" className={styles.hiddenOnMobile}>
										<Typography
											variant="body2"
											color="textPrimary"
											className={styles.bold}
										>
											{t('membersTable.type')}
										</Typography>
									</TableCell>

									{application.options.askForMemberOwnership && (
										<TableCell className={styles.hiddenOnMobile}>
											<Typography
												variant="body2"
												color="textPrimary"
												className={styles.bold}
											>
												{t('membersTable.shares_owned')}
											</Typography>
										</TableCell>
									)}
								</>
							)}

							<TableCell align="right">
								<Typography
									variant="body2"
									color="textPrimary"
									className={styles.bold}
								>
									{t('membersTable.actions')}
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{memberApplications
							.sort((a, b) => a.id.localeCompare(b.id))
							.map((memberApplication) => (
								<SharesRegistryMemberRowItem
									key={memberApplication.id}
									memberApplication={memberApplication}
									editMember={onEditMemberClick}
									{...(!memberApplication.members.length && {
										deleteMember: onDeleteMember,
									})}
									{...{ application }}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>

			<Grid container spacing={2}>
				<Grid item>
					<Button variant="contained" onClick={onAddMemberClick}>
						{t('membersTable.add')}
					</Button>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};
