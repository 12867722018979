import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	bold: {
		fontWeight: 500,
	},
	bottomSpace: {
		marginBottom: '20px',
	},
	buttonWrap: {
		height: '44px',
		marginBottom: '-2px',
		marginRight: '-2px',
		marginTop: '-2px',
	},
	deletable: {
		'&:focus': {
			backgroundColor: 'transparent',
		},
	},
	errorStyle: {
		backgroundColor: '#FCF8F8',
		border: '2px solid #FF3500',
		borderRadius: '4px',
	},
	fileInput: {
		display: 'none',
	},
	fileInputStyle: {
		alignItems: 'center',
		backgroundColor: 'transparent',
		borderRadius: '4px',
		boxSizing: 'border-box',
		display: 'flex',
		fontSize: '14px',
		height: '40px',
		justifyContent: 'space-between',
		lineHeight: '20px',
		marginRight: '10px',
		padding: '8px 0 8px',
		width: '100%',
	},
	fileInputUpload: {
		float: 'right' as 'right',
		height: '44px',
		width: '90px',
	},
	fileLabel: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
	noErrorStyle: {
		backgroundColor: '#F8F8FC',
		border: '2px solid #D5D5E3',
		borderRadius: '4px',
	},
	noShadow: {
		boxShadow: 'none',
	},
	overflowHandling: {
		'@media (max-width: 468px)': {
			maxWidth: '140px',
		},
		'@media (min-width: 469px) and (max-width: 660px)': {
			maxWidth: '240px',
		},
		'@media (min-width: 661px) and (max-width: 900px)': {
			maxWidth: '300px',
		},
		maxWidth: '500px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	titleSpace: {
		marginBottom: '10px',
	},
});
