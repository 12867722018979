import intersection from 'lodash/intersection';
import { LinkObject } from 'react-force-graph-2d';

import { KnownAttribute } from '../../../../common-ts/src/customSchemas';
import {
	IApplication,
	IApplicationType,
	IMember,
} from '../../../../common-ts/src/interface/application';
import { parse } from '../../../../common-ts/src/util/path';

import { getAttribute } from '../../helpers/attributes';

import { GraphData, NODE_SIZE, NodeObject } from './HierarchyGraph';

const toLinkObject = (base: string, paths: string[]): LinkObject[] => {
	return paths.reduce<LinkObject[]>((array, node, index, nodes) => {
		if (index === nodes.length - 1) return [{ source: node, target: base }];

		return [...array, { source: node, target: nodes[index + 1] }];
	}, []);
};

const toNode = (application: IApplication): NodeObject => {
	const name =
		application.type === IApplicationType.CORPORATE
			? (getAttribute(application.attributes, KnownAttribute.CompanyName)
					.value as string)
			: `${
					getAttribute(application.attributes, KnownAttribute.FirstName)
						.value as string
			  } ${
					getAttribute(application.attributes, KnownAttribute.LastName)
						.value as string
			  }`;

	return {
		id: application.id,
		name,
		type: application.type,
		members: application.members.reduce<Record<string, IMember>>(
			(obj, member) => {
				obj[member.application.toString()] = member;

				return obj;
			},
			{}
		),
		val: NODE_SIZE,
	};
};

export const createHierarchyData = (applications: IApplication[]) => {
	const applicationIds = applications.map((a) => a.id);
	return applications.reduce<GraphData>(
		({ nodes: previousNodes, links: previousLinks }, application) => {
			const links =
				application.paths?.flatMap((path) => {
					const existingApplications = intersection(
						applicationIds,
						parse(path)
					);
					return toLinkObject(application.id, existingApplications);
				}) ?? [];

			const node = toNode(application);

			return {
				nodes: [...previousNodes, node],
				links: [...previousLinks, ...links],
			};
		},
		{ nodes: [], links: [] }
	);
};
