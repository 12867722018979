import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import { IKYCSectionProps, SectionNavigation } from './Section';

interface IWelcomePageProps extends IKYCSectionProps {
	welcomeText: string;
}

export const WelcomePage: React.FC<IWelcomePageProps> = (props) => {
	const { t } = useTranslation();
	return (
		<CustomerPortalTheme>
			<Typography align="left" variant="h1" style={{ marginBottom: '35px' }}>
				{t('welcomePage.title')}
			</Typography>
			<div
				className="html-container"
				dangerouslySetInnerHTML={{ __html: props.welcomeText }}
			/>
			<SectionNavigation
				loading={false}
				prevCallback={props.prevCallback}
				nextCallback={props.nextCallback}
				position="center"
			/>
		</CustomerPortalTheme>
	);
};
