import { IFormSubmission } from '../../../../../common-ts/src/interface/form';
import { apiClient } from '../../../../../common-ts/src/services/network/api';

export const submitOffSystemForm = (
	id: string,
	formSubmissionId: string,
	uploadId: string
) => {
	return apiClient.post<IFormSubmission>(
		`/api/v2/applications/${id}/forms/${formSubmissionId}/document`,
		{ document: uploadId }
	);
};
