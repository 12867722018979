import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ResetPasswordForm } from '../../../../../common/src/react/Login/resetPasswordForm';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import { sendPasswordReset } from '../../../services/api';
import { TemplateIdContext } from '../AuthLayout';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { TitleArea } from './TitleArea';

interface IResetProps {
	hasSignUp: boolean;
	passwordlessFlow: boolean;
}

export const ResetPassword: React.FC<IResetProps> = (props) => {
	const { t } = useTranslation();
	const [templateId] = React.useContext(TemplateIdContext);

	const [displayError, setDisplayError] = React.useState(false);
	const [displaySuccess, setDispaySuccess] = React.useState(false);

	const translationTitle = props.passwordlessFlow
		? 'passwordlessFlow'
		: 'reset';

	const resetSubmit = (email: string) => {
		setDispaySuccess(false);
		setDisplayError(false);
		return sendPasswordReset(email)
			.then(() => {
				setDispaySuccess(true);
			})
			.catch(() => {
				setDisplayError(true);
			});
	};

	return (
		<CustomerPortalTheme>
			<LanguageSwitcher />
			<TitleArea
				title={t(`${translationTitle}.title`)}
				description={t(`${translationTitle}.description`)}
			/>
			{displaySuccess ? (
				<Typography
					component="p"
					color="primary"
					align="left"
					variant="caption"
					gutterBottom={true}
				>
					{t(`${translationTitle}.success`)}
				</Typography>
			) : null}
			{displayError ? (
				<Typography variant="subtitle1" color="error">
					{t(`${translationTitle}.error`)}
				</Typography>
			) : null}
			<ResetPasswordForm
				translationTitle={translationTitle}
				submitFunc={resetSubmit}
			/>
			<BottomNavigation className="bottomNavigation">
				{!props.passwordlessFlow && (
					<BottomNavigationAction
						showLabel={true}
						component={Link}
						to="/auth/login"
						label={t(`${translationTitle}.sign-in`)}
					/>
				)}

				{props.hasSignUp && !templateId && (
					<BottomNavigationAction
						showLabel={true}
						component={Link}
						to="/auth/signup"
						label={t(`${translationTitle}.sign-up`)}
					/>
				)}
				{templateId ? (
					<>
						<Divider orientation="vertical" />
						<BottomNavigationAction
							showLabel={true}
							component={Link}
							to={`/auth/signup/${templateId}`}
							label={t(`${translationTitle}.no-account`)}
						/>
					</>
				) : null}
			</BottomNavigation>
		</CustomerPortalTheme>
	);
};
