import { jurisdictions } from './../countries';

export const getAlpha2Jurisdiction = (alpha3Code: string) =>
	jurisdictions.find((j) => j.alpha3 === alpha3Code.toUpperCase());

export const convertToAlpha2Code = (countryCode = '') => {
	if (!countryCode) {
		return null;
	}
	// check for us-ca for example
	if (countryCode.length === 5 && countryCode[2] === '-') {
		return countryCode.split('-')[0].toUpperCase();
	} else if (countryCode.length === 2) {
		return countryCode.toUpperCase();
	} else if (countryCode.length === 3) {
		const alpha2Jurisdiction = getAlpha2Jurisdiction(countryCode);
		return alpha2Jurisdiction ? alpha2Jurisdiction.jc : null;
	}
	return null;
};

export const convertCountryNameToAlpha2Code = (countryName: string) => {
	const name = countryName.toLowerCase();
	const entry = jurisdictions.find((j) => j.jn.toLowerCase() === name);
	return entry ? entry.jc : '';
};
