import { Divider, Theme, makeStyles } from '@material-ui/core';
import * as React from 'react';

import {
	DefaultDateFormat,
	DefaultTimeFormat,
} from '../../../../common-ts/src/interface/scope';
import Editor from './Editor';
import Messages from './Messages';

export interface IMessage {
	id: string;
	chat: string;
	user: string;
	userName: string;
	userRole: 'client' | 'manager';
	message: string;

	createdAt: string;
	updatedAt: string;
}

const styles = makeStyles((_: Theme) => ({
	hr: {
		'@media (max-width: 600px)': {
			margin: '20px 0 20px -80px',
		},
		margin: '40px 0 40px -80px',
		width: 'calc(100% + 160px)',
	},
	root: {
		display: 'flex',
		flexDirection: 'column' as any,
		height: '100%',
	},
}));

interface IChatProps {
	userId: string;
	messages: IMessage[];
	className?: string;
	placeholderText?: string;
	dateTimeFormat?: string;

	handleOnSave: (message: string) => Promise<void>;
	handleEdit: (id: string, message: string) => void;
}

const Chat = (props: IChatProps) => {
	const classes = styles();
	const {
		className,
		placeholderText,
		messages,
		userId,
		handleOnSave,
		handleEdit,
		dateTimeFormat,
	} = props;
	const dtFormat =
		dateTimeFormat ||
		`${DefaultDateFormat.DATE_NO_LEADING_ZEROS_MONTH_3_LETTERS_NO_COMMA}, ${DefaultTimeFormat.TIME_HOUR_MIN}`;
	return (
		<div className={`${classes.root} ${className}`}>
			<Messages
				messages={messages}
				userId={userId}
				onEdit={handleEdit}
				dateTimeFormat={dtFormat}
			/>
			<Divider className={classes.hr} />
			<Editor placeholderText={placeholderText} onSend={handleOnSave} />
		</div>
	);
};

export default Chat;
