import { Reducer } from 'redux';
import { IApplication } from '../../../../common-ts/src/interface/application';

import {
	ApplicationActions,
	IApplicationActions,
} from '../actions/application';

export interface IApplicationState {
	applications: IApplication[];
	currentApplicationId: string;
	currentApplication: IApplication;
	loading: boolean;
}

const initialState: IApplicationState = {
	applications: [],
	currentApplication: null,
	currentApplicationId: null,
	loading: false,
};

export const applicationReducer: Reducer<
	IApplicationState,
	IApplicationActions
> = (state = initialState, action) => {
	switch (action.type) {
		case ApplicationActions.SET_APPLICATION:
			return { ...state, currentApplicationId: action.applicationId };
		case ApplicationActions.SET_LOADING:
			return { ...state, loading: true };
		case ApplicationActions.RECEIVE_APPLICATIONS:
			return { ...state, loading: false, applications: action.applications };
		case ApplicationActions.SET_CURRENT_APPLICATION_DATA:
			return {
				...state,
				currentApplication: action.application,
				loading: false,
			};
		case ApplicationActions.SET_APPLICATION_DATA_BY_ID: {
			return {
				...state,
				applications: state.applications.map((application) => {
					if (application.id === action.id) return action.application;

					return application;
				}),
				loading: false,
			};
		}
		case ApplicationActions.API_ERROR:
			return { ...state, loading: false, error: action.error };
		case ApplicationActions.UPSERT_APPLICATIONS_DATA: {
			const currentApplication = action.applications.find(
				(application) => application.id === state.currentApplication.id
			);

			const applicationsMap = action.applications.reduce<Record<string, any>>(
				(obj, current) => {
					obj[current.id] = current;

					return obj;
				},
				{}
			);

			const applications = state.applications.map((a) => {
				const augment = applicationsMap[a.id];

				if (!augment) return a;

				return { ...a, ...augment };
			});

			return {
				...state,
				...(currentApplication && {
					currentApplication: {
						...state.currentApplication,
						...currentApplication,
					},
				}),
				applications,
			};
		}
		default:
			return state;
	}
};
