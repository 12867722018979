import { Theme, makeStyles } from '@material-ui/core';
import { Grid, Paper, Typography } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { format } from 'date-fns';
import { EditorState, convertFromRaw } from 'draft-js';
import EditorComponent from 'draft-js-plugins-editor';
import * as React from 'react';

// No types available
// tslint:disable-next-line: no-var-requires
const { markdownToDraft } = require('markdown-draft-js');

const styles = makeStyles((theme: Theme) => ({
	admin: {
		border: '1px solid black',
		margin: theme.spacing(),
		marginRight: 0,
	},
	blueicon: {
		border: '1px solid #0854C7',
		fill: '#0854C7',
		margin: theme.spacing(),
	},
	content: {
		'@media (max-width: 600px)': {
			margin: 0,
		},
		display: 'flex',
		flexDirection: 'column' as any,
		margin: theme.spacing(),
		maxWidth: '66%',
	},
	contentLeft: {
		alignItems: 'start',
	},
	contentRight: {
		alignItems: 'end',
	},
	defaultTimeContainer: {
		alignItems: 'flex-start',
	},
	edited: {
		fontStyle: 'italic',
	},
	editor: {
		'@media (max-width: 600px)': {
			padding: theme.spacing(1),
		},
		border: 'none',
		boxShadow: 'none',
		fontSize: '16px',
		lineHeight: '24px',
		maxWidth: '100%',
		padding: theme.spacing(2),
	},
	editorAdmin: {
		backgroundColor: '#DDDDEE',
	},
	editorUser: {
		backgroundColor: '#EDEDF4',
	},
	icon: {
		'@media (max-width: 600px)': {
			height: theme.spacing(2),
			padding: '6px',
			width: theme.spacing(2),
		},
		borderRadius: '50%',
		height: theme.spacing(3),
		padding: theme.spacing(),
		width: theme.spacing(3),
	},
	name: {
		marginBottom: theme.spacing(),
		marginTop: theme.spacing(1.25),
		width: '100%',
	},
	reverseTimeContainer: {
		alignItems: 'flex-end',
	},
	root: {
		display: 'flex', // flexDirection: 'column' as any
		// padding: theme.spacing()
	},
	time: {
		'@media (max-width: 600px)': {
			fontSize: '0.7rem',
			marginTop: '1px',
		},
		color: theme.palette.text.hint,
		fontSize: '0.8rem',
		marginBottom: theme.spacing(-1),
		marginTop: theme.spacing(),
	},
	timeContainer: {
		display: 'flex',
		flexDirection: 'column' as any,
		width: '100%',
	},
	title: {
		// display: 'flex',
		// flexDirection: 'row' as any
	},
}));

interface MessageProps {
	id: string;
	text: string;
	name: string;
	time: string;
	editedTime: string;
	manager: boolean;
	dateTimeFormat?: string;

	ownMessage?: boolean;
}

const Message = (props: MessageProps) => {
	const classes = styles();
	const { name, text, time, ownMessage, manager, editedTime, dateTimeFormat } =
		props;
	const Icon = manager ? BusinessIcon : PersonOutlineIcon;

	const [editorState, setEditorState] = React.useState(
		EditorState.createWithContent(convertFromRaw(markdownToDraft(text)))
	);

	const editorStyle = ownMessage
		? `${classes.editor} ${classes.editorUser}`
		: `${classes.editor} ${classes.editorAdmin}`;
	const timeContainer = ownMessage
		? classes.defaultTimeContainer
		: classes.reverseTimeContainer;
	const nameStyle = ownMessage ? 'left' : 'right';

	return (
		<Grid
			container
			direction={ownMessage ? 'row' : 'row-reverse'}
			style={{ alignSelf: ownMessage ? 'row' : 'row-reverse' }}
			className={classes.root}
		>
			<div className={classes.title}>
				<Icon
					className={
						ownMessage
							? `${classes.icon} ${classes.blueicon}`
							: `${classes.icon} ${classes.admin}`
					}
				/>
			</div>
			<Grid
				className={`${classes.content} ${
					ownMessage ? classes.contentRight : classes.contentLeft
				}`}
			>
				<div className={classes.name}>
					<Typography variant="h2" align={nameStyle}>
						<b>{name}</b>
					</Typography>
				</div>
				<Paper classes={{ root: editorStyle }}>
					<EditorComponent
						readOnly
						editorState={editorState}
						onChange={(newEditorState) => setEditorState(newEditorState)}
						plugins={[]}
					/>
				</Paper>
				<div className={`${classes.timeContainer} ${timeContainer}`}>
					<Typography className={classes.time}>
						{format(new Date(time), dateTimeFormat)}
					</Typography>
					{time !== editedTime && (
						<Typography className={`${classes.time} ${classes.edited}`}>
							Edited at {format(new Date(editedTime), dateTimeFormat)}
						</Typography>
					)}
				</div>
			</Grid>
		</Grid>
	);
};

export default Message;
