import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

import { IFileSchema } from '../../../../../common-ts/src/interface/file';
import {
	IFormSubmission,
	IKycDocumentTemplate,
} from '../../../../../common-ts/src/interface/form';
import { useStyles } from '../CustomFields/styles';
import { UploadPageItem } from '../CustomFields/UploadPageItem';

// TODO on-system-fill
/*
interface IFormFill {
	form: any;
	fieldValues: {[id: string]: string};
	onLoadFinish: () => void;
	onDataChange: (fieldId: string, data: {value: string}) => void;
	downloadFunc: (preview: IPreview, abort: any) => Promise<ArrayBuffer>; // function to download image from API
};

export const FormFill = (props: IFormFill) => {
	const [previews, setPreviews] = useState([]);
	const [fields, setFields] = useState(null);

	useEffect(() => {
		const processedBuild = props.form.build.map((build: any) => ({
			height: build.size.height,
			id: build._id,
			locationX: build.location.x,
			locationY: build.location.y,
			page: build.page,
			type: build.config.type,
			width: build.size.width
		}));

		setFields(processedBuild);

		const file = props.form.files[0];

		setPreviews(
			file.preview.map((p: any) => {
				p.originalPageWidth = p.originalSize.width;
				p.originalPageHeight = p.originalSize.height;
				return p;
			})
		);
	}, []);

	const renderFormPage = (preview: ILoadedPreview, index: number) => {
		const previewEntry = previews.find(p => p.page === preview.page);

		return (
			<SizeMe key={preview.fileURL}>
				{({ size }) => {
					const scale = size.width / previewEntry.originalPageWidth;
					return (
						<div>
							<img src={preview.fileURL} />
							{fields
								.filter(
									(field: any) => (field.page === index + 1 ? field : false)
								)
								.map((field: any) => {
									return (
										<DynamicField
											page={field.page}
											key={field.id}
											locationX={field.locationX}
											locationY={field.locationY}
											scale={scale}
											id={field.id}
											height={field.height}
											width={field.width}
											type={field.type}
											value={props.fieldValues[field.id]}
											onDataChange={(data: {value: string}) => props.onDataChange(field.id, data)}
										/>
									);
								})}
						</div>
					);
				}}
			</SizeMe>
		);

	};

	return previews.length > 0 ? (
		<ImagePreview
			hasLoaded={props.onLoadFinish}
			downloadFunc={props.downloadFunc}
			previews={previews}
			renderFunc={renderFormPage}
		/>
	) : null;
};

interface IFormFillDialogProps {
	downloadFunc: (preview: IPreview, abort: any) => Promise<ArrayBuffer>; // function to download image from API
	open: boolean;
	title: string;
	description: string;
	onClose: () => void;
	formRequirement: any;
	onSubmitClick: (fieldValues: {[id: string]: string}) => void;
}

export const FormFillDialog = (props: IFormFillDialogProps) => {

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const { t } = useTranslation();
	const classes = useUploadDialogStyles();
	const [fieldValues, setFieldValues] = useState<{ [id: string]: string }>({});

	const onDataChange = (fieldId: string, data: {value: string}) => {
		setFieldValues(prevValues => {
			const newValues = { ...prevValues };
			newValues[fieldId] = data.value;
			return newValues;
		});
	};

	return (
		<CustomerPortalTheme>
			<Dialog
				fullWidth={true}
				maxWidth={'xl'}
				open={props.open}
				onClose={() => !loading && props.onClose()}
				aria-labelledby="form-dialog-title"
			>
				<div className={classes.iconWrap}>
					<IconButton
						color="inherit"
						onClick={() => !loading && props.onClose()}
						className={classes.closeIcon}
					>
						<CloseIcon />
					</IconButton>
				</div>
				<Typography variant="h1">Add information for: {props.title}</Typography>
				{props.description && (
					<Typography variant="h2">{props.description}</Typography>
				)}
				{error ? (
					<Typography gutterBottom={true} variant="subtitle1" color="error">
						{t(error)}
					</Typography>
				) : null}
				<DialogContent className={classes.content}>
					<FormFill
						fieldValues={fieldValues}
						form={props.formRequirement.form}
						downloadFunc={props.downloadFunc}
						onDataChange={onDataChange}
						onLoadFinish={() => setLoading(false)}
					/>
				</DialogContent>
				<div>
					<div className={classes.wrapper}>
						<Button
							variant="contained"
							onClick={() => props.onSubmitClick(fieldValues)}
							disabled={loading}
							className={classes.rightSpace}
						>
							{t('fileUploadDialog.save')}
						</Button>
						{loading && (
							<CircularProgress size={24} className={classes.buttonProgress} />
						)}
					</div>
					<Button
						variant="contained"
						color="primary"
						disabled={loading}
						onClick={() => props.onClose()}
					>
						{t('fileUploadDialog.cancel')}
					</Button>
				</div>
			</Dialog>
		</CustomerPortalTheme>
	);
};
*/

interface IFormDocumentProps {
	downloadBlankForm: (form: IKycDocumentTemplate) => any;
	formRequirement: IFormSubmission;
	onFormUploadChange: (form: IFormSubmission, file: any) => any;
	// openFormFillDialog: () => void;
}

export const FormDocument = (props: IFormDocumentProps) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const uploadRef: React.RefObject<HTMLInputElement> = React.createRef();
	const formDocument = props.formRequirement.document as IFileSchema;
	const form = props.formRequirement.form as IKycDocumentTemplate;

	const files = props.formRequirement.document
		? [
				{
					file: {
						content: formDocument._id,
						mimeType: formDocument.contentType,
						...formDocument,
					},
				},
		  ]
		: [];

	return (
		<UploadPageItem
			files={files}
			fileNames={[]}
			title={props.formRequirement.title || form.name}
			description={props.formRequirement.description || form.description}
			optional={props.formRequirement.optional}
			isAdditional={props.formRequirement.isAdditional}
			valid={props.formRequirement.valid}
		>
			<input
				id={`form-upload-${props.formRequirement._id}`}
				className={classes.hidden}
				name={`form-upload-${props.formRequirement._id}`}
				ref={uploadRef}
				onChange={(event) =>
					props.onFormUploadChange(props.formRequirement, event.target.files[0])
				}
				type="file"
			/>

			<Button
				color="secondary"
				aria-label="menu"
				onClick={() => props.downloadBlankForm(form)}
			>
				<Grid container justify="center" alignItems="center" direction="column">
					<GetAppIcon />
					<Typography variant="body2" className="bold" gutterBottom>
						{t('forms.download')}
					</Typography>
				</Grid>
			</Button>

			<Button
				color="secondary"
				aria-label="menu"
				onClick={() => uploadRef.current.click()}
			>
				<Grid container justify="center" alignItems="center" direction="column">
					<PublishIcon />

					<Typography variant="body2" className="bold" gutterBottom>
						{t('forms.off-system-upload')}
					</Typography>
				</Grid>
			</Button>

			{/*
			// TODO on-system-fill
			<Button
				color="secondary"
				aria-label="menu"
				onClick={() => props.openFormFillDialog(props.formRequirement)}
			>
				<Grid
					container
					justify="center"
					alignItems="center"
					direction="column"
				>
					<CreateIcon />
					<Typography variant="body2" className="bold" gutterBottom>
						Fill Form
					</Typography>
				</Grid>
			</Button>
		   */}
		</UploadPageItem>
	);
};
