import {
	Box,
	Button,
	Dialog,
	DialogActions,
	LinearProgress,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useInterval } from 'react-use';

import { getCheck } from '../../../../../../common-ts/src/aiscan';
import { loadDocumentSupport } from '../../../../../../common-ts/src/aiscan/documentSupport';
import { KycStatuses } from '../../../../../../common-ts/src/application/constants';
import { findIndexOfStatus } from '../../../../../../common-ts/src/application/statusLog';
import {
	AISCAN_ACTION,
	AISCAN_CHECK_TYPE,
	AISCAN_COMPLETED_STATUSES,
	AISCAN_STATUS,
	IAIScanSession,
} from '../../../../../../common-ts/src/interface/aiscan';
import { IApplication } from '../../../../../../common-ts/src/interface/application';
import { IDSCANFLOWSTRICTNESS } from '../../../../../../common-ts/src/interface/idScan';
import { EXTERNAL_VENDORS } from '../../../../../../common-ts/src/interface/vendors';
import { KYCWebcam } from '../../../../../../common/src/react/WebcamPopup/KYCWebcam';
import { dataURItoFile } from '../../../../../../common/src/util/dataUriToFile';
import { greyLighter } from '../../../../../../ui/src/colors';
import { getMatchedAttributes } from '../../../../helpers';
import { DocumentVerificationMode } from '../../../../models';
import { getSession, uploadFile } from '../../../../models/AIScan';
import { setCurrentApplicationData } from '../../../../redux/actions/application';
import { RootState } from '../../../../redux/reducers';
import {
	addOrSkipIDJourneyId,
	createAIScanSession,
	updateApplication,
} from '../../../../services/api';
import { IKYCSectionProps, SectionNavigation } from '../Section';
import {
	IDDocVerStatusMessage,
	IDDocVerStatusPage,
} from './IDDocVerStatusPage';
import { SwitchToMobileQRMessage } from './SwitchToMobileQR';

const useStyles = makeStyles({
	button: {
		marginTop: '1em',
	},
	instructionsImage: {
		padding: '2em 8px 0 0',
	},
	messageContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		'@media (max-width: 780px)': {
			flexDirection: 'column',
			rowGap: '10px',
		},
	},
});

interface IAIScanCaptureProps extends IKYCSectionProps {
	iframeRedirect?: boolean;
	token: string;
	backendUrl: string;
	flowTriesLimit: number;
	flowStrictness: IDSCANFLOWSTRICTNESS;
}

export const AIScanCapture = (props: IAIScanCaptureProps) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [idDocVerifMode, setIdDocVerifMode] =
		React.useState<DocumentVerificationMode>(null);
	const application = useSelector(
		(root: RootState) => root.applicationStore.currentApplication
	);
	const applicationStoreLoading = useSelector(
		(root: RootState) => root.applicationStore.loading
	);

	const [loading, setLoading] = React.useState(true);
	const [delay, setDelay] = React.useState(null);
	const [getSessionLoading, setGetSessionLoading] = React.useState(false);
	const [uploading, setUploading] = React.useState(false);
	const [
		gettingSessionAfterBacksideUpload,
		setGettingSessionAfterBacksideUpload,
	] = React.useState(false);
	const [hasFailed, setHasFailed] = useState(false);
	const [instructionsModalOpen, setInstructionsModalOpen] = useState(false);

	const [extractingOcrData, setExtractingOcrData] = useState(false);
	const [documentSupport, setDocumentSupport] = useState(null);
	const [currentSession, setCurrentSession] = useState<IAIScanSession>(null);
	const flowIsOptional = props.flowStrictness === IDSCANFLOWSTRICTNESS.optional;

	const journeyIsFinished = currentSession
		? AISCAN_COMPLETED_STATUSES.includes(currentSession.status)
		: false;

	const nextButtonText =
		flowIsOptional && !hasFailed ? 'sections.next-skip' : null;
	const additionalNextButtonProps = flowIsOptional
		? {
				color: 'secondary',
				variant: 'outlined',
		  }
		: {};

	const dispatchApplicationUpdate = React.useCallback(
		(application: IApplication) => {
			dispatch(setCurrentApplicationData(application));
		},
		[dispatch, setCurrentApplicationData]
	);

	// FIXME pass current section in from Sections.tsx
	const currentApplicationSection =
		application.sections && application.sections.length > 0
			? application.sections.find((s: any) => s.components.includes('AIScan'))
			: null;
	const sectionTitle = currentApplicationSection?.title ?? null;
	const sectionDescription = currentApplicationSection?.description ?? null;
	const pageTitle =
		sectionTitle && sectionTitle.length ? sectionTitle : t(props.title);
	const pageDesc =
		sectionDescription && sectionDescription.length ? sectionDescription : null;

	React.useEffect(() => {
		if (isMobile) {
			setIdDocVerifMode(DocumentVerificationMode.Mobile);
		}

		if (props.token) {
			setGetSessionLoading(true);
			getSession(props.backendUrl, props.token).then((session) => {
				setGetSessionLoading(false);
				setCurrentSession(session);
			});
		} else {
			// create session
			createAIScanSession(application.id).then((res) => {
				setGetSessionLoading(true);
				getSession(props.backendUrl, res.token).then((session) => {
					setGetSessionLoading(false);
					setCurrentSession(session);
				});
			});
		}

		loadDocumentSupport().then((support) => {
			setDocumentSupport(support);
		});

		// clean up
		return () => {
			setDelay(null);
		};
	}, []);

	const showInstructionsModal =
		currentSession?.nextAction === AISCAN_ACTION.documentUpload ||
		currentSession?.nextAction === AISCAN_ACTION.documentUploadBackside ||
		currentSession?.nextAction === AISCAN_ACTION.retryDocumentUpload ||
		currentSession?.nextAction ===
			AISCAN_ACTION.retryDocumentUploadDifferentType;

	React.useEffect(() => {
		if (showInstructionsModal && !!idDocVerifMode) {
			setInstructionsModalOpen(true);
		}
	}, [idDocVerifMode, showInstructionsModal]);

	const [problematicFields, setProblematicFields] = useState([]);

	React.useEffect(() => {
		if (currentSession === null) {
			return;
		}

		const documentCheck = getCheck(
			currentSession,
			AISCAN_CHECK_TYPE.idDocument
		);

		(async () => {
			// OCR extraction
			if (documentCheck?.result?.attributes && !extractingOcrData) {
				let attributes = getMatchedAttributes(
					application,
					documentCheck.result.attributes,
					EXTERNAL_VENDORS.AISCAN
				);

				// Check if attributes array include problematic fields and if it does remove them so that update application doesn't fail on loop
				attributes = attributes.filter(
					(attr) => !problematicFields.includes(attr.schemaId)
				);

				if (attributes.length) {
					try {
						setExtractingOcrData(true);
						const updatedApplication = await updateApplication(application.id, {
							attributes,
						});
						dispatchApplicationUpdate(updatedApplication);
						setProblematicFields([]);
					} catch (err) {
						if (err.response && err.response.status === 422) {
							const errorResponse = err.response.data;
							errorResponse.validationErrors.forEach((error: any) => {
								if (
									error.details &&
									error.details.data &&
									error.details.data.schemaId
								) {
									setProblematicFields((prev) => [
										...prev,
										error.details.data.schemaId,
									]);
								}
							});
						} else {
							// fail silently
						}
					} finally {
						setExtractingOcrData(false);
					}
				}
			}

			if (
				(currentSession.nextAction === AISCAN_ACTION.checkCompleted &&
					documentCheck &&
					AISCAN_COMPLETED_STATUSES.includes(documentCheck.status)) ||
				AISCAN_COMPLETED_STATUSES.includes(currentSession.status)
			) {
				if (
					currentSession.status === AISCAN_STATUS.failed &&
					props.flowStrictness === IDSCANFLOWSTRICTNESS.strict &&
					findIndexOfStatus(application.statusLog, KycStatuses.reopened) < 0
				) {
					setDelay(null);
					setHasFailed(true);
					return;
				}

				if (!extractingOcrData && !applicationStoreLoading) {
					nextClick();
				}
				return;
			}
		})();
		// no need to get session through polling after backside upload if there is selfie check or in case document processing failed
		if (
			currentSession.nextAction === AISCAN_ACTION.selfieUpload ||
			currentSession.nextAction === AISCAN_ACTION.retryDocumentUpload ||
			currentSession.nextAction ===
				AISCAN_ACTION.retryDocumentUploadDifferentType
		) {
			setGettingSessionAfterBacksideUpload(false);
		}

		// polling
		if (
			(!currentSession.nextAction &&
				currentSession.status === AISCAN_STATUS.pending) ||
			(!AISCAN_COMPLETED_STATUSES.includes(currentSession.status) &&
				(currentSession.nextAction === AISCAN_ACTION.waitForDocumentCheck ||
					currentSession.nextAction === AISCAN_ACTION.waitForSelfieCheck ||
					gettingSessionAfterBacksideUpload))
		) {
			startPolling();
		} else {
			setDelay(null);
		}

		setLoading(false);
	}, [currentSession, gettingSessionAfterBacksideUpload]);

	const startPolling = () => {
		setDelay(3000);
	};

	useInterval(() => {
		if (!getSessionLoading) {
			setGetSessionLoading(true);
			getSession(props.backendUrl, currentSession.sessionId).then((session) => {
				setGetSessionLoading(false);
				setCurrentSession(session);
			});
		}
	}, delay);

	const onChange = async (file: any, type: AISCAN_CHECK_TYPE) => {
		if (!file) {
			return;
		}
		setUploading(true);
		setDelay(null);
		await uploadFile(props.backendUrl, currentSession.sessionId, file, type)
			.then((result) => {
				if (!result?.sessionId) {
					toast.error(t(`aiscan.documentUpload.unexpectedResponse`));
					return;
				}
				setGetSessionLoading(false);
				setCurrentSession(result);
				if (type === AISCAN_CHECK_TYPE.idDocumentBackside) {
					setGettingSessionAfterBacksideUpload(true);
				}
			})
			.catch((_) => toast.error(t(`aiscan.documentUpload.uploadFailure`)))
			.finally(() => {
				setGetSessionLoading(false);
				setUploading(false);
			});
	};

	const continueOnDesktop = async () => {
		setIdDocVerifMode(DocumentVerificationMode.Desktop);
	};

	const nextClick = async () => {
		setDelay(null);

		if (flowIsOptional && !journeyIsFinished) {
			await addOrSkipIDJourneyId(application.id, null).then(
				(updatedApplication) => {
					dispatchApplicationUpdate(updatedApplication);
				}
			);
		}

		props.nextCallback();
	};

	const getImageCapture = (p: any) => {
		if (p) {
			if (p.base64) {
				return dataURItoFile(p.base64);
			} else if (p.file) {
				return p.file;
			}
		}
		return null;
	};

	const displayDocumentUpload =
		!uploading &&
		!gettingSessionAfterBacksideUpload &&
		!loading &&
		(currentSession.nextAction === AISCAN_ACTION.documentUpload ||
			currentSession.nextAction === AISCAN_ACTION.retryDocumentUpload ||
			currentSession.nextAction ===
				AISCAN_ACTION.retryDocumentUploadDifferentType);
	const shouldUploadBackside = currentSession?.availableChecks.includes(
		AISCAN_CHECK_TYPE.idDocumentBackside
	);
	const displayDocumentUploadBackside =
		!uploading &&
		!gettingSessionAfterBacksideUpload &&
		!loading &&
		shouldUploadBackside &&
		currentSession.nextAction === AISCAN_ACTION.documentUploadBackside;
	const displaySelfieUpload =
		currentSession?.availableChecks.includes(AISCAN_CHECK_TYPE.selfie) &&
		!uploading &&
		!loading &&
		(currentSession.nextAction === AISCAN_ACTION.selfieUpload ||
			currentSession.nextAction === AISCAN_ACTION.retrySelfieUpload);
	const displayDocumentUploadButton =
		application.options.idScanShowPhotoUploadOption ?? true;
	const displaySelfieUploadButton =
		application.options.idScanShowSelfieUploadOption ?? true;

	let messageTitle = t('aiscan.initializing.title');
	let messageDescription = t('aiscan.initializing.description');
	let captureButtonText = '';
	let uploadButtonText = '';
	let currentAttempt = 1;
	let securityCheckUIErrors: string[] = [];

	if (delay && !uploading) {
		messageTitle = t('aiscan.processing.title');
		messageDescription = t('aiscan.processing.description');
	} else if (uploading || gettingSessionAfterBacksideUpload) {
		messageTitle = t('aiscan.uploading.title');
		messageDescription = t('aiscan.uploading.description');
	} else if (displayDocumentUpload) {
		messageTitle = currentSession.nextAction.includes('retry')
			? t('aiscan.documentUpload.retryTitle')
			: shouldUploadBackside
			? t('aiscan.documentUpload.titleFront')
			: t('aiscan.documentUpload.title');
		messageDescription = null;
		captureButtonText = t('aiscan.documentUpload.captureButton');
		uploadButtonText = t('aiscan.documentUpload.uploadButtonText');
		currentAttempt = currentSession.attempts.idDocument;

		// ID document validation failed UI errors setup
		if (
			(currentSession.nextAction === AISCAN_ACTION.retryDocumentUpload ||
				currentSession.nextAction ===
					AISCAN_ACTION.retryDocumentUploadDifferentType) &&
			currentSession.checks[currentSession.checks.length - 1]
		) {
			const securityChecksObj =
				currentSession.checks[currentSession.checks.length - 1]?.result
					?.securityChecks ?? {};
			securityCheckUIErrors = Object.keys(securityChecksObj).filter(
				(k) =>
					// check failed
					!securityChecksObj[k] &&
					// it's a check that we care about displaying
					[
						'faceExistsInID',
						'firstNameExists',
						'lastNameExists',
						'documentNumberExists',
						'issuingCountryExists',
						'dateOfBirthExists',
						'expirationDateExists',
						'expiryDateInFuture',
						'documentTypeAllowed',
					].includes(k)
			);
		}
	} else if (displayDocumentUploadBackside) {
		messageTitle = currentSession.nextAction.includes('retry')
			? t('aiscan.documentUpload.retryTitle')
			: t('aiscan.documentUpload.titleBack');
		messageDescription = null;
		captureButtonText = t('aiscan.documentUpload.captureButton');
		uploadButtonText = t('aiscan.documentUpload.uploadButtonText');
		currentAttempt = currentSession.attempts.idDocument;
	} else if (displaySelfieUpload) {
		messageTitle = currentSession.nextAction.includes('retry')
			? t('aiscan.selfieUpload.retryTitle')
			: t('aiscan.selfieUpload.title');
		messageDescription = null;
		captureButtonText = t('aiscan.selfieUpload.captureButton');
		uploadButtonText = t('aiscan.selfieUpload.uploadButtonText');
		currentAttempt = currentSession.attempts.selfie;
	}

	const allowedDocumentTypes: string[] =
		currentSession?.allowedDocumentCategory && documentSupport
			? documentSupport
					.filter(
						(d: any) => d.category === currentSession.allowedDocumentCategory
					)
					.map((d: any) => d.id)
			: [];
	const formattedTypes = allowedDocumentTypes.map((d) =>
		capitalize(t(`aiscan.documentType.${d}`))
	);
	const formattedTypesString =
		formattedTypes.length > 1
			? `${formattedTypes.slice(0, -1).join(', ')} and ${
					formattedTypes[formattedTypes.length - 1]
			  }`
			: formattedTypes[0];

	return (
		<div>
			<Dialog
				fullWidth={true}
				maxWidth="md"
				open={instructionsModalOpen}
				onClose={() => setInstructionsModalOpen(false)}
			>
				<Typography variant="h1">
					{t('aiscan.documentUpload.instructionsTitle')}
				</Typography>
				<img
					className={classes.instructionsImage}
					src={
						displayDocumentUploadBackside
							? 'https://cdn.kyc-chain.com/images/instructions-back.png'
							: 'https://cdn.kyc-chain.com/images/instructions.png'
					}
				/>
				<DialogActions>
					<Button
						onClick={() => setInstructionsModalOpen(false)}
						variant="contained"
						color="primary"
						className={classes.button}
					>
						I Understand
					</Button>
				</DialogActions>
			</Dialog>
			{pageTitle && (
				<Typography align="left" variant="h1" style={{ marginBottom: '15px' }}>
					{pageTitle}
				</Typography>
			)}
			{pageDesc && (
				<Typography variant="h6" gutterBottom>
					<div dangerouslySetInnerHTML={{ __html: pageDesc }}></div>
				</Typography>
			)}
			{!idDocVerifMode && !isMobile && (
				<SwitchToMobileQRMessage
					applicationId={application.id}
					continueFunc={continueOnDesktop}
				/>
			)}
			{hasFailed && <IDDocVerStatusPage status="outOfTries" />}
			{idDocVerifMode && !hasFailed && (
				<>
					<Box
						className={
							(displayDocumentUpload ||
								displayDocumentUploadBackside ||
								displaySelfieUpload) &&
							currentSession &&
							classes.messageContainer
						}
					>
						{(currentSession?.nextAction ===
							AISCAN_ACTION.retryDocumentUpload ||
							currentSession?.nextAction ===
								AISCAN_ACTION.retryDocumentUploadDifferentType) &&
						!delay &&
						!uploading ? (
							<IDDocVerStatusMessage
								status={'failed'}
								securityCheckUIErrors={securityCheckUIErrors}
								shouldUploadBackside={shouldUploadBackside}
							/>
						) : (
							<>
								<Typography
									variant="h2"
									style={{ fontWeight: 'bold', fontSize: '16px' }}
								>
									{messageTitle}
								</Typography>
							</>
						)}
						{messageDescription && (
							<Typography
								variant="body1"
								style={{
									marginBottom: '1em',
									marginTop: '1em',
									color: greyLighter,
								}}
							>
								{messageDescription}
							</Typography>
						)}
						{(loading || uploading || delay) && <LinearProgress />}
						{(displayDocumentUpload ||
							displayDocumentUploadBackside ||
							displaySelfieUpload) &&
							currentSession && (
								<Typography
									variant="body1"
									style={{
										fontWeight: 'bold',
										fontSize: '16px',
										color: greyLighter,
									}}
								>
									You have {currentSession.maxAttemptsPerCheck - currentAttempt}{' '}
									attempt
									{currentSession.maxAttemptsPerCheck - currentAttempt > 1 &&
										's'}{' '}
									left.
								</Typography>
							)}
					</Box>

					{allowedDocumentTypes.length > 0 &&
						displayDocumentUpload &&
						!loading &&
						!uploading &&
						!delay && (
							<Typography
								variant="body1"
								style={{
									marginBottom: '1em',
									marginTop: '1em',
									color: greyLighter,
								}}
							>
								{t('aiscan.allowedDocumentTypes', {
									types: formattedTypesString,
								})}
							</Typography>
						)}

					{flowIsOptional && !uploading && !delay && !loading && (
						<Typography variant="body1" style={{ marginTop: '0.5rem' }}>
							{t('idverification.optional-flow')}
						</Typography>
					)}
					{(displayDocumentUpload ||
						displayDocumentUploadBackside ||
						displaySelfieUpload) && (
						<KYCWebcam
							displayOverlay={
								displayDocumentUpload || displayDocumentUploadBackside
									? 'document'
									: 'selfie'
							}
							facingMode={displaySelfieUpload ? 'user' : 'environment'}
							captureButtonText={captureButtonText}
							displayUploadButton={
								displayDocumentUpload || displayDocumentUploadBackside
									? displayDocumentUploadButton
									: displaySelfieUploadButton
							}
							mirror={displaySelfieUpload}
							uploadButtonText={uploadButtonText}
							onImageCapture={(image) =>
								onChange(
									getImageCapture(image),
									displayDocumentUpload
										? AISCAN_CHECK_TYPE.idDocument
										: displayDocumentUploadBackside
										? AISCAN_CHECK_TYPE.idDocumentBackside
										: AISCAN_CHECK_TYPE.selfie
								)
							}
							isMobile={isMobile}
							instructions={
								displaySelfieUpload
									? t('aiscan.selfieUpload.description')
									: t('aiscan.documentUpload.description')
							}
						/>
					)}
				</>
			)}
			{(idDocVerifMode || isMobile) && (
				<SectionNavigation
					nextCallback={props.iframeRedirect ? null : nextClick}
					nextButtonText={nextButtonText}
					nextButtonProps={{
						disabled: !flowIsOptional || hasFailed,
						...additionalNextButtonProps,
					}}
					position="right"
				/>
			)}
		</div>
	);
};
