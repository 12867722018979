import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { primaryLighter, white } from '../../../../../ui/src/colors';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';

interface KYCStep {
	// custom interface to support separate name and title
	name: string;
	title: string;
}

interface MobileStepIndicatorContainerProps {
	activeStep: number;
	steps: KYCStep[];
}

const useStyles = makeStyles({
	active: {
		backgroundColor: primaryLighter,
		borderRadius: '50%',
		color: white,
		padding: '2.5px 9px',
		fontWeight: 700,
	},
	step: {
		marginTop: '40px',
		textAlign: 'center',
	},
	termsMobileCta: {
		'@media (max-width: 960px)': {
			position: 'fixed',
			bottom: 0,
			right: 0,
			width: '100%',
			padding: '10px 0',
			marginTop: 0,
			background: 'white',
		},
	},
});

export const MobileStepIndicatorContainer: React.FC<MobileStepIndicatorContainerProps> =
	(props) => {
		const classes = useStyles();
		const { t } = useTranslation();
		const { pathname } = useLocation();
		const isTermsPage = pathname.includes('terms');
		return (
			<CustomerPortalTheme>
				<Hidden mdUp>
					<div
						className={`${classes.step} ${
							isTermsPage && classes.termsMobileCta
						}`}
					>
						<span className={classes.active}>
							{t('step_indicator_mobile.active_step', {
								activeStep: props.activeStep + 1,
							})}
						</span>
						<span> / </span>
						<span>
							{t('step_indicator_mobile.total_step', {
								total: props.steps.length,
							})}
						</span>
					</div>
				</Hidden>
			</CustomerPortalTheme>
		);
	};

export default MobileStepIndicatorContainer;
