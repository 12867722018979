import * as React from 'react';
import { SFC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Clear from '@material-ui/icons/Clear';
import Done from '@material-ui/icons/Done';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { IPasswordStrengthResult } from '../../../../common-ts/src/interface/user';
import { checkPasswordStrength } from '../../../../common-ts/src/password';
import { accent, error, tertiary } from '../../../../ui/src/colors';

const useStyles = makeStyles({
	button: {
		marginTop: '2em',
	},
	checkText: {
		paddingLeft: '1em',
	},
	doneColor: {
		color: accent,
	},
	errorColor: {
		color: error,
	},
	helperColor: {
		color: tertiary,
	},
	icon: {
		fontSize: '15px',
		left: 0,
		position: 'relative' as 'relative',
		top: '3px',
	},
	input: {
		marginBottom: '1em',
		marginTop: '1.5em',
	},
	inputBottom: {
		marginBottom: '16px',
	},
	inputMaxWidth: {
		maxWidth: '400px',
	},
	listElement: {
		marginBottom: '5px',
	},
	notStarted: {
		display: 'inline-block',
		height: '15px',
		textAlign: 'center' as 'center',
		width: '15px',
	},
	visibilityIcon: {
		fontSize: '17px',
	},
});

export const PasswordStrengthChecker: SFC<{
	password: string;
	strengthResult: IPasswordStrengthResult;
}> = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const typographyProps = {
		align: 'left',
		variant: 'subtitle2',
	} as any;

	return (
		<>
			<Typography
				{...typographyProps}
				className={`${classes.listElement} ${classes.helperColor}`}
			>
				{t('password-strength.description')}
			</Typography>
			<Typography
				className={`${classes.listElement} ${
					props.strengthResult.passLength
						? classes.helperColor
						: classes.errorColor
				}`}
				{...typographyProps}
			>
				{props.strengthResult.inputStarted ? (
					<>
						{props.strengthResult.passLength ? (
							<Done className={`${classes.icon} ${classes.doneColor}`} />
						) : (
							<Clear className={classes.icon} color="error" />
						)}
					</>
				) : (
					<span className={classes.notStarted}>•</span>
				)}
				<span className={classes.checkText}>
					{t('password-strength.length')}
				</span>
			</Typography>
			<Typography
				className={`${classes.listElement} ${
					props.strengthResult.passCase
						? classes.helperColor
						: classes.errorColor
				}`}
				{...typographyProps}
			>
				{props.strengthResult.inputStarted ? (
					<>
						{props.strengthResult.passCase ? (
							<Done className={`${classes.icon} ${classes.doneColor}`} />
						) : (
							<Clear className={classes.icon} color="error" />
						)}
					</>
				) : (
					<span className={classes.notStarted}>•</span>
				)}
				<span className={classes.checkText}>{t('password-strength.case')}</span>
			</Typography>
			<Typography
				className={`${classes.listElement} ${
					props.strengthResult.passNumeric
						? classes.helperColor
						: classes.errorColor
				}`}
				{...typographyProps}
			>
				{props.strengthResult.inputStarted ? (
					<>
						{props.strengthResult.passNumeric ? (
							<Done className={`${classes.icon} ${classes.doneColor}`} />
						) : (
							<Clear className={classes.icon} color="error" />
						)}
					</>
				) : (
					<span className={classes.notStarted}>•</span>
				)}
				<span className={classes.checkText}>
					{t('password-strength.numeric')}
				</span>
			</Typography>
			<Typography
				className={`${classes.listElement} ${
					props.strengthResult.passSpecial
						? classes.helperColor
						: classes.errorColor
				}`}
				{...typographyProps}
			>
				{props.strengthResult.inputStarted ? (
					<>
						{props.strengthResult.passSpecial ? (
							<Done className={`${classes.icon} ${classes.doneColor}`} />
						) : (
							<Clear className={classes.icon} color="error" />
						)}
					</>
				) : (
					<span className={classes.notStarted}>•</span>
				)}
				<span className={classes.checkText}>
					{t('password-strength.special')}
				</span>
			</Typography>
		</>
	);
};

interface IPasswordFormProps {
	onCancelClick?: () => void;
	displayCancel?: boolean;
	displayRetype?: boolean;
	submit: (password: string) => Promise<any>;
	setButtonProps?: any;
	setButtonText: string;
	formTitle: string;
}

export const PasswordForm = (props: IPasswordFormProps) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [values, setValues] = useState({
		password: '',
		passwordMatch: '',
		showPassword: false,
		showPasswordMatch: false,
		validationError: false,
	});

	const [_, setSubmitLoading] = useState(false);

	const passwordStrengthResult = checkPasswordStrength(values.password);
	const passwordStrengthPass = Object.keys(passwordStrengthResult).every(
		(key) => passwordStrengthResult[key]
	);

	const handleClickShowPassword = (match: any) => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleClickShowPasswordMatch = (match: any) => {
		setValues({ ...values, showPasswordMatch: !values.showPasswordMatch });
	};

	const handleMouseDownPassword = (event: React.MouseEvent) => {
		event.preventDefault();
	};

	const handleChange =
		(prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
		};

	const setPasswordSubmit = (event: React.SyntheticEvent) => {
		event.preventDefault();
		if (!passwordStrengthPass) {
			return;
		}
		setValues({ ...values, validationError: false });
		if (values.password.length <= 0) {
			setValues({ ...values, validationError: true });
			return;
		}
		setSubmitLoading(true);
		props
			.submit(values.password)
			.then(() => {
				setValues({
					password: '',
					passwordMatch: '',
					showPassword: false,
					showPasswordMatch: false,
					validationError: false,
				});
				setSubmitLoading(false);
			})
			.catch(() => {
				setSubmitLoading(false);
			});
	};

	return (
		<form onSubmit={setPasswordSubmit}>
			<FormControl
				className={classes.input}
				error={values.password.length <= 0 && values.validationError}
				fullWidth
			>
				<InputLabel shrink htmlFor="password">
					{t(props.formTitle)} *
				</InputLabel>
				<Input
					id="password"
					disabled={false}
					type={values.showPassword ? 'text' : 'password'}
					value={values.password}
					placeholder={t('set-password.password')}
					onChange={handleChange('password')}
					className={`${classes.inputBottom} ${classes.inputMaxWidth}`}
					disableUnderline
					required
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
							>
								{values.showPassword ? (
									<Visibility className={classes.visibilityIcon} />
								) : (
									<VisibilityOff className={classes.visibilityIcon} />
								)}
							</IconButton>
						</InputAdornment>
					}
				/>
				{values.password.length <= 0 && values.validationError ? (
					<FormHelperText>Required</FormHelperText>
				) : null}
				<PasswordStrengthChecker
					strengthResult={passwordStrengthResult}
					password={values.password}
				/>
				{props.displayRetype && (
					<>
						<InputLabel shrink htmlFor="password" style={{ marginTop: '2em' }}>
							{t('set-password.retype')} *
						</InputLabel>
						<Input
							id="password"
							disabled={false}
							type={values.showPasswordMatch ? 'text' : 'password'}
							value={values.passwordMatch}
							onChange={handleChange('passwordMatch')}
							placeholder={t('set-password.retype')}
							className={`${classes.inputBottom} ${classes.inputMaxWidth}`}
							disableUnderline
							required
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPasswordMatch}
										onMouseDown={handleMouseDownPassword}
									>
										{values.showPasswordMatch ? (
											<Visibility className={classes.visibilityIcon} />
										) : (
											<VisibilityOff className={classes.visibilityIcon} />
										)}
									</IconButton>
								</InputAdornment>
							}
						/>
					</>
				)}
				{values.password.length <= 0 && values.validationError ? (
					<FormHelperText>Required</FormHelperText>
				) : null}
			</FormControl>
			<Button
				type="submit"
				className={classes.button}
				variant="contained"
				disabled={
					!passwordStrengthPass ||
					(props.displayRetype && values.password !== values.passwordMatch) ||
					values.password.length === 0
				}
				{...props.setButtonProps}
			>
				{t(props.setButtonText)}
			</Button>
			{props.displayCancel && (
				<Button
					className={classes.button}
					variant="contained"
					color="primary"
					onClick={props.onCancelClick}
				>
					{t('set-password.cancel')}
				</Button>
			)}
		</form>
	);
};
