export enum ApplicationsView {
	List = 'list',
	Hierarchy = 'hierarchy',
}

export enum ManagerGroupingTypes {
	ASSIGNED = 'assigned',
	UNASSIGNED = 'unassigned',
	ASSIGNEDTOOTHERS = 'assignedToOthers',
}
