import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
	ISession,
	IUserFields,
} from '../../../../common-ts/src/interface/user';
import { ILoginPayload } from '../../models';

import { getMyUser, login, saveSessionId } from '../../services/api';

export enum UserAction {
	LOGOUT = 'LOGOUT',
	LOGIN_SUCCESS = 'LOGIN_SUCCESS',
	FETCH_USER = 'FETCH_USER',
	LOGIN_FAILURE = 'LOGIN_FAILURE',
}

interface IUserActionsLoginSuccess extends Action<UserAction.LOGIN_SUCCESS> {
	user: IUserFields & ISession;
	token?: string;
}

export const userLoginSuccess = (user: any, token?: string) => ({
	token,
	type: UserAction.LOGIN_SUCCESS,
	user,
});

interface IUserActionsLoginFailure extends Action<UserAction.LOGIN_FAILURE> {
	error: any;
}

export const userLoginFailure = (error: any): IUserActionsLoginFailure => ({
	error,
	type: UserAction.LOGIN_FAILURE,
});

interface IUserActionsLogout extends Action<UserAction.LOGOUT> {}

export const userLogout = (): IUserActionsLogout => ({
	type: UserAction.LOGOUT,
});

interface IUserActionsFetchUser extends Action<UserAction.FETCH_USER> {
	user?: IUserFields;
}

export const fetchUser = (): IUserActionsFetchUser => ({
	type: UserAction.FETCH_USER,
});

export type IUserActions =
	| IUserActionsLoginSuccess
	| IUserActionsLoginFailure
	| IUserActionsLogout
	| IUserActionsFetchUser;

export const getMySession = () => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(fetchUser());
		return getMyUser()
			.then((user: IUserFields & ISession) => {
				saveSessionId(user.sessionId);
				dispatch(userLoginSuccess(user));
			})
			.catch((err) => {
				dispatch(userLoginFailure(err.response));
			});
	};
};

export const userLogin = (payload: ILoginPayload, loginMethod?: string) => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(fetchUser());
		return login(payload, loginMethod)
			.then((data: { jwt: string; user: IUserFields & ISession }) => {
				saveSessionId(data.user?.sessionId);
				dispatch(userLoginSuccess(data.user, data.jwt));
			})
			.catch((err) => {
				dispatch(userLoginFailure(err.response));
			});
	};
};
