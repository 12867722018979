import { Box, Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';

import { accent, greyLighter, white } from '../../../../ui/src/colors';

interface IResultImage {
	file?: any;
	base64?: string;
}

interface IWebcamProps {
	onImageCapture: (image: IResultImage) => void;
	uploadButtonText?: string;
	captureButtonText?: string;
	displayUploadButton?: boolean;
	facingMode?: 'user' | 'environment';
	displayOverlay?: 'document' | 'selfie';
	mirror?: boolean;
	isMobile?: boolean;
	instructions?: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			marginTop: '16px',
			marginBottom: '36px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		cameraContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		loadingContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignContent: 'center',
			alignItems: 'center',
		},
		webcam: {
			width: '100%',
			objectFit: 'cover',
			height: 430,
			borderRadius: '8px',
			'@media (max-width: 480px)': {
				height: 490,
			},
		},
		overlay: {
			position: 'absolute',
			left: 0,
			top: 0,
			width: '100%',
			height: '100%',
			zIndex: 2,
		},
		instructions: {
			marginTop: '1em',
			textAlign: 'center',
		},
		uploadButton: {
			textTransform: 'uppercase',
			letterSpacing: '1px',
			borderColor: accent,
			borderRadius: '8px',
			padding: '12px 16px',
			color: greyLighter,
		},
	})
);

const Overlay = (props: { selfie?: 'desktop' | 'mobile' }) => {
	if (props.selfie === 'mobile') {
		return (
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 375 440"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				preserveAspectRatio="none"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M375 0H0V440H375V0ZM188.5 76C257.812 76 314 125.536 314 186.641V253.359C314 314.464 257.812 364 188.5 364C119.188 364 63 314.464 63 253.359V186.641C63 125.536 119.188 76 188.5 76Z"
					fill="black"
					fill-opacity="0.75"
				/>
				<rect
					x="65.5"
					y="78.5"
					width="247"
					height="283"
					rx="112.5"
					stroke="white"
				/>
			</svg>
		);
	}
	if (props.selfie === 'desktop') {
		return (
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 687 427"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				preserveAspectRatio="none"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M687 8C687 3.58172 683.418 0 679 0H8C3.58172 0 0 3.58172 0 7.99999V419C0 423.418 3.58173 427 8.00001 427H679C683.418 427 687 423.418 687 419V8ZM344 44C266.68 44 204 106.68 204 184V244C204 321.32 266.68 384 344 384C421.32 384 484 321.32 484 244V184C484 106.68 421.32 44 344 44Z"
					fill="black"
					fill-opacity="0.75"
				/>
				<path
					d="M483 174.465C483 102.964 420.991 45 344.5 45C268.009 45 206 102.964 206 174.465V252.535C206 324.036 268.009 382 344.5 382C420.991 382 483 324.036 483 252.535V174.465Z"
					stroke="white"
					stroke-width="2"
				/>
			</svg>
		);
	}
	// default to document
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 687 427"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8 0C3.58172 0 0 3.58172 0 7.99999V419C0 423.418 3.58173 427 8.00001 427H679C683.418 427 687 423.418 687 419V8C687 3.58172 683.418 0 679 0H8ZM32 24C27.5817 24 24 27.5817 24 32V394C24 398.418 27.5817 402 32 402H655C659.418 402 663 398.418 663 394V32C663 27.5817 659.418 24 655 24H32Z"
				fill="black"
				fill-opacity="0.75"
			/>
			<rect
				x="24.5"
				y="24.5"
				width="638"
				height="378"
				rx="7.5"
				stroke="white"
			/>
		</svg>
	);
};

export const KYCWebcam = (props: IWebcamProps) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [settings, setSettings] = React.useState({
		facingMode: props.facingMode ?? 'user',
		deviceId: null,
	});
	const [devices, setDevices] = React.useState([]);
	const [currentDeviceId, setCurrentDeviceId] = React.useState(null);
	const [uploadRef] = React.useState(
		React.createRef() as React.RefObject<HTMLInputElement>
	);
	const [img, setImg] = React.useState<IResultImage>(null);
	const [webcam, setWebcam] = React.useState(null);
	const [displayError, setDisplayError] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const mirror = props.mirror ? props.mirror : false;

	const displayUploadButton =
		typeof props.displayUploadButton === 'undefined'
			? true
			: props.displayUploadButton;

	const handleDevices = React.useCallback(
		(mediaDevices) =>
			setDevices(mediaDevices.filter(({ kind }: any) => kind === 'videoinput')),
		[setDevices]
	);

	React.useEffect(() => {
		if (navigator.mediaDevices?.enumerateDevices) {
			navigator.mediaDevices.enumerateDevices().then(handleDevices);
		}
	}, [handleDevices]);

	const capture = () => {
		if (!webcam) {
			return;
		}

		const value = webcam.getScreenshot();
		const newImage = {
			base64: value,
		};
		setImg(newImage);
	};

	const onError = () => {
		setDisplayError(true);
	};

	React.useEffect(() => {
		if (props.onImageCapture) {
			props.onImageCapture(img);
		}
	}, [img]);

	React.useEffect(() => {
		if (webcam && !currentDeviceId) {
			const stream = webcam.stream;
			const track = stream.getVideoTracks()[0];
			const { deviceId } = track.getSettings();
			setCurrentDeviceId(deviceId);
		}
	}, [webcam?.stream]);

	const webcamProps: any = {
		onUserMediaError: onError,
	};

	const image =
		!img || !img.base64 ? (
			<Webcam
				className={classes.webcam}
				minScreenshotWidth={1920}
				audio={false}
				height=""
				ref={(wb: any) => setWebcam(wb)}
				onUserMedia={() => setLoading(false)}
				screenshotFormat="image/jpeg"
				screenshotQuality={1}
				imageSmoothing={false}
				mirrored={mirror}
				videoConstraints={settings}
				// fixes ts error caused by problem in the npm package
				{...webcamProps}
			/>
		) : (
			<img src={img.base64} />
		);

	// Break 'instructions' prop into two lines
	const periodIndex = props.instructions?.indexOf('.');
	const instructionsFirstLine = props.instructions?.substring(
		0,
		periodIndex + 1
	);
	const instructionsSecondLine = props.instructions?.substring(periodIndex + 1);

	return (
		<div className={classes.container}>
			<div className={classes.cameraContainer}>
				<input
					ref={uploadRef}
					onChange={(event) => setImg({ file: event.target.files[0] })}
					id="upload-button-webcam"
					style={{ display: 'none' }}
					type="file"
				/>
				{loading && !displayError && (
					<div className={classes.loadingContainer}>
						<CircularProgress />
						<Typography style={{ textAlign: 'center' }} variant="body2">
							{t('webcam.init')}
						</Typography>
					</div>
				)}
				{displayError && <Typography>{t('webcam.failure')}</Typography>}
			</div>
			{!displayError && !loading && devices.length > 1 && !props.isMobile && (
				<div>
					<ButtonGroup
						variant="contained"
						color="primary"
						aria-label="contained primary button group"
					>
						{devices.map((device, index) => {
							return (
								<Button
									key={device.deviceId}
									variant="contained"
									disabled={device.deviceId === currentDeviceId}
									color="primary"
									onClick={() => {
										if (currentDeviceId !== device.deviceId) {
											setSettings({ ...settings, deviceId: device.deviceId });
											setCurrentDeviceId(device.deviceId);
										}
									}}
								>
									{device.label?.length
										? device.label
										: `Camera ${index + 1}${
												device.deviceId === currentDeviceId ? ' (Active)' : ''
										  }`}
								</Button>
							);
						})}
					</ButtonGroup>
				</div>
			)}
			<div
				style={{
					position: 'relative',
					width: '100%',
				}}
			>
				{!loading && props.displayOverlay === 'document' && (
					<div className={classes.overlay}>
						<Overlay />
					</div>
				)}
				{!loading && props.displayOverlay === 'selfie' && (
					<div className={classes.overlay}>
						<Overlay selfie={props.isMobile ? 'mobile' : 'desktop'} />
					</div>
				)}
				{!displayError && image}
			</div>
			{props.instructions && (
				<Typography variant="body1" className={classes.instructions}>
					{instructionsFirstLine}
					<br />
					{instructionsSecondLine}
				</Typography>
			)}
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				flexDirection="column"
				marginTop="16px"
				style={{ gap: '24px' }}
			>
				{!loading && (
					<Button
						variant="contained"
						onClick={capture}
						style={{
							width: '64px',
							height: '64px',
							borderRadius: '100px',
							color: white,
						}}
					>
						<CameraAltIcon style={{ width: '24px', height: '24px' }} />
					</Button>
				)}
				{displayUploadButton && (
					<Button
						variant="outlined"
						onClick={() => uploadRef.current.click()}
						className={classes.uploadButton}
					>
						{props.uploadButtonText ?? 'Upload'}
					</Button>
				)}
			</Box>
		</div>
	);
};
