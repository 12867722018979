import { makeStyles } from '@material-ui/core/styles';

import { tertiary } from '../../../../../../../../ui/src/colors';

export const useStyles = makeStyles({
	hiddenOnMobile: {
		'@media (max-width: 600px)': {
			display: 'none',
		},
		display: 'table-cell',
	},
	iconButton: {
		margin: '0 0 0 8px',
	},
	iconType: {
		marginTop: '5px',
	},
	mobile: {
		'@media (max-width: 600px)': {
			display: 'table-cell',
			verticalAlign: 'baseline',
		},
		display: 'none',
	},
	mobileIconElement: {
		padding: '6px 0 6px 6px',
	},
	mobileOverflowProtection: {
		maxWidth: '180px',
		overflow: 'hidden',
		whiteSpace: 'pre-line',
	},
	overflowProtection: {
		maxWidth: '120px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	subtitle: {
		color: tertiary,
	},
	textOnMobileView: {
		'@media (max-width: 600px)': {
			fontWeight: 'bold',
		},
		fontWeight: 'initial',
	},
	vertical: {
		'@media (max-width: 600px)': {
			verticalAlign: 'top',
		},
		verticalAlign: 'inherit',
	},
});
