import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles({
	button: {
		position: 'relative' as any,
	},
	buttonProgress: {
		color: blue[500],
		left: '0.5em',
		position: 'relative' as any,
		zIndex: 1,
	},
});

export default React.forwardRef((props: any, ref: any) => {
	const {
		loading,
		className,
		children,
		loaderSize,
		ButtonClass = Button,
		...rest
	} = props;
	const styleClasses = useStyles();
	return (
		<ButtonClass
			className={className + ' ' + styleClasses.button}
			disabled={loading}
			{...rest}
		>
			{children}
			{loading && (
				<CircularProgress
					key="k"
					size={loaderSize || 24}
					className={styleClasses.buttonProgress}
				/>
			)}
		</ButtonClass>
	);
});
