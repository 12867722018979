import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Redirect,
	Route,
	Switch,
	useLocation,
	useRouteMatch,
} from 'react-router-dom';

import { isInIFrame } from '../../../../../common-ts/src/index';
import { ITemplateSimple } from '../../../../../common-ts/src/interface/template';
import { InactiveScopeWarning } from '../KYCLayout/InactiveScopeWarning';
import { ListApplications } from './ApplicationsList';
import { ListTemplates } from './KYCTemplateList';
import { Login } from './Login';
import { ResetPassword } from './ResetPassword';
import { SetPassword } from './SetPassword';
import { SignUp } from './SignUp';

declare const ASSETS_BASE_URL: string;

const useStyles = makeStyles({
	bottomSpace: {
		marginBottom: '5em',
	},
	logoGrid: {
		minHeight: '150px',
		width: '100%',
	},
	root: {
		'& .bottomNavigation': {
			borderTop: '1px solid #EDEDF4',
			marginLeft: '-3.5em',
			marginRight: '-3.5em',
			marginTop: '2.5em',
		},
		'@media (max-width: 600px)': {
			maxWidth: '500px',
			paddingLeft: '2em',
			paddingRight: '2em',
			paddingTop: '2em',
			width: '100%',
		},
		boxShadow: '0px 20px 20px -18px #C0C5DA',
		boxSizing: 'border-box',
		overflow: 'hidden',
		paddingBottom: 0,
		paddingLeft: '3.5em',
		paddingRight: '3.5em',
		paddingTop: '2.5em',
		width: '520px',
	},
});

const iframe = isInIFrame();

export const Logo = (props: { logoUrl?: string }) => (
	<div className="logo-auth">
		<img src={props.logoUrl} alt="logo" />
	</div>
);

export const PoweredBy: React.FC<{}> = () => (
	<div className="powered-by">
		<span>Powered by</span>
		<img src={`https://cdn.kyc-chain.com/common/selfkey-logo.png`} alt="logo" />
	</div>
);

export const AuthLayout = (props: {
	children: React.ReactNode;
	logoUrl?: string;
	hidePoweredBySelfkey: boolean;
}) => {
	const classes = useStyles();
	const hidePoweredBySelfkey = iframe || props.hidePoweredBySelfkey;
	const logoUrl =
		props.logoUrl ?? `${ASSETS_BASE_URL}/images/logo-vertical.png`;
	return (
		<>
			<Grid container justify="center" direction="column" alignItems="center">
				{!iframe && (
					<Grid className={classes.logoGrid} item xs={12}>
						<Logo logoUrl={logoUrl} />
					</Grid>
				)}
				<Grid item xs={12} className={!iframe ? classes.bottomSpace : null}>
					<Paper className={classes.root}>{props.children}</Paper>
				</Grid>
			</Grid>
			{!hidePoweredBySelfkey && <PoweredBy />}
		</>
	);
};

export const TemplateIdContext = React.createContext([]);

const TemplateIdProvider: React.FC<{}> = (props) => {
	const [state, dispatch] = React.useState(null);
	return (
		<TemplateIdContext.Provider value={[state, dispatch]}>
			{props.children}
		</TemplateIdContext.Provider>
	);
};

export const AuthenticationMainPage: React.FC<{
	settings: any;
	hasSignUp: boolean;
	title: string;
	templates: {
		signUpTemplates: ITemplateSimple[];
		templates: ITemplateSimple[];
	};
}> = (props) => {
	const { url } = useRouteMatch();
	const { t } = useTranslation();
	const location = useLocation();
	const { settings, hasSignUp } = props;

	const searchParams = new URLSearchParams(location.search);
	const validErrors = [
		'invalid',
		'expired',
		'invalid-user-token',
		'expired-reset',
		'new-token',
		'application-closed',
	];
	const error = validErrors.includes(searchParams.get('error'))
		? searchParams.get('error')
		: null;

	return (
		<>
			<InactiveScopeWarning contractActive={settings.contractActive} />
			<TemplateIdProvider>
				<AuthLayout
					logoUrl={settings.logoUrl}
					hidePoweredBySelfkey={settings.hidePoweredBySelfkey}
				>
					{error && (
						<Typography variant="subtitle1" color="error">
							{t(`signup.error-${error}`)}
						</Typography>
					)}
					<Switch>
						<Route path={`${url}/login`}>
							{!settings.passwordlessFlow ? (
								<Login
									loginMethod={settings.authMethod}
									loginWithEmail={settings.loginWithEmail}
									loginWithWallet={settings.loginWithWallet}
									loginRecaptcha={settings.loginRecaptcha}
									title={props.title}
								/>
							) : (
								<Redirect to={`${url}/reset`} />
							)}
						</Route>
						<Route path={`${url}/set-password`}>
							{!settings.passwordlessFlow && error !== 'expired-reset' ? (
								<SetPassword />
							) : (
								// pass password reset token expiration error to the new page
								<Redirect
									to={{
										pathname: `${url}/reset`,
										search:
											error === 'expired-reset' ? '?error=expired-reset' : '',
									}}
								/>
							)}
						</Route>
						<Route path={`${url}/reset`}>
							<ResetPassword
								hasSignUp={hasSignUp}
								passwordlessFlow={settings.passwordlessFlow}
							/>
						</Route>
						{!settings.disableApplicationListing && (
							<Route path={`${url}/applications`}>
								<ListApplications templates={props.templates.templates} />
							</Route>
						)}
						{hasSignUp && (
							<>
								<Route exact path={`${url}/signup`}>
									<ListTemplates
										templates={props.templates.signUpTemplates}
										passwordlessFlow={settings.passwordlessFlow}
									/>
								</Route>
								<Route path={`${url}/signup/:templateId`}>
									<SignUp
										title={props.title}
										templates={props.templates.signUpTemplates}
										passwordlessFlow={settings.passwordlessFlow}
									/>
								</Route>
							</>
						)}
						<Route
							component={() => (
								<Redirect to={hasSignUp ? `${url}/signup` : `${url}/login`} />
							)}
						/>
					</Switch>
				</AuthLayout>
			</TemplateIdProvider>
		</>
	);
};
