import React from 'react';

import { IPreview } from '../../helpers/ImageLoader/ImageLoader';
import { ImagePreview } from './image-preview';
import PDFPreview from './pdf-preview';

export interface IFilePreviewProps {
	documentId?: string;
	file?: {
		contentType: string;
		_id: string;
	};
	canDownload?: boolean;
	downloadFunc?: (preview: IPreview, abort: any) => Promise<ArrayBuffer>; // function to download image from API
}

const FilePreview = ({
	file,
	downloadFunc,
	canDownload = true,
	documentId,
}: IFilePreviewProps) => {
	const type: string = file ? file.contentType : null;
	const f = file
		? {
				contentType: file.contentType,
				documentId,
				fileId: file._id,
				page: 1,
		  }
		: null;
	const previews = file ? [f] : [];

	return (
		<>
			{(() => {
				switch (type) {
					case 'application/pdf':
						return (
							<PDFPreview
								downloadFunc={downloadFunc}
								file={f}
								canDownload={canDownload}
							/>
						);
					case 'image/png':
					case 'image/jpg':
					case 'image/jpeg':
						return (
							<ImagePreview downloadFunc={downloadFunc} previews={previews} />
						);
				}
			})()}
		</>
	);
};

export default FilePreview;
