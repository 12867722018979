export enum EXTERNAL_VENDORS {
	BREXIO = 'BrexIO',
	COINFIRM = 'CoinFirm',
	COMPLY_ADVANTAGE = 'ComplyAdvantage',
	DATAZOO = 'DataZoo',
	IDSCAN = 'IDScan',
	AISCAN = 'AIScan',
	OPEN_CORPORATES = 'OpenCorporates',
	SCRAPER = 'Scraper',
}

export const KYCC_ACTIVE_VENDORS = [
	EXTERNAL_VENDORS.BREXIO,
	EXTERNAL_VENDORS.COINFIRM,
	EXTERNAL_VENDORS.COMPLY_ADVANTAGE,
	EXTERNAL_VENDORS.DATAZOO,
	EXTERNAL_VENDORS.AISCAN,
	EXTERNAL_VENDORS.SCRAPER,
];

export const KYCC_DATA_VENDORS = [
	EXTERNAL_VENDORS.BREXIO,
	EXTERNAL_VENDORS.COMPLY_ADVANTAGE,
	EXTERNAL_VENDORS.SCRAPER,
];

export const KYCC_DOC_VER_VENDORS = [
	EXTERNAL_VENDORS.IDSCAN,
	EXTERNAL_VENDORS.AISCAN,
];

export type IDocumentVerificationVendor =
	| EXTERNAL_VENDORS.IDSCAN
	| EXTERNAL_VENDORS.AISCAN;

export enum BREX_DATASET {
	SUPER = 'super',
	FULL = 'full',
	MASTER = 'master',
	MINI = 'mini',
	REFRESH = 'refresh',
}
