import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { tertiary } from '../../../../ui/src/colors';

const useStyles = makeStyles({
	description: {
		color: tertiary,
		paddingTop: '5px',
	},
});

export const TitleField = (params: {
	id: string;
	required: boolean;
	title: string;
}) => {
	const { t } = useTranslation();
	return <h1>{t(`${params.title}`)}</h1>;
};

export const CustomDescriptionField = (params: { description: string }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Typography
			gutterBottom
			className={classes.description}
			variant="subtitle1"
		>
			{t(`${params.description}`)}
		</Typography>
	);
};
