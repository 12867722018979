import { IApplication } from '../../../../../common-ts/src/interface/application';
import { apiClient } from '../../../../../common-ts/src/services/network/api';

interface IAddAttributeData {
	jsonSchema?: {
		title: string;
	};
	schemaId: string;
	// TODO: Replace any with type
	value: any;
}

export const addAttribute = (id: string, data: IAddAttributeData) => {
	// TODO: Replace any with type
	return apiClient.post<any>(`/api/v2/applications/${id}/attributes`, data);
};

export const updateAttribute = (
	applicationId: string,
	attributeId: string,
	// TODO: Replace any with type
	data: any
) => {
	return apiClient.patch<IApplication>(
		`/api/v2/applications/${applicationId}/attributes/${attributeId}`,
		data
	);
};

export const clearAttribute = (applicationId: string, attributeId: string) => {
	return apiClient.delete<IApplication>(
		`/api/v2/applications/${applicationId}/attributes/${attributeId}`
	);
};
