import Typography from '@material-ui/core/Typography';
import * as React from 'react';

import { IErrorPage } from './index';

export const ErrorMessage: React.FC<IErrorPage> = ({ message, title }) => {
	return (
		<>
			<Typography align="left" variant="h5" gutterBottom={true}>
				{title}
			</Typography>
			<Typography align="left" variant="body1" gutterBottom={true}>
				{message}
			</Typography>
		</>
	);
};
