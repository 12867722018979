import React from 'react';
import DatePicker from 'react-datepicker';
import ReactDOM from 'react-dom';

import { Input } from '@material-ui/core';

import 'react-datepicker/dist/react-datepicker.css';

// TODO: Set props types
const CustomDatePicker: React.FC<any> = (props) => {
	const CalendarContainer = ({ children }: any) =>
		children
			? ReactDOM.createPortal(
					React.cloneElement(children, {
						className: 'react-datepicker-popper',
					}),
					document.body
			  )
			: null;

	return (
		<DatePicker
			peekNextMonth
			showPopperArrow={false}
			showMonthDropdown
			showYearDropdown
			dropdownMode="select"
			withPortal
			customInput={<Input fullWidth />}
			popperContainer={CalendarContainer}
			{...props}
		/>
	);
};

export default CustomDatePicker;
