import { IApplication, IAttribute } from '../interface/application';

export const isDatazooAttribute = (schemaId: string) => {
	return schemaId.includes('https://cdn.kyc-chain.com/datazoo/');
};

export const hasDatazooAttribute = (application: IApplication) => {
	return Object.keys(
		application.attributes as { [key: string]: IAttribute }
	).some((attributeId) =>
		isDatazooAttribute(
			(application.attributes as { [key: string]: IAttribute })[attributeId]
				.schemaId
		)
	);
};
