import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';

import { IPreview } from '../../helpers/ImageLoader/ImageLoader';
import { FileStatusView } from './file-status-view';

export interface IPDFPreviewProps {
	file: any;
	downloadFunc: (preview: IPreview, abort: any) => Promise<ArrayBuffer>; // function to download image from API
	stopLoading?: boolean; // loading should be aborted
	hasLoaded?: () => void; // notify parent after loading has finished
	canDownload: boolean;
}

export interface IPDFPreviewState {
	abortLoading: (value?: unknown) => void; // way to cancel loading using $http timeout parameter
	file: string;
	loading: boolean;
	error: boolean;
}

class PDFPreview extends React.Component<IPDFPreviewProps, IPDFPreviewState> {
	constructor(props: IPDFPreviewProps) {
		super(props);
		this.state = {
			abortLoading: null,
			error: false,
			file: this.props.file.fileUrl ? this.props.file.fileUrl : null,
			loading: false,
		};
	}

	// load previews if dialog is open at mount time
	public componentDidMount() {
		this.loadFile();
	}

	// cancel loading when component is being destroyed
	public componentWillUnmount() {
		this.abortLoading();
	}

	public render() {
		const height =
			(window.innerHeight ||
				document.documentElement.clientHeight ||
				document.body.clientHeight) * 0.85;
		return (
			<>
				{this.state.error ? (
					<FileStatusView
						message={'fileStatus.genericError'}
						type={'error'}
						tryAgainFunc={this.loadFile}
					/>
				) : null}
				{this.state.loading && !this.state.error ? (
					<LinearProgress variant="query" />
				) : null}
				{this.state.file && !this.state.error ? (
					<>
						{!(window as any).safari ? (
							<Button
								target="_blank"
								href={`${this.state.file}${
									this.props.canDownload ? '' : '#toolbar=0'
								}`}
							>
								Open in a new tab
							</Button>
						) : null}
						<object
							height={height}
							width="100%"
							type="application/pdf"
							data={`${this.state.file}${
								this.props.canDownload ? '' : '#toolbar=0'
							}`}
						/>
					</>
				) : null}
			</>
		);
	}

	private abortLoading() {
		if (this.state.abortLoading) {
			this.state.abortLoading();
		}
	}

	// helper function to load preview images of kyc document template
	private loadFile = () => {
		// check if already loaded
		if (this.state.loading || !this.props.downloadFunc || this.state.file) {
			return;
		}
		this.setState({ loading: true, error: false });
		const abortPromise = new Promise((resolve) => {
			this.setState({ abortLoading: resolve });
		});
		this.props
			.downloadFunc(this.props.file, abortPromise)
			.then((ar: ArrayBuffer) => {
				if (this.props.hasLoaded) {
					this.props.hasLoaded();
				}
				const fileURL = window.URL.createObjectURL(
					new Blob([ar], { type: 'application/pdf' })
				);
				this.setState({ file: fileURL, loading: false });
			})
			.catch((_: any) => {
				this.setState({ error: true, loading: false });
			});
	};
}

export default PDFPreview;
