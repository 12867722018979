import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import * as React from 'react';

import {
	accent,
	error,
	grey,
	greyLight,
	link,
	primary,
	secondary,
	tertiary,
	white,
} from '../colors';
import '../fonts-nunito-sans.css';

const theme = createMuiTheme({
	overrides: {
		MuiBottomNavigationAction: {
			label: {
				color: tertiary,
				fontSize: '14px',
				fontWeight: 'bold',
			},
			root: {
				maxWidth: 'initial',
			},
		},
		MuiButton: {
			root: {
				'&$disabled': {
					color: white,
					opacity: 0.5,
				},
				borderRadius: '4px',
				boxSizing: 'border-box',
				color: primary,
				fontSize: '14px',
				fontWeight: 'bold',
				height: '36px',
				letterSpacing: '0.2',
				lineHeight: '16px',
				padding: '0 20px',
				textTransform: 'capitalize',
			},
			contained: {
				'&$disabled': {
					backgroundColor: accent,
					color: primary,
					opacity: 0.5,
				},
				'&:focus': {
					backgroundColor: '#0BA68B',
					boxShadow: 'none',
				},
				'&:hover': {
					backgroundColor: '#07EBC4',
					boxShadow: 'none',
				},
				backgroundColor: accent,
				boxShadow: 'none',
				color: white,
			},
			containedPrimary: {
				'&$disabled': {
					backgroundColor: greyLight,
					color: secondary,
					opacity: 0.5,
				},
				'&:focus': {
					backgroundColor: '#E3E3EC',
					boxShadow: 'none',
				},
				'&:hover': {
					backgroundColor: '#F1F1FF',
					boxShadow: 'none',
				},
				backgroundColor: greyLight,
				color: secondary,
			},
			containedSecondary: {
				'&:focus': {
					backgroundColor: '#0F83D9',
					boxShadow: 'none',
				},
				'&:hover': {
					backgroundColor: '#4CB2FF',
					boxShadow: 'none',
				},
				backgroundColor: '#2DA1F8',
				boxShadow: 'none',
				color: white,
			},
			sizeLarge: {
				fontSize: '14px',
				height: '44px',
				lineHeight: '16px',
			},
			sizeSmall: {
				fontSize: '12px',
				fontWeight: 400,
				height: '26px',
				lineHeight: '15px',
				minHeight: '26px',
				minWidth: '50px',
				padding: '0 10px',
			},
			textPrimary: {
				'&:hover': {
					backgroundColor: '#F1F1FF',
				},
				color: link,
				minHeight: '50px',
			},
			textSecondary: {
				'&:hover': {
					backgroundColor: '#F1F1FF',
				},
				color: tertiary,
				minHeight: '50px',
			},
		},
		MuiCard: {
			root: {
				boxShadow: 'none',
				height: '100%',
			},
		},
		MuiCardContent: {
			root: {
				height: '100%',
			},
		},
		MuiCardHeader: {
			subheader: {
				color: white,
				fontSize: '16px',
				fontWeight: 600,
			},
			title: {
				color: white,
				fontSize: '18px',
				fontWeight: 400,
				lineHeight: '22px',
			},
		},
		MuiCheckbox: {
			root: {
				'&$checked': {
					border: '1px solid #BABACF !important',
					borderRadius: '3px',
					boxSizing: 'border-box',
					color: `white !important`,
				},
				'&$checked:not($disabled):not($colorPrimary)': {
					backgroundColor: `#4B6584 !important`,
					border: 'none !important',
				},
				'&$disabled': {
					border: '1px solid #BABACF',
					borderRadius: '3px',
					color: 'transparent',
					opacity: '0.5',
					padding: 0,
				},
				'&$disabled:not($checked)': {
					color: 'transparent !important',
				},
				'&:hover': {
					backgroundColor: '#F4F5F7',
				},
				border: '1px solid #BABACF',
				borderRadius: '3px',
				boxSizing: 'border-box',
				color: 'transparent',
				height: '16px',
				marginRight: '10px',
				padding: 0,
				width: '16px',
			},
		},
		MuiDialog: {
			container: {
				backgroundColor: 'rgba(29, 38, 59, 0.8)',
			},
			paper: {
				'@media (max-width: 600px)': {
					margin: '20px',
					padding: '30px 20px',
					width: 'calc(100% - 40px) !important',
				},
				borderRadius: '10px',
				boxSizing: 'border-box',
				maxWidth: '520px',
				padding: '60px 70px',
				position: 'initial',
			},
		},
		MuiFormControlLabel: {
			root: {
				marginLeft: 0,
			},
		},
		MuiFormGroup: {
			root: {
				flexDirection: 'row',
			},
		},
		MuiFormHelperText: {
			root: {
				textAlign: 'right',
			},
		},
		MuiIconButton: {
			root: {
				'&$disabled': {
					opacity: '0.3',
				},
			},
		},
		MuiInput: {
			formControl: {
				'label + &': {
					marginTop: '10px',
				},
				marginTop: 0,
			},
			root: {
				'&$disabled': {
					color: '#93B0C1',
					opacity: 0.5,
				},
				'&$error': {
					backgroundColor: '#FCF8F8',
					border: `2px solid ${error}`,
					color: error,
					marginBottom: '6px',
				},
				'&$focused': {
					'&$focused:not($error):not($disabled)': {
						border: '2px solid #9FA5C0',
					},
				},
				backgroundColor: '#F8F8FC',
				border: `2px solid ${grey}`,
				borderRadius: '4px',
				boxSizing: 'border-box',
				color: primary,
				fontSize: '14px',
				fontWeight: 500,
				lineHeight: '21px',
				minHeight: '44px',
				minWidth: '166px',
				paddingLeft: '16px',
			},
			underline: {
				'&:after': {
					borderBottom: '0',
				},
				'&:before': {
					borderBottom: '0',
				},
				'&:hover:not($disabled):before': {
					borderBottom: '0 !important',
				},
				'&:hover:not($disabled):not($focused):not($error):after': {
					borderBottom: '0',
				},
				'&:hover:not($disabled):not($focused):not($error):before': {
					borderBottom: '0',
				},
			},
		},
		MuiInputBase: {
			input: {
				'&::placeholder': {
					color: tertiary,
					fontWeight: 400,
					opacity: 1,
				},
			},
			multiline: {
				overflow: 'scroll',
			},
		},
		MuiInputLabel: {
			filled: {
				marginTop: '5px',
			},
			formControl: {
				position: 'initial',
			},
		},
		MuiList: {
			padding: {
				boxSizing: 'border-box',
				paddingBottom: '4px',
				paddingLeft: '8px',
				paddingRight: '8px',
				paddingTop: '5px',
			},
			root: {
				backgroundColor: '#F8F8FC',
				color: primary,
				paddingRight: '8px!important',
			},
		},
		MuiListItem: {
			button: {
				'&:hover': {
					backgroundColor: secondary,
				},
				borderRadius: '4px',
				margin: '0',
			},
			root: {
				'&$selected': {
					'&:hover': {
						backgroundColor: grey,
					},
					backgroundColor: accent,
				},
				marginBottom: '10px',
				paddingBottom: '0',
				paddingTop: '0',
			},
		},
		MuiListItemText: {
			root: {
				color: tertiary,
				fontSize: '15px',
			},
		},
		MuiMenu: {
			paper: {
				marginLeft: '-18px',
				marginTop: '5px',
				minWidth: '200px',
			},
		},
		MuiMenuItem: {
			root: {
				'&:hover': {
					color: white,
				},
				fontSize: '14px',
				fontWeight: 500,
				margin: '10px 0',
				paddingBottom: '2px',
				paddingTop: '2px',
				transition: 'all 0.15s',
			},
		},
		MuiPaper: {
			elevation1: {
				boxShadow: '0 20px 20px -18px rgba(192, 197, 218, 0.8)',
			},
			elevation8: {
				boxShadow: '0 2px 10px 0 #C0C5DA',
			},
			root: {
				'@media (max-width: 600px)': {
					left: 0,
				},
				boxSizing: 'border-box',
				left: '31px',
				minWidth: '200px',
				// top: '535px'
			},
		},
		MuiRadio: {
			root: {
				marginRight: '10px',
				padding: 0,
			},
		},
		MuiSelect: {
			icon: {
				color: 'rgba(147, 176, 193, 0.5)',
			},
			root: {
				'& option': {
					backgroundColor: `${error} !important`,
					border: `1px solid ${grey} !important`,
					color: `#FFFFFF !important`,
				},
				borderRadius: '4px',
				lineHeight: 'initial',
				width: '100%',
			},
			select: {
				fontWeight: 'normal',
			},
			selectMenu: {
				color: primary,
				fontSize: '14px',
				fontWeigth: 600,
			},
		},
		MuiStepConnector: {
			vertical: {
				marginLeft: '12px',
				marginTop: '2px',
			},
		},
		MuiStepIcon: {
			root: {
				'&$active': {
					color: primary,
				},
				'&$completed': {
					color: primary,
				},
				'&$error': {
					color: '#F38E2F',
				},
				color: '#C0C5DA',
				height: '24px',
				width: '24px',
			},
			text: {
				fontWeight: 500,
			},
		},
		MuiStepLabel: {
			label: {
				'&$completed': {
					fontWeight: 700,
				},
			},
		},
		MuiStepper: {
			root: {
				border: 'none',
			},
		},
		MuiSvgIcon: {
			root: {
				height: '18px',
				width: '18px',
			},
		},
		MuiTab: {
			root: {
				'&$selected': {
					color: primary,
				},
				'&:hover': {
					borderBottom: `6px solid ${grey}`,
					boxSizing: 'border-box',
				},
				borderBottom: `6px solid transparent`,
				color: primary,
				minWidth: '0 !important',
				textTransform: 'initial',
			},
			wrapper: {
				width: 'initial',
			},
		},
		MuiTableCell: {
			body: {
				borderBottom: '1px solid #D2D2DA',
				boxSizing: 'border-box',
				height: '50px',
				minHeight: '50px',
			},
			footer: {
				color: error,
			},
			head: {
				backgroundColor: greyLight,
				borderBottom: 0,
			},
			root: {
				borderBottom: 0,
				overflow: 'hidden',
				padding: '7px 15px',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
		},
		MuiTableRow: {
			root: {
				backgroundColor: white,
			},
		},
		MuiTabs: {
			indicator: {
				backgroundColor: '#0FCEAD',
				height: '6px',
				marginLeft: '-1px',
			},
			root: {
				borderBottom: `1px solid ${grey}`,
			},
		},
		MuiTooltip: {
			arrow: {
				color: secondary,
			},
			tooltip: {
				backgroundColor: secondary,
				fontSize: '10px',
				lineHeight: '16px',
			},
		},
		MuiTypography: {
			body1: {
				fontSize: '16px',
				lineHeight: '24px',
			},
			body2: {
				fontSize: '14px',
				lineHeight: '20px',
			},
			caption: {
				color: white,
				fontSize: '10px',
				letterSpacing: 0,
				lineHeight: '16px',
			},
			gutterBottom: {
				marginBottom: '10px',
			},
			h1: {
				fontSize: '24px',
				fontWeight: 700,
				lineHeight: '34px',
			},
			h2: {
				fontSize: '18px',
				fontWeight: 500,
				lineHeight: '26px',
			},
			h3: {
				fontSize: '16px',
				lineHeight: '24px',
			},
			h4: {
				fontSize: '18px',
				fontWeight: 600,
				lineHeight: '26px',
			},
			h5: {
				fontSize: '16px',
				fontWeight: 600,
				lineHeight: '20px',
				zIndex: 1,
			},
			h6: {
				fontSize: '15px',
				lineHeight: '18px',
			},
			overline: {},
			root: {},
			subtitle1: {
				fontSize: '12px',
				lineHeight: '18px',
			},
			subtitle2: {
				fontSize: '15px',
				fontWeight: 400,
				lineHeight: '20px',
			},
		},
	},
	palette: {
		error: {
			main: error,
		},
		primary: {
			main: primary,
		},
		secondary: {
			light: tertiary,
			main: secondary,
		},
		text: {
			disabled: primary,
			hint: 'rgba(0, 0, 0, 0.38)',
			primary,
			secondary,
		},
	},
	typography: {
		fontSize: 16,
		fontFamily: 'Nunito Sans, sans-serif',
	},
});

export const CustomerPortalTheme: React.SFC<{}> = ({ children }) => {
	return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default CustomerPortalTheme;
