import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

import { getFileNameByContentId } from '../../../../../../common/src/react/helpers';

import { AssociatedFile } from '../../../../../../common/src/models/associated-file';

import { FileItem } from './FileItem';

import { useStyles } from './UploadPageItem.style';

export interface Props {
	children: React.ReactNode;
	description: string;
	fileNames: AssociatedFile[];
	files: any[];
	isAdditional: boolean;
	optional: boolean;
	title: string;
	valid: boolean;
}

export const UploadPageItem: React.FC<Props> = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={`${classes.tableWrap} ${classes.documentsTable}`}>
			<div className={classes.tableHeader}>
				<div>
					<Typography
						variant="body1"
						color="textPrimary"
						className={classes.semibold}
					>
						{props.title} {!props.optional ? ' *' : ''}
					</Typography>

					<Typography
						variant="subtitle1"
						className={classes.tertiaryColor}
						gutterBottom
					>
						{props.description}
					</Typography>

					{!props.valid && (
						<Typography
							variant="subtitle1"
							className={classes.errorColor}
							gutterBottom
						>
							{t('uploads.uploadAgain')}
						</Typography>
					)}

					{props.isAdditional && props.valid && (
						<Typography
							variant="subtitle1"
							className={classes.errorColor}
							gutterBottom
						>
							{t('uploads.additional')}
						</Typography>
					)}
				</div>
				<div className={classes.tableHeaderIcons}>{props.children}</div>
			</div>

			{props.files && props.files.length > 0 && (
				<div className={classes.fileArea}>
					{props.files.map((f: any, index: number) => {
						const name = f.file.fileNameToShow
							? f.file.fileNameToShow
							: getFileNameByContentId(props.fileNames, f.file.content);
						return (
							<div key={index} className={classes.fileItem}>
								<FileItem file={f.file} name={`${name}`} />
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
