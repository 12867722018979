import { configureScope, setUser } from '@sentry/react';
import { Reducer } from 'redux';

import { IUserFields } from '../../.././../common-ts/src/interface/user';
import { purgeSessionStorage } from '../../services/api/session';
import { IUserActions, UserAction } from '../actions/user';
import { persistor } from '../store';

export interface IUserState {
	user: IUserFields;
	error: any;
	loading: boolean;
}

const initialState: IUserState = {
	error: null,
	loading: false,
	user: null,
};

export const userReducer: Reducer<IUserState, IUserActions> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case UserAction.LOGIN_SUCCESS:
			setUser({ email: action.user.email, id: action.user._id });
			return { ...state, user: action.user, loading: false, error: null };

		case UserAction.FETCH_USER:
			if (action.user) {
				setUser({ email: action.user.email, id: action.user._id });
			}
			return { ...state, loading: true, error: null };

		case UserAction.LOGIN_FAILURE:
			configureScope((scope) => scope.setUser(null));
			return { ...state, user: null, loading: false, error: action.error };

		case UserAction.LOGOUT: {
			configureScope((scope) => scope.setUser(null));

			setTimeout(() =>
				purgeSessionStorage(persistor).then(() => {
					window.location.href = `/api/v2/auth/logout`;
				})
			);

			return initialState;
		}

		default:
			return state;
	}
};
