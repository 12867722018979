// import { makeStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Recaptcha from 'react-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// import { LWSButton } from '@selfkey/lws-react-client';
import { apiClient } from '../../../../../common-ts/src/services/network/api';
import { goToPrivacyPolicy } from '../../../../../common/src/react/Login';
import { LoginForm } from '../../../../../common/src/react/Login/loginForm';
// import { tertiary } from '../../../../../ui/src/colors';
import { CustomerPortalTheme } from '../../../../../ui/src/theme/kyc-default-theme';
import { WagmiContext } from '../../../App';
// import { ILoginPayload } from '../../../models';
import { userLogin } from '../../../redux/actions/user';
import { RootState } from '../../../redux/reducers';
import {
	authWallet,
	getApplicationsList,
	getRedirectToken,
	verifyRecaptcha,
} from '../../../services/api';
import { TemplateIdContext } from '../AuthLayout';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { ConnectWallet } from './ConnectWallet';
import { TitleArea } from './TitleArea';

declare const __APP_CONFIG__: any;
const config = __APP_CONFIG__;

const isChrome = /Chrome/.test(navigator.userAgent);

const useStyles = makeStyles({
	recaptchaContainer: {
		display: 'flex',
		marginTop: '1em',
		marginBottom: '1em',
	},
});

export interface Props {
	loginMethod?: string;
	loginWithEmail?: boolean;
	loginWithWallet: boolean;
	loginRecaptcha?: boolean;
	title: string;
}

export const Login = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		loginWithEmail: isEnabledLoginWithEmail,
		loginWithWallet: isEnabledLoginWithWallet,
		loginRecaptcha: isEnabledLoginRecaptcha,
	} = props;
	const userStore = useSelector((store: RootState) => store.userStore);
	const [templateId] = useContext(TemplateIdContext);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [openWallet, setOpenWallet] = useState(false);
	const [refreshedAccount, refreshAccount] = useState(false);
	const [error, setError] = useState('');
	const { config: wagmiConfig } = useContext(WagmiContext);

	const lws = {
		...config.features.loginWithSelfKey,
		enabled: config.features.loginWithSelfKey.enabled && isChrome,
		website: {
			name: props.title,
			url: window.origin,
		},
	};

	const loginSubmit = async (payload: {
		credentials: { email: string; password: string };
	}) => {
		if (isEnabledLoginRecaptcha) {
			setRecaptchaError(t('login.recaptcha-error'));
			await verifyRecaptcha(recaptcha)
				.then(() => {
					// if (recaptchaInstance) recaptchaInstance.reset();
					setRecaptchaError('');
				})
				.catch(() => {
					setRecaptchaError(t('login.recaptcha-error'));
					return;
				});
		}
		dispatch(userLogin(payload, props.loginMethod));
		setIsSubmitted(true);
	};

	const [recaptcha, setRecaptcha] = useState('');
	const [recaptchaError, setRecaptchaError] = useState('');

	const [isReadyToFill, setIsReadyToFill] = useState(
		isEnabledLoginRecaptcha ? false : true
	);
	const [, setRecaptchaInstance] = useState(null);

	// called when recaptcha component has loaded
	const recaptchaOnLoadCallback = () => {
		setIsReadyToFill(true);
	};

	const recaptchaOnVerifyCallback = (response: any) => {
		setRecaptcha(response);
		setRecaptchaError('');
	};
	const recaptchaOnExpiredCallback = (response: any) => {
		setRecaptcha('');
		setRecaptchaError(t('login.recaptcha-error'));
	};

	/*
	const lwsLogin = (err: Error, payload: ILoginPayload) => {
		if (!err) {
			dispatch(userLogin(payload));
		}
	};
	*/

	const onWalletAccount = async (account: `0x${string}`) => {
		try {
			await verifyRecaptcha(recaptcha)
				.then(() => {
					// if (recaptchaInstance) recaptchaInstance.reset();
					setRecaptchaError('');
				})
				.catch(() => {
					setRecaptchaError(t('login.recaptcha-error'));
					throw new Error();
				});
			const token = await authWallet(account, wagmiConfig);

			// need to add token in header for get all application list
			apiClient.client.defaults.headers.common.authorization =
				'Bearer ' + token;

			const redirectToken = await getRedirectToken();
			const apps = await getApplicationsList();

			if (apps?.length) {
				const applicationId = apps.sort((a, b) => {
					return (
						new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
					);
				})[apps.length - 1].id;
				window.location.href = `/api/v2/applications/${applicationId}?access_token=${redirectToken.jwt}`;
			} else {
				setError('unauthorized');
				refreshAccount(!refreshedAccount);
				setOpenWallet(false);
			}
		} catch (err) {
			setError('unauthorized');
			refreshAccount(!refreshedAccount);
			setOpenWallet(false);
		}
	};

	return (
		<CustomerPortalTheme>
			<LanguageSwitcher />
			{(isEnabledLoginWithEmail || isEnabledLoginWithWallet || lws.enabled) && (
				<TitleArea
					title={t('login.title')}
					description={t('login.description', { instance: props.title })}
				/>
			)}
			{!isEnabledLoginWithEmail && !isEnabledLoginWithWallet && !lws.enabled && (
				<TitleArea
					title={t('login.closed')}
					description={t('login.closed-description', {
						instance: props.title,
					})}
				/>
			)}
			{!!userStore.error && isSubmitted ? (
				<Typography variant="subtitle1" color="error">
					{t('login.error')}
				</Typography>
			) : null}
			{error && (
				<Typography variant="subtitle1" color="error">
					{t(`login.error-${error}`)}
				</Typography>
			)}
			{recaptchaError && (
				<Typography variant="subtitle1" color="error">
					{recaptchaError}
				</Typography>
			)}

			{isEnabledLoginWithEmail && (
				<LoginForm
					submitFunc={loginSubmit}
					loading={userStore.loading}
					disabled={!isReadyToFill || (!recaptcha && isEnabledLoginRecaptcha)}
				/>
			)}
			{/* FIXME - check the number of initalizations when navigate from emails */}
			{/* {lws.enabled && !openWallet && (
				<>
					{isEnabledLoginWithEmail && (
						<Typography variant="subtitle2" className={classes.dividerText}>
							or
						</Typography>
					)}

					<LWSButton
						className="kyc-lws-button"
						rootEndpoint={lws.rootEndpoint}
						endpoints={lws.endpoints}
						website={lws.website}
						did={true}
						onAuthResponse={lwsLogin}
					/>
				</>
			)} */}
			{isEnabledLoginWithWallet && (
				<>
					<ConnectWallet
						onAccount={onWalletAccount}
						setOpenWallet={setOpenWallet}
						refreshedAccount={refreshedAccount}
						disabled={!isReadyToFill || (isEnabledLoginRecaptcha && !recaptcha)}
					/>
				</>
			)}

			{isEnabledLoginRecaptcha && (
				<div className={classes.recaptchaContainer}>
					<Recaptcha
						render="explicit"
						ref={(e: any) => setRecaptchaInstance(e)}
						sitekey={config.app.recaptcha}
						onloadCallback={recaptchaOnLoadCallback}
						verifyCallback={recaptchaOnVerifyCallback}
						expiredCallback={recaptchaOnExpiredCallback}
					/>
				</div>
			)}

			<Typography variant="subtitle1" gutterBottom style={{ marginTop: '1em' }}>
				{t('login.privacy-notice')}
				<Link
					color="inherit"
					underline="hover"
					style={{ cursor: 'pointer', marginLeft: '4px' }}
					onClick={goToPrivacyPolicy}
				>
					{t('login.privacy-notice-link')}
				</Link>
				.
			</Typography>

			<BottomNavigation className="bottomNavigation">
				{templateId && (
					<>
						<BottomNavigationAction
							showLabel={true}
							component={Link}
							to={`/auth/signup/${templateId}`}
							label={t('login.no-account')}
						/>
						<Divider orientation="vertical" />
					</>
				)}

				{isEnabledLoginWithEmail && (
					<BottomNavigationAction
						showLabel={true}
						component={Link}
						to="/auth/reset"
						label={t('login.reset-password')}
					/>
				)}
			</BottomNavigation>
		</CustomerPortalTheme>
	);
};
