import { Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { withTheme } from '@rjsf/core';
import validateFormData from '@rjsf/core/lib/validate';
import { Theme as MuiTheme } from '@rjsf/material-ui';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';
import { isArray, isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTimeoutFn } from 'react-use';

import { KnownAttribute } from '../../../../../common-ts/src/customSchemas';
import { IApplication } from '../../../../../common-ts/src/interface/application';
import { getAttribute } from '../../../../../common/src/helpers/attributes';
import { CustomAutocompleteWidget } from '../../../../../common/src/react/CustomFields/CustomAutocompleteWidget';
import { CustomCheckboxesWidget } from '../../../../../common/src/react/CustomFields/CustomCheckboxesWidget';
import { CustomDateTemplate } from '../../../../../common/src/react/CustomFields/CustomDateTemplate';
import { transformErrors } from '../../../../../common/src/react/CustomFields/CustomErrors';
import { FieldTemplate } from '../../../../../common/src/react/CustomFields/CustomFieldTemplate';
import { DefaultInputTemplate } from '../../../../../common/src/react/CustomFields/CustomInputTemplate';
import { ObjectFieldTemplate } from '../../../../../common/src/react/CustomFields/CustomObjectFieldTemplate';
import {
	CustomDescriptionField,
	TitleField,
} from '../../../../../common/src/react/CustomFields/CustomRjsfTitle';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import {
	loadCurrentApplication,
	setCurrentApplicationData,
} from '../../../redux/actions/application';
import { RootState } from '../../../redux/reducers';
import { updateApplication } from '../../../services/api';
import { isStatusError } from '../../Helpers';
import { SectionNavigation } from '../KYCLayout/Section';
import { Props as IRequirementInputProps } from './Uploads';

const useStyles = makeStyles({
	defaultTopSpace: {
		marginTop: 0,
	},
	title: {
		marginBottom: '17px',
	},
});

const Form = withTheme(MuiTheme);

const widgets = {
	CheckboxesWidget: CustomCheckboxesWidget,
	DateWidget: CustomDateTemplate,
	EmailWidget: DefaultInputTemplate,
	SelectWidget: CustomAutocompleteWidget,
	TextWidget: DefaultInputTemplate,
	UpDownWidget: CustomAutocompleteWidget,
};

const uiSchema: { [key: string]: any } = {};

const fields = {
	DescriptionField: CustomDescriptionField,
	TitleField,
} as any;

export const RequirementInputs: React.FC<IRequirementInputProps> = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [values, setValues] = useState({});
	const dispatch = useDispatch();
	const application = useSelector((root: RootState) =>
		props.isMemberApplication
			? props.application
			: root.applicationStore.currentApplication
	);
	const dispatchApplicationUpdate = props.isMemberApplication
		? props.onApplicationUpdate
		: (application: IApplication) => {
				dispatch(setCurrentApplicationData(application));
		  };

	const [loading, setLoading] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [error, setError] = useState(null);

	const handleAutoSave = () => {
		cancel();
		const errorSchema = validateFormData(
			values,
			props.requirements
		).errorSchema;

		saveApplication(true, Object.keys(errorSchema));
	};

	const [_, cancel, reset] = useTimeoutFn(handleAutoSave, 3000);

	useEffect(() => {
		// this prevents the values from refreshing as the applicant is filling
		// out the form.
		// We only refresh if it's the first load, or if an attribute has been
		// added dynamically in the middle of filling them out
		if (
			!isEmpty(values) &&
			Object.keys(values).length ===
				Object.keys(props.requirements.properties).length
		) {
			return;
		}

		setInitialLoad(true);
		const initialValues: any = {};
		const keys = Object.keys(props.requirements.properties);
		for (const key of keys) {
			const p = props.requirements.properties[key];
			const value = application.attributes[key]
				? application.attributes[key].value
				: p.value;

			// add help texts for invalid
			const helpText: string[] = !p.valid
				? [t('questions.answerAgain')]
				: p.isAdditional && (!value || value.length <= 0)
				? [t('questions.additional')]
				: [];

			// if there is only one property hide the title
			// https://github.com/kyc-chain/kyc-chain-monorepo/issues/1385
			const propertyKeys = p.properties ? Object.keys(p.properties) : [];
			if (propertyKeys.length === 1) {
				const modify: any = {
					'ui:title': '',
				};
				uiSchema[key] = { ...uiSchema[key], ...modify };
				// added only when there are existing helper text is indicated in different color (error)
				// we want to display description in "gray"
				if (helpText.length > 0) {
					helpText.push(p.description);
				}
			}

			// Hide also title for national-id-number attribute for better UI
			if (p.schemaId === KnownAttribute.NationalIdNumber) {
				const modify: any = {
					'ui:title': '',
				};
				uiSchema[key] = { ...uiSchema[key], ...modify };
			}

			// process initial values
			if (value) {
				if (p.schemaId) {
					initialValues[key] = value;
				} else {
					// NOTE these if statements contains some backwards functionality
					// previously question answers were saved as array e.g. ["hello"]
					// in a new format they are saved as "mixed"
					if (p.type === 'array') {
						initialValues[key] = value;
					} else {
						// isArray check is for backwards compatibility
						if (isArray(value)) {
							initialValues[key] = value[0];
						} else {
							initialValues[key] = value;
						}
					}
				}
			} else {
				initialValues[key] = p.properties ? {} : undefined;
			}

			if (p.type === 'array' && p.uniqueItems) {
				if (
					p.schemaId === KnownAttribute.BusinessTypes ||
					(p.items?.uiSchema &&
						p.items?.uiSchema['ui:widget'] === 'multiselect')
				) {
					// business types should use autocomplete
					uiSchema[key] = {
						'ui:widget': CustomAutocompleteWidget,
					};
				} else {
					// other array attributes use checkboxes
					uiSchema[key] = {
						'ui:widget': 'checkboxes',
					};
				}
			}

			if (helpText.length > 0) {
				uiSchema[key] = { ...uiSchema[key], 'ui:help': helpText.join(' - ') };
			}

			// generate random ID to be used in some field templates
			const randomId = Math.random().toString(16).substring(2, 8);
			if (uiSchema[key]) {
				uiSchema[key].uiId = randomId;
			} else {
				uiSchema[key] = { uiId: randomId };
			}
		}
		// sort attributes
		const order = Object.keys(props.requirements.properties).sort(
			(a, b) =>
				props.requirements.properties[a].weight -
				props.requirements.properties[b].weight
		);
		if (order.length > 0) {
			order.push('*'); // everything else
			uiSchema['ui:order'] = order;
		}
		setValues(initialValues);
		setInitialLoad(false);
	}, [props.requirements]);

	useEffect(() => {
		reset();
	}, [values]);

	// Trim white space from wallet addresses
	const trimInputValues = (formData: any) => {
		if (props.requirements?.properties) {
			Object.entries(props.requirements.properties).forEach(
				([key, property]) => {
					if (property && property.type === 'string' && formData[key]) {
						formData[key] = formData[key].trim();
					}
				}
			);
		}
		return formData;
	};

	const handleChange = (formData: any) => {
		setValues(trimInputValues(formData));
	};

	const refreshOnStatusError = (err: AxiosError) => {
		// if the API throws a status error, then re-load the application
		if (isStatusError(err)) {
			dispatch(loadCurrentApplication());
			return;
		}
	};

	const saveApplication = async (
		autoSave: boolean,
		excludeFromUpdateIds: string[] = []
	) => {
		if (loading) {
			return;
		}

		cancel();

		if (!autoSave) {
			setLoading(true);
		}

		let selectedNationality: string = null;

		// format data for update
		const attributes: any[] = Object.keys(props.requirements.properties)
			.filter((key: string) => {
				const property = props.requirements.properties[key];
				// check if application value has been changed
				const data = (values as any)[key] ? (values as any)[key] : null;
				return (
					property.schemaId &&
					!isEqual(
						// convert undefined to null just in case
						property.value ? property.value : null,
						(property.type === 'string' && typeof data === 'undefined') ||
							(property.type === 'object' && isEmpty(data))
							? null
							: data
					)
				);
			})
			.filter((key) => !excludeFromUpdateIds.includes(key))
			.map((key: string) => {
				const data = (values as any)[key];
				const property = props.requirements.properties[key];
				// if an optional attribute is empty or has all empty properties then
				// we send null to the API
				const dataIsEmpty =
					isEmpty(data) ||
					(typeof data === 'object' &&
						Object.keys(data).every((k: string) => data[k] === undefined));
				return {
					data: data
						? dataIsEmpty
							? null
							: data
						: data === undefined
						? null
						: '',
					id: key,
					schemaId: property.schemaId,
				};
			});

		// format questions for saving
		const questions: any[] = Object.keys(props.requirements.properties)
			.filter((key: string) => {
				const property = props.requirements.properties[key];
				const data = (values as any)[key] ? (values as any)[key] : null;
				let originalComparisonValue = property.value ? property.value : null;
				if (
					isArray(property.value) &&
					(property.value.length === 0 || !property.value[0])
				) {
					originalComparisonValue = null;
				}
				// check if application value has been changed
				return (
					!property.schemaId &&
					!isEqual(
						// convert undefined to null just in case
						originalComparisonValue,
						(property.type === 'string' && typeof data === 'undefined') ||
							(property.type === 'object' && isEmpty(data))
							? null
							: data
					)
				);
			})
			.filter((key) => !excludeFromUpdateIds.includes(key))
			.map((key: string) => {
				const value = (values as any)[key] ? (values as any)[key] : '';
				return {
					data: value ? value : '',
					id: key,
				};
			});

		// updated only if something changed
		if (attributes.length > 0 || questions.length > 0) {
			try {
				const updatedApplication = await updateApplication(application.id, {
					attributes,
					questions,
				});
				const nationality = getAttribute(
					updatedApplication.attributes,
					'http://platform.selfkey.org/schema/attribute/nationality.json',
					null
				);
				selectedNationality = nationality?.value?.country;
				dispatchApplicationUpdate(updatedApplication);
			} catch (err) {
				refreshOnStatusError(err);
				const ex = err?.response
					? new Error('updatedApplication questions Error')
					: err;
				captureException(ex, {
					contexts: {
						application: {
							id: application.id,
						},
						error: err?.response ?? err,
						function: {
							name: 'updateApplication',
						},
					},
					tags: {
						section: 'questions',
					},
				});
				if (err.response.status === 422) {
					setError('errors.save-422');
				} else if (err.response.status === 500) {
					setError('errors.save-500');
				} else {
					setError('errors.save-other');
				}
				setLoading(false);
				return;
			}
		}

		if (!autoSave) {
			setLoading(false);
			props.nextCallback(selectedNationality);
		}
	};

	return (
		<CustomerPortalTheme>
			<Typography align="left" variant="h1" className={classes.title}>
				{t(props.title ? props.title : 'sections.questions')}
			</Typography>
			{error ? (
				<Typography gutterBottom={true} variant="subtitle1" color="error">
					{t(error)}
				</Typography>
			) : null}
			{!initialLoad && (
				<Box style={{ margin: 'auto', maxWidth: '80%' }}>
					<Form
						className={'attributes-form'}
						FieldTemplate={FieldTemplate}
						ObjectFieldTemplate={ObjectFieldTemplate}
						schema={props.requirements}
						uiSchema={uiSchema}
						onSubmit={() => {
							saveApplication(false);
						}}
						fields={fields}
						onChange={({ formData }) => {
							handleChange(formData);
						}}
						formData={values}
						widgets={widgets}
						showErrorList={false}
						transformErrors={transformErrors}
						noHtml5Validate={true}
					>
						<SectionNavigation
							nextButtonProps={{ disabled: loading, type: 'submit' }}
							loading={loading}
							prevCallback={props.prevCallback}
							hasPrevSection={props.hasPrevSection()}
							nextButtonText={props.isLastStep ? 'sections.submit' : null}
							position="center"
						/>
					</Form>
				</Box>
			)}
			{initialLoad && <LinearProgress />}
		</CustomerPortalTheme>
	);
};
