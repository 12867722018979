import { AxiosResponse } from 'axios';

export const getNameFromDisposition = (response: AxiosResponse) => {
	let filename = '';
	const disposition = response.headers['content-disposition'];
	if (disposition) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		const matches = filenameRegex.exec(disposition);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}
	return filename;
};

export const triggerFileDownload = (
	byteArray: any,
	contentType: string,
	fileName: string
) => {
	const file = new Blob([byteArray], { type: contentType });
	const fileURL = URL.createObjectURL(file);
	const downloadLink = document.createElement('a');
	downloadLink.setAttribute('href', fileURL);
	downloadLink.setAttribute('download', fileName);
	document.body.append(downloadLink);
	downloadLink.click();

	setTimeout(() => {
		downloadLink.remove();
	}, 300);

	return fileURL;
};
