// tslint:disable:object-literal-sort-keys

export interface IJurisdiction {
	jn: string; // country name
	jc: string; // country code
	alpha3: string; // alpha3 code
}

export const jurisdictions = [
	{
		jn: 'Afghanistan',
		jc: 'AF',
		alpha3: 'AFG',
	},
	{
		jn: 'Albania',
		jc: 'AL',
		alpha3: 'ALB',
	},
	{
		jn: 'Åland Islands',
		jc: 'AX',
		alpha3: 'ALA',
	},
	{
		jn: 'Algeria',
		jc: 'DZ',
		alpha3: 'DZA',
	},
	{
		jn: 'American Samoa',
		jc: 'AS',
		alpha3: 'ASM',
	},
	{
		jn: 'Andorra',
		jc: 'AD',
		alpha3: 'AND',
	},
	{
		jn: 'Angola',
		jc: 'AO',
		alpha3: 'AGO',
	},
	{
		jn: 'Anguilla',
		jc: 'AI',
		alpha3: 'AIA',
	},
	{
		jn: 'Antarctica',
		jc: 'AQ',
		alpha3: 'ATA',
	},
	{
		jn: 'Antigua and Barbuda',
		jc: 'AG',
		alpha3: 'ATG',
	},
	{
		jn: 'Argentina',
		jc: 'AR',
		alpha3: 'ARG',
	},
	{
		jn: 'Armenia',
		jc: 'AM',
		alpha3: 'ARM',
	},
	{
		jn: 'Aruba',
		jc: 'AW',
		alpha3: 'ABW',
	},
	{
		jn: 'Australia',
		jc: 'AU',
		alpha3: 'AUS',
	},
	{
		jn: 'Austria',
		jc: 'AT',
		alpha3: 'AUT',
	},
	{
		jn: 'Azerbaijan',
		jc: 'AZ',
		alpha3: 'AZE',
	},
	{
		jn: 'Bahamas',
		jc: 'BS',
		alpha3: 'BHS',
	},
	{
		jn: 'Bahrain',
		jc: 'BH',
		alpha3: 'BHR',
	},
	{
		jn: 'Bangladesh',
		jc: 'BD',
		alpha3: 'BGD',
	},
	{
		jn: 'Barbados',
		jc: 'BB',
		alpha3: 'BRB',
	},
	{
		jn: 'Belarus',
		jc: 'BY',
		alpha3: 'BLR',
	},
	{
		jn: 'Belgium',
		jc: 'BE',
		alpha3: 'BEL',
	},
	{
		jn: 'Belize',
		jc: 'BZ',
		alpha3: 'BLZ',
	},
	{
		jn: 'Benin',
		jc: 'BJ',
		alpha3: 'BEN',
	},
	{
		jn: 'Bermuda',
		jc: 'BM',
		alpha3: 'BMU',
	},
	{
		jn: 'Bhutan',
		jc: 'BT',
		alpha3: 'BTN',
	},
	{
		jn: 'Bolivia',
		jc: 'BO',
		alpha3: 'BOL',
	},
	{
		jn: 'Bonaire, Saint Eustatius and Saba',
		jc: 'BQ',
		alpha3: 'BES',
	},
	{
		jn: 'Bosnia-Herzegovina',
		jc: 'BA',
		alpha3: 'BIH',
	},
	{
		jn: 'Botswana',
		jc: 'BW',
		alpha3: 'BWA',
	},
	{
		jn: 'Bouvet Island',
		jc: 'BV',
		alpha3: 'BVT',
	},
	{
		jn: 'Brazil',
		jc: 'BR',
		alpha3: 'BRA',
	},
	{
		jn: 'British Indian Ocean Territory',
		jc: 'IO',
		alpha3: 'IOT',
	},
	{
		jn: 'Brunei Darussalam',
		jc: 'BN',
		alpha3: 'BRN',
	},
	{
		jn: 'Bulgaria',
		jc: 'BG',
		alpha3: 'BGR',
	},
	{
		jn: 'Burkina Faso',
		jc: 'BF',
		alpha3: 'BFA',
	},
	{
		jn: 'Burundi',
		jc: 'BI',
		alpha3: 'BDI',
	},
	{
		jn: 'Cambodia',
		jc: 'KH',
		alpha3: 'KHM',
	},
	{
		jn: 'Cameroon',
		jc: 'CM',
		alpha3: 'CMR',
	},
	{
		jn: 'Canada',
		jc: 'CA',
		alpha3: 'CAN',
	},
	{
		jn: 'Cape Verde',
		jc: 'CV',
		alpha3: 'CPV',
	},
	{
		jn: 'Cayman Islands',
		jc: 'KY',
		alpha3: 'CYM',
	},
	{
		jn: 'Central African Rep',
		jc: 'CF',
		alpha3: 'CAF',
	},
	{
		jn: 'Chad',
		jc: 'TD',
		alpha3: 'TCD',
	},
	{
		jn: 'Chile',
		jc: 'CL',
		alpha3: 'CHL',
	},
	{
		jn: 'China',
		jc: 'CN',
		alpha3: 'CHN',
	},
	{
		jn: 'Christmas Island',
		jc: 'CX',
		alpha3: 'CXR',
	},
	{
		jn: 'Cocos (Keeling) Islands',
		jc: 'CC',
		alpha3: 'CCK',
	},
	{
		jn: 'Colombia',
		jc: 'CO',
		alpha3: 'COL',
	},
	{
		jn: 'Comoros',
		jc: 'KM',
		alpha3: 'COM',
	},
	{
		jn: 'Congo (Brazzaville)',
		jc: 'CG',
		alpha3: 'COG',
	},
	{
		jn: 'Congo, the Democratic Republic',
		jc: 'CD',
		alpha3: 'COD',
	},
	{
		jn: 'Cook Islands',
		jc: 'CK',
		alpha3: 'COK',
	},
	{
		jn: 'Costa Rica',
		jc: 'CR',
		alpha3: 'CRI',
	},
	{
		jn: 'Curaçao',
		jc: 'CW',
		alpha3: 'CUW',
	},
	{
		jn: 'Ivory Coast',
		jc: 'CI',
		alpha3: 'CIV',
	},
	{
		jn: 'Croatia',
		jc: 'HR',
		alpha3: 'HRV',
	},
	{
		jn: 'Cuba',
		jc: 'CU',
		alpha3: 'CUB',
	},
	{
		jn: 'Cyprus',
		jc: 'CY',
		alpha3: 'CYP',
	},
	{
		jn: 'Czech Republic',
		jc: 'CZ',
		alpha3: 'CZE',
	},
	{
		jn: 'Denmark',
		jc: 'DK',
		alpha3: 'DNK',
	},
	{
		jn: 'Djibouti',
		jc: 'DJ',
		alpha3: 'DJI',
	},
	{
		jn: 'Dominica',
		jc: 'DM',
		alpha3: 'DMA',
	},
	{
		jn: 'Dominican Republic',
		jc: 'DO',
		alpha3: 'DOM',
	},
	{
		jn: 'Ecuador',
		jc: 'EC',
		alpha3: 'ECU',
	},
	{
		jn: 'Egypt',
		jc: 'EG',
		alpha3: 'EGY',
	},
	{
		jn: 'El Salvador',
		jc: 'SV',
		alpha3: 'SLV',
	},
	{
		jn: 'Equatorial Guinea',
		jc: 'GQ',
		alpha3: 'GNQ',
	},
	{
		jn: 'Eritrea',
		jc: 'ER',
		alpha3: 'ERI',
	},
	{
		jn: 'Estonia',
		jc: 'EE',
		alpha3: 'EST',
	},
	{
		jn: 'Ethiopia',
		jc: 'ET',
		alpha3: 'ETH',
	},
	{
		jn: 'Falkland Islands (Malvinas)',
		jc: 'FK',
		alpha3: 'FLK',
	},
	{
		jn: 'Faroe Islands',
		jc: 'FO',
		alpha3: 'FRO',
	},
	{
		jn: 'Fiji',
		jc: 'FJ',
		alpha3: 'FJI',
	},
	{
		jn: 'Finland',
		jc: 'FI',
		alpha3: 'FIN',
	},
	{
		jn: 'France',
		jc: 'FR',
		alpha3: 'FRA',
	},
	{
		jn: 'French Guiana',
		jc: 'GF',
		alpha3: 'GUF',
	},
	{
		jn: 'French Polynesia',
		jc: 'PF',
		alpha3: 'PYF',
	},
	{
		jn: 'French Southern Territories',
		jc: 'TF',
		alpha3: 'ATF',
	},
	{
		jn: 'Gabon',
		jc: 'GA',
		alpha3: 'GAB',
	},
	{
		jn: 'Gambia',
		jc: 'GM',
		alpha3: 'GMB',
	},
	{
		jn: 'Georgia',
		jc: 'GE',
		alpha3: 'GEO',
	},
	{
		jn: 'Germany',
		jc: 'DE',
		alpha3: 'DEU',
	},
	{
		jn: 'Ghana',
		jc: 'GH',
		alpha3: 'GHA',
	},
	{
		jn: 'Gibraltar',
		jc: 'GI',
		alpha3: 'GIB',
	},
	{
		jn: 'Greece',
		jc: 'GR',
		alpha3: 'GRC',
	},
	{
		jn: 'Greenland',
		jc: 'GL',
		alpha3: 'GRL',
	},
	{
		jn: 'Grenada',
		jc: 'GD',
		alpha3: 'GRD',
	},
	{
		jn: 'Guadeloupe',
		jc: 'GP',
		alpha3: 'GLP',
	},
	{
		jn: 'Guam',
		jc: 'GU',
		alpha3: 'GUM',
	},
	{
		jn: 'Guatemala',
		jc: 'GT',
		alpha3: 'GTM',
	},
	{
		jn: 'Guernsey',
		jc: 'GG',
		alpha3: 'GGY',
	},
	{
		jn: 'Guinea',
		jc: 'GN',
		alpha3: 'GIN',
	},
	{
		jn: 'Guinea Bissau',
		jc: 'GW',
		alpha3: 'GNB',
	},
	{
		jn: 'Guyana',
		jc: 'GY',
		alpha3: 'GUY',
	},
	{
		jn: 'Haiti',
		jc: 'HT',
		alpha3: 'HTI',
	},
	{
		jn: 'Heard Island and McDonald Islands',
		jc: 'HM',
		alpha3: 'HMD',
	},
	{
		jn: 'Vatican City State (Holy See)',
		jc: 'VA',
		alpha3: 'VAT',
	},
	{
		jn: 'Honduras',
		jc: 'HN',
		alpha3: 'HND',
	},
	{
		jn: 'Hong Kong',
		jc: 'HK',
		alpha3: 'HKG',
	},
	{
		jn: 'Hungary',
		jc: 'HU',
		alpha3: 'HUN',
	},
	{
		jn: 'Iceland',
		jc: 'IS',
		alpha3: 'ISL',
	},
	{
		jn: 'India',
		jc: 'IN',
		alpha3: 'IND',
	},
	{
		jn: 'Indonesia',
		jc: 'ID',
		alpha3: 'IDN',
	},
	{
		jn: 'Iran, Islamic Republic of',
		jc: 'IR',
		alpha3: 'IRN',
	},
	{
		jn: 'Iraq',
		jc: 'IQ',
		alpha3: 'IRQ',
	},
	{
		jn: 'Ireland',
		jc: 'IE',
		alpha3: 'IRL',
	},
	{
		jn: 'Isle of Man',
		jc: 'IM',
		alpha3: 'IMN',
	},
	{
		jn: 'Israel',
		jc: 'IL',
		alpha3: 'ISR',
	},
	{
		jn: 'Italy',
		jc: 'IT',
		alpha3: 'ITA',
	},
	{
		jn: 'Jamaica',
		jc: 'JM',
		alpha3: 'JAM',
	},
	{
		jn: 'Japan',
		jc: 'JP',
		alpha3: 'JPN',
	},
	{
		jn: 'Jersey',
		jc: 'JE',
		alpha3: 'JEY',
	},
	{
		jn: 'Jordan',
		jc: 'JO',
		alpha3: 'JOR',
	},
	{
		jn: 'Kazakhstan',
		jc: 'KZ',
		alpha3: 'KAZ',
	},
	{
		jn: 'Kenya',
		jc: 'KE',
		alpha3: 'KEN',
	},
	{
		jn: 'Kiribati',
		jc: 'KI',
		alpha3: 'KIR',
	},
	{
		jn: 'Kosovo',
		jc: 'XK',
		alpha3: 'XXK',
	},
	{
		jn: 'North Korea',
		jc: 'KP',
		alpha3: 'PRK',
	},
	{
		jn: 'South Korea',
		jc: 'KR',
		alpha3: 'KOR',
	},
	{
		jn: 'South Sudan',
		jc: 'SS',
		alpha3: 'SSD',
	},
	{
		jn: 'Kuwait',
		jc: 'KW',
		alpha3: 'KWT',
	},
	{
		jn: 'Kyrgyzstan',
		jc: 'KG',
		alpha3: 'KGZ',
	},
	{
		jn: "Lao People's Democratic Republic",
		jc: 'LA',
		alpha3: 'LAO',
	},
	{
		jn: 'Latvia',
		jc: 'LV',
		alpha3: 'LVA',
	},
	{
		jn: 'Lebanon',
		jc: 'LB',
		alpha3: 'LBN',
	},
	{
		jn: 'Lesotho',
		jc: 'LS',
		alpha3: 'LSO',
	},
	{
		jn: 'Liberia',
		jc: 'LR',
		alpha3: 'LBR',
	},
	{
		jn: 'Libya',
		jc: 'LY',
		alpha3: 'LBY',
	},
	{
		jn: 'Liechtenstein',
		jc: 'LI',
		alpha3: 'LIE',
	},
	{
		jn: 'Lithuania',
		jc: 'LT',
		alpha3: 'LTU',
	},
	{
		jn: 'Luxembourg',
		jc: 'LU',
		alpha3: 'LUX',
	},
	{
		jn: 'Macau',
		jc: 'MO',
		alpha3: 'MAC',
	},
	{
		jn: 'Macedonia',
		jc: 'MK',
		alpha3: 'MKD',
	},
	{
		jn: 'Madagascar',
		jc: 'MG',
		alpha3: 'MDG',
	},
	{
		jn: 'Malawi',
		jc: 'MW',
		alpha3: 'MWI',
	},
	{
		jn: 'Malaysia',
		jc: 'MY',
		alpha3: 'MYS',
	},
	{
		jn: 'Maldives',
		jc: 'MV',
		alpha3: 'MDV',
	},
	{
		jn: 'Mali',
		jc: 'ML',
		alpha3: 'MLI',
	},
	{
		jn: 'Malta',
		jc: 'MT',
		alpha3: 'MLT',
	},
	{
		jn: 'Marshall Islands',
		jc: 'MH',
		alpha3: 'MHL',
	},
	{
		jn: 'Martinique',
		jc: 'MQ',
		alpha3: 'MTQ',
	},
	{
		jn: 'Mauritania',
		jc: 'MR',
		alpha3: 'MRT',
	},
	{
		jn: 'Mauritius',
		jc: 'MU',
		alpha3: 'MUS',
	},
	{
		jn: 'Mayotte',
		jc: 'YT',
		alpha3: 'MYT',
	},
	{
		jn: 'Mexico',
		jc: 'MX',
		alpha3: 'MEX',
	},
	{
		jn: 'Micronesia',
		jc: 'FM',
		alpha3: 'FSM',
	},
	{
		jn: 'Moldova',
		jc: 'MD',
		alpha3: 'MDA',
	},
	{
		jn: 'Monaco',
		jc: 'MC',
		alpha3: 'MCO',
	},
	{
		jn: 'Mongolia',
		jc: 'MN',
		alpha3: 'MNG',
	},
	{
		jn: 'Montenegro',
		jc: 'ME',
		alpha3: 'MNE',
	},
	{
		jn: 'Montserrat',
		jc: 'MS',
		alpha3: 'MSR',
	},
	{
		jn: 'Morocco',
		jc: 'MA',
		alpha3: 'MAR',
	},
	{
		jn: 'Mozambique',
		jc: 'MZ',
		alpha3: 'MOZ',
	},
	{
		jn: 'Myanmar',
		jc: 'MM',
		alpha3: 'MMR',
	},
	{
		jn: 'Namibia',
		jc: 'NA',
		alpha3: 'NAM',
	},
	{
		jn: 'Nauru',
		jc: 'NR',
		alpha3: 'NRU',
	},
	{
		jn: 'Nepal',
		jc: 'NP',
		alpha3: 'NPL',
	},
	{
		jn: 'Netherlands',
		jc: 'NL',
		alpha3: 'NLD',
	},
	{
		jn: 'Netherlands Antilles',
		jc: 'AN',
		alpha3: 'ANT',
	},
	{
		jn: 'New Caledonia',
		jc: 'NC',
		alpha3: 'NCL',
	},
	{
		jn: 'New Zealand',
		jc: 'NZ',
		alpha3: 'NZL',
	},
	{
		jn: 'Nicaragua',
		jc: 'NI',
		alpha3: 'NIC',
	},
	{
		jn: 'Niger',
		jc: 'NE',
		alpha3: 'NER',
	},
	{
		jn: 'Nigeria',
		jc: 'NG',
		alpha3: 'NGA',
	},
	{
		jn: 'Niue',
		jc: 'NU',
		alpha3: 'NIU',
	},
	{
		jn: 'Norfolk Island',
		jc: 'NF',
		alpha3: 'NFK',
	},
	{
		jn: 'North Mariana Islands',
		jc: 'MP',
		alpha3: 'MNP',
	},
	{
		jn: 'Norway',
		jc: 'NO',
		alpha3: 'NOR',
	},
	{
		jn: 'Oman',
		jc: 'OM',
		alpha3: 'OMN',
	},
	{
		jn: 'Pakistan',
		jc: 'PK',
		alpha3: 'PAK',
	},
	{
		jn: 'Palau',
		jc: 'PW',
		alpha3: 'PLW',
	},
	{
		jn: 'Palestinian Territory',
		jc: 'PS',
		alpha3: 'PSE',
	},
	{
		jn: 'Panama',
		jc: 'PA',
		alpha3: 'PAN',
	},
	{
		jn: 'Papua New Guinea',
		jc: 'PG',
		alpha3: 'PNG',
	},
	{
		jn: 'Paraguay',
		jc: 'PY',
		alpha3: 'PRY',
	},
	{
		jn: 'Peru',
		jc: 'PE',
		alpha3: 'PER',
	},
	{
		jn: 'Philippines',
		jc: 'PH',
		alpha3: 'PHL',
	},
	{
		jn: 'Pitcairn',
		jc: 'PN',
		alpha3: 'PCN',
	},
	{
		jn: 'Poland',
		jc: 'PL',
		alpha3: 'POL',
	},
	{
		jn: 'Portugal',
		jc: 'PT',
		alpha3: 'PRT',
	},
	{
		jn: 'Puerto Rico',
		jc: 'PR',
		alpha3: 'PRI',
	},
	{
		jn: 'Qatar',
		jc: 'QA',
		alpha3: 'QAT',
	},
	{
		jn: 'Réunion',
		jc: 'RE',
		alpha3: 'REU',
	},
	{
		jn: 'Romania',
		jc: 'RO',
		alpha3: 'ROU',
	},
	{
		jn: 'Russian Federation',
		jc: 'RU',
		alpha3: 'RUS',
	},
	{
		jn: 'Rwanda',
		jc: 'RW',
		alpha3: 'RWA',
	},
	{
		jn: 'Sint Maarten',
		jc: 'SX',
		alpha3: 'SXM',
	},
	{
		jn: 'Saint Barthelemy',
		jc: 'BL',
		alpha3: 'BLM',
	},
	{
		jn: 'Saint Helena, Ascension and Trista',
		jc: 'SH',
		alpha3: 'SHN',
	},
	{
		jn: 'Saint Martin',
		jc: 'MF',
		alpha3: 'MAF',
	},
	{
		jn: 'St Kitts & Nevis',
		jc: 'KN',
		alpha3: 'KNA',
	},
	{
		jn: 'St Lucia',
		jc: 'LC',
		alpha3: 'LCA',
	},
	{
		jn: 'Saint Pierre and Miquelon',
		jc: 'PM',
		alpha3: 'SPM',
	},
	{
		jn: 'St Vincent & Gren',
		jc: 'VC',
		alpha3: 'VCT',
	},
	{
		jn: 'Samoa',
		jc: 'WS',
		alpha3: 'WSM',
	},
	{
		jn: 'San Marino',
		jc: 'SM',
		alpha3: 'SMR',
	},
	{
		jn: 'Sao Tome and Prin.',
		jc: 'ST',
		alpha3: 'STP',
	},
	{
		jn: 'Saudi Arabia',
		jc: 'SA',
		alpha3: 'SAU',
	},
	{
		jn: 'Senegal',
		jc: 'SN',
		alpha3: 'SEN',
	},
	{
		jn: 'Serbia',
		jc: 'RS',
		alpha3: 'SRB',
	},
	{
		jn: 'Seychelles',
		jc: 'SC',
		alpha3: 'SYC',
	},
	{
		jn: 'Sierra Leone',
		jc: 'SL',
		alpha3: 'SLE',
	},
	{
		jn: 'Singapore',
		jc: 'SG',
		alpha3: 'SGP',
	},
	{
		jn: 'Slovakia',
		jc: 'SK',
		alpha3: 'SVK',
	},
	{
		jn: 'Slovenia',
		jc: 'SI',
		alpha3: 'SVN',
	},
	{
		jn: 'Solomon Islands',
		jc: 'SB',
		alpha3: 'SLB',
	},
	{
		jn: 'Somalia',
		jc: 'SO',
		alpha3: 'SOM',
	},
	{
		jn: 'South Africa',
		jc: 'ZA',
		alpha3: 'ZAF',
	},
	{
		jn: 'South Georgia and the South Sandwich Islands',
		jc: 'GS',
		alpha3: 'SGS',
	},
	{
		jn: 'Spain',
		jc: 'ES',
		alpha3: 'ESP',
	},
	{
		jn: 'Sri Lanka',
		jc: 'LK',
		alpha3: 'LKA',
	},
	{
		jn: 'Sudan',
		jc: 'SD',
		alpha3: 'SDN',
	},
	{
		jn: 'Suriname',
		jc: 'SR',
		alpha3: 'SUR',
	},
	{
		jn: 'Svalbard and Mayen',
		jc: 'SJ',
		alpha3: 'SJM',
	},
	{
		jn: 'Swaziland (Eswatini)',
		jc: 'SZ',
		alpha3: 'SWZ',
	},
	{
		jn: 'Sweden',
		jc: 'SE',
		alpha3: 'SWE',
	},
	{
		jn: 'Switzerland',
		jc: 'CH',
		alpha3: 'CHE',
	},
	{
		jn: 'Syria',
		jc: 'SY',
		alpha3: 'SYR',
	},
	{
		jn: 'Taiwan',
		jc: 'TW',
		alpha3: 'TWN',
	},
	{
		jn: 'Tajikistan',
		jc: 'TJ',
		alpha3: 'TJK',
	},
	{
		jn: 'Tanzania',
		jc: 'TZ',
		alpha3: 'TZA',
	},
	{
		jn: 'Thailand',
		jc: 'TH',
		alpha3: 'THA',
	},
	{
		jn: 'Timor-Leste',
		jc: 'TL',
		alpha3: 'TLS',
	},
	{
		jn: 'Togo',
		jc: 'TG',
		alpha3: 'TGO',
	},
	{
		jn: 'Tokelau',
		jc: 'TK',
		alpha3: 'TKL',
	},
	{
		jn: 'Tonga',
		jc: 'TO',
		alpha3: 'TON',
	},
	{
		jn: 'Trinidad & Tobago',
		jc: 'TT',
		alpha3: 'TTO',
	},
	{
		jn: 'Tunisia',
		jc: 'TN',
		alpha3: 'TUN',
	},
	{
		jn: 'Turkey',
		jc: 'TR',
		alpha3: 'TUR',
	},
	{
		jn: 'Turkmenistan',
		jc: 'TM',
		alpha3: 'TKM',
	},
	{
		jn: 'Turks & Caicos',
		jc: 'TC',
		alpha3: 'TCA',
	},
	{
		jn: 'Tuvalu',
		jc: 'TV',
		alpha3: 'TUV',
	},
	{
		jn: 'Uganda',
		jc: 'UG',
		alpha3: 'UGA',
	},
	{
		jn: 'Ukraine',
		jc: 'UA',
		alpha3: 'UKR',
	},
	{
		jn: 'United Arab Emirates',
		jc: 'AE',
		alpha3: 'ARE',
	},
	{
		jn: 'United Kingdom',
		jc: 'GB',
		alpha3: 'GBR',
	},
	{
		jn: 'United States',
		jc: 'US',
		alpha3: 'USA',
	},
	{
		jn: 'United States Minor Outlying Islands',
		jc: 'UM',
		alpha3: 'UMI',
	},
	{
		jn: 'Uruguay',
		jc: 'UY',
		alpha3: 'URY',
	},
	{
		jn: 'Uzbekistan',
		jc: 'UZ',
		alpha3: 'UZB',
	},
	{
		jn: 'Vanuatu',
		jc: 'VU',
		alpha3: 'VUT',
	},
	{
		jn: 'Venezuela',
		jc: 'VE',
		alpha3: 'VEN',
	},
	{
		jn: 'Vietnam',
		jc: 'VN',
		alpha3: 'VNM',
	},
	{
		jn: 'British Virgin Islands',
		jc: 'VG',
		alpha3: 'VGB',
	},
	{
		jn: 'United States Virgin Islands',
		jc: 'VI',
		alpha3: 'VIR',
	},
	{
		jn: 'Wallis and Futuna',
		jc: 'WF',
		alpha3: 'WLF',
	},
	{
		jn: 'Western Sahara',
		jc: 'EH',
		alpha3: 'ESH',
	},
	{
		jn: 'Yemen',
		jc: 'YE',
		alpha3: 'YEM',
	},
	{
		jn: 'Zambia',
		jc: 'ZM',
		alpha3: 'ZMB',
	},
	{
		jn: 'Zimbabwe',
		jc: 'ZW',
		alpha3: 'ZWE',
	},
];

export const jurisdictionDisplay = (jurisdictionCode: string = ''): string => {
	// sometimes the jurisdiction might be xx_xx format (opencorporates)
	let code = jurisdictionCode?.toUpperCase();
	if (jurisdictionCode.length === 5 && jurisdictionCode[2] === '_') {
		code = jurisdictionCode.split('_')[0].toUpperCase();
	}
	const jurisdiction = jurisdictions.find((j: IJurisdiction) => j.jc === code);
	return jurisdiction
		? `${jurisdiction.jn} (${jurisdiction.jc})`
		: `${jurisdictionCode}`;
};
