import React, { useEffect } from 'react';

interface IUpdateFaviconProps {
	url: string;
}

export const UpdateFavicon = ({ url }: IUpdateFaviconProps): null => {
	useEffect(() => {
		// Function to update favicon URLs
		const updateFaviconUrls = () => {
			// Selecting the favicon link elements by their rel and sizes attributes
			const iconsToUpdate = [
				{ rel: 'icon', sizes: '32x32' },
				{ rel: 'icon', sizes: '192x192' },
				{ rel: 'apple-touch-icon-precomposed', sizes: null },
			];

			iconsToUpdate.forEach((icon) => {
				// For apple-touch-icon-precomposed, sizes attribute is not used
				const selector = icon.sizes
					? `link[rel='${icon.rel}'][sizes='${icon.sizes}']`
					: `link[rel='${icon.rel}']`;
				const linkElement = document.querySelector(selector) as HTMLLinkElement; // Cast to HTMLLinkElement

				if (linkElement) {
					linkElement.href = url;
				}
			});
		};

		if (url) {
			updateFaviconUrls();
		}
	}, [url]); // Dependency array to re-run effect if the URL changes

	return null; // This component does not render anything
};
