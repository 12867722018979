import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { WidgetProps } from '@rjsf/core';
import * as React from 'react';

import { CustomDescriptionField } from './CustomRjsfTitle';

export const CustomAutocompleteWidget = ({
	schema,
	label,
	id,
	disabled,
	options,
	value,
	readonly,
	required,
	onChange,
	onBlur,
	multiple,
}: WidgetProps) => {
	const { enumOptions } = options;

	const onAutocompleteChange = (
		_: React.ChangeEvent,
		val: string[] | string
	) => {
		onChange(val);
	};

	const handleAutocompleteBlur = (
		event: React.FocusEvent<HTMLInputElement>
	) => {
		onBlur(null, event.target.value);
	};

	const labelMap: { [key: string]: string } = {};
	const autocompleteItems = [];
	for (const i of enumOptions as any) {
		autocompleteItems.push(i.value);
		labelMap[i.value] = i.label;
	}
	const initialValue = multiple ? value || [] : value || '';

	return (
		<FormControl fullWidth={true} required={required}>
			<InputLabel shrink htmlFor={id}>
				{label || schema.title}
			</InputLabel>
			{schema.description && schema.description.length && (
				<CustomDescriptionField description={schema.description} />
			)}
			<FormGroup>
				<Autocomplete
					id={id}
					multiple={multiple ?? false}
					disabled={disabled || readonly}
					fullWidth
					value={initialValue}
					options={autocompleteItems}
					getOptionLabel={(option: any) => labelMap[option] || ''}
					onChange={onAutocompleteChange}
					onBlur={handleAutocompleteBlur}
					renderInput={(params: any) => (
						<TextField {...params} variant="standard" fullWidth />
					)}
				/>
			</FormGroup>
		</FormControl>
	);
};
