import { FormControl, Input, InputLabel } from '@material-ui/core';
import { WidgetProps } from '@rjsf/core';
import React from 'react';

import { useStyles } from './AddMemberDialog.style';

export type IProps = WidgetProps;

export const DefaultInputTemplate: React.FC<IProps> = (props: WidgetProps) => {
	const styles = useStyles();

	const onChange = ({
		target: { value },
	}: React.ChangeEvent<HTMLInputElement>) =>
		props.onChange(value === '' ? props.options.emptyValue : value);

	const onBlur: any = ({
		target: { value },
	}: React.FocusEvent<HTMLInputElement>) => props.onBlur(props.id, value);

	const onFocus: any = ({
		target: { value },
	}: React.FocusEvent<HTMLInputElement>) => props.onFocus(props.id, value);

	return (
		<FormControl fullWidth>
			<InputLabel
				shrink={true}
				htmlFor={props.label}
				className={styles.defaultTopSpace}
			>
				{props.label || props.schema.title} {props.required ? '*' : ''}
			</InputLabel>

			<Input
				id={props.label}
				placeholder={props.label}
				type={props.schema.type as string}
				disabled={
					props.disabled || props.readonly || (props.schema as any)?.disabled
				}
				disableUnderline
				fullWidth
				required={props.required}
				value={props.value ? props.value : ''}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
			/>
		</FormControl>
	);
};
