export const primary = '#1E283B';
export const primaryLighter = '#24242E';
export const accent = '#0FCEAD';
export const secondary = '#394C65';
export const tertiary = '#7F8FA4';
export const grey = '#D5D5E3';
export const greyLight = '#EDEDF4';
export const greyLighter = '#8787A5';
export const link = '#0854C7';
export const success = '#00BA84';
export const warning = '#F38E2F';
export const error = '#FF3500';
export const white = '#FFFFFF';
