import { Button, Input } from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { LWSButton } from '@selfkey/lws-react-client';
import { get } from 'lodash';
import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Recaptcha from 'react-recaptcha';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { ITemplateSimple } from '../../../../../common-ts/src/interface/template';
import { IUserFields } from '../../../../../common-ts/src/interface/user';
import { tertiary } from '../../../../../ui/src/colors';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import { WagmiContext } from '../../../App';
import { ILoginPayload } from '../../../models';
import { userLogin } from '../../../redux/actions/user';
import { authWallet, getMyUser, signUp } from '../../../services/api';
import { TemplateIdContext } from '../AuthLayout';
import { NotFoundPage } from '../Errors';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { ConnectWallet } from './ConnectWallet';
import { TitleArea } from './TitleArea';

declare const __APP_CONFIG__: any;
const config = __APP_CONFIG__;

const emailRegex = /^.+@.+\..+/i;

const isChrome = /Chrome/.test(navigator.userAgent);

const useStyles = makeStyles({
	button: {
		marginTop: '2em',
	},
	buttonWrapper: {
		margin: 0,
	},
	dividerText: {
		color: tertiary,
		marginBottom: '1em',
		marginTop: '1em',
		textAlign: 'center',
	},
	input: {
		marginTop: '1.5em',
	},
	recaptcha: {
		marginTop: '2em',
	},
	recaptchaLoading: {
		display: 'block',
		marginTop: '1em',
	},
	recaptchaContainer: {
		display: 'flex',
		marginTop: '2em',
	},
});

interface ISignUpProps {
	passwordlessFlow?: boolean;
	title: string;
	templates: ITemplateSimple[];
}

interface ISignUpData {
	email: string;
	name: string;
	recaptcha?: string;
	templateId: string;
}

export const SignUp: React.FC<ISignUpProps> = (props) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation();

	const { templates } = props;
	const params = useParams<{ templateId: string }>();
	const [templateId, dispatchTemplateId] = useContext(TemplateIdContext);
	const [recaptchaKey, setRecaptchaKey] = useState(0);

	const { config: wagmiConfig } = useContext(WagmiContext);

	const [values, setValues] = useState({
		email: '',
		name: '',
		recaptcha: '',
	});

	const [errors, setErrors] = useState({
		email: '',
		name: '',
		recaptcha: '',
	});

	const lws = {
		...config.features.loginWithSelfKey,
		enabled: config.features.loginWithSelfKey.enabled && isChrome,
		website: {
			name: props.title,
			url: window.origin,
		},
	};

	const [currentUser, setCurrentUser] = useState<IUserFields>(null);
	const [jwt, setJwt] = useState<string>(null);
	const [walletConnection, setWalletConnection] = useState<any>(null);

	const [isSubmitted, setIsSubmitted] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [signUpSuccess, setSignUpSuccess] = useState(false);
	const [signUpLoading, setSignUpLoading] = useState(false);
	const [isReadyToFill, setIsReadyToFill] = useState(
		config.app.recaptcha ? false : true
	);
	const [recaptchaInstance, setRecaptchaInstance] = useState(null);

	useEffect(() => {
		if (currentUser) {
			setValues({
				...values,
				email: currentUser.email,
				name: currentUser.name,
			});
		}
	}, [currentUser]);

	useEffect(() => {
		const urlTemplateId = params.templateId;
		if (templateId !== urlTemplateId) {
			dispatchTemplateId(urlTemplateId);
		}
	}, []);

	const signUpTemplate = templates.find(
		(tmpl) => tmpl.templateId === templateId
	);

	if (!signUpTemplate) {
		return (
			<NotFoundPage message={t('errors.not-found-template-description')} />
		);
	}

	const lwsSignupAttributes = get(signUpTemplate, 'lwsSignupAttributes');
	const templateName = get(signUpTemplate, 'title');

	const handleChange =
		(prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
			setErrors({ ...errors, [prop]: '' });
		};

	const getValidationErrors = () => {
		const validationErrors = {
			email: '',
			name: '',
			recaptcha: '',
		};

		if (!values.email) {
			validationErrors.email = 'signup.error-email-required';
		} else if (!values.email.match(emailRegex)) {
			validationErrors.email = 'signup.error-bad-email';
		}

		if (!values.name) {
			validationErrors.name = 'signup.error-name-required';
		}

		if (config.app.recaptcha && !values.recaptcha) {
			validationErrors.recaptcha = 'signup.error-captcha';
		}

		return validationErrors;
	};

	const validate = (prop: string) => (_: React.SyntheticEvent) => {
		const error = (getValidationErrors() as any)[prop];
		setErrors({ ...errors, [prop]: error });
	};

	const signUpFlow = (signUpData: ISignUpData, authJwt: string = null) => {
		setIsSubmitted(true);
		setSignUpLoading(true);
		setErrorMessage(null);
		setSignUpSuccess(false);
		signUp(signUpData, authJwt)
			.then(() => {
				setSignUpLoading(false);
				setSignUpSuccess(true);
				if (recaptchaInstance) {
					recaptchaInstance.reset();
				}
				setValues({
					email: values.email,
					name: '',
					recaptcha: '',
				});
				setCurrentUser(null);
				setWalletConnection(null);
				setJwt(null);
			})
			.catch((err) => {
				setSignUpLoading(false);
				const errResponse = err.response;

				if (!errResponse) {
					setErrorMessage('error-generic');
					return;
				}

				switch (errResponse.status) {
					case 422:
						const details = errResponse.data?.details;
						switch (details) {
							case 'Nonexistent templateId':
								setErrorMessage('error-template');
								break;
							case 'Captcha verification failed.':
								setErrorMessage('error-captcha');
								refreshRecaptcha();
								break;
							case 'User with this email exists':
							case 'User exists in the DB':
								setErrorMessage('error-exists');
								break;
							case 'The selected template does not request the minimum required attributes or member templates set up.':
								setErrorMessage('error-template-minimum-attributes');
								break;
							default:
								setErrorMessage('error-generic');
						}
						break;
					case 403:
						const errorId = errResponse.data?.error;
						if (errorId === 'ALREADY_EXISTS_ERROR') {
							setErrorMessage('error-application-exists');
						} else {
							setErrorMessage('error-generic');
						}
						break;
					case 409:
						setErrorMessage('error-manager-email');
						break;
					default:
						setErrorMessage('error-generic');
				}
			});
	};

	const onWalletAccount = async (account: `0x${string}`) => {
		setWalletConnection({ account });

		let user: any = null;
		let authJwt: string = null;
		try {
			authJwt = await authWallet(account, wagmiConfig);
			setJwt(authJwt);
		} catch (err) {
			return;
		}
		try {
			user = await getMyUser(authJwt);
			setCurrentUser(user);
		} catch (err) {
			const error = getValidationErrors();
			if (error.email || error.name) {
				setErrors({ email: error.email, name: error.name, recaptcha: '' });
				return;
			}
		}
	};

	const signUpSubmit = async (event: React.SyntheticEvent) => {
		event.preventDefault();
		const error = getValidationErrors();
		if (error.email || error.name || error.recaptcha) {
			setErrors(error);
			return;
		}

		let authJwt: string = jwt;

		if (walletConnection && walletSignupEnabled) {
			if (!authJwt) {
				// authenticate if there is no jwt yet; for example user has clicked cancel during
				// signature process or previous authentication process failed
				try {
					authJwt = await authWallet(walletConnection.account, wagmiConfig);
					setJwt(authJwt);
				} catch (err) {
					return;
				}
			}
		} else if (!emailSignUpEnabled) {
			// stop here if sign up does not have all required details
			// note that this should only happen if wallet is not authenticated for some reason and
			// email authentication is disabled
			return;
		}

		signUpFlow(
			{
				email: values.email,
				name: values.name,
				recaptcha: values.recaptcha,
				templateId,
			},
			authJwt
		);
	};

	// called when recaptcha component has loaded
	const recaptchaOnLoadCallback = () => {
		setIsReadyToFill(true);
	};

	const recaptchaOnVerifyCallback = (response: any) => {
		setValues((prevValues) => ({ ...prevValues, recaptcha: response }));
		setErrors((prevValues) => ({ ...prevValues, recaptcha: '' }));
	};

	const refreshRecaptcha = () => {
		setRecaptchaKey((prevKey) => prevKey + 1);
	};

	const lwsLogin = (err: Error, payload: ILoginPayload) => {
		if (!err) {
			dispatch(userLogin(payload));
		}
	};

	const walletSignupEnabled = get(
		signUpTemplate,
		'requirements.options.signUpMethodWallet',
		false
	);
	const emailSignUpEnabled = get(
		signUpTemplate,
		'requirements.options.signUpMethodEmail',
		true
	);

	return (
		<CustomerPortalTheme>
			<LanguageSwitcher />
			<TitleArea
				title={t('signup.title')}
				description={t('signup.description', { instance: props.title })}
			/>
			{isSubmitted && errorMessage ? (
				<Typography variant="subtitle1" color="error">
					{t('signup.error', {
						message: `$t(signup.${errorMessage})`,
						templateName,
					})}
				</Typography>
			) : null}
			{isSubmitted && signUpSuccess ? (
				<Typography variant="subtitle1" color="error">
					{t('signup.success', { email: values.email })}
				</Typography>
			) : null}
			<form onSubmit={signUpSubmit} noValidate>
				<FormControl className={classes.input} fullWidth>
					<InputLabel shrink htmlFor="email">
						{t('signup.email')}
					</InputLabel>
					<Input
						id="email"
						placeholder="Email"
						disabled={!isReadyToFill || signUpLoading}
						type="email"
						onChange={handleChange('email')}
						error={!!errors.email}
						onBlur={validate('email')}
						fullWidth
						disableUnderline
						required
						value={values.email}
					/>
					{!!errors.email && (
						<Typography variant="subtitle1" color="error">
							{t(errors.email)}
						</Typography>
					)}
				</FormControl>
				<FormControl className={classes.input} fullWidth error={false}>
					<InputLabel shrink htmlFor="name">
						{t('signup.name')}
					</InputLabel>
					<Input
						id="name"
						placeholder={t('signup.name')}
						disabled={!isReadyToFill || signUpLoading}
						onChange={handleChange('name')}
						error={!!errors.name}
						onBlur={validate('name')}
						fullWidth
						disableUnderline
						required
						value={values.name}
					/>
					{!!errors.name && (
						<Typography variant="subtitle1" color="error">
							{t(errors.name)}
						</Typography>
					)}
				</FormControl>
				{config.app.recaptcha ? (
					<div className={classes.recaptchaContainer}>
						{!isReadyToFill ? (
							<CircularProgress className={classes.recaptchaLoading} />
						) : null}
						<Recaptcha
							key={recaptchaKey}
							render="explicit"
							ref={(e: any) => setRecaptchaInstance(e)}
							className={classes.recaptcha}
							sitekey={config.app.recaptcha}
							onloadCallback={recaptchaOnLoadCallback}
							verifyCallback={recaptchaOnVerifyCallback}
						/>
						{!!errors.recaptcha && (
							<Typography variant="subtitle1" color="error">
								{t(errors.recaptcha)}
							</Typography>
						)}
					</div>
				) : null}
				{((signUpTemplate && emailSignUpEnabled) || walletConnection) && (
					<Button
						type="submit"
						disabled={!isReadyToFill || signUpLoading}
						className={classes.button}
						fullWidth
						size="large"
						variant="contained"
					>
						{t('signup.sign-up')}
					</Button>
				)}
			</form>
			{lws.enabled && lwsSignupAttributes && (
				<>
					<Typography variant="subtitle2" className={classes.dividerText}>
						or
					</Typography>
					<LWSButton
						className="kyc-lws-button signup-lws"
						rootEndpoint={lws.rootEndpoint}
						endpoints={lws.endpoints}
						website={lws.website}
						attributes={lwsSignupAttributes}
						meta={{ templateId }}
						did={true}
						onAuthResponse={lwsLogin}
					/>
				</>
			)}
			{signUpTemplate && walletSignupEnabled && (
				<ConnectWallet
					disabled={!isReadyToFill || signUpLoading}
					onAccount={onWalletAccount}
				/>
			)}
			<BottomNavigation className="bottomNavigation">
				<BottomNavigationAction
					showLabel={true}
					component={Link}
					to="/auth/login"
					label={t(
						`${props.passwordlessFlow ? 'passwordlessFlow' : 'signup'}.login`
					)}
				/>
			</BottomNavigation>
		</CustomerPortalTheme>
	);
};
