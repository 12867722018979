import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { WalletConnectIcon } from '../../../helpers/walletConnectIcon';
import { WALLET_PROVIDERS } from '../../../helpers/wallets';

interface IWalletSignUpProps {
	buttonClass?: string;
	disabled?: boolean;
	onConnect: (provider: string) => void;
}

const useStyles = makeStyles({
	icon: {
		marginRight: '8px',
	},
	metamaskConnected: {
		marginTop: '25px',
	},
	metamaskIcon: {
		height: '30px',
		position: 'relative',
		top: '10px',
		width: '30px',
	},
});

export const ConnectWalletConnect = (props: IWalletSignUpProps) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const handleClick = async (evt: React.MouseEvent<HTMLButtonElement>) => {
		evt.preventDefault();
		props.onConnect(WALLET_PROVIDERS.walletConnect);
	};

	return (
		<Button
			className={props.buttonClass}
			onClick={handleClick}
			type="submit"
			disabled={props.disabled}
			fullWidth
			size="large"
			variant="contained"
		>
			<WalletConnectIcon className={classes.icon} />
			{t('signup.connect-wallet-connect')}
		</Button>
	);
};
