import Ajv from 'ajv';
import { isArray } from 'lodash';

import { IApplicationAttribute } from '..';
import {
	IApplicationType,
	IAttribute,
	IMember,
} from '../../../common-ts/src/interface/application';
import { IUserFields } from '../../../common-ts/src/interface/user';
import { apiClient } from '../util/apiClient';

const ajv = new Ajv({ removeAdditional: 'all' }).addFormat('file', () => true);

export const getAttributeId = (
	attributes: { [key: string]: any },
	schemaId: string
) =>
	Object.keys(attributes).find(
		(attrId) => attributes[attrId].schemaId === schemaId
	);

export const getTemplateAttributeIdBySchema = (
	templateAttributes: { [key: string]: any },
	schemaId: string
) =>
	Object.values(templateAttributes).find((attr) => attr.schemaId === schemaId)
		?._id;

export const getMemberEntryInApplication = (
	memberApplicationEntries: IMember[],
	memberApplicationId: string
) =>
	memberApplicationEntries.find(
		(member) => member.application === memberApplicationId
	);

export const getAttribute = <T = undefined>(
	attributes: Record<string, IAttribute>,
	schemaId: string,
	fallback?: T
): IAttribute | T => {
	const attributeId = getAttributeId(attributes, schemaId);

	return attributes[attributeId] ?? fallback;
};

export const getAllBySchemaIds = (
	attributes: { [attributeId: string]: IApplicationAttribute },
	schemaIds: string[]
): any[] => {
	const data = [];
	for (const attributeId of Object.keys(attributes)) {
		const attribute = attributes[attributeId];
		if (schemaIds.includes(attribute.schemaId)) {
			data.push(attributes[attributeId]);
		}
	}
	return data;
};

export const fullNameFromApplication = (application: any): string => {
	if (!application) {
		return '';
	}

	if (application.type === IApplicationType.INDIVIDUAL) {
		const middleName = getAttribute(
			application.attributes,
			'http://platform.selfkey.org/schema/attribute/middle-name.json'
		);
		const firstName = getAttribute(
			application.attributes,
			'http://platform.selfkey.org/schema/attribute/first-name.json'
		);
		const lastName = getAttribute(
			application.attributes,
			'http://platform.selfkey.org/schema/attribute/last-name.json'
		);
		const email = getAttribute(
			application.attributes,
			'http://platform.selfkey.org/schema/attribute/email.json'
		);

		let name;
		if (!firstName && !lastName) {
			name = [email && email.value ? email.value : null];
		} else {
			name = [
				firstName && firstName.value ? firstName.value : null,
				middleName && middleName.value ? middleName.value : null,
				lastName && lastName.value ? lastName.value : null,
			];
		}

		return name.filter((n) => n).join(' ');
	} else {
		const name =
			application.owners?.length && (application.owners[0] as IUserFields).name;
		const email = getAttribute(
			application.attributes,
			'http://platform.selfkey.org/schema/attribute/email.json'
		);
		return name ? name : email.value;
	}
};

export const getApplicationAttributeSchemaBulk = async (
	schemaIds: string[],
	applicationId: string
): Promise<Record<string, IAttributeSchema>> =>
	apiClient({
		method: 'GET',
		params: {
			schemaIds,
		},
		url: `/api/v2/applications/${applicationId}/attributes`,
	}).then((res) => res.data);

export const getAttributeSchemaBulk = async (schemaIds: string[]) =>
	apiClient({
		method: 'GET',
		params: {
			schemaIds,
		},
		url: '/api/v2/attributes',
	}).then((res) => res.data);

const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');

export interface IAttributeSchema {
	$id: string;
	$schema: string;
	identityAttribute: boolean;
	identityAttributeRepository: string;
	title: string;
	description: string;
	type: string;
	properties?: { [key: string]: any };
	items?: IAttributeSchema;
	required: string[];
}

export interface FileReference {
	fileId: string;
	mimeType?: string;
	size?: number;
	dataPath: string;
	content?: string;
}

// This method assumes attribute data structure is already validated
const extractFileReferences = (
	schema: any,
	data: any,
	files: FileReference[],
	dataPath: string[] = [],
	depth: number = 10
) => {
	if (depth < 1 || !data || !schema) {
		return;
	}
	if (schema.type === 'object' && !!schema.properties) {
		if (schema.format === 'file') {
			if (!checkForHexRegExp.test(data.content)) {
				const message = `Invalid file reference: ${data.content}`;
				throw new Error(message);
			}
			files.push({
				dataPath: dataPath.join('.'),
				fileId: data.content,
				mimeType: data.mimeType,
				size: data.size,
			});
		} else {
			Object.keys(data).forEach((key) => {
				extractFileReferences(
					schema.properties[key],
					data[key],
					files,
					[...dataPath, key],
					depth - 1
				);
			});
		}
	}

	if (schema.type === 'array') {
		(data as any[]).forEach((item, i) => {
			extractFileReferences(
				schema.items,
				item,
				files,
				[...dataPath, i.toString()],
				depth - 1
			);
		});
	}
};

export const removeMeta = (schema: IAttributeSchema, maxDepth = 10) => {
	if (maxDepth < 0) {
		return schema;
	}
	schema = { ...schema };
	delete schema.$id;
	delete schema.$schema;

	if (schema.type === 'object') {
		schema.properties = { ...schema.properties };
		Object.keys(schema.properties).forEach((key) => {
			schema.properties[key] = removeMeta(schema.properties[key], maxDepth - 1);
		});
	}
	if (schema.type === 'array') {
		schema.items = removeMeta(schema.items, maxDepth - 1);
	}
	return schema;
};

export class Attribute {
	public id: string;
	private files: FileReference[] = [];

	constructor(
		public schema: IAttributeSchema,
		public data: any,
		skipValidation: boolean = false
	) {
		if (!skipValidation) {
			this.validate();
		}
		this.schema = schema;
		this.id = this.schema.$id;
		extractFileReferences(this.schema, this.data, this.files);
	}

	public getFileReferences() {
		return this.files;
	}

	public getFileReferenceIds() {
		return this.files.map((file) => file.fileId);
	}

	private validate() {
		const schema = removeMeta(this.schema);
		const validate = ajv.compile(schema);
		const valid = validate(this.data);
		if (!valid) {
			throw new Error(validate.errors.join('\n'));
		}
	}
}

export const getAttributesBySchema = async (
	attributes: {
		[id: string]: IAttribute;
	},
	schemaIds: string[]
): Promise<{
	[id: string]: Attribute;
}> => {
	const neededSchemas = Object.keys(attributes)
		.map((id) => attributes[id].schemaId)
		.filter((schema) => schemaIds.includes(schema));
	if (!neededSchemas.length) {
		return {};
	}
	const schemas = await getAttributeSchemaBulk(neededSchemas);
	if (!schemas) {
		return {};
	}
	const res = neededSchemas.reduce((obj: any, schemaId: string) => {
		const attributeIds = Object.keys(attributes).filter(
			(id) => attributes[id].schemaId === schemaId
		);
		for (const attributeId of attributeIds) {
			const attr = attributes[attributeId];
			const schema =
				schemas[Object.keys(schemas).find((s: string) => s === attr.schemaId)];
			const attribute = new Attribute(schema, attr.value, true);
			obj[attributeId] = attribute;
		}
		return obj;
	}, {});

	return res;
};

export const getFileName = (path: string): string => {
	const name = [];
	const keys = path.split('.');
	const isImage = keys.find((key) => key.includes('image'));

	if (keys.includes('front')) {
		name.push('Front');
	}
	if (keys.includes('back')) {
		name.push('Back');
	}

	if (keys.includes('selfie')) {
		name.push('Selfie');
	}

	if (isImage) {
		name.push('Image');
	}

	if (keys.includes('images')) {
		const imagesPos = keys.indexOf('images');
		name.push(+keys[imagesPos + 1] + 1);
	}

	// Edge case where the path is just a number, like '0' or '2'
	const pathAsNumber = parseInt(path, 10);
	const onlyNumber = typeof pathAsNumber === 'number' && isFinite(pathAsNumber);
	if (onlyNumber) {
		name.push('Document');
		name.push(pathAsNumber + 1);
	}

	return name.length ? name.join(' ') : path;
};

interface IAttributeUploadValue {
	image?: FileReference;
	images?: FileReference[];
	extra?: FileReference[];
	front?: FileReference;
	back?: FileReference;
}

export const normalizeAttributeValue = (
	attributeValue: IAttributeUploadValue
): FileReference[] => {
	if (!attributeValue) {
		return [];
	}
	let f: FileReference[] = [];
	if (isArray(attributeValue)) {
		f = f.concat(attributeValue);
	}
	if (attributeValue.image) {
		f = f.concat([attributeValue.image]);
	}
	if (attributeValue.images) {
		f = f.concat(attributeValue.images);
	}
	if (attributeValue.front) {
		f = f.concat([attributeValue.front]);
	}
	if (attributeValue.back) {
		f = f.concat([attributeValue.back]);
	}
	if (attributeValue.extra) {
		f = f.concat(attributeValue.extra);
	}
	return f;
};
