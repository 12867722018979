import * as React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import {
	ILoadedPreview,
	ImageLoader,
	IPreview,
} from '../../helpers/ImageLoader/ImageLoader';
import { FileStatusView } from './file-status-view';

export interface IImagePreviewProps {
	previews: IPreview[];
	downloadFunc: (preview: IPreview, abort: any) => Promise<ArrayBuffer>; // function to download image from API
	renderFunc?: (preview: ILoadedPreview, index: number) => any; // override default render function
	hasLoaded?: () => any; // notify parent after loading has finished
}

export interface IImagePreviewState {
	abortLoading: (value?: unknown) => void; // way to cancel loading using $http timeout parameter
	loadedPreviews: ILoadedPreview[];
	loading: boolean;
	error: boolean;
}

export const ImagePreview = (props: IImagePreviewProps) => {
	const [error, setError] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [loadedPreviews, setLoadedPreviews] = React.useState([]);
	const [abortLoading, setAbortLoading] = React.useState(null);

	const abortLoadingFunc = () => {
		if (abortLoading) {
			abortLoading();
		}
	};

	const loadPreviews = () => {
		if (loading) {
			return;
		}
		setLoading(false);
		setError(false);
		// downloadFilePreviewOfSubmission === downloadFunc
		// downloadFilePreviewOfSubmission function is using angular's $http module which accepts "timeout" parameter.
		// Angular docs: "timeout in milliseconds, or promise that should abort the request when resolved."
		// second parameter of this function is passed as "timeout" to $http
		// create empty promise which will be resolved if loading needs to be cancelled
		const abortPromise = new Promise((resolve) => {
			setAbortLoading(resolve);
		});
		ImageLoader.LoadPreviews(props.downloadFunc, props.previews, abortPromise)
			.then((loadedPreviews: ILoadedPreview[]) => {
				if (props.hasLoaded) {
					props.hasLoaded();
				}
				setLoading(false);
				setLoadedPreviews(loadedPreviews);
			})
			.catch((_: any) => {
				setLoading(false);
				setError(true);
			});
	};

	React.useEffect(() => {
		loadPreviews();
		return () => {
			// cleanup
			abortLoadingFunc();
		};
	}, []);

	React.useEffect(() => {
		loadPreviews();
	}, [props.previews]);

	if (error) {
		return (
			<FileStatusView
				message={'fileStatus.genericError'}
				type={'error'}
				tryAgainFunc={loadPreviews}
			/>
		);
	}

	if (loading) {
		return <LinearProgress variant="query" />;
	}

	return (
		<>
			{loadedPreviews.map((preview: ILoadedPreview, index) => {
				if (props.renderFunc) {
					return props.renderFunc(preview, index);
				}
				return <img src={preview.fileURL} key={index} />;
			})}
		</>
	);
};
