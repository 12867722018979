import Typography from '@material-ui/core/Typography';
import { AxiosError } from 'axios';
import { ObjectID } from 'bson';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	KnownAttribute,
	fieldToKnownAttributeMap,
} from '../../../../../../common-ts/src/customSchemas';
import {
	IApplication,
	IApplicationType,
	ISection,
} from '../../../../../../common-ts/src/interface/application';
import { ApplicationsView } from '../../../../../../common-ts/src/interface/applications-view';
import { Feature } from '../../../../../../common-ts/src/interface/feature';
import {
	AddMemberOperationOptions,
	AddMemberOptions,
	IKYCTemplateQueryResponseType,
	IMemberTemplateCondition,
	INDIVIDUAL_ENTITY,
	LEGAL_ENTITY_TYPES,
	MemberOperation,
} from '../../../../../../common-ts/src/interface/template';
import {
	getErrorMesssage,
	selectMemberTemplate,
} from '../../../../../../common-ts/src/memberTemplates';
import {
	append,
	format,
	getBase,
} from '../../../../../../common-ts/src/util/path';
import {
	getAttribute,
	getAttributeId,
	getMemberEntryInApplication,
	getTemplateAttributeIdBySchema,
} from '../../../../../../common/src/helpers/attributes';
import { AddMemberDialog } from '../../../../../../common/src/react/AddMemberDialog';
import { ApplicationsViewSelector } from '../../../../../../common/src/react/ApplicationsViewSelector';
import { HierarchyViewTab } from '../../../../../../common/src/react/HierarchyViewTab';
import { CustomerPortalTheme } from '../../../../../../ui/src/theme/kyc-default-theme';
import { SettingsContext } from '../../../../App';
import {
	receiveApplicationsList,
	setApplicationDataById,
	setCurrentApplicationData,
	upsertApplicationsData,
} from '../../../../redux/actions/application';
import { RootState } from '../../../../redux/reducers';
import { AppDispatch } from '../../../../redux/store';
import {
	AddMemberApplicationData,
	addMemberApplication,
	addMemberToExistingApplication,
	deleteMemberApplication,
	editMemberApplication,
	getTemplateById,
	updateApplication,
} from '../../../../services/api';
import { ErrorSnackBar } from '../../Errors';
import { SectionNavigation } from '../../KYCLayout/Section';
import { ListViewTab } from './ListViewTab';
import { useStyles } from './SharesRegistry.style';

const addMemberOperation = async ({
	parentApplication: application,
	memberType,
	values,
	memberConditions,
	parentEntityType,
}: AddMemberOperationOptions) => {
	if (!values.roles || values.roles?.length <= 0) {
		throw new Error('errors.add-member-fill');
	}

	const selectedTemplate = selectMemberTemplate(
		memberConditions,
		application.type,
		parentEntityType.value,
		values.legalEntityType,
		memberType,
		values.roles
	);
	// load selected template
	const memberTemplate = await getTemplateById(
		selectedTemplate.template as string,
		IKYCTemplateQueryResponseType.STANDARD
	);

	const attributes = Object.entries(values)
		.filter(([key]) => key !== 'shares' && key !== 'roles' && key !== 'type')
		.map(([key, value]) => {
			return {
				data: value,
				id: getTemplateAttributeIdBySchema(
					memberTemplate.requirements.questions,
					fieldToKnownAttributeMap[key]
				),
				schemaId: fieldToKnownAttributeMap[key],
			};
		});

	const payload: AddMemberApplicationData = {
		application: {
			// @ts-ignore
			attributes,
			id: new ObjectID().toString(),
			paths: application.paths?.map((path) => append(path, application.id)) ?? [
				format([application.id]),
			],
			templateId: selectedTemplate.template as string,
		},
		memberRoles: values.roles,
		shares: values.shares,
	};

	return addMemberApplication(application.id, payload);
};

interface Props {
	application: IApplication;
	memberConditions: IMemberTemplateCondition[];
	nextCallback?: () => void;
	prevCallback?: () => void;
}

interface State {
	parents?: IApplication[];
	selected: IApplication;
	isOpen: boolean;
	openedFor?: 'add' | 'edit';
	isLoading: boolean;
	error?: string;
}

export const SharesRegistry: React.FC<Props> = ({
	application,
	memberConditions,
	nextCallback,
	prevCallback,
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const styles = useStyles();
	const { t } = useTranslation();

	const [view, setView] = React.useState(ApplicationsView.List);

	const { applications, currentApplication } = useSelector(
		(store: RootState) => store.applicationStore
	);

	const settings = React.useContext(SettingsContext);

	const [state, setState] = React.useState<State>({
		selected: application,
		isOpen: false,
		isLoading: false,
	});

	const legalEntityAttribute = getAttribute(
		state.selected.attributes,
		KnownAttribute.LegalEntityType,
		null
	);

	const legalType: string = legalEntityAttribute
		? legalEntityAttribute.value
		: null;

	const normalizedParent =
		state.parents?.length > 0 ? state.parents[0] : application;

	const legalEntity =
		normalizedParent.type === 'corporate'
			? LEGAL_ENTITY_TYPES.find((e) => e.value === legalType)
			: INDIVIDUAL_ENTITY;

	// process member applications
	const memberApplicationIds = state.selected.members.map(
		(member) => member.application as string
	);
	const memberApplications = applications.filter((a) =>
		memberApplicationIds.includes(a.id)
	);

	const rootMemberApplicationsIds = application.members.map(
		(member) => member.application as string
	);
	const rootMemberApplications = applications.filter((a) =>
		rootMemberApplicationsIds.includes(a.id)
	);

	const handleError =
		(operation: MemberOperation) => (error: Error | AxiosError) => {
			const message = getErrorMesssage(operation, error);

			setState((previous) => ({
				...previous,
				isLoading: false,
				error: message,
			}));
		};

	const openAddMemberModal = (selected?: IApplication) => {
		setState((previous) => ({
			...previous,
			...(selected && { selected }),
			isOpen: true,
			openedFor: 'add',
		}));
	};

	const openEditMemberModal = (id: string) => {
		const selected = applications.find((application) => application.id === id);

		setState((previous) => ({
			...previous,
			selected,
			isOpen: true,
			openedFor: 'edit',
		}));
	};

	const editMember = async (memberApplicationId: string, values: any) => {
		setState((previous) => ({ ...previous, isLoading: true }));

		const memberApplication = applications.find(
			(m) => m.id === memberApplicationId
		);

		if (!memberApplication) {
			setState((previous) => ({ ...previous, isLoading: false }));
		}

		// update values
		// payload is sligthly different in edit than create
		const payload: any = {
			attributes: [],
		};

		for (const key of Object.keys(values)) {
			const attribute = getAttribute(
				memberApplication.attributes,
				fieldToKnownAttributeMap[key],
				null
			);
			if (attribute && key !== 'email' && attribute.value !== values[key]) {
				payload.attributes.push({
					data: values[key],
					id: getAttributeId(
						memberApplication.attributes,
						fieldToKnownAttributeMap[key]
					),
					schemaId: fieldToKnownAttributeMap[key],
				});
			}
		}

		try {
			// check if shares if edited
			const index = application.members.findIndex(
				(m) => m.application === memberApplication.id
			);
			if (index >= 0 && values.shares !== application.members[index].shares) {
				// update shares in parent application
				await editMemberApplication(application.id, memberApplication.id, {
					shares: values.shares,
				});
				const updated = { ...application };
				updated.members[index].shares = values.shares;
				dispatch(setCurrentApplicationData(updated));
			}

			// edit member data in member application (firsName, companyName..)
			const updatedMemberApplication = await updateApplication(
				memberApplicationId,
				payload
			);
			// update applications list
			const updatedApplicationList = applications.filter(
				(a: IApplication) => a.id !== memberApplicationId
			);
			updatedApplicationList.push(updatedMemberApplication);
			dispatch(receiveApplicationsList(updatedApplicationList));

			setState(({ openedFor, ...previous }) => ({
				...previous,
				isOpen: false,
				isLoading: false,
			}));
		} catch (err) {
			handleError('edit')(err);
		}
	};

	const addMember = async ({
		rootApplication,
		parentApplication,
		memberType,
		values,
	}: AddMemberOptions) => {
		setState((previous) => ({ ...previous, isLoading: true }));

		await addMemberOperation({
			parentApplication,
			memberType,
			values,
			memberConditions,
			parentEntityType: legalEntity,
		})
			.then((response) => {
				dispatch(receiveApplicationsList(applications.concat(response)));

				const members = [
					...parentApplication.members,
					{
						application: response.id,
						memberRoles: values.roles,
						shares: values.shares,
						softDelete: false,
					},
				];

				const updatedApplication = {
					...parentApplication,
					members,
				};

				if (updatedApplication.id === rootApplication.id) {
					dispatch(setCurrentApplicationData(updatedApplication));
				}

				dispatch(
					setApplicationDataById(updatedApplication.id, updatedApplication)
				);

				setState(({ openedFor, ...previous }) => ({
					...previous,
					selected: updatedApplication,
					isOpen: false,
					isLoading: false,
				}));
			})
			.catch(handleError('add'));
	};

	const mapMemberData = (
		memberApplicationId: string,
		parent?: IApplication[]
	) => {
		const normalizedParent = parent?.length > 0 ? parent[0] : application;
		const memberApplication = applications.find(
			(application) => application.id === memberApplicationId
		);

		const memberApplicationEntry = getMemberEntryInApplication(
			normalizedParent.members,
			memberApplication.id
		);

		const editData = {
			email: getAttribute(memberApplication.attributes, KnownAttribute.Email)
				.value,
			...(memberApplication.type === IApplicationType.INDIVIDUAL
				? {
						firstName: getAttribute(
							memberApplication.attributes,
							KnownAttribute.FirstName
						).value,
						lastName: getAttribute(
							memberApplication.attributes,
							KnownAttribute.LastName
						).value,
				  }
				: {
						name: getAttribute(
							memberApplication.attributes,
							KnownAttribute.CompanyName
						).value,
						legalEntityType: getAttribute(
							memberApplication.attributes,
							KnownAttribute.LegalEntityType
						).value,
				  }),
		};

		return {
			...editData,
			memberApplicationId,
			roles: memberApplicationEntry.memberRoles,
			shares: memberApplicationEntry.shares,
			type: memberApplication.type,
		};
	};

	const deleteMember = async (
		parentApplication: IApplication,
		memberApplicationId: string
	) => {
		setState((previous) => ({ ...previous, isLoading: true }));

		try {
			await deleteMemberApplication(parentApplication.id, memberApplicationId);

			const updatedParentApplication = {
				...parentApplication,
				members: parentApplication.members.filter(
					(m) => m.application !== memberApplicationId
				),
			};

			if (updatedParentApplication.id === application.id) {
				dispatch(setCurrentApplicationData(updatedParentApplication));
			}

			dispatch(
				setApplicationDataById(
					updatedParentApplication.id,
					updatedParentApplication
				)
			);

			const updatedApplicationList = applications
				.map((app) => {
					if (app.id === updatedParentApplication.id) {
						return updatedParentApplication;
					}

					if (app.id !== memberApplicationId) {
						return app;
					}

					const newPaths = app.paths?.filter((path) => {
						return getBase(path) !== parentApplication.id;
					});

					if (!newPaths.length) return undefined;

					return { ...app, paths: newPaths };
				})
				.filter(Boolean);

			dispatch(receiveApplicationsList(updatedApplicationList));

			setState((previous) => ({ ...previous, isLoading: false }));
		} catch (err) {
			handleError('delete')(err);
		}
	};

	const closeErrorMessage = () => {
		setState(({ error, ...previous }) => previous);
	};

	// FIXME pass current section in from Sections.tsx
	const currentApplicationSection: ISection =
		application.sections?.find((s) =>
			s.components.includes('SharesRegistry')
		) ?? null;

	const pageTitle = currentApplicationSection?.title?.length
		? currentApplicationSection?.title
		: t('sections.shares_registry');
	const pageDescription = currentApplicationSection?.description?.length
		? currentApplicationSection?.description
		: undefined;

	return (
		<CustomerPortalTheme>
			<ErrorSnackBar
				message={state.error}
				open={!!state.error}
				variant="error"
				handleClose={closeErrorMessage}
			/>

			{state.isOpen && (
				<AddMemberDialog
					applicationType={normalizedParent.type}
					open={state.isOpen}
					onClose={() => {
						setState(({ openedFor, ...previous }) => ({
							...previous,
							isOpen: false,
						}));
					}}
					loading={state.isLoading}
					legalEntity={legalEntity}
					addMember={(memberType, values) => {
						addMember({
							memberType,
							values,
							rootApplication: application,
							parentApplication: state.selected,
							memberConditions,
							parentEntityType: legalEntity,
						});
					}}
					editMember={editMember}
					member={
						state.openedFor === 'edit'
							? mapMemberData(state.selected.id, state.parents)
							: undefined
					}
					askForMemberOwnership={normalizedParent.options.askForMemberOwnership}
				/>
			)}

			<div className={styles.titleAndViewSelector}>
				<Typography align="left" variant="h1">
					{pageTitle}
				</Typography>

				{settings.features.includes(Feature.XmasTree) && (
					<ApplicationsViewSelector
						value={view}
						onChange={(view) => {
							setView(view);
							setState(({ parents: parent, ...previous }) => ({
								...previous,
								selected: application,
							}));
						}}
					/>
				)}
			</div>

			{pageDescription && (
				<div
					className="html-container"
					dangerouslySetInnerHTML={{ __html: pageDescription }}
				></div>
			)}

			{view === ApplicationsView.List && (
				<ListViewTab
					onAddMemberClick={() => openAddMemberModal(application)}
					onEditMemberClick={openEditMemberModal}
					onDeleteMember={(id) => deleteMember(application, id)}
					{...{
						application,
						memberApplications: rootMemberApplications,
					}}
				/>
			)}

			{settings.features.includes(Feature.XmasTree) &&
				view === ApplicationsView.Hierarchy && (
					<HierarchyViewTab
						allApplications={applications}
						currentApplication={currentApplication}
						parents={state.parents}
						onAddMemberClick={() => openAddMemberModal(state.selected)}
						onEditMember={openEditMemberModal}
						onDeleteMember={(id, parentId) =>
							deleteMember(
								applications.find((app) => app.id === parentId) ?? application,
								id
							)
						}
						selected={state.selected}
						onNodeClick={({ selected, parents }) => {
							setState((previous) => ({
								...previous,
								parents,
								selected,
							}));
						}}
						{...{
							application,
							memberApplications,
							memberConditions,
							legalEntity,
						}}
						onMemberLinkedSubmit={(selected, applicationId, values) =>
							addMemberToExistingApplication({
								source: selected.id,
								target: applicationId,
								memberRoles: values.roles,
								shares: values.shares,
							}).then((response) => {
								dispatch(upsertApplicationsData(response));
							})
						}
					/>
				)}

			<SectionNavigation
				nextButtonProps={{ disabled: state.isLoading }}
				{...{ nextCallback, loading: state.isLoading, prevCallback }}
				position="center"
			/>
		</CustomerPortalTheme>
	);
};
