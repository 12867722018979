import { AISCAN_STATUS, IAIScanSession } from './aiscan';
import { IFileSchema } from './file';
import { EXTERNAL_VENDORS } from './vendors';

export enum DOCUMENTVERIFICATION_STATUS {
	pending = 'pending',
	ready = 'ready',
	skipped = 'skipped',
	invalidated = 'invalidated',
}

export enum EXTRACTED_FIELD_V1 {
	BirthDate = 'ExtractedFields_BirthDate',
	CountryCode = 'CountryCode',
	FirstName = 'ExtractedFields_FirstName',
	LastName = 'ExtractedFields_LastName',
	NationalityCode = 'ExtractedFields_NationalityCode',
}

export enum EXTRACTED_FIELD_V2 {
	BirthDate = 'BirthDate',
	ExpiryDate = 'ExpiryDate',
	FirstName = 'FirstName',
	LastName = 'LastName',
	DocumentNumber = 'DocumentNumber',
	PersonalNumber = 'PersonalNumber',
	BirthPlace = 'BirthPlace',
	IssueDate = 'IssueDate',
	IssuingAuthority = 'IssuingAuthority',
	MRZFull = 'MRZFull',
	DocumentCategory = 'DocumentCategory',
	DocumentType = 'DocumentType',
	IssuingStateName = 'IssuingStateName',
	Height = 'Height',
	NationalityName = 'NationalityName',
	NationalityCode = 'NationalityCode',
	Sex = 'Sex',
}

export enum IDSCAN_EMAIL_STEP {
	IDDOCUMENT = 'ID DOCUMENT',
	SELFIE = 'SELFIE',
}

export enum IDSCANFLOWTYPES {
	disabled = 'disabled',
	uiflow = 'uiflow',
	liveness = 'liveness',
}

export enum IDSCANFLOWSTRICTNESS {
	optional = 'optional',
	non_strict = 'non_strict',
	strict = 'strict',
}

export interface IVerificationFile {
	fileType: 'WhiteImage' | 'SelfiePhoto';
	file: string | IFileSchema;
	checkId?: string;
	response: {
		CurrentResult: string;
		EntryData: any;
		HasError: boolean;
		HighLevelResult: string;
		IsFinished: boolean;
		ResultDetails: string[];
	};
	fileBackside?: string | null;
}

export interface IVerificationCheckError {
	failCount: number;
	nextRunAt: Date;
	explanation?: string;
}

export interface IDocumentVerificationCheckModel {
	createdAt: string | Date;
	updatedAt: string | Date;
	files: IVerificationFile[];
	journeyData: IJourneyData | IAIScanSession;
	personEntryId: string;
	removed: boolean;
	status: DOCUMENTVERIFICATION_STATUS | AISCAN_STATUS;
	outOfDate: boolean;
	unresolvedError: IVerificationCheckError;
	gdprDelete: boolean;
	scope: string;
	vendor: EXTERNAL_VENDORS.AISCAN | EXTERNAL_VENDORS.IDSCAN;
}

export interface IJourneyImage {
	StepName: string;
	Attempt: string;
	ImageRole: string;
	ImageUrl: string;
}

export interface IDocumentImage {
	Role: string;
	Url: string;
}

export interface IExtractedField {
	Name: string;
	Value: string;
}

export interface IProcessedDocument {
	FaceMatchConfidenceScore?: number;
	IssuingCountryCode: string;
	IssuingCountryName: string;
	IssuingStateName: string;
	DocumentCategory: string;
	DocumentType: string;
	DocumentName: string;
	DocumentSide: string;
	IsValidated: boolean;
	ScanDateTime: Date;
	HighLevelResult: string;
	ExtractedFields: IExtractedField[];
	DocumentImages: IDocumentImage[];
	DigitalTamperingValidator: string;
}

export interface IProcessedLiveness {
	LivenessActions: any[];
	LivenessOverallResult: string;
	LivenessOverallFailureReason: string;
}

export interface IJourneyData {
	JourneyId: string;
	HighLevelResult: string;
	HighLevelResultDetails: string[];
	InitiatedDateTime: Date;
	ProcessedDocuments: IProcessedDocument[];
	ReferenceNumber: string;
	AuthenticationToleranceLevel: string;
	LastDecision: any;
	AdditionalData: any[];
	MetaData: any[];
	ProcessedLiveness: IProcessedLiveness;
	JourneyImages: IJourneyImage[];
	JourneySteps: any[];
	IsOcrOnly: boolean;
	RequiredAction?: string;
}
