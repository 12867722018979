import axios from 'axios';
import { stringify } from 'qs';

export const apiClient = axios.create({
	headers: {
		contentType: 'application/json',
	},
	paramsSerializer: (params) => {
		return stringify(params, { skipNulls: false });
	},
	timeout: 30 * 1000 * 60,
	validateStatus: (status: number) => {
		return status < 300;
	},
});
