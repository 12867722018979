import { Action, combineReducers } from 'redux';

import { ApplicationActions } from '../actions/application';
import { TemplateActions } from '../actions/templates';
import { UserAction } from '../actions/user';

import { applicationReducer, IApplicationState } from './application';
import { ITemplateState, templateReducer } from './template';
import { IUserState, userReducer } from './user';

type AllActions = ApplicationActions | TemplateActions | UserAction;

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers<
	{
		applicationStore: IApplicationState;
		templateStore: ITemplateState;
		userStore: IUserState;
	},
	Action<AllActions>
>({
	applicationStore: applicationReducer,
	templateStore: templateReducer,
	userStore: userReducer,
});

export default rootReducer;
