import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { getAttributeIdBySchemaId } from '../../../common-ts/src/application/getAttributeIdBySchemaId';
import { KnownAttribute } from '../../../common-ts/src/customSchemas';
import {
	IAIScanAttributes,
	OCR_FIELDS,
} from '../../../common-ts/src/interface/aiscan';
import { IApplication } from '../../../common-ts/src/interface/application';
import {
	EXTRACTED_FIELD_V2,
	IExtractedField,
	IProcessedDocument,
} from '../../../common-ts/src/interface/idScan';
import { EXTERNAL_VENDORS } from '../../../common-ts/src/interface/vendors';
import { convertToAlpha2Code } from '../../../common-ts/src/util/convertToAlpha2Code';

// get value from extracted field based on schema Id from attributes
export const findValueForField = (
	map: Map<EXTRACTED_FIELD_V2 | string, KnownAttribute>,
	extractedFields: IExtractedField[]
) => {
	const result = new Map<KnownAttribute, any>();
	extractedFields.forEach((extField) => {
		const schemaId = map.get(extField.Name as EXTRACTED_FIELD_V2);
		if (schemaId) {
			result.set(schemaId, extField.Value);
		}
	});
	return result;
};

export const getMatchedAttributes = (
	application: IApplication,
	sourceData: IAIScanAttributes | IProcessedDocument[],
	vendor: EXTERNAL_VENDORS.AISCAN | EXTERNAL_VENDORS.IDSCAN
) => {
	const isAIScan = vendor === EXTERNAL_VENDORS.AISCAN;
	const MatchingMap = new Map();
	MatchingMap.set(
		isAIScan ? OCR_FIELDS.firstName : EXTRACTED_FIELD_V2.FirstName,
		KnownAttribute.FirstName
	);
	MatchingMap.set(
		isAIScan ? OCR_FIELDS.lastName : EXTRACTED_FIELD_V2.LastName,
		KnownAttribute.LastName
	);
	MatchingMap.set(
		isAIScan ? OCR_FIELDS.dateOfBirth : EXTRACTED_FIELD_V2.BirthDate,
		KnownAttribute.DateOfBirth
	);
	MatchingMap.set(
		isAIScan ? OCR_FIELDS.nationality : EXTRACTED_FIELD_V2.NationalityCode,
		KnownAttribute.Nationality
	);
	MatchingMap.set(
		isAIScan ? OCR_FIELDS.personalNumber : EXTRACTED_FIELD_V2.PersonalNumber,
		KnownAttribute.NationalIdNumber
	);
	if (isAIScan) {
		MatchingMap.set(
			OCR_FIELDS.issuingCountry,
			KnownAttribute.CountryOfResidency
		);
		MatchingMap.set(OCR_FIELDS.middleName, KnownAttribute.MiddleName);
	}

	const extractedFields = isAIScan
		? Object.keys(sourceData).map((key) => ({
				Name: key,
				Value: (sourceData as IAIScanAttributes)[key],
		  }))
		: (sourceData as IProcessedDocument[])[0].ExtractedFields;

	const issuingCountryCode = isAIScan
		? convertToAlpha2Code(
				(sourceData as IAIScanAttributes)[OCR_FIELDS.issuingCountry]
		  )
		: convertToAlpha2Code(
				(sourceData as IProcessedDocument[])[0].IssuingCountryCode
		  );

	const result = findValueForField(MatchingMap, extractedFields);
	const attributes: Array<{ data: any; schemaId: string; id: string }> = [];

	extractedFields.forEach((extField) => {
		const fieldKey = isAIScan
			? (extField.Name as OCR_FIELDS)
			: (extField.Name as EXTRACTED_FIELD_V2);
		const schemaId = MatchingMap.get(fieldKey);
		const attrId = getAttributeIdBySchemaId(application.attributes, schemaId);
		const value = result.get(schemaId);
		const existingAttribute = attrId ? application.attributes[attrId] : null;
		const existingValue = existingAttribute ? existingAttribute.value : null;

		// allow AIScan values to overwrite existing data
		if (
			value &&
			attrId &&
			(vendor === EXTERNAL_VENDORS.AISCAN ||
				!existingValue ||
				isEmpty(existingValue))
		) {
			let data = {};
			if (schemaId === KnownAttribute.Nationality) {
				const country = convertToAlpha2Code(value);
				if (country) {
					data = { country };
				}
			} else if (
				schemaId === KnownAttribute.NationalIdNumber &&
				issuingCountryCode
			) {
				data = {
					issuing_country: issuingCountryCode,
					id_number: value,
				};
			} else if (
				schemaId === KnownAttribute.CountryOfResidency &&
				issuingCountryCode &&
				vendor === EXTERNAL_VENDORS.AISCAN
			) {
				// fill country of residency in case of AISCAN
				// using issuing country code
				const countryOfResidencyAttr = getAttributeIdBySchemaId(
					application.attributes,
					KnownAttribute.CountryOfResidency
				);
				if (countryOfResidencyAttr) {
					data = {
						country: issuingCountryCode,
					};
				}
			} else {
				data = value;
			}
			if (data && !isEmpty(data) && !isEqual(data, existingValue)) {
				attributes.push({ data, schemaId, id: attrId });
			}
		}
	});

	return attributes;
};
