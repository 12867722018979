import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	title: {
		marginBottom: '0.5rem',
	},
	buttonsContainer: {
		marginTop: '0.5rem',
		display: 'inline-block',
		width: '100%',
	},
	closeIcon: {
		'& svg': {
			height: '30px',
			width: '30px',
		},
		'@media (max-width: 600px)': {
			marginRight: '-20px',
			marginTop: '-40px',
		},
		marginRight: '-60px',
		marginTop: '-90px',
		position: 'relative',
	},
	content: {
		padding: 0,
	},
	cancelBtn: {
		marginRight: '10px',
	},
	iconWrap: {
		marginBottom: '-20px',
		marginTop: '10px',
		textAlign: 'right',
	},
	applicationsLabel: {
		'margin-bottom': '0.5rem',
		display: 'inline-block',
	},
});
