import { IApplication } from '../../../common-ts/src/interface/application';
import { IAttributeSchema } from './attributes';

export const reducer =
	(application: IApplication, schemas: Record<string, IAttributeSchema>) =>
	(res: any, key: string) => {
		const attr = application.attributes[key];
		const schema = schemas[attr.schemaId];
		res[key] = {
			...schema,
			...attr,
			// TODO deprecate label in attributes; use title instead
			title: attr.label ? attr.label : schema.title,
		};

		// set required field
		if (attr.overrides?.required?.length > 0) {
			res[key].required = attr.overrides.required;
		}

		// delete $id field because for some reason it breakes attribute form validation
		// use schemaId instead
		delete res[key].$id;

		// FIXME hard coded "denonym" removal
		if (
			schema.$id ===
			'http://platform.selfkey.org/schema/attribute/nationality.json'
		) {
			delete res[key].properties.denonym;
		}

		// FIXME HACK
		// if there is only one property use the main title instead of field title
		// main title will be hidden in Questions.tsx
		// https://github.com/kyc-chain/kyc-chain-monorepo/issues/1385
		const propertyKeys = res[key].properties
			? Object.keys(res[key].properties)
			: [];
		if (propertyKeys.length === 1) {
			res[key].properties[propertyKeys[0]].title = res[key].label
				? res[key].label
				: schema.title;
		}

		return res;
	};
