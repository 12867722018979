import { makeStyles } from '@material-ui/core';
import { compareAsc } from 'date-fns';
import * as React from 'react';

import { IMessage } from '.';
import Message from './Message';

const styles = makeStyles(() => ({
	messages: {
		bottom: 0,
		left: 0,
		overflowX: 'hidden' as any,
		overflowY: 'auto' as any,
		// position: 'absolute' as any,
		right: 0,
		top: 0,
	},
	root: {
		display: 'flex',
		flexDirection: 'column' as any,
		height: '100%',
		overflowX: 'hidden' as any,
		overflowY: 'auto' as any,
		position: 'relative' as any,
	},
}));

interface IMessagesProps {
	messages: IMessage[];
	userId: string;
	dateTimeFormat?: string;

	onEdit: (id: string, message: string) => void;
}

const Messages = (props: IMessagesProps) => {
	const classes = styles();
	const { userId, messages, dateTimeFormat } = props;
	const sortedMessages = messages.sort((a, b) =>
		compareAsc(new Date(a.createdAt), new Date(b.createdAt))
	);

	return (
		<div className={classes.root}>
			<div className={classes.messages}>
				{sortedMessages.length >= 0 &&
					sortedMessages.map((message) => (
						<Message
							key={message.id}
							id={message.id}
							time={message.createdAt}
							editedTime={message.updatedAt}
							text={message.message}
							name={message.userName}
							manager={message?.userRole === 'manager'}
							ownMessage={message.user === userId}
							dateTimeFormat={dateTimeFormat}
						/>
					))}
			</div>
		</div>
	);
};

export default Messages;
