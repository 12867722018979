import LinearProgress from '@material-ui/core/LinearProgress';
import { Theme, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { IApplication } from '../../../common-ts/src/interface/application';
import { ApiInfoSettings } from '../../../common-ts/src/interface/settings';
import { ITemplateSimple } from '../../../common-ts/src/interface/template';
import { getBase } from '../../../common-ts/src/util/path';
import {
	loadApplications,
	loadCurrentApplication,
	setCurrentApplication,
} from '../redux/actions/application';
import { getMySession } from '../redux/actions/user';
import { RootState } from '../redux/reducers';
import { AuthLayout } from './components/AuthLayout';
import { MainLayout } from './components/KYCLayout/Layout';

const REPOSITORY_URL = 'https://cdn.kyc-chain.com/datazoo/repository.json';

const useStyles = makeStyles((theme: Theme) => ({
	loadingIndicator: {
		marginBottom: theme.spacing(2),
	},
}));

interface Props {
	urlApplicationId?: string;
	className: string;
	hasSignUp: boolean;
	settings: ApiInfoSettings;
	templates: ITemplateSimple[];
}

export const selectableApplicationFilter = (
	application: IApplication,
	templates: ITemplateSimple[]
) => {
	return (
		!application.paths &&
		templates.find((tmpl) => tmpl.templateId === application.template)
	);
};

export const Main = (props: React.PropsWithChildren<Props>) => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const location = useLocation();

	const [datazooAttributesRepository, setDatazooAttributesRepository] =
		useState([]);

	const userStore = useSelector((store: RootState) => store.userStore);
	const { hasSignUp } = props;

	const applicationStore = useSelector(
		(store: RootState) => store.applicationStore
	);

	const selectableApplicationsCount = applicationStore.applications.filter(
		(a: IApplication) => selectableApplicationFilter(a, props.templates)
	).length;

	// triggers only once
	// check if session exists and redirect to the app; unless we are on set-password page
	useEffect(() => {
		axios.get(REPOSITORY_URL).then(async (res) => {
			setDatazooAttributesRepository(
				res.data.attributes.filter((a: any) => a.enabled)
			);
		});

		if (!location.pathname.includes('/auth/set-password')) {
			dispatch(getMySession());
		}
	}, []);

	// triggered when user is loaded
	useEffect(() => {
		if (!userStore.user) {
			return;
		}

		dispatch(loadApplications());
	}, [userStore.user]);

	useEffect(() => {
		// no reason to load application if we don't have a session
		if (!userStore.user) {
			return;
		}
		// we have id for current application but it's not loaded yet; load it
		if (
			applicationStore.currentApplicationId &&
			!applicationStore.currentApplication
		) {
			dispatch(loadCurrentApplication());
		}
	}, [applicationStore.currentApplicationId, userStore.user]);

	// application selection; triggered when applicationstore is loaded
	useEffect(() => {
		if (
			!applicationStore.applications ||
			applicationStore.applications.length <= 0
		) {
			return;
		}

		if (location && props.urlApplicationId) {
			// check if urlApplicationId is member application and choose the parent instead
			// FIXME later add support to fill member application directly
			const urlApplication = applicationStore.applications.find(
				(a: IApplication) => a.id === props.urlApplicationId
			);
			if (!urlApplication) {
				return;
			}
			const selectApplicationId =
				urlApplication.paths && urlApplication.paths.length > 0
					? getBase(urlApplication.paths[0])
					: props.urlApplicationId;

			if (selectApplicationId !== applicationStore.currentApplicationId) {
				dispatch(setCurrentApplication(selectApplicationId));
				location.search = '';
				return;
			}
		}

		// if user has only one application select that
		const mainApplications = applicationStore.applications.filter(
			(a: IApplication) => selectableApplicationFilter(a, props.templates)
		);
		if (
			!applicationStore.currentApplicationId &&
			mainApplications.length === 1
		) {
			const selectedApplication = mainApplications[0];
			dispatch(setCurrentApplication(selectedApplication.id));
		}
	}, [applicationStore.applications]);

	// display loading indicator
	if (applicationStore.loading) {
		return (
			<AuthLayout
				logoUrl={props.settings.logoUrl}
				hidePoweredBySelfkey={props.settings.hidePoweredBySelfkey}
			>
				<LinearProgress className={classes.loadingIndicator} />
			</AuthLayout>
		);
	}

	// user is not authenticated or no application/KYC to select
	if (!userStore.user) {
		if (
			!location.pathname.includes('/auth') ||
			location.pathname === '/auth' ||
			location.pathname === '/auth/'
		) {
			return hasSignUp ? (
				<Redirect to="/auth/signup" />
			) : (
				<Redirect to="/auth/login" />
			);
		}
		return <>{props.children}</>;
	}

	// user has multiple applications; display application select page
	if (
		!applicationStore.currentApplicationId &&
		applicationStore.applications.filter((a: IApplication) => !a.paths).length >
			1
	) {
		if (props.settings.disableApplicationListing) {
			// select the latest application
			const sorted = applicationStore.applications
				.filter((a) => !a.paths)
				.sort((a: IApplication, b: IApplication) => {
					const aDate = new Date(a.createdAt);
					const bDate = new Date(b.createdAt);
					if (aDate > bDate) {
						return -1;
					} else if (aDate < bDate) {
						return 1;
					}
					return 0;
				});
			dispatch(setCurrentApplication(sorted[0].id));
		} else if (!location.pathname.includes('/auth/applications')) {
			return <Redirect to="/auth/applications" />;
		}
		return <>{props.children}</>;
	}

	// application has been selected
	if (applicationStore.currentApplication && datazooAttributesRepository) {
		return (
			<MainLayout
				key={`main-${applicationStore.currentApplicationId}`}
				displaySwitcher={
					selectableApplicationsCount > 1 &&
					!props.settings.disableApplicationListing
				}
				datazooAttributesRepository={datazooAttributesRepository}
				passwordlessFlow={props.settings.passwordlessFlow}
				messagesEnabled={props.settings.messages.enabled}
				logoUrl={props.settings.logoUrl}
				contractActive={props.settings.contractActive}
			/>
		);
	}

	return <>{props.children}</>;
};
