import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ReactCrop from 'react-image-crop';

import { Typography } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

interface IUploadSignatureProps {
	onSignatureUpdated: any;
	signatureRequested: any;
	proportion?: number;
}

interface IUploadSignatureState {
	crop: ReactCrop.Crop;
	signature: string;
	uploadedImage: string;
	loadedImage: HTMLImageElement;
}

const useStyles = makeStyles({
	bold: {
		fontWeight: 600,
	},
	dropArea: {
		backgroundColor: '#F8F8FC',
		border: '2px dashed #D5D5E3',
		borderRadius: '4px',
		boxSizing: 'border-box',
		color: '#bbb',
		marginBottom: '20px',
		paddingBottom: '50px',
		paddingTop: '50px',
		textAlign: 'center' as 'center',
		width: '100%',
	},
	hiddenOnMobile: {
		'@media (max-width: 800px)': {
			display: 'none',
		},
	},
	publishIcon: {
		marginRight: '10px',
	},
	uploadBtn: {
		'@media (max-width: 800px)': {
			width: '100%',
		},
		marginBottom: '10px',
	},
	wrap: {
		'@media (max-width: 800px)': {
			width: '100%',
		},
		width: '380px',
	},
});

export const UploadSignature = (props: IUploadSignatureProps) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();

	const [state, setState] = React.useState<IUploadSignatureState>({
		crop: {
			aspect: props.proportion ? 1 / props.proportion : 1,
			height: 80,
			x: 10,
			y: 10,
		},
		loadedImage: null,
		signature: null,
		uploadedImage: null,
	});

	React.useEffect(() => {
		if (props.signatureRequested && state.uploadedImage) {
			props.onSignatureUpdated(state.uploadedImage);
		}
	}, [props.signatureRequested]);

	const onCropChange = (crop: ReactCrop.Crop) => {
		setState((previous) => ({ ...previous, ...crop }));
	};

	const handleFileChanged = (event: any) => {
		event.stopPropagation();
		event.preventDefault();

		let file: File = null;
		if (
			event.dataTransfer &&
			event.dataTransfer.files &&
			event.dataTransfer.files.length >= 0
		) {
			file = event.dataTransfer.files[0];
		} else if (
			event.target &&
			event.target.files &&
			event.target.files.length >= 0
		) {
			file = event.target.files[0];
		} else {
			return;
		}
		// this happens if you don't choose the file and close the file select window
		if (!file) {
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => {
			setState((previous) => ({
				...previous,
				uploadedImage: reader.result as string,
			}));
		};
	};

	const handleDragOver = (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		event.dataTransfer.dropEffect = 'copy';
	};

	const handleUploadClick = () => {
		fileInputRef.current.click();
	};

	const onImageLoaded = (image: HTMLImageElement) => {
		setState((previous) => ({
			...previous,
			loadedImage: image,
			signature: getCroppedImg(image, {
				height: image.height,
				width: image.width,
			}),
		}));
	};

	const onComplete = (crop: ReactCrop.Crop, percentCrop: ReactCrop.Crop) => {
		setState((previous) => ({
			...previous,
			signature: getCroppedImg(state.loadedImage, crop),
		}));
	};

	const getCroppedImg = (
		image: HTMLImageElement,
		crop: ReactCrop.Crop
	): string => {
		if (!image) {
			return null;
		}
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return canvas.toDataURL();
	};

	return (
		<div className={classes.wrap}>
			{state.uploadedImage ? (
				<ReactCrop
					onChange={onCropChange}
					onComplete={onComplete}
					onImageLoaded={
						// onImageLoaded has been casted as any because definition file is out of date
						onImageLoaded as any
					}
					src={state.uploadedImage}
					crop={state.crop}
				/>
			) : null}
			<input
				style={{ display: 'none' }}
				accept="image/*"
				type="file"
				onChange={handleFileChanged}
				ref={fileInputRef}
			/>
			<Typography
				variant="body2"
				gutterBottom
				className={classes.hiddenOnMobile}
			>
				{t('signatureDialog.upload.description')}
			</Typography>
			<div
				onDragOver={handleDragOver}
				onDrop={handleFileChanged}
				className={`${classes.dropArea} ${classes.hiddenOnMobile}`}
			>
				<Typography variant="body2" align="center">
					{t('signatureDialog.upload.dropArea')}
				</Typography>
			</div>
			<Button
				onClick={handleUploadClick}
				variant="contained"
				color="primary"
				className={classes.uploadBtn}
			>
				<Grid container justify="center" alignItems="center">
					<PublishIcon className={classes.publishIcon} />
					<Typography variant="body2" className={classes.bold}>
						{t('signatureDialog.upload.upload')}
					</Typography>
				</Grid>
			</Button>
		</div>
	);
};

export default UploadSignature;
