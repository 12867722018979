import { List, ListItem, ListItemText } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AISCAN_SECURITY_CHECK_ERRORS_THRESHOLD } from '../../../../../../common-ts/src/interface/aiscan';
import { primary } from '../../../../../../ui/src/colors';
import CustomerPortalTheme from '../../../../../../ui/src/theme/kyc-default-theme';
import { SettingsContext } from '../../../../App';
import { useStyles } from './common.style';

type IDDocVerStatus = 'outOfTries' | 'failed' | 'success';

interface IIDDocVerStatusPageProps {
	status: IDDocVerStatus;
	tryAgainEnabled?: boolean;
	canContinue?: boolean;
	optional?: boolean;
}

export const IDDocVerStatusMessage = (props: {
	status: IDDocVerStatus;
	securityCheckUIErrors: string[];
	shouldUploadBackside?: boolean;
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	let iconClass = '';
	let icon: any = null;
	switch (props.status) {
		case 'failed':
		case 'outOfTries':
			iconClass = classes.failedColor;
			icon = <WarningIcon fontSize="large" className={classes.resultIcon} />;
			break;
		case 'success':
			iconClass = classes.successColor;
			icon = <CheckIcon fontSize="large" className={classes.resultIcon} />;
			break;
	}

	return (
		<Typography className={iconClass} align="left" variant="h1">
			{icon}
			{t(`idscan.result.${props.status}.title`)}
			{props.shouldUploadBackside && (
				<Typography
					variant="h2"
					style={{
						fontWeight: 'bold',
						fontSize: '16px',
						color: props.securityCheckUIErrors?.length === 0 && primary,
					}}
				>
					{t('aiscan.attemptFailed.tryAgainFrontSide')}
				</Typography>
			)}
			{props.securityCheckUIErrors.length >
			AISCAN_SECURITY_CHECK_ERRORS_THRESHOLD ? (
				<Typography>
					{t('aiscan.attemptFailed.failedEssentialSecurityChecks')}
				</Typography>
			) : (
				props.securityCheckUIErrors.length > 0 && (
					<>
						<Typography className={classes.aiScanErrorListTitle}>
							{t('aiscan.attemptFailed.attributeListTitle')}:
						</Typography>
						<List disablePadding className={classes.aiScanErrorList}>
							{props.securityCheckUIErrors.map((failedCheckName) => (
								<ListItem
									key={failedCheckName}
									className={classes.aiScanErrorListItem}
								>
									<ListItemText
										className={classes.aiScanErrorListItemText}
										primary={`- ${t(
											`aiscan.attemptFailed.checks.${failedCheckName}`
										)}`}
									/>
								</ListItem>
							))}
						</List>
					</>
				)
			)}
		</Typography>
	);
};

export const IDDocVerStatusPage = (props: IIDDocVerStatusPageProps) => {
	const { t } = useTranslation();
	const settings = useContext(SettingsContext);

	return (
		<CustomerPortalTheme>
			<Typography align="left" variant="body1">
				{props.tryAgainEnabled ? (
					<>
						{t(
							`idscan.result.${props.status}.help-try-again${
								props.optional ? '-optional' : ''
							}`,
							{ supportEmail: settings.supportEmail }
						)}
					</>
				) : (
					<>
						{t(
							`idscan.result.${props.status}.help${
								props.optional ? '-optional' : ''
							}${props.canContinue ? '-next' : ''}`,
							{ supportEmail: settings.supportEmail }
						)}
					</>
				)}
			</Typography>
		</CustomerPortalTheme>
	);
};
