import { createStyles, Theme, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';

const labelStyle = (theme: Theme) =>
	createStyles({
		bold: {
			fontWeight: 600,
			lineHeight: 'initial',
		},
		default: {
			background: '#7F8FA4',
		},
		label: {
			'& svg': {
				fill: 'white',
				height: 'auto',
				marginRight: '8px',
				padding: '3px 0',
				width: '14px',
			},
			alignItems: 'center',
			borderRadius: '2px',
			display: 'inline-block',
			height: '20px',
			justifyContent: 'center',
			minWidth: '75px',
			padding: '0 10px',
		},
		primary: {
			background: '#394C65',
		},
		success: {
			background: '#00BA84',
		},
		error: {
			background: '#FF0000',
		},
		warning: {
			background: '#F38E2F',
		},
	});

export const Label = withStyles(labelStyle)(
	({ classes, text, status, icon }: any) => {
		let statusClass;

		switch (status) {
			case 'default':
				statusClass = classes.success;
				break;
			case 'error':
				statusClass = classes.error;
				break;
			case 'primary':
				statusClass = classes.primary;
				break;
			case 'secondary':
				statusClass = classes.warning;
				break;
			default:
				statusClass = classes.default;
		}

		return (
			<div className={`${classes.label} ${statusClass}`}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{icon}
					<Typography variant="caption" className={classes.bold}>
						{text}
					</Typography>
				</div>
			</div>
		);
	}
);
