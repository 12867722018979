import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogAction from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { APPLICATION_TYPE } from '../../../../../common-ts/src/interface/application';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';

interface IKYCRefreshModalProps {
	type: APPLICATION_TYPE;
	open: boolean;
	onClose: () => void;
}

export const KYCRefreshModal = (props: IKYCRefreshModalProps) => {
	const { t } = useTranslation();

	const translationPrefix = `kycrefresh.${props.type}`;

	return (
		<CustomerPortalTheme>
			<Dialog fullWidth open={props.open} disableEscapeKeyDown={true}>
				<DialogContent>
					<Typography
						align="left"
						variant="h1"
						style={{ marginBottom: '35px' }}
					>
						{t(`${translationPrefix}.title`)}
					</Typography>
					<Typography
						align="left"
						variant="body1"
						style={{ marginBottom: '35px' }}
					>
						{t(`${translationPrefix}.paragraph1`)}
					</Typography>
					<Typography
						align="left"
						variant="body1"
						style={{ marginBottom: '35px' }}
					>
						<List>
							<ListItem>
								<ListItemIcon>
									<CheckIcon />
								</ListItemIcon>
								<ListItemText
									primary={t(
										`${translationPrefix}.instructions1`
									)}
								/>
							</ListItem>
							<ListItem>
								<ListItemIcon>
									<CheckIcon />
								</ListItemIcon>
								<ListItemText
									primary={t(
										`${translationPrefix}.instructions2`
									)}
								/>
							</ListItem>
							<ListItem>
								<ListItemIcon>
									<CheckIcon />
								</ListItemIcon>
								<ListItemText
									primary={t(
										`${translationPrefix}.instructions3`
									)}
								/>
							</ListItem>
						</List>
					</Typography>
					<Typography
						align="left"
						variant="body1"
						style={{ marginBottom: '35px' }}
					>
						{t('kycrefresh.confirmation')}
					</Typography>
					<DialogAction>
						<Button
							color="secondary"
							variant="contained"
							onClick={() => props.onClose()}
						>
							I understand
						</Button>
					</DialogAction>
				</DialogContent>
			</Dialog>
		</CustomerPortalTheme>
	);
};
