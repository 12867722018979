import axios from 'axios';
import { format } from 'date-fns';

import { jurisdictionDisplay } from './countries';
import { IAttribute, IQuestion } from './interface/application';
import { IDateFormat } from './interface/scope';

interface ISupportSchema {
	$id: string;
	enum: string[];
	enumNames: string[];
}

export const supportSchemaCache: { [key: string]: ISupportSchema } = {};

export const loadSupportSchema = (
	schemaId: string,
	cache?: { [schemaId: string]: ISupportSchema }
): Promise<ISupportSchema> => {
	return cache[schemaId]
		? Promise.resolve(cache[schemaId])
		: axios({
				method: 'GET',
				url: schemaId,
		  }).then((res) => {
				cache[schemaId] = res.data;
				return res.data;
		  });
};

// cache support schemas for translations
if (!process.env.MOCK_MODE) {
	loadSupportSchema(
		'https://platform.selfkey.org/schema/misc/country.json',
		supportSchemaCache
	);
	loadSupportSchema(
		'https://platform.selfkey.org/schema/attribute/legal-entity-type.json',
		supportSchemaCache
	);
	loadSupportSchema(
		'https://platform.selfkey.org/schema/misc/business-type.json',
		supportSchemaCache
	);
}

export const getTranslatedValueFromSupportSchema = (
	value: string,
	supportSchema: ISupportSchema
): string => {
	const index: number = supportSchema.enum.findIndex((e) => e === value);
	return supportSchema.enumNames[index]
		? supportSchema.enumNames[index]
		: value;
};

export const formatValueForSchema = (
	schemaId: string,
	value: string,
	cache: { [schemaId: string]: ISupportSchema }
) => {
	const supportSchema = cache[schemaId] ? cache[schemaId] : null;
	const translated = supportSchema
		? getTranslatedValueFromSupportSchema(value, supportSchema)
		: value;
	return translated;
};

export const hasNoValue = (input: { value?: any; valid: boolean }) => {
	return (
		!input.value ||
		!input.valid ||
		(Array.isArray(input.value) && !input.value.length) ||
		Object.keys(input.value).every(
			(k: string) => input.value[k] === '' || input.value[k] === undefined
		)
	);
};

export const isObjectValue = (input: { value?: any }) =>
	input.value !== null && typeof input.value === 'object';

export const getTextValue = (
	schemaId: string,
	value: string,
	dateFormat: IDateFormat,
	uiType?: string
) => {
	if (schemaId) {
		switch (schemaId) {
			case 'http://platform.selfkey.org/schema/attribute/business-types.json':
				return formatValueForSchema(
					'https://platform.selfkey.org/schema/misc/business-type.json',
					value,
					supportSchemaCache
				);
			case 'http://platform.selfkey.org/schema/attribute/legal-jurisdiction.json':
				return (
					formatValueForSchema(
						'https://platform.selfkey.org/schema/misc/country.json',
						value,
						supportSchemaCache
					) + ` (${value})`
				);
			case 'http://platform.selfkey.org/schema/attribute/legal-entity-type.json':
				return formatValueForSchema(
					'https://platform.selfkey.org/schema/attribute/legal-entity-type.json',
					value,
					supportSchemaCache
				);
			case 'http://platform.selfkey.org/schema/attribute/date-of-birth.json':
			case 'http://platform.selfkey.org/schema/attribute/incorporation-date.json':
				const [year, month, day] = (value as any).split('-');
				if (year && month && day) {
					const d = new Date(year, month - 1, day);
					return format(d, dateFormat.DATE_MONTH_3_LETTERS_NO_COMMA);
				}
				return value;
		}
	} else if (uiType === 'date') {
		const [year, month, day] = (value as any).split('-');
		if (year && month && day) {
			const d = new Date(year, month - 1, day);
			return format(d, dateFormat.DATE_MONTH_3_LETTERS_NO_COMMA);
		}
		return value;
	}
	if (typeof value === 'string') {
		return value;
	}
	return '';
};

export const ValueToText = (
	input: IAttribute | IQuestion,
	empty: string = 'No data',
	separator: string = ' ',
	dateFormat: IDateFormat
): string => {
	if (hasNoValue(input)) {
		return empty;
	}

	// array
	if (input.valid && Array.isArray(input.value)) {
		return input.value.join(separator);
	}

	if (isObjectValue(input)) {
		if (
			input.schemaId ===
				'http://platform.selfkey.org/schema/attribute/physical-address.json' ||
			(input as any).uiType === 'addressInput'
		) {
			return [
				input.value.address_line_1,
				input.value.address_line_2,
				input.value.city,
				input.value.province,
				input.value.postalcode,
			]
				.filter((i) => i)
				.join(separator);
		}

		return Object.keys(input.value)
			.filter((k: string) => k !== 'denonym')
			.map(
				// TODO remove denonym removal hack
				(key: any) => {
					const isCountry = key === 'country' || key === 'issuing_country';
					const value = isCountry
						? jurisdictionDisplay(input.value[key])
						: input.value[key];
					return value;
				}
			)
			.join(separator);
	}

	return getTextValue(
		input.schemaId,
		input.value,
		dateFormat,
		(input as IQuestion).uiType
	);
};
