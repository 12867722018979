import difference from 'lodash/difference';

import {
	KycStatuses,
	MinimalCorporateAttributes,
	MinimalIndividualAttributes,
} from '../application/constants';
import { IApplication } from '../interface/application';
import { IFormSubmission } from '../interface/form';
import {
	getFilledAttributesOrQuestions,
	getRequirementsValues,
} from './allRequirementsFulfilled';

const minimalAttributes = [
	...MinimalIndividualAttributes.map((a) => a.schemaId),
	...MinimalCorporateAttributes.map((a) => a.schemaId),
];

export const memberApplicationIsDeletableByApplicant = (
	application: IApplication
) => {
	if (
		application.currentStatus === KycStatuses.invited ||
		application.currentStatus === KycStatuses.terms_accepted
	) {
		return true;
	}

	const hasFilledQuestions = Object.values(application.questions ?? {}).some(
		(q) => q.value
	);
	if (hasFilledQuestions) {
		return false;
	}

	const hasFilledForms = (application.forms as IFormSubmission[])?.some(
		(f) => f.submitted
	);
	if (hasFilledForms) {
		return false;
	}

	const values = getRequirementsValues(application);
	const filledSchemaIds = getFilledAttributesOrQuestions(values.attributes).map(
		(a) => a.schemaId
	);
	// if there is anything else than basic attributes then deleting is not allowed
	const diff = difference(filledSchemaIds, minimalAttributes);
	return diff.length === 0;
};
