import { applyMiddleware, compose, createStore } from 'redux';
import {
	PersistConfig,
	persistReducer,
	persistStore,
	StateReconciler,
} from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import rootReducer, { RootState } from './reducers';

// save only current application id
const applicationStoreFilter = createFilter('applicationStore', [
	'currentApplicationId',
]);

const persistConfig: PersistConfig<RootState> = {
	blacklist: ['userStore', 'templateStore'],
	key: 'root',
	stateReconciler: autoMergeLevel2 as StateReconciler<RootState>,
	storage: storageSession,
	transforms: [applicationStoreFilter],
};

// redux-dev tools support
const composeEnhancers =
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware(thunk))
);

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
