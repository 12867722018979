import { Theme } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import BaseField, { IBaseFieldProps } from './base-field';

const styles = (theme: Theme) =>
	createStyles({
		container: {
			backgroundColor: '#ffffff',
			border: '1px solid #b4bcc2',
			borderRadius: '50%',
			cursor: 'pointer',
			lineHeight: 1,
			textAlign: 'center',
		},
		disabled: {
			backgroundColor: '#ccc',
		},
	});

export interface IRadioFieldProps extends IBaseFieldProps {
	height: number;
	groupName: string;
	classes: any;
}

class RadioField extends BaseField<IRadioFieldProps, {}> {
	public render() {
		return (
			<div
				style={this.props.style}
				className={`${this.props.classes.container} ${this.getDisabled()}`}
				onClick={() => {
					if (this.props.disabled || this.props.value) {
						return;
					}
					this.onChange({
						value: !this.props.value,
					});
				}}
			>
				{this.getChecked()}
			</div>
		);
	}

	private getChecked() {
		if (this.props.value) {
			return '✓';
		} else {
			return '';
		}
	}

	private getDisabled() {
		if (this.props.disabled) {
			return this.props.classes.disabled;
		} else {
			return '';
		}
	}
}

export default withStyles(styles)(RadioField);
