import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
	IApplication,
	IMember,
} from '../../../../common-ts/src/interface/application';
import { getApplicationById, getApplicationsList } from '../../services/api';

export enum ApplicationActions {
	SET_APPLICATION = 'SET_APPLICATION',
	FETCH_APPLICATIONS = 'FETCH_APPLICATIONS',
	SET_LOADING = 'SET_LOADING',
	RECEIVE_APPLICATIONS = 'RECEIVE_APPLICATIONS',
	SET_CURRENT_APPLICATION_DATA = 'SET_CURRENT_APPLICATION_DATA',
	SET_APPLICATION_DATA_BY_ID = 'SET_APPLICATION_DATA_BY_ID',
	API_ERROR = 'API_ERROR',
	UPSERT_APPLICATIONS_DATA = 'UPSERT_APPLICATIONS_DATA',
}

interface IApplicationActionSetApplication
	extends Action<ApplicationActions.SET_APPLICATION> {
	applicationId: string;
}

interface IApplicationActionReceiveApplications
	extends Action<ApplicationActions.RECEIVE_APPLICATIONS> {
	applications: IApplication[];
}

interface IApplicationActionSetCurrentApplicationData
	extends Action<ApplicationActions.SET_CURRENT_APPLICATION_DATA> {
	application: IApplication;
}

interface IApplicationActionSetApplicationDataById
	extends Action<ApplicationActions.SET_APPLICATION_DATA_BY_ID> {
	id: string;
	application: IApplication;
}

interface IApplicationActionApiError
	extends Action<ApplicationActions.API_ERROR> {
	error: any;
}

interface IApplicationActionSetLoading
	extends Action<ApplicationActions.SET_LOADING> {}

interface IApplicationActionUpsertApplicationsData
	extends Action<ApplicationActions.UPSERT_APPLICATIONS_DATA> {
	applications: { id: string; paths: string[]; members: IMember[] }[];
}

export type IApplicationActions =
	| IApplicationActionReceiveApplications
	| IApplicationActionSetApplication
	| IApplicationActionSetCurrentApplicationData
	| IApplicationActionSetApplicationDataById
	| IApplicationActionApiError
	| IApplicationActionSetLoading
	| IApplicationActionUpsertApplicationsData;

export const setCurrentApplicationById = (
	applicationId: string
): IApplicationActionSetApplication => ({
	applicationId,
	type: ApplicationActions.SET_APPLICATION,
});

export const setCurrentApplication = (applicationId: string) => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
		if (!applicationId) {
			dispatch(setCurrentApplicationById(null));
			return;
		}
		dispatch(setCurrentApplicationById(applicationId));
		return dispatch(loadCurrentApplication());
	};
};

export const loadCurrentApplication = () => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
		const currentApplicationId =
			getState().applicationStore.currentApplicationId;
		if (!currentApplicationId) {
			throw Error('Current application id is not set.');
		}
		dispatch(setLoading());
		return getApplicationById(currentApplicationId)
			.then((receivedApplication: IApplication) => {
				dispatch(setCurrentApplicationData(receivedApplication));
			})
			.catch((err) => {
				dispatch(apiError(err.response));
			});
	};
};

export const setLoading = (): IApplicationActionSetLoading => ({
	type: ApplicationActions.SET_LOADING,
});

export const apiError = (error: any): IApplicationActionApiError => ({
	error,
	type: ApplicationActions.API_ERROR,
});

export const receiveApplicationsList = (
	applications: IApplication[]
): IApplicationActionReceiveApplications => ({
	applications,
	type: ApplicationActions.RECEIVE_APPLICATIONS,
});

export const setCurrentApplicationData = (
	application: IApplication
): IApplicationActionSetCurrentApplicationData => ({
	application,
	type: ApplicationActions.SET_CURRENT_APPLICATION_DATA,
});

export const upsertApplicationsData = (
	applications: { id: string; paths: string[]; members: IMember[] }[]
): IApplicationActionUpsertApplicationsData => {
	return {
		applications,
		type: ApplicationActions.UPSERT_APPLICATIONS_DATA,
	};
};

export const setApplicationDataById = (
	id: string,
	application: IApplication
): IApplicationActionSetApplicationDataById => ({
	id,
	application,
	type: ApplicationActions.SET_APPLICATION_DATA_BY_ID,
});

export const loadApplications = () => {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(setLoading());
		return getApplicationsList()
			.then((applications: IApplication[]) => {
				dispatch(receiveApplicationsList(applications));
			})
			.catch((err) => {
				dispatch(apiError(err.response));
			});
	};
};
