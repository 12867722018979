import {
	AISCAN_CHECK_TYPE,
	AISCAN_COMPLETED_STATUSES,
	AISCAN_STATUS,
	IAIScanCheck,
	IAIScanSession,
	OCR_FIELDS,
} from '../interface/aiscan';
import { IDocumentVerificationCheckModel } from '../interface/idScan';
import { getAlpha2Jurisdiction } from '../util/convertToAlpha2Code';

export const getCheck = (session: IAIScanSession, type: AISCAN_CHECK_TYPE) => {
	const filtered = session.checks
		.filter(
			(c) => AISCAN_COMPLETED_STATUSES.includes(c.status) && c.type === type
		)
		.sort(
			(a, b) =>
				new Date(b.dateSubmitted).valueOf() -
				new Date(a.dateSubmitted).valueOf()
		);

	const passed = filtered.find((c) => c.status === AISCAN_STATUS.complete);
	return passed ? passed : filtered[0];
};

export const getAttributeFromOcr = (attributes: any, attr: string) => {
	const attributeMap: { [key: string]: OCR_FIELDS } = {
		'http://platform.selfkey.org/schema/attribute/date-of-birth.json':
			OCR_FIELDS.dateOfBirth,
		'http://platform.selfkey.org/schema/attribute/first-name.json':
			OCR_FIELDS.firstName,
		'http://platform.selfkey.org/schema/attribute/last-name.json':
			OCR_FIELDS.lastName,
	};

	const fieldName = attributeMap[attr];
	return attributes[fieldName];
};

export const getIDDocumentType = (idDocumentCheck: IAIScanCheck) => {
	return idDocumentCheck.result?.attributes?.documentType?.toLowerCase();
};

export const getCompletedChecks = (
	idVerificationCheck: IDocumentVerificationCheckModel
) => {
	return (idVerificationCheck.journeyData as IAIScanSession).checks.filter(
		(c) => AISCAN_COMPLETED_STATUSES.includes(c.status)
	);
};

export const getUnfetchedFiles = (
	idVerificationCheck: IDocumentVerificationCheckModel
) => {
	const completedChecks = getCompletedChecks(idVerificationCheck);
	const fetchedChecks = idVerificationCheck.files
		.filter((f) => f.checkId)
		.map((f) => f.checkId);

	// filter files to be fetched
	return completedChecks.filter(
		(c) =>
			c.type !== AISCAN_CHECK_TYPE.passiveLiveness &&
			!fetchedChecks.includes(c.id)
	);
};

export const getFileTitle = (check: IAIScanCheck) => {
	if (!Object.keys(AISCAN_CHECK_TYPE).includes(check.type)) {
		// something has gone wrong or we have added something that is not supported
		return 'Unknown';
	}

	let type = 'SELFIE';
	if (check.type === AISCAN_CHECK_TYPE.idDocument) {
		type =
			check.result?.attributes && check.result?.attributes.documentType
				? check.result.attributes.documentType.toUpperCase()
				: 'Unknown';
	}
	if (type === 'SELFIE') {
		return 'Selfie';
	}
	const country =
		check.result?.attributes && check.result?.attributes.issuingCountry
			? getAlpha2Jurisdiction(check.result.attributes.issuingCountry)
			: null;
	return [country?.jn, type].filter((s) => s).join(' ');
};

export const getOCRDataKeys = (
	check: IAIScanCheck
): Array<{ key: string; value: string }> => {
	const ocrDataKeys =
		check.type === AISCAN_CHECK_TYPE.selfie
			? Object.keys(check.result)
			: Object.keys(check.result?.attributes ?? []).filter(
					(k) => !!check.result.attributes[k]
			  );
	const dataObject: any =
		check.type === AISCAN_CHECK_TYPE.selfie
			? check.result
			: check.result?.attributes ?? {};
	return ocrDataKeys.map((key) => {
		const value =
			key === 'similarityScore'
				? `${Math.floor(dataObject[key] * 100)} %`
				: dataObject[key];
		return {
			key,
			value,
		};
	});
};
