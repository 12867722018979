import i18n from 'i18next';
import { InitOptions } from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const isManagement = window.location.href.includes('/management');

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		backend: {
			loadPath: `${isManagement ? '/management' : ''}/locales/{{lng}}.json`,
		},
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		lng: 'en',
		react: { useSuspense: false },
	} as InitOptions);

export default i18n;
