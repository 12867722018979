import { withTheme } from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, Input, InputLabel, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { IApplicationType } from '../../../../common-ts/src/interface/application';
import {
	ILegalEntityType,
	LEGAL_ENTITY_TYPES,
} from '../../../../common-ts/src/interface/template';
import LoadingButton from '../LoadingButton';

import { CustomCheckboxesWidget } from '../CustomFields/CustomCheckboxesWidget';
import { FieldTemplate } from '../CustomFields/CustomFieldTemplate';

import { useStyles } from './AddMemberDialog.style';
import { DefaultInputTemplate } from './DefaultInputTemplate';

const MemberForm = withTheme(MuiTheme);

export type MemberData =
	| {
			memberApplicationId: string;
			roles: string[];
			shares: number;
			type: IApplicationType;
			firstName: any;
			lastName: any;
			email: any;
	  }
	| {
			memberApplicationId: string;
			roles: string[];
			shares: number;
			type: IApplicationType;
			name: any;
			legalEntityType: any;
			email: any;
	  };

export interface IProps {
	addMember: (type: IApplicationType, values: any) => void;
	applicationType: string;
	editMember: (memberApplicationId: string, values: any) => void;
	onClose: () => void;
	member?: MemberData;
	open: boolean;
	loading: boolean;
	legalEntity?: ILegalEntityType;
	askForMemberOwnership: boolean;
}

const widgets = {
	CheckboxesWidget: CustomCheckboxesWidget,
	EmailWidget: DefaultInputTemplate,
	TextWidget: DefaultInputTemplate,
};

const transformErrors = (errors: any[]) => {
	return errors.map((error) => {
		switch (error.name) {
			case 'pattern':
				error.message = 'errors.pattern';
				break;
			case 'required':
				error.message = 'errors.required';
				break;
			case 'minLength':
				error.message = 'errors.minLength';
				break;
			case 'format':
				error.message = 'errors.format';
				break;
		}
		return error;
	});
};

export const AddMemberDialog: React.FC<IProps> = (props) => {
	const { t } = useTranslation();
	const styles = useStyles();
	const [values, setValues] = React.useState({});
	const [memberType, setMemberType] = React.useState<IApplicationType>(
		IApplicationType.INDIVIDUAL
	);
	const uiSchema: any = {
		roles: {
			'ui:widget': 'checkboxes',
		},
		'ui:order': [
			'email',
			'name',
			'legalEntityType',
			'firstName',
			'lastName',
			'shares',
			'roles',
		],
	};

	React.useEffect(() => {
		if (props.member) {
			setValues(props.member);
			setMemberType(props.member.type);
		} else {
			setValues({});
		}
	}, [props.member]);

	const close = () => {
		setValues({});
		props.onClose();
	};

	// requirements hard coded for now
	const requirements: { [key: string]: any } = {
		corporate: {
			properties: {
				email: {
					format: 'email',
					title: t('membersDialog.email'),
					type: 'string',
				},
				legalEntityType: {
					enum: LEGAL_ENTITY_TYPES.map((entityType) => entityType.value),
					enumNames: LEGAL_ENTITY_TYPES.map((entityType) => entityType.name),
					title: t('membersDialog.legalEntityType'),
					type: 'string',
				},
				name: {
					title: t('membersDialog.companyName'),
					type: 'string',
				},
			},
			required: ['email', 'legalEntityType'],
		},
		individual: {
			properties: {
				email: {
					format: 'email',
					title: t('membersDialog.email'),
					type: 'string',
				},
				firstName: {
					title: t('membersDialog.firstName'),
					type: 'string',
				},
				lastName: {
					title: t('membersDialog.lastName'),
					type: 'string',
				},
			},
			required: ['email', 'firstName', 'lastName'],
		},
	};

	// only ask for ownership info if selected in scope
	if (props.askForMemberOwnership && props.applicationType === 'corporate') {
		const sharesRequirement = {
			shares: {
				min: 0,
				title: t('membersDialog.shares'),
				type: 'number',
			},
		};
		// always add it as a corporate requirement
		requirements.corporate.properties = {
			...requirements.corporate.properties,
			...sharesRequirement,
		};

		requirements.individual.properties = {
			...requirements.individual.properties,
			...sharesRequirement,
		};
	}

	// make email disabled when edit a member
	if (props.member) {
		requirements.individual.properties = {
			...requirements.individual.properties,
			...{
				email: {
					...requirements.individual.properties.email,
					disabled: true,
				},
			},
		};
		requirements.corporate.properties = {
			...requirements.corporate.properties,
			...{
				email: {
					...requirements.corporate.properties.email,
					disabled: true,
				},
			},
		};
	}

	if (!props.member) {
		requirements.individual.properties = {
			...requirements.individual.properties,
			...{
				roles: {
					items: {
						enum: props.legalEntity.memberRoles,
						enumNames: props.legalEntity.memberRoles.map((r) =>
							t(`roles.${r}`)
						),
						type: 'string',
					},
					title: t('membersDialog.positions'),
					type: 'array',
					uniqueItems: true,
				},
			},
		};
		requirements.corporate.properties = {
			...requirements.corporate.properties,
			...{
				roles: {
					items: {
						enum: props.legalEntity.memberRoles,
						enumNames: props.legalEntity.memberRoles.map((r) =>
							t(`roles.${r}`)
						),
						type: 'string',
					},
					title: t('membersDialog.positions'),
					type: 'array',
					uniqueItems: true,
				},
			},
		};
	}

	const onMemberTypeChange = (
		event: React.ChangeEvent<{ value: IApplicationType }>
	) => {
		setMemberType(event.target.value);
		setValues({});
	};

	const handleChange = (elem: any, errors: any) => {
		setValues(elem.formData);
	};

	return (
		<Dialog
			fullWidth
			open={props.open}
			onClose={close}
			aria-labelledby="add-member"
		>
			<div className={styles.iconWrap}>
				<IconButton
					color="inherit"
					onClick={close}
					className={styles.closeIcon}
				>
					<CloseIcon />
				</IconButton>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'start',
					marginBottom: '20px',
				}}
			>
				<Typography variant="h1" color="textPrimary" id="form-dialog-title">
					{props.member
						? t('membersDialog.editTitle')
						: t('membersDialog.title')}
				</Typography>
			</div>

			<DialogContent classes={{ root: styles.content }}>
				{!props.member && props.applicationType !== 'individual' && (
					<Grid direction="column" container spacing={2}>
						<Grid item>
							<Typography variant="body2" gutterBottom>
								{t('membersDialog.type')}
							</Typography>

							<Select
								value={memberType}
								onChange={onMemberTypeChange}
								fullWidth
							>
								<MenuItem key="individual" value="individual">
									{t('membersDialog.individual')}
								</MenuItem>

								<MenuItem key="corporate" value="corporate">
									{t('membersDialog.corporate')}
								</MenuItem>
							</Select>
						</Grid>
					</Grid>
				)}

				<MemberForm
					className={'attributes-form'}
					FieldTemplate={FieldTemplate}
					schema={requirements[memberType]}
					uiSchema={uiSchema}
					onSubmit={() =>
						!props.member
							? props.addMember(memberType, values)
							: props.editMember(props.member.memberApplicationId, values)
					}
					onChange={handleChange}
					formData={values}
					showErrorList={false}
					noHtml5Validate={true}
					widgets={widgets}
					transformErrors={transformErrors}
				>
					{props.member && (
						<FormControl fullWidth style={{ marginTop: '10px' }}>
							<InputLabel shrink={true} className={styles.defaultTopSpace}>
								{t('membersDialog.roles')}
							</InputLabel>

							<Input
								disabled={true}
								disableUnderline
								fullWidth
								value={props.member.roles
									.map((r: string) => t(`roles.${r}`))
									.join(', ')}
							/>

							<Typography variant="subtitle1" className={styles.subtitle}>
								{t('membersDialog.roles_not_editable')}
							</Typography>
						</FormControl>
					)}

					<Button
						onClick={close}
						variant="contained"
						color="primary"
						disabled={props.loading}
						className={styles.cancelBtn}
					>
						{t('membersDialog.cancel')}
					</Button>

					<LoadingButton
						variant="contained"
						type="submit"
						loading={props.loading}
					>
						{t('membersDialog.save')}
					</LoadingButton>
				</MemberForm>
			</DialogContent>
		</Dialog>
	);
};
