import { apiClient } from '../../../../../common-ts/src/services/network/api';

export interface AssociatedFile {
	_id: string;
	fileNameToShow: string;
}

export const getAssociatedFiles = (id: string) => {
	return apiClient.get<AssociatedFile[]>(`/api/v2/applications/${id}/files`);
};
