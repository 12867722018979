import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { IApplication } from '../../../../../../common-ts/src/interface/application';
import { IRepositoryEntry } from '../../../../../../common-ts/src/interface/datazoo';
import { fullNameFromApplication } from '../../../../../../common/src/helpers/attributes';

import { setApplicationDataById } from '../../../../redux/actions/application';
import { getApplicationById } from '../../../../services/api';

import { KYCLayout } from '../../KYCLayout';

import { useStyles } from './MemberDataFillForm.style';

interface IMemberDataFillForm {
	open: boolean;
	applicationId: string;
	datazooAttributesRepository?: IRepositoryEntry[];
	onClose: (updatedApplication: IApplication, hardRefresh: boolean) => void;
}

export const MemberDataFillForm = (props: IMemberDataFillForm) => {
	const { t } = useTranslation();
	const styles = useStyles();
	const [application, setApplication] = useState(null);

	const onClose = (hardRefresh: boolean) => {
		props.onClose(application, hardRefresh);
		setApplication(null);
	};

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.applicationId) {
			getApplicationById(props.applicationId).then((app) => {
				setApplication(app);
			});
		}
	}, [props.applicationId]);

	return (
		<Dialog
			fullWidth
			open={props.open}
			onClose={() => onClose(false)}
			aria-labelledby="form-dialog-title"
			classes={{ paper: styles.dialogPaper }}
		>
			<div className={styles.iconWrap}>
				<IconButton
					color="inherit"
					onClick={() => onClose(false)}
					className={styles.closeIcon}
				>
					<CloseIcon />
				</IconButton>
			</div>
			{!application ? (
				<LinearProgress />
			) : (
				<>
					<div className={styles.dialogTitle}>
						<Typography variant="h1" color="textPrimary" id="form-dialog-title">
							{t('membersDataForm.title')}{' '}
							{fullNameFromApplication(application)}
						</Typography>
					</div>
					<DialogContent classes={{ root: styles.dialogContent }}>
						<KYCLayout
							application={application}
							datazooAttributesRepository={props.datazooAttributesRepository}
							onApplicationUpdate={(app) => {
								dispatch(setApplicationDataById(app.id, app));
								setApplication(app);
							}}
							overrideStyle={styles.popupStyle}
							useRouter={false}
							lastStepCallback={() => onClose(true)}
						/>
					</DialogContent>
				</>
			)}
		</Dialog>
	);
};
