import { Button, Typography } from '@material-ui/core';
import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useCheckUserSession } from '../../../../helpers';
import {
	buildUrlApplicationWithAccessToken,
	getURLWithRedirectToken,
} from '../../../../services/api';
import { useStyles } from './common.style';

interface ISwitchToMobileQRMessageProps {
	applicationId: string;

	continueFunc: () => void;
}

export const SwitchToMobileQRMessage = (
	props: ISwitchToMobileQRMessageProps
) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const [qrCodeLink, setQrCodeLink] = useState('');
	const [intervalId, setIntervalId] = useState(null);

	const updateQrCode = (accessToken: string) => {
		const URL = buildUrlApplicationWithAccessToken(
			accessToken,
			props.applicationId
		);
		QRCode.toDataURL(URL).then(setQrCodeLink);
	};

	const createQrCode = () => {
		getURLWithRedirectToken().then((accessTokenResponse) => {
			const { jwt: accessToken } = accessTokenResponse;
			updateQrCode(accessToken);
		});
	};

	useEffect(() => {
		getURLWithRedirectToken().then((accessTokenResponse) => {
			const { jwt: accessToken, ttl } = accessTokenResponse;
			updateQrCode(accessToken);

			// when the token expires, then create a new QR Code
			setInterval(() => {
				createQrCode();
			}, ttl);

			const newIntervalId = setInterval(() => {
				useCheckUserSession(history, dispatch);
			}, 30000);
			setIntervalId(newIntervalId);
		});
	}, []);

	return (
		<div className={classes.qrCodeContainer}>
			<Typography> {t('idverification.qrCode.title')}</Typography>
			<Typography className={classes.bold}>
				{' '}
				{t('idverification.qrCode.recommendation')}
			</Typography>
			<Typography> {t('idverification.qrCode.startHelp')}</Typography>

			<img src={qrCodeLink} />

			<Typography> {t('idverification.qrCode.alternative')}</Typography>
			<Button
				variant="contained"
				onClick={() => {
					if (!!intervalId) {
						clearInterval(intervalId);
						setIntervalId(null);
					}
					props.continueFunc();
				}}
				className={classes.continueButton}
			>
				{t('idverification.qrCode.continueOnDesktop')}
			</Button>
		</div>
	);
};
