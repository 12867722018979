export interface IPreview {
	page: number;
	contentType: string;
	documentId?: string;
	fileId?: string;
}

export interface ILoadedPreview {
	page: number;
	fileURL: string;
}

export class ImageLoader {
	public static LoadPreviews(
		apiDownloadFunc: (preview: IPreview, abort: any) => Promise<ArrayBuffer>,
		previews: IPreview[],
		abortPromise: Promise<any>
	): Promise<ILoadedPreview[]> {
		// download each preview image
		return Promise.all(
			previews.map(preview => {
				return apiDownloadFunc(preview, abortPromise).then(
					(ar: ArrayBuffer) => {
						return {
							buffer: ar,
							contentType: preview.contentType,
							page: preview.page
						};
					}
				);
			})
		).then(result => {
			return result
				.map(r => {
					return {
						fileURL: window.URL.createObjectURL(
							new Blob([r.buffer], { type: r.contentType })
						),
						page: r.page
					};
				})
				.sort((a, b) => a.page - b.page);
		});
	}
}
