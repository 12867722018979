import { FieldTemplateProps } from '@rjsf/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	errorField: {
		'& div:nth-child(2)': {
			border: '2px solid red',
			borderRadius: '5px',
		},
		borderRadius: '5px',
	},
	fieldTemplate: {
		boxSizing: 'border-box' as 'border-box',
	},
}));

export const FieldTemplate = ({
	id,
	children,
	displayLabel,
	rawErrors = [],
	rawHelp,
	rawDescription,
}: FieldTemplateProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const fieldClassName = rawErrors.length
		? [classes.fieldTemplate, classes.errorField].join(' ')
		: classes.fieldTemplate;

	return (
		<FormControl
			className={fieldClassName}
			fullWidth={true}
			error={rawErrors.length ? true : false}
		>
			{children}
			{rawErrors.length > 0 && (
				<List dense={true} style={{ backgroundColor: 'transparent' }}>
					{rawErrors.map((error, i: number) => {
						return (
							<Typography key={i} variant="subtitle1" color="error">
								{t(error)}
							</Typography>
						);
					})}
				</List>
			)}
			{rawHelp && (
				<Typography id={id} variant="subtitle1" color="error">
					{rawHelp}
				</Typography>
			)}
		</FormControl>
	);
};
