import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	root: {
		display: 'flex',
		justifyContent: 'center',
	},
	divider: {
		margin: '2rem 0',
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexWrap: 'wrap',
		margin: '-0.25rem -0.25rem 0.25rem -0.25rem',
	},
	button: {
		margin: '0.25rem',
	},
	applicationsLabel: {
		'font-weight': 'bold',
		'margin-bottom': '0.5rem',
		display: 'inline-block',
	},
	applicationsSelect: {
		'margin-bottom': '0.5rem',
	},
});
