import { AxiosResponse } from 'axios';
import { apiClient } from '../../../common/src/util/apiClient';

export interface IFile {
	id: string;
	originalname: string;
	fieldname: 'string';
}

export interface IUploadFileParameter {
	name: string;
	file: any;
}

export const uploadFiles = async (
	files: IUploadFileParameter[]
): Promise<IFile | IFile[]> => {
	const upload = new FormData();
	files.forEach((f) => upload.append(f.name, f.file, f.file.name));
	return apiClient({
		data: upload,
		method: 'POST',
		url: `/api/v2/files`,
	}).then((response: AxiosResponse) => response.data);
};

export const downloadFile = (id: string) => {
	return apiClient({
		method: 'GET',
		responseType: 'arraybuffer',
		url: `/api/v2/files/${id}`,
	}).then((response: AxiosResponse) => response.data);
};
