import * as React from 'react';

import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';

import { APPLICATION_TYPE } from '../../../common-ts/src/interface/application';

export interface Props {
	type: APPLICATION_TYPE;
	size?: 'inherit' | 'default' | 'small' | 'large';
	classes?: string;
	style?: any;
}

export const MemberTypeIcon: React.FC<Props> = ({
	type,
	size,
	classes,
	style,
}) =>
	type === 'individual' ? (
		<PersonIcon fontSize={size ?? 'small'} className={classes} {...{ style }} />
	) : (
		<BusinessIcon
			fontSize={size ?? 'small'}
			className={classes}
			{...{ style }}
		/>
	);
