import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WarningIcon from '@material-ui/icons/Warning';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { allRequirementsFulfilled } from '../../../../../common-ts/src/application/allRequirementsFulfilled';
import { KycProcessClosedStatuses } from '../../../../../common-ts/src/application/constants';
import { getAttrNameFromSchemaId } from '../../../../../common-ts/src/attributes';
import { formIsTermsForm } from '../../../../../common-ts/src/forms';
import {
	IApplication,
	IAttribute,
	IQuestion,
} from '../../../../../common-ts/src/interface/application';
import { IFileSchema } from '../../../../../common-ts/src/interface/file';
import {
	IFormSubmission,
	IKycDocumentTemplate,
} from '../../../../../common-ts/src/interface/form';
import { ITemplateSimple } from '../../../../../common-ts/src/interface/template';
import { attributeHasUploads } from '../../../../../common/src/helpers/attribute-has-uploads';
import {
	fullNameFromApplication,
	normalizeAttributeValue,
} from '../../../../../common/src/helpers/attributes';
import { ValueFormatter } from '../../../../../common/src/react/Attribute/valueFormatter';
import { PreviewDialogV2 } from '../../../../../common/src/react/Preview/preview-v2';
import { accent, tertiary } from '../../../../../ui/src/colors';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import { DETAILS_PAGE_ATTRIBUTES } from '../../../constants';
import {
	loadCurrentApplication,
	receiveApplicationsList,
	setCurrentApplicationData,
} from '../../../redux/actions/application';
import { RootState } from '../../../redux/reducers';
import {
	getAssociatedFiles,
	isApplicationEditable,
	submitApplication,
} from '../../../services/api';
import { isStatusError } from '../../Helpers';
import { loadSchemas } from '../../Helpers/sections';
import { MemberDataFillForm } from '../MembersTable/MemberDataFillForm';
import { SectionNavigation } from './Section';

interface IConfirmationProps {
	application: IApplication;
	template: ITemplateSimple;
	schemas: any;
	prevCallback: any;
	setCurrentSection: (destination: string) => void;
}

interface IEditButtonProps {
	title: string;
	onClick: () => any;
}

interface IViewButtonProps {
	title: string;
	image: any;
	onPreviewClick: (image: any) => void;
}

interface IConfirmationDataTableProps {
	application: IApplication;
	templateMetadata?: any;
	files: any[];
	schemas: { [key: string]: any };
	isMember: boolean;
	setCurrentSection: (destination: string) => void;
	onEditClick?: () => void;
	onPreviewOpenClick: (image: any) => void;
}

const useStyles = makeStyles((_: Theme) =>
	createStyles({
		bold: {
			fontWeight: 600,
		},
		bottomSpace: {
			marginBottom: '36px',
		},
		buttonRoot: {
			minWidth: 'initial',
			padding: '0 10px',
		},
		hiddenOnMobile: {
			'@media (max-width: 600px)': {
				display: 'none',
			},
			display: 'table-cell',
		},
		iconCell: {
			lineHeight: 0,
		},
		mobile: {
			'@media (max-width: 600px)': {
				display: 'block',
			},
			display: 'none',
		},
		mobileBold: {
			'@media (max-width: 600px)': {
				fontWeight: 'bold',
			},
			fontWeight: 400,
		},
		narrowCell: {
			boxSizing: 'border-box',
			width: '48px',
		},
		overflowProtection: {
			maxWidth: '180px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		subtitle: {
			color: tertiary,
			marginBottom: '36px',
			fontSize: '16px',
		},
		thankYouTitle: {
			marginBottom: '36px',
		},
		title: {
			marginBottom: '7px',
		},
		warningDiv: {
			alignItems: 'center',
			backgroundColor: '#e9e9f3',
			borderRadius: '5px',
			color: 'red',
			display: 'flex',
			height: '38px',
			justifyContent: 'center',
			marginTop: '20px',
			width: '100%',
		},
		wrap: {
			whiteSpace: 'break-spaces',
		},
	})
);

const EditButton = (props: IEditButtonProps) => {
	const classes = useStyles();
	return (
		<Tooltip title={props.title}>
			<Button
				disabled={false}
				onClick={props.onClick}
				classes={{ root: classes.buttonRoot }}
			>
				<EditIcon />
			</Button>
		</Tooltip>
	);
};

const ViewButton = (props: IViewButtonProps) => {
	const classes = useStyles();
	return (
		<Tooltip title={props.title}>
			<Button
				disabled={false}
				onClick={() => props.onPreviewClick(props.image)}
				classes={{ root: classes.buttonRoot }}
			>
				<VisibilityIcon />
			</Button>
		</Tooltip>
	);
};

const ConfirmationTableHeader = (props: { type: string }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<TableHead>
			<TableRow>
				<TableCell className={classes.narrowCell} />
				<TableCell>
					<Typography
						variant="body2"
						color="textPrimary"
						className={classes.bold}
					>
						{t(`confirmation.tables.${props.type}`)}
					</Typography>
				</TableCell>
				<TableCell className={classes.hiddenOnMobile}>
					<Typography
						variant="body2"
						color="textPrimary"
						className={classes.bold}
					>
						{t('confirmation.tables.file')}
					</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography
						variant="body2"
						color="textPrimary"
						className={classes.bold}
					>
						{t('confirmation.tables.actions')}
					</Typography>
				</TableCell>
			</TableRow>
		</TableHead>
	);
};

const ConfirmationDataTable = (props: IConfirmationDataTableProps) => {
	const reduceHelper = (res: any, key: string) => {
		const attr = (
			props.application.attributes as { [key: string]: IAttribute }
		)[key];
		const schema = props.schemas[attr.schemaId];
		res[key] = {
			...schema,
			...attr,
		};
		return res;
	};

	const classes = useStyles();
	const { t } = useTranslation();

	const unsortedUploads: { [key: string]: IAttribute } = Object.keys(
		props.application.attributes
	)
		.filter((key) => {
			return (
				attributeHasUploads(
					props?.schemas[
						(props.application.attributes as { [key: string]: IAttribute })[key]
							?.schemaId
					]
				) &&
				// do not display uploads which are fetch from IDScan dynamically
				!(props.application.attributes as { [key: string]: IAttribute })[key]
					.idVerification.dynamic
			);
		})
		.reduce(reduceHelper, {});

	const sortedApplicationUploadsByWeight: {
		[key: string]: IAttribute;
	} = {};

	Object.keys(unsortedUploads)
		.sort((a, b) => unsortedUploads[a].weight - unsortedUploads[b].weight)
		.forEach((key) => {
			sortedApplicationUploadsByWeight[key] = unsortedUploads[key];
		});

	const forms = props.templateMetadata
		? ((props.application.forms as IFormSubmission[]).filter(
				(f) =>
					!formIsTermsForm(
						props.templateMetadata,
						(f.form as IKycDocumentTemplate)._id as string
					)
		  ) as IFormSubmission[])
		: (props.application.forms as IFormSubmission[]);

	const unsortedQuestions: { [key: string]: IQuestion | IAttribute } = {
		...Object.keys(props.application.attributes)
			.filter(
				(key) =>
					!attributeHasUploads(
						props.schemas[
							(props.application.attributes as { [key: string]: IAttribute })[
								key
							].schemaId
						]
					)
			)
			.reduce(reduceHelper, {}),
		...props.application.questions,
	};

	const sortedApplicationQuestionsByWeight: {
		[key: string]: IQuestion | IAttribute;
	} = {};

	Object.keys(unsortedQuestions)
		.sort((a, b) => unsortedQuestions[a].weight - unsortedQuestions[b].weight)
		.forEach((key) => {
			sortedApplicationQuestionsByWeight[key] = unsortedQuestions[key];
		});

	// return true if a property is required but doesn't have a value
	const isMissingRequirement = (property: any) => {
		if (!property) {
			return false;
		}
		return (
			!property.valid ||
			(!property.optional &&
				(property.value === null ||
					property.value.length === 0 ||
					typeof property.value === 'undefined'))
		);
	};

	return (
		<>
			{typeof props.onEditClick === 'function' &&
				isApplicationEditable(props.application) && (
					<>
						<EditButton
							onClick={() => props.onEditClick()}
							title={t('confirmation.title')}
						/>
					</>
				)}
			{Object.keys(sortedApplicationUploadsByWeight).length > 0 && (
				<>
					<Typography align="left" variant="h2" gutterBottom>
						{t('confirmation.documentsTitle')}
					</Typography>
					<TableContainer className={classes.bottomSpace}>
						<Table aria-label="simple table">
							<ConfirmationTableHeader type={'document'} />
							<TableBody>
								{Object.keys(sortedApplicationUploadsByWeight).map((key) => {
									const u = sortedApplicationUploadsByWeight[key];
									const normalized = normalizeAttributeValue(u.value);
									const confirmationFiles = normalized
										? normalized.map((n) => {
												const file = props.files.find(
													(f: any) => f._id === n.content
												);
												return {
													...n,
													...file,
												};
										  })
										: [];
									return (
										<TableRow key={key}>
											<TableCell
												classes={{ body: classes.iconCell }}
												className={classes.narrowCell}
											>
												{isMissingRequirement(u) ? (
													<Tooltip title="Required">
														<WarningIcon style={{ fill: '#FF3500' }} />
													</Tooltip>
												) : u.value ? (
													<DoneIcon style={{ color: accent }} />
												) : null}
											</TableCell>
											<TableCell component={'th' as any} scope="row">
												<Typography
													variant="body2"
													color="secondary"
													className={classes.mobileBold}
												>
													{u.label}
												</Typography>
												{confirmationFiles.length ? (
													confirmationFiles.map((file) => {
														return (
															<Typography
																key={file.content}
																variant="body2"
																className={`${classes.overflowProtection} ${classes.mobile}`}
																title={file.fileNameToShow}
															>
																<ViewButton
																	onPreviewClick={() =>
																		props.onPreviewOpenClick(file)
																	}
																	image={file}
																	title={file.fileNameToShow}
																/>
																{file.fileNameToShow}
															</Typography>
														);
													})
												) : (
													<Typography
														variant="body2"
														color="error"
														className={classes.mobile}
													>
														{t('confirmation.uploadMissing')}
													</Typography>
												)}
											</TableCell>
											<TableCell className={classes.hiddenOnMobile}>
												{confirmationFiles.length ? (
													confirmationFiles.map((file) => {
														return (
															<Typography
																key={file.content}
																variant="body2"
																className={classes.overflowProtection}
																title={file.fileNameToShow}
															>
																<ViewButton
																	onPreviewClick={() =>
																		props.onPreviewOpenClick(file)
																	}
																	image={file}
																	title={file.fileNameToShow}
																/>
																{file.fileNameToShow}
															</Typography>
														);
													})
												) : (
													<Typography variant="body2" color="error">
														{t('confirmation.uploadMissing')}
													</Typography>
												)}
											</TableCell>
											<TableCell align="right" style={{ paddingRight: '5px' }}>
												{!props.isMember && (
													<EditButton
														onClick={() => props.setCurrentSection('documents')}
														title={t('confirmation.title')}
													/>
												)}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
			{forms.length > 0 && (
				<>
					<Typography align="left" variant="h2" gutterBottom>
						{t('confirmation.formsTitle')}
					</Typography>
					<TableContainer className={classes.bottomSpace}>
						<Table aria-label="simple table">
							<ConfirmationTableHeader type={'form'} />
							<TableBody>
								{forms.map((form) => {
									return (
										<TableRow key={form._id as string}>
											<TableCell
												classes={{ body: classes.iconCell }}
												className={classes.narrowCell}
											>
												{!form.document && !form.optional ? (
													<Tooltip title="Required">
														<WarningIcon style={{ fill: '#FF3500' }} />
													</Tooltip>
												) : form.document ? (
													<DoneIcon style={{ color: accent }} />
												) : null}
											</TableCell>
											<TableCell component={'th' as any} scope="row">
												<Typography
													variant="body2"
													color="secondary"
													className={classes.mobileBold}
												>
													{(form.form as IKycDocumentTemplate).name}
												</Typography>
												{form.document ? (
													<Typography
														variant="body2"
														className={`${classes.overflowProtection} ${classes.mobile}`}
														title={
															(form.document as IFileSchema).fileNameToShow
														}
													>
														{(form.document as IFileSchema).fileNameToShow}
													</Typography>
												) : (
													<Typography
														variant="body2"
														color="error"
														className={classes.mobile}
													>
														{t('confirmation.formMissing')}
													</Typography>
												)}
											</TableCell>
											<TableCell className={classes.hiddenOnMobile}>
												{form.document ? (
													<Typography
														variant="body2"
														className={classes.overflowProtection}
														title={
															(form.document as IFileSchema).fileNameToShow
														}
													>
														{(form.document as IFileSchema).fileNameToShow}
													</Typography>
												) : (
													<Typography variant="body2" color="error">
														{t('confirmation.formMissing')}
													</Typography>
												)}
											</TableCell>
											<TableCell align="right" style={{ paddingRight: '5px' }}>
												{form.document && (
													<ViewButton
														onPreviewClick={() =>
															props.onPreviewOpenClick({
																content: (form.document as IFileSchema)._id,
																mimeType: (form.document as IFileSchema)
																	.contentType,
																...(form.document as IFileSchema),
															})
														}
														image={{
															content: (form.document as IFileSchema)._id,
															mimeType: (form.document as IFileSchema)
																.contentType,
															...(form.document as IFileSchema),
														}}
														title={t('confirmation.view')}
													/>
												)}
												<EditButton
													onClick={() => props.setCurrentSection('documents')}
													title={t('confirmation.title')}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
			{Object.keys(sortedApplicationQuestionsByWeight).length > 0 && (
				<>
					<Typography align="left" variant="h2" gutterBottom>
						{t('confirmation.questionsTitle')}
					</Typography>
					<TableContainer>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell className={classes.narrowCell} />
									<TableCell>
										<Typography
											variant="body2"
											color="textPrimary"
											className={classes.bold}
										>
											{t('confirmation.tables.question')}
										</Typography>
									</TableCell>
									<TableCell className={classes.hiddenOnMobile}>
										<Typography
											variant="body2"
											color="textPrimary"
											className={classes.bold}
										>
											{t('confirmation.tables.answer')}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography
											variant="body2"
											color="textPrimary"
											className={classes.bold}
										>
											{t('confirmation.tables.actions')}
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Object.keys(sortedApplicationQuestionsByWeight).map((key) => {
									const q = sortedApplicationQuestionsByWeight[key];
									const typographyPropsMobile = {
										className: `${classes.overflowProtection} ${classes.mobile}`,
										color: 'secondary',
										variant: 'body2',
									};
									const typographyPropsDesktop = {
										className: `${classes.overflowProtection}`,
										color: 'secondary',
										variant: 'body2',
									};
									const title = (q as IQuestion).jsonSchema
										? (q as IQuestion).jsonSchema.title
										: (q as IAttribute).label;

									const valueFormatterRequirement = {
										value: q.value,
										valid: q.valid,
										schemaId: q.schemaId,
										uiType: (q as IQuestion).uiType,
									};
									return (
										<TableRow key={key}>
											<TableCell
												classes={{ body: classes.iconCell }}
												className={classes.narrowCell}
											>
												{isMissingRequirement(q) ? (
													<Tooltip title="Required">
														<WarningIcon style={{ fill: '#FF3500' }} />
													</Tooltip>
												) : q.value ? (
													<DoneIcon style={{ color: accent }} />
												) : null}
											</TableCell>
											<TableCell
												component={'th' as any}
												scope="row"
												className={classes.wrap}
											>
												<Typography
													variant="body2"
													color="secondary"
													className={classes.mobileBold}
												>
													{t(title)}
												</Typography>
												<ValueFormatter
													typographyProps={typographyPropsMobile}
													requirement={valueFormatterRequirement}
													emptyRequirementText={t('confirmation.answerMissing')}
												/>
											</TableCell>
											<TableCell className={classes.hiddenOnMobile}>
												<ValueFormatter
													typographyProps={typographyPropsDesktop}
													requirement={valueFormatterRequirement}
													emptyRequirementText={t('confirmation.answerMissing')}
												/>
											</TableCell>
											<TableCell align="right">
												{!props.isMember && (
													<EditButton
														onClick={() =>
															props.setCurrentSection(
																DETAILS_PAGE_ATTRIBUTES.includes(
																	(q as IAttribute).$id
																)
																	? 'details'
																	: 'questions'
															)
														}
														title={t('confirmation.title')}
													/>
												)}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
		</>
	);
};

export const ConfirmationPage: React.FC<IConfirmationProps> = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [error, setError] = useState(null);
	const dispatch = useDispatch();

	const [memberSchemas, setMemberSchemas] = useState(null);
	const [loading, setLoading] = useState(false);
	const [initLoading, setInitLoading] = useState(true);
	const [editMember, setEditMember] = useState(null);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [currentPreviewImage, setCurrentPreviewImage] = useState(null);
	const [filesMap, setFilesMap] = useState(null);
	const onPreviewOpenClick = (image: any) => {
		setCurrentPreviewImage(image);
		setPreviewOpen(true);
	};

	// process member applications
	const applications = useSelector(
		(store: RootState) => store.applicationStore.applications
	);

	// process member applications
	const memberApplicationIds = props.application.members.map(
		(member) => member.application as string
	);
	const memberApplications = applications.filter(
		(a) =>
			memberApplicationIds.includes(a.id) &&
			a.id !== props.application.id &&
			!KycProcessClosedStatuses.includes(a.currentStatus)
	);

	const onDetailsClose = (updatedMemberApplication: IApplication) => {
		setEditMember(null);
		// update applications list
		if (updatedMemberApplication) {
			const newApplications = [...applications];
			const index = newApplications.findIndex(
				(a) => a.id === updatedMemberApplication.id
			);
			newApplications.splice(index, 1);
			newApplications.push(updatedMemberApplication);
			dispatch(receiveApplicationsList(newApplications));
		}
	};

	const refreshOnStatusError = (err: AxiosError) => {
		// if the API throws a status error, then re-load the application
		if (isStatusError(err)) {
			dispatch(loadCurrentApplication());
			return;
		}
	};

	const submitApplications = async () => {
		setLoading(true);
		await submitApplication(props.application.id, null)
			.then((updatedApplication: IApplication) => {
				dispatch(setCurrentApplicationData(updatedApplication));
				setLoading(false);
			})
			.catch((err) => {
				refreshOnStatusError(err);
				const e = err.response ?? err;
				const status = err?.response?.status;
				const errorData = err?.response?.data;
				const ex = err?.response ? new Error('submitApplicationError') : err;
				captureException(ex, {
					contexts: {
						error: e,
						function: {
							name: 'submitApplication',
						},
					},
					tags: {
						section: 'confirmation',
					},
				});
				setLoading(false);
				if (status === 422) {
					if (errorData.error === 'INVALID_INPUT_ERROR') {
						// check if something was unfinished
						if (errorData.details.includes('unfinished')) {
							const splitted = errorData.details.split('unfinished:')[1];
							const applicationIds = splitted.split(',');
							if (!applicationIds.includes(props.application.id)) {
								setError(`errors.submit-422-unfinished-members`);
							} else if (
								applicationIds.includes(props.application.id) &&
								applicationIds.length > 1
							) {
								setError(`errors.submit-422-unfinished-with-members`);
							} else {
								setError(`errors.submit-422-unfinished`);
							}
							return;
						}
					} else if (errorData.error === 'UNMET_CONDITION_ERROR') {
						setError(`errors.submit-422-${errorData.details}`);
						return;
					} else if (errorData.error === 'INSUFFICIENT_PERMISSION_ERROR') {
						setError(`errors.submit-422-not-editable`);
						return;
					}
				} else if (
					status === 403 &&
					errorData.error === 'INSUFFICIENT_PERMISSION_ERROR'
				) {
					setError(`errors.submit-403-not-editable`);
					return;
				} else if (status === 500) {
					setError('errors.submit-500');
					return;
				}

				setError('errors.submit-other');
			});
	};

	// check requirements
	const isAllRequirementsFilled = allRequirementsFulfilled(props.application);
	const allMemberApplicationsFilled =
		memberApplications.length > 0
			? !memberApplications.some((a) => !allRequirementsFulfilled(a))
			: true;

	// load schemas for member applications
	useEffect(() => {
		Promise.all(
			memberApplications.map((memberApplication: IApplication) =>
				loadSchemas(memberApplication)
			)
		).then((results) => {
			let result = {};
			results.forEach((r) => {
				result = { ...result, ...r };
			});
			setMemberSchemas(result);
		});
	}, []);

	useEffect(() => {
		Promise.all(
			[props.application, ...memberApplications].map(
				(memberApplication: IApplication) =>
					// get files associated with this application in order to display file names
					getAssociatedFiles(memberApplication.id)
						.then((result) => {
							return {
								applicationId: memberApplication.id,
								files: result,
							};
						})
						.catch((err) => {
							const e = err && err.response ? err.response : err;
							const ex =
								err && err.response
									? new Error('getAssociatedFilesError')
									: err;
							captureException(ex, {
								contexts: {
									error: e,
									function: {
										name: 'getAssociatedFiles',
									},
								},
								tags: {
									section: 'confirmation',
								},
							});
							setError('errors.load-other');
						})
			)
		).then((result) => {
			const resultMap = result.reduce((map: any, obj: any) => {
				map[obj.applicationId] = obj.files;
				return map;
			}, {});
			setFilesMap(resultMap);
			if (memberSchemas) {
				setInitLoading(false);
			}
		});
	}, [memberSchemas]);

	if (initLoading) {
		return <LinearProgress />;
	}

	return (
		<CustomerPortalTheme>
			{error && (
				<Typography gutterBottom={true} variant="subtitle1" color="error">
					{t(error)}
				</Typography>
			)}
			<>
				<PreviewDialogV2
					closeFunc={() => setPreviewOpen(false)}
					isOpen={previewOpen}
					file={currentPreviewImage}
					canDownload={true}
				/>
				<MemberDataFillForm
					applicationId={editMember}
					onClose={onDetailsClose}
					open={editMember ? true : false}
				/>
				<Typography align="left" variant="h1" className={classes.title}>
					{t('confirmation.title')}
				</Typography>
				<Typography
					align="left"
					variant="subtitle1"
					className={classes.subtitle}
				>
					{t('confirmation.subtitle')}
				</Typography>
				<ConfirmationDataTable
					application={props.application}
					files={filesMap[props.application.id]}
					templateMetadata={props.template.metadata}
					isMember={false}
					schemas={props.schemas}
					setCurrentSection={props.setCurrentSection}
					onPreviewOpenClick={onPreviewOpenClick}
				/>
				{memberApplications.map((memberApplication) => {
					return (
						<div key={memberApplication.id}>
							<Typography align="left" variant="h1" className={classes.title}>
								{t('confirmation.members', {
									name: fullNameFromApplication(memberApplication),
								})}
							</Typography>
							<ConfirmationDataTable
								application={memberApplication}
								files={filesMap[memberApplication.id]}
								isMember={true}
								schemas={memberSchemas}
								setCurrentSection={props.setCurrentSection}
								onPreviewOpenClick={onPreviewOpenClick}
								onEditClick={() => {
									setEditMember(memberApplication.id);
								}}
							/>
						</div>
					);
				})}
				{!isAllRequirementsFilled || !allMemberApplicationsFilled ? (
					<div className={classes.warningDiv}>
						<WarningIcon
							style={{
								fill: '#FF3500',
								marginRight: '10px',
								marginTop: '-2px',
							}}
						/>
						<Typography variant="subtitle1" color="error">
							{t('confirmation.detailsMissing')}
						</Typography>
					</div>
				) : null}

				<SectionNavigation
					loading={loading}
					prevCallback={props.prevCallback}
					nextCallback={submitApplications}
					nextButtonProps={{
						disabled:
							loading ||
							!isAllRequirementsFilled ||
							!allMemberApplicationsFilled,
					}}
					nextButtonText={'sections.submit'}
					position="center"
				/>
			</>
		</CustomerPortalTheme>
	);
};
