import * as React from 'react';

import CheckField from './check-field';
import RadioField from './radio-field';
import SelectField, { ISelectFieldOption } from './select-field';
import SignatureField from './signature-field';
import TextField from './text-field';

import DateField from './date-field';

export interface IDynamicFieldProps {
	id: string;
	page: number;
	locationX: number;
	locationY: number;
	scale?: number;
	height: number;
	width: number;
	type: any;
	value?: any;
	disabled?: boolean;
	groupName?: string;
	onDataChange?: any;
	options?: ISelectFieldOption[];
}

interface IDynamicFieldState {
	style: any;
	locationY: number;
	locationX: number;
	width: number;
	height: number;
}

class DynamicField extends React.Component<
	IDynamicFieldProps,
	IDynamicFieldState
> {
	constructor(props: IDynamicFieldProps) {
		super(props);
		this.state = {
			height: null,
			locationX: null,
			locationY: null,
			style: {},
			width: null,
		};
	}

	public render() {
		const style = {
			display: 'block',
			fontSize: this.props.height * this.props.scale + 'px',
			height: this.props.height * this.props.scale + 'px',
			left: this.props.locationX * this.props.scale + 'px',
			// compatibilty with CSSProperties
			position: 'absolute' as 'absolute',
			top: this.props.locationY * this.props.scale + 'px',
			width: this.props.width * this.props.scale + 'px',
		};
		let field = null;

		switch (this.props.type) {
			case 'signature':
				field = (
					<SignatureField
						id={this.props.id}
						style={style}
						type="signature"
						value={this.props.value}
						signatureHeight={this.props.height}
						signatureWidth={this.props.width}
						scale={this.props.scale}
						disabled={this.props.disabled}
						onDataChange={this.props.onDataChange}
					/>
				);
				break;
			/*case "stamp":
                field = <SignatureField style={style}
                                        $mdDialog={this.props.$mdDialog}
                                        Commons={this.props.Commons}/>
                break;*/
			case 'text':
				field = (
					<TextField
						id={this.props.id}
						style={style}
						value={this.props.value}
						disabled={this.props.disabled}
						onDataChange={this.props.onDataChange}
					/>
				);
				break;
			case 'select':
				field = (
					<SelectField
						id={this.props.id}
						style={style}
						options={this.props.options}
						value={this.props.value}
						disabled={this.props.disabled}
						onDataChange={this.props.onDataChange}
					/>
				);
				break;

			case 'radio':
				field = (
					<RadioField
						id={this.props.id}
						style={style}
						value={this.props.value}
						groupName={this.props.groupName}
						height={this.props.height * this.props.scale}
						disabled={this.props.disabled}
						onDataChange={this.props.onDataChange}
					/>
				);

				break;
			case 'checkbox':
				field = (
					<CheckField
						id={this.props.id}
						style={style}
						value={this.props.value}
						height={this.props.height * this.props.scale}
						disabled={this.props.disabled}
						onDataChange={this.props.onDataChange}
					/>
				);

				break;
			case 'date':
				field = (
					<DateField
						id={this.props.id}
						style={style}
						value={this.props.value}
						disabled={this.props.disabled}
						onDataChange={this.props.onDataChange}
					/>
				);
				break;

			default:
				field = (
					<div style={{ ...style, fontSize: undefined, border: '1px solid' }}>
						You need to define type
					</div>
				);
		}

		return field;
	}
}

export default DynamicField;
