import { makeStyles } from '@material-ui/core/styles';

import { error, tertiary } from '../../../../../../ui/src/colors';

export const useStyles = makeStyles({
	documentsTable: {
		border: '2px solid #B9C2CC',
		borderRadius: '4px',
		boxSizing: 'border-box',
		padding: '20px',
	},
	errorColor: {
		color: error,
	},
	fileArea: {
		'@media (max-width: 600px)': {
			flexDirection: 'column',
		},
		display: 'flex',
		flexFlow: 'row wrap',
	},
	fileItem: {
		'@media (max-width: 600px)': {
			marginBottom: '10px',
			marginTop: '5px',
			width: '100%',
		},
		alignItems: 'center',
		backgroundColor: '#EDEDF4',
		borderRadius: '4px',
		boxSizing: 'border-box',
		display: 'flex',
		height: '37px',
		justifyContent: 'space-between',
		marginBottom: '10px',
		marginRight: '10px',
		padding: '8px 0 8px 12px',
		width: '266px',
	},
	semibold: {
		fontWeight: 500,
	},
	tableHeader: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	tableHeaderIcons: {
		'@media (max-width: 600px)': {
			minWidth: '100px',
		},
		alignItems: 'flex-start',
		display: 'flex',
		justifyContent: 'flex-end',
		minWidth: '250px',
	},
	tableWrap: {
		'& .documents-table-header': {
			marginBottom: '5px',
		},
		marginBottom: '20px',
	},
	tertiaryColor: {
		color: tertiary,
	},
});
