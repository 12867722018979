import { History } from 'history';
import { Dispatch } from 'react';

import { isStorageAvailable } from '../../../common-ts/src';
import { SESSION_ID } from '../constants';
import { userLogout } from '../redux/actions/user';
import { getMyUser } from '../services/api';

export const useCheckUserSession = async (
	history: History<unknown>,
	dispatch: Dispatch<any>
) => {
	if (isStorageAvailable(window.sessionStorage)) {
		// if getMyUser fails then interceptor will set the expired message to the UI
		const userResponse = await getMyUser();
		const { sessionId } = userResponse;
		const sessionIdFromStorage = sessionStorage.getItem(SESSION_ID);

		// same user was logging on the phone using the QR code
		if (sessionId !== sessionIdFromStorage) {
			dispatch(userLogout());
			history.push('/auth/login');
		}
	}
};
