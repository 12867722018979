import * as React from 'react';

import { Theme } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import SignatureDialog from '../../SignatureDialog/SignatureDialog';
import { default as BaseField, IBaseFieldProps } from './base-field';

interface ISignatureProps extends IBaseFieldProps, WithTranslation {
	signatureHeight: number;
	signatureWidth: number;
	scale: number;
	type: any;
	classes: any;
}

interface ISignatureState {
	signatureDialogOpen: boolean;
}

const styles = (theme: Theme) =>
	createStyles({
		disabled: {
			backgroundColor: '#ccc',
		},
		infoField: {
			backgroundColor: '#cccccc',
			fontSize: '10px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		mainContainer: {
			alignItems: 'center',
			backgroundColor: '#ffffff',
			border: '1px solid #b4bcc2',
			cursor: 'pointer',
			display: 'flex',
			flexDirection: 'column',
			fontSize: '20',
			fontWeight: 'bold',
			height: '100%',
			justifyContent: 'center',
			letterSpacing: '2px',
			textTransform: 'uppercase',
		},
	});

class SignatureField extends BaseField<ISignatureProps, ISignatureState> {
	constructor(props: ISignatureProps) {
		super(props);
		this.state = {
			signatureDialogOpen: false,
		};
	}

	public render() {
		const height = this.props.signatureHeight * this.props.scale;
		const width = this.props.signatureWidth * this.props.scale;
		// 7 is an approximate ratio that worked for the english word "signature"
		// we may need to rework this if we add more languages
		const fontSize = width / height < 7 ? width / 7 : height;

		const signature = this.props.value ? (
			<img src={this.props.value} style={{ width: '100%' }} />
		) : (
			<div style={{ fontSize }}>
				{this.props.t('onlineFill.signatureField.signature')}
			</div>
		);

		let signatureDialog = null;
		if (this.state.signatureDialogOpen) {
			signatureDialog = (
				<SignatureDialog
					proportion={this.props.signatureHeight / this.props.signatureWidth}
					isOpen={true}
					onSignatureReady={(value: string) => {
						this.props.onDataChange({ value });
					}}
					closeFunc={() => {
						this.setState({ signatureDialogOpen: false });
					}}
				/>
			);
		}

		return (
			<div style={this.props.style}>
				<div
					onClick={() => {
						this.openDrawer();
					}}
					className={this.props.classes.mainContainer}
				>
					{signature}
				</div>
				{signatureDialog}
			</div>
		);
	}

	public openDrawer = () => {
		// if the field is not disabled open the window
		if (!this.props.disabled) {
			this.setState({ signatureDialogOpen: true });
		}
	};
}

export default withStyles(styles)(withTranslation()(SignatureField));
