export type Modify<T, R> = Omit<T, keyof R> & R;

export const isInIFrame = (): boolean => {
	return window.name === 'Kyc-Chain-iframe';
};

export const isLivenessIFrame = (): boolean => {
	return (window as any).LIVENESS_FLOW ? true : false;
};

export const isStorageAvailable = (
	storage: typeof window.sessionStorage | typeof window.localStorage
) => {
	try {
		if (storage) {
			storage.setItem('test', 'test');
			storage.removeItem('test');
			return true;
		}
	} catch (e) {
		return false;
	}
	return false;
};
