import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { FilePreviewV2 } from './file-preview-v2';

const useStyles = makeStyles({
	closeIcon: {
		'& svg': {
			height: '30px',
			width: '30px',
		},
		'@media (max-width: 600px)': {
			marginRight: '-20px',
			marginTop: '-40px',
		},
		marginRight: '-60px',
		marginTop: '-90px',
		position: 'relative',
	},
	dialogHeader: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '30px',
	},
	previewContent: {
		textAlign: 'center',
	},
});

export interface IPreviewProps {
	file: {
		content: string;
		mimeType: string;
	};
	canDownload: boolean;
	isOpen: boolean;
	closeFunc: () => void; // function to be called when dialog closes
}

export const PreviewDialogV2: React.FC<IPreviewProps> = (props) => {
	const classes = useStyles();
	if (!props.file) {
		return null;
	}
	return (
		<Dialog
			disableEnforceFocus={true}
			open={props.isOpen}
			aria-labelledby="form-dialog-title"
			fullWidth={true}
			maxWidth="lg"
			onClose={props.closeFunc}
		>
			<div className={classes.dialogHeader}>
				<Typography id="form-dialog-title" align="left" variant="h1">
					Preview
				</Typography>
				<IconButton
					color="inherit"
					onClick={props.closeFunc}
					className={classes.closeIcon}
				>
					<CloseIcon />
				</IconButton>
			</div>
			<DialogContent className={classes.previewContent}>
				<FilePreviewV2
					content={props.file.content}
					mimeType={props.file.mimeType}
					canDownload={props.canDownload}
				/>
			</DialogContent>
		</Dialog>
	);
};
