import { withTheme } from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/material-ui';
import React from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import {
	IApplication,
	IApplicationType,
} from '../../../../../common-ts/src/interface/application';
import { ILegalEntityType } from '../../../../../common-ts/src/interface/template';
import { DefaultInputTemplate } from '../../../../../common/src/react/AddMemberDialog/DefaultInputTemplate';
import { CustomCheckboxesWidget } from '../../../../../common/src/react/CustomFields/CustomCheckboxesWidget';
import { FieldTemplate } from '../../../../../common/src/react/CustomFields/CustomFieldTemplate';
import LoadingButton from '../../../../../common/src/react/LoadingButton';
import { ApplicationSelect } from '../../ApplicationSelect';
import { useStyles } from './LinkExistingApplicationModal.style';

export interface Props {
	selected: IApplication;
	applications: IApplication[];
	isOpen: boolean;
	legalEntity: ILegalEntityType;
	applicationType: IApplicationType;
	onMemberLinkedSubmit: (
		selected: { id: string },
		applicationId: string,
		values: {
			shares?: number;
			roles?: string[];
		}
	) => Promise<void>;
	onClose: () => void;
}

interface GetRequirementsOptions {
	legalEntity: ILegalEntityType;
	applicationType: IApplicationType;
	askForMemberOwnership: boolean;
	t: UseTranslationResponse[0];
}

const LinkExistingApplicationForm = withTheme(MuiTheme);

const widgets = {
	CheckboxesWidget: CustomCheckboxesWidget,
	TextWidget: DefaultInputTemplate,
};

const getRequirements = ({
	legalEntity,
	askForMemberOwnership,
	applicationType,
	t,
}: GetRequirementsOptions) => {
	return {
		properties: {
			...(askForMemberOwnership &&
				applicationType === IApplicationType.CORPORATE && {
					shares: {
						min: 0,
						title: t('membersDialog.shares'),
						type: 'number',
					},
				}),
			roles: {
				items: {
					enum: legalEntity.memberRoles,
					enumNames: legalEntity.memberRoles.map((r) => t(`roles.${r}`)),
					type: 'string',
				},
				title: t('membersDialog.positions'),
				type: 'array',
				uniqueItems: true,
			},
		},
	};
};

export const LinkExistingApplicationModal: React.FC<Props> = ({
	selected,
	applicationType,
	legalEntity,
	applications,
	isOpen,
	onClose,
	onMemberLinkedSubmit,
}) => {
	const { t } = useTranslation();
	const styles = useStyles();
	const [applicationId, setApplicationId] = React.useState<string>(
		applications[0]?.id ?? ''
	);
	const [values, setValues] =
		React.useState<{ shares?: number; roles?: string[] }>();
	const [loading, setLoading] = React.useState(false);

	const requirements = getRequirements({
		legalEntity,
		applicationType,
		askForMemberOwnership: selected.options.askForMemberOwnership,
		t,
	});

	const handleChange = (elem: any) => {
		setValues(elem.formData);
	};

	return (
		<Dialog
			fullWidth
			{...{ open: isOpen, onClose }}
			aria-labelledby="add-member"
		>
			<div className={styles.iconWrap}>
				<IconButton
					color="inherit"
					onClick={onClose}
					className={styles.closeIcon}
				>
					<CloseIcon />
				</IconButton>
			</div>

			<Typography variant="h1" color="textPrimary" className={styles.title}>
				Link existing application
			</Typography>

			<DialogContent classes={{ root: styles.content }}>
				<label
					htmlFor="link-application-select"
					className={styles.applicationsLabel}
				>
					Link to
				</label>

				<ApplicationSelect
					id="link-application-select"
					items={applications}
					value={applicationId}
					onChange={setApplicationId}
					fullWidth
				/>

				<LinkExistingApplicationForm
					className="attributes-form"
					FieldTemplate={FieldTemplate}
					schema={requirements as any}
					uiSchema={{
						roles: {
							'ui:widget': 'checkboxes',
						},
						'ui:order': ['shares', 'roles'],
					}}
					onSubmit={() => {
						setLoading(true);
						onMemberLinkedSubmit(selected, applicationId, values)
							.then(() => {
								setLoading(false);
								onClose();
							})
							.catch(() => {
								setLoading(false);
							});
					}}
					onChange={handleChange}
					formData={values}
					showErrorList={false}
					noHtml5Validate={true}
					widgets={widgets}
				>
					<div className={styles.buttonsContainer}>
						<Button
							onClick={close}
							variant="contained"
							color="primary"
							disabled={loading}
							className={styles.cancelBtn}
						>
							{t('membersDialog.cancel')}
						</Button>

						<LoadingButton variant="contained" type="submit" {...{ loading }}>
							{t('membersDialog.save')}
						</LoadingButton>
					</div>
				</LinkExistingApplicationForm>
			</DialogContent>
		</Dialog>
	);
};
