import { IPasswordStrengthResult } from "./interface/user";

export const checkPasswordStrength = (password: string): IPasswordStrengthResult => {
	const inputStarted = password.length > 0;
	const passLength = password.length >= 8 || !inputStarted;
	const passCase =
		!(
			password.toUpperCase() === password || password.toLowerCase() === password
		) || !inputStarted;
	const passNumeric = /\d/.test(password) || !inputStarted;
	const passSpecial =
		/[`~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password) || !inputStarted;

	return {
		inputStarted,
		passCase,
		passLength,
		passNumeric,
		passSpecial
	};
};