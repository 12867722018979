import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';

import { triggerFileDownload } from '../../../../../../../common/src/helpers/triggerFileDownload';
import { downloadFile } from '../../../../../../../common/src/models/files';
import { PreviewDialogV2 } from '../../../../../../../common/src/react/Preview/preview-v2';
import CustomerPortalTheme from '../../../../../../../ui/src/theme/kyc-default-theme';
import { useStyles } from './FileItem.style';

export interface Props {
	file: any;
	name: string;
}

export const FileItem: React.FC<Props> = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [previewOpen, setPreviewOpen] = React.useState(false);
	const classes = useStyles();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const download = () => {
		downloadFile(props.file.content).then((res) => {
			triggerFileDownload(res, props.file.mimeType, props.name);
		});
	};

	const openPreview = () => {
		setPreviewOpen(true);
	};

	return (
		<CustomerPortalTheme>
			<PreviewDialogV2
				closeFunc={() => setPreviewOpen(false)}
				isOpen={previewOpen}
				file={props.file}
				canDownload={true}
			/>

			<div className={classes.fileItemWrap}>
				<div className={classes.overflowProtection}>
					<Typography variant="body2" color="textPrimary">
						{props.name}
					</Typography>
				</div>

				<div>
					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						onClick={handleClick}
					>
						<MoreVertIcon />
					</IconButton>

					<Menu
						id={`menu-${name}`}
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem onClick={openPreview}>
							<VisibilityIcon className={classes.menuItemIcon} /> View File
						</MenuItem>

						<MenuItem onClick={download}>
							<GetAppIcon className={classes.menuItemIcon} /> Download
						</MenuItem>
					</Menu>
				</div>
			</div>
		</CustomerPortalTheme>
	);
};
