import React from 'react';

import { UploadDialogEditContext } from '../UploadDocumentDialog';

export interface Props {
	itemKey: string;
	schema: string;
	multiple: boolean;
	children?: any;
	fileNames: any[];
	required: boolean;
	error?: any;
}

export const BaseWidget: React.FC<Props> = (props) => {
	const [fileUploadContext, dispatch] = React.useContext(
		UploadDialogEditContext
	);

	const onChange = (file: File) => {
		let newFiles = [...fileUploadContext.files];
		if (!props.multiple) {
			// find previous file and remove
			newFiles = newFiles.filter((f: any) => f.itemKey !== props.itemKey);
		}

		newFiles.push({
			file,
			id: Math.random().toString(36).substring(7),
			itemKey: props.itemKey,
			schema: props.schema,
		});

		const newState = { ...fileUploadContext };
		newState.files = newFiles;
		dispatch(newState);
	};

	const handleDelete = (id: string) => {
		const newFiles = [...fileUploadContext.files].filter((f) => f.id !== id);
		const newState = { ...fileUploadContext };
		newState.files = newFiles;
		dispatch(newState);
	};

	// supports only one child
	return React.cloneElement(props.children, { onChange, handleDelete });
};
