import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomDatePicker from '../DatePicker';
import { UploadDialogEditContext } from '../UploadDocumentDialog';
import { useStyles } from './style';

export interface Props {
	itemKey: string;
	error: any;
	title: string;
	required: boolean;
}

export const DatePickerWidget: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [fileUploadContext, dispatch] = React.useContext(
		UploadDialogEditContext
	);

	const field = fileUploadContext.fields.find(
		(f: any) => f.itemKey === props.itemKey
	);
	const valueAsDate = field ? new Date(field.value) : null;
	const value = valueAsDate
		? new Date(valueAsDate.getTime() + valueAsDate.getTimezoneOffset() * 60000)
		: '';

	const hasError =
		props.error && props.error.keyword === 'required' ? true : false;
	const inputClass = hasError ? classes.errorStyle : classes.noErrorStyle;

	const onChange = (date: string) => {
		const newState = { ...fileUploadContext };
		const newFieldsArray = newState.fields.filter(
			(f: any) => f.itemKey !== props.itemKey
		);
		newFieldsArray.push({
			itemKey: props.itemKey,
			value: date,
		});
		newState.fields = newFieldsArray;

		dispatch(newState);
	};

	return (
		<FormControl margin="normal" fullWidth>
			<InputLabel shrink>
				{t(`widgetLabels.${props.title.toLowerCase()}`)}{' '}
				{props.required ? '*' : null}
			</InputLabel>

			<CustomDatePicker
				className={inputClass}
				dateFormat="yyyy-MM-dd"
				placeholderText={t('questions.select_date')}
				selected={value}
				onChange={(selectedDate: Date) => {
					const d = selectedDate
						? new Date(
								selectedDate.getTime() -
									selectedDate.getTimezoneOffset() * 60000
						  )
								.toISOString()
								.split('T')[0]
						: null;
					if (d) {
						onChange(d);
					}
				}}
			/>
			{hasError ? (
				<Grid item style={{ marginTop: '5px' }}>
					<Typography gutterBottom={true} variant="subtitle1" color="error">
						{t('errors.required')}
					</Typography>
				</Grid>
			) : null}
		</FormControl>
	);
};
