import $RefParser from '@apidevtools/json-schema-ref-parser';
import React from 'react';

import { KycStatuses } from '../../../../common-ts/src/application/constants';
import { findIndexOfStatus } from '../../../../common-ts/src/application/statusLog';
import { formIsTermsForm } from '../../../../common-ts/src/forms';
import { AISCANFLOWTYPES } from '../../../../common-ts/src/interface/aiscan';
import {
	IApplication,
	IAttribute,
	IQuestion,
} from '../../../../common-ts/src/interface/application';
import { GetApplicationIDVerificationTokenResponse } from '../../../../common-ts/src/interface/application-id-verification-token';
import { IRepositoryEntry } from '../../../../common-ts/src/interface/datazoo';
import {
	IFormSubmission,
	IKycDocumentTemplate,
} from '../../../../common-ts/src/interface/form';
import { IDSCANFLOWSTRICTNESS } from '../../../../common-ts/src/interface/idScan';
import { EXTERNAL_VENDORS } from '../../../../common-ts/src/interface/vendors';
import { attributeHasUploads } from '../../../../common/src/helpers/attribute-has-uploads';
import {
	IAttributeSchema,
	getApplicationAttributeSchemaBulk,
} from '../../../../common/src/helpers/attributes';
import { reducer } from '../../../../common/src/helpers/attributes-object-reducer';
import { IAttributeRequirement } from '../../../../common/src/models/attribute-requirement';
import { DETAILS_PAGE_ATTRIBUTES } from '../../constants';
import { getSchemaById } from '../../helpers';
import { RequirementInputs } from '../components/CustomFields/Questions';
import { FileUploads } from '../components/CustomFields/Uploads';
import { ConfirmationPage } from '../components/KYCLayout/Confirmation';
import { AIScanCapture } from '../components/KYCLayout/IDScan/AIScanCapture';
import { IDDocVerStatusPage } from '../components/KYCLayout/IDScan/IDDocVerStatusPage';
import { TermsPage } from '../components/KYCLayout/TermsPage';
import { WelcomePage } from '../components/KYCLayout/WelcomePage';
import { MembersTable } from '../components/MembersTable/MembersTable';
import { SharesRegistry } from '../components/MembersTable/SharesRegistry';

export enum SectionType {
	Welcome = 'welcome',
	Terms = 'terms',
	IDDocVerification = 'iddocverification',
	Details = 'details',
	Questions = 'questions',
	Documents = 'documents',
	SharesRegistry = 'shares_registry',
	Members = 'members',
	Confirmation = 'confirmation',
}

export const loadSchemas = (application: IApplication) => {
	// load attribute schemas
	const attributeIds = Object.keys(application.attributes)
		.filter(
			(key) =>
				(application.attributes as { [key: string]: IAttribute })[key].schemaId
		)
		.map(
			(key) =>
				(application.attributes as { [key: string]: IAttribute })[key].schemaId
		);

	return getApplicationAttributeSchemaBulk(attributeIds, application.id);
};

export const getDetailsAttribute = (
	application: IApplication,
	schemas: { [key: string]: IAttributeSchema }
) => {
	const reduceHelper = reducer(application, schemas);

	const detailAttributes: any = {
		properties: Object.keys(application.attributes)
			.filter((key) =>
				DETAILS_PAGE_ATTRIBUTES.includes(
					(application.attributes as { [key: string]: IAttribute })[key]
						.schemaId
				)
			)
			.reduce(reduceHelper, {}),
		required: [],
	};

	// all of these are required except middle name
	detailAttributes.required = Object.keys(detailAttributes.properties).filter(
		(key) => !detailAttributes.properties[key].optional
	);

	return { requirements: detailAttributes };
};

export const getQuestionRequierements = async (
	application: IApplication,
	schemas: { [key: string]: IAttributeSchema }
) => {
	const reduceHelper = reducer(application, schemas);

	const attributeProperties = Object.keys(application.attributes)
		.filter(
			(key) =>
				getSchemaById(
					schemas,
					(application.attributes as { [key: string]: IAttribute })[key]
						.schemaId
				) &&
				!attributeHasUploads(
					getSchemaById(
						schemas,
						(application.attributes as { [key: string]: IAttribute })[key]
							.schemaId
					)
				) &&
				!DETAILS_PAGE_ATTRIBUTES.includes(
					(application.attributes as { [key: string]: IAttribute })[key]
						.schemaId
				)
		)
		.reduce(reduceHelper, {});

	const questionProperties: any = {};

	for (const key of Object.keys(application.questions)) {
		const question = (application.questions as { [key: string]: IQuestion })[
			key
		];
		const q: IQuestion = {
			isAdditional: question.isAdditional,
			...question.jsonSchema,
			optional: question.optional,
			valid: question.valid,
			value: question.value,
			weight: question.weight,
		};

		const isCountryQuestion = !!question.jsonSchema?.properties?.country?.$ref;

		// FIXME implement some kind of caching for $ref fields
		// Check for presence of $ref before dereferencing - dereferencing is expensive and only needed if $ref is present
		const schema =
			isCountryQuestion || (question.jsonSchema && question.jsonSchema.$ref)
				? await $RefParser.dereference(question.jsonSchema as any)
				: question.jsonSchema;

		questionProperties[key] = {
			...schema,
			...q,
		};

		// FIXME HACK
		// if there is only one property use the main title instead of field title
		// main title will be hidden in Questions.tsx
		// https://github.com/kyc-chain/kyc-chain-monorepo/issues/1385
		const propertyKeys = questionProperties[key].properties
			? Object.keys(questionProperties[key].properties)
			: [];
		if (propertyKeys.length === 1) {
			questionProperties[key].properties[propertyKeys[0]].title =
				questionProperties[key].label
					? questionProperties[key].label
					: schema.title;
		}
	}

	const uploadAttributes: IAttributeRequirement = {
		properties: Object.keys(application.attributes)
			.filter(
				(key) =>
					attributeHasUploads(
						getSchemaById(
							schemas,
							(application.attributes as { [key: string]: IAttribute })[key]
								.schemaId
						)
					) &&
					// do not display uploads which are fetch from IDScan dynamically
					!(application.attributes as { [key: string]: IAttribute })[key]
						.idVerification.dynamic
			)
			.reduce(reduceHelper, {}),
		required: [],
	};

	const questionPageRequirements: any = {
		properties: { ...attributeProperties, ...questionProperties },
		required: [],
	};

	[questionPageRequirements, uploadAttributes].forEach((list) => {
		// set required array
		list.required = Object.keys(list.properties)
			.filter((key) => !list.properties[key].optional)
			.map((key) => key);
	});

	// FIXME hack - Remove required from properties in UI side until we have KYC template overrides
	// in API side
	Object.keys(questionPageRequirements.properties)
		.filter((key) => questionPageRequirements.properties[key].optional)
		.map((key) => {
			questionPageRequirements.properties[key].required = [];
			return key;
		});

	return { requirements: questionPageRequirements };
};

export const getUploadRequirements = async (
	application: IApplication,
	schemas: { [key: string]: IAttributeSchema },
	template: { metadata: any }
) => {
	const reduceHelper = reducer(application, schemas);

	const uploadAttributes: IAttributeRequirement = {
		properties: Object.keys(application.attributes)
			.filter(
				(key) =>
					attributeHasUploads(
						getSchemaById(
							schemas,
							(application.attributes as { [key: string]: IAttribute })[key]
								.schemaId
						)
					) &&
					// do not display uploads which are fetch from IDScan dynamically
					!(application.attributes as { [key: string]: IAttribute })[key]
						.idVerification.dynamic
			)
			.reduce(reduceHelper, {}),
		required: [],
	};

	const forms = (application.forms as IFormSubmission[]).filter(
		(f) =>
			!formIsTermsForm(
				template.metadata,
				(f.form as IKycDocumentTemplate)._id as string
			)
	) as IFormSubmission[];

	return { requirements: uploadAttributes, forms };
};

export const getRequirementsForSection = (section: any) => {
	const forms = section.component?.props.forms ?? [];

	if (section.component?.props?.requirements?.properties) {
		return [
			...Object.values(section.component?.props?.requirements?.properties),
			...forms,
		];
	}

	return [];
};

export async function getSectionsForApplication(
	application: IApplication,
	template: any,
	schemas: { [key: string]: IAttributeSchema },
	idScanConfig: GetApplicationIDVerificationTokenResponse,
	datazooAttributesRepository: IRepositoryEntry[],
	isBaseApplication: boolean,
	nextSection: () => void,
	prevSection: () => void,
	hasPrevSection: () => boolean,
	setCurrentSection: (currentSection: string) => void
) {
	const { requirements: detailAttributes } = getDetailsAttribute(
		application,
		schemas
	);
	const { requirements: questionPageRequirements } =
		await getQuestionRequierements(application, schemas);
	const { requirements: uploadAttributes, forms } = await getUploadRequirements(
		application,
		schemas,
		template
	);

	const displayIdScanFlow =
		!!idScanConfig &&
		!idScanConfig.completed &&
		isBaseApplication &&
		application.options.idScanFlowTriesLimit > application.idScanFlowTries &&
		!(
			application.options.idDocVerVendor === EXTERNAL_VENDORS.AISCAN &&
			application.options.idScanFlowType === AISCANFLOWTYPES.api
		);

	// if idscan flow is set to be strict and user has not passed it
	// (unless the flow was re-opened afterwords)
	if (
		idScanConfig &&
		!idScanConfig.passed &&
		isBaseApplication &&
		!displayIdScanFlow &&
		application.options.idScanFlowStrictness === IDSCANFLOWSTRICTNESS.strict &&
		findIndexOfStatus(application.statusLog, KycStatuses.reopened) < 0
	) {
		return [
			{
				component: <IDDocVerStatusPage status="outOfTries" />,
				name: SectionType.IDDocVerification,
				title: 'sections.idDocVer',
			},
		];
	}

	const shouldRender = {
		confirmationSection: isBaseApplication,
		detailsSection: Object.keys(detailAttributes).length > 0,
		documentsSection:
			Object.keys(uploadAttributes.properties).length > 0 || forms.length > 0,
		idDocVerificationSection: displayIdScanFlow,
		membersSection:
			isBaseApplication &&
			application.options.membersEnabled &&
			application.members.filter((m) => !m.softDelete).length > 0,
		questionsSection:
			Object.keys(questionPageRequirements.properties).length > 0,
		registrySection: isBaseApplication && application.options.membersEnabled,
		termsSection:
			isBaseApplication &&
			template?.metadata.terms &&
			template?.metadata.termsActive,
		welcomeSection: isBaseApplication && template?.metadata.welcomeText,
	};

	// refresh sections
	// TODO later thense will be as template sections
	return [
		shouldRender.welcomeSection && {
			component: (
				<WelcomePage
					welcomeText={template.metadata.welcomeText}
					nextCallback={nextSection}
				/>
			),
			name: SectionType.Welcome,
			title: 'sections.welcome',
		},
		shouldRender.termsSection && {
			component: (
				<TermsPage
					termsDocumentId={template.metadata.terms}
					nextCallback={nextSection}
					prevCallback={template.metadata.welcomeText ? prevSection : null}
				/>
			),
			name: SectionType.Terms,
			title: 'sections.terms',
		},
		shouldRender.idDocVerificationSection && {
			component: (
				<AIScanCapture
					title="sections.idDocVer"
					nextCallback={nextSection}
					prevCallback={template.metadata.terms ? prevSection : null}
					token={idScanConfig.token}
					backendUrl={idScanConfig.backendUrl}
					flowStrictness={application.options.idScanFlowStrictness}
					flowTriesLimit={application.options.idScanFlowTriesLimit}
				/>
			),
			name: SectionType.IDDocVerification,
			title: 'sections.idDocVer',
		},
		shouldRender.detailsSection && {
			component: (
				<RequirementInputs
					title="sections.details"
					nextCallback={nextSection}
					prevCallback={prevSection}
					hasPrevSection={hasPrevSection}
					requirements={detailAttributes}
					isMemberApplication={!isBaseApplication}
				/>
			),
			name: SectionType.Details,
			title: 'sections.details',
		},
		shouldRender.questionsSection && {
			component: (
				<RequirementInputs
					nextCallback={nextSection}
					prevCallback={prevSection}
					hasPrevSection={hasPrevSection}
					requirements={questionPageRequirements}
					isMemberApplication={!isBaseApplication}
				/>
			),
			name: SectionType.Questions,
			title: 'sections.questions',
		},
		shouldRender.documentsSection && {
			component: (
				<FileUploads
					application={application}
					nextCallback={nextSection}
					prevCallback={prevSection}
					requirements={uploadAttributes}
					forms={forms}
					isMemberApplication={!isBaseApplication}
				/>
			),
			name: SectionType.Documents,
			title: 'sections.documents',
		},
		shouldRender.registrySection && {
			component: (
				<SharesRegistry
					application={application}
					memberConditions={template.properties.memberTemplates}
					nextCallback={nextSection}
					prevCallback={prevSection}
				/>
			),
			name: SectionType.SharesRegistry,
			title:
				application.type === 'corporate'
					? 'sections.shares_registry'
					: 'sections.members',
		},
		shouldRender.membersSection && {
			component: (
				<MembersTable
					application={application}
					datazooAttributesRepository={datazooAttributesRepository}
					nextCallback={nextSection}
					prevCallback={prevSection}
				/>
			),
			name: SectionType.Members,
			title:
				application.type === 'corporate'
					? 'sections.members'
					: 'sections.members_details',
		},
		shouldRender.confirmationSection && {
			component: (
				<ConfirmationPage
					application={application}
					template={template}
					schemas={schemas}
					prevCallback={prevSection}
					setCurrentSection={setCurrentSection}
				/>
			),
			name: SectionType.Confirmation,
			title: 'sections.confirmation',
		},
	].filter(Boolean);
}
