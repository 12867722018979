import { LinearProgress } from '@material-ui/core';
import * as d3 from 'd3-force';
import React from 'react';
import { renderToString } from 'react-dom/server';
import ForceGraph2D, {
	ForceGraphMethods,
	ForceGraphProps,
	GraphData as ForceGraphData,
	NodeObject as ForceNodeObject,
} from 'react-force-graph-2d';
import { useTranslation } from 'react-i18next';

import {
	IApplicationType,
	IMember,
} from '../../../../common-ts/src/interface/application';
import { accent, grey } from '../../../../ui/src/colors';
import { MemberTypeIcon } from '../MemberTypeIcon';
import { useStyles } from './HierarchyGraph.style';

export interface NodeObject
	extends Omit<ForceNodeObject, 'id'>,
		Record<string, any> {
	id: string;
	name: string;
	members: Record<string, IMember>;
}
export interface GraphData extends Omit<ForceGraphData, 'nodes'> {
	nodes: NodeObject[];
}

export interface Props extends Omit<ForceGraphProps, 'graphData'> {
	data: GraphData;
}

export const NODE_SIZE = 4;
export const NODE_RELATIVE_SIZE = 10;

const isNodeObject = (
	node: string | number | NodeObject | ForceNodeObject
): node is NodeObject => {
	return typeof node !== 'string' && typeof node !== 'number';
};

const createSvg = (component: React.ReactElement) => {
	const rendered = renderToString(component);

	const normalized = rendered.replace(
		'<svg ',
		'<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" '
	);

	return `data:image/svg+xml;utf8,${normalized}`;
};

const COOLDOWN_TIME = 3000;

export const HierarchyGraph: React.FC<Props> = ({
	data,
	width,
	height,
	...rest
}) => {
	const { t } = useTranslation();
	const fgRef = React.useRef<ForceGraphMethods>();
	const [loading, setLoading] = React.useState(true);
	const styles = useStyles();
	const [corporateIcon, individualIcon] = React.useMemo(
		() =>
			[
				<MemberTypeIcon type="corporate" style={{ fill: 'white' }} />,
				<MemberTypeIcon type="individual" style={{ fill: 'white' }} />,
			].map(createSvg),
		[]
	);

	React.useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, COOLDOWN_TIME);
		const fg = fgRef.current;

		if (!fg) return;

		fg.d3Force(
			'collision',
			d3.forceCollide(() => 40)
		);
	}, []);

	return (
		<>
			<div className={styles.container}>
				{loading && (
					<div className={styles.overlay}>
						<LinearProgress />
					</div>
				)}

				<ForceGraph2D
					ref={fgRef}
					{...{ height, width }}
					graphData={data as ForceGraphData}
					cooldownTime={COOLDOWN_TIME}
					nodeColor={() => accent}
					dagMode="td"
					nodeRelSize={NODE_RELATIVE_SIZE}
					dagLevelDistance={80}
					enableNodeDrag={false}
					linkDirectionalArrowLength={3.5}
					linkDirectionalArrowRelPos={1}
					onEngineStop={() => fgRef.current?.zoomToFit(400)}
					nodeCanvasObject={(node: NodeObject, ctx) => {
						const label = node.name;
						const fontSize = 4;
						ctx.font = `${fontSize}px Sans-Serif`;

						const img = new Image();
						img.onload = () => {
							ctx.drawImage(img, node.x - 6, node.y - 16, 12, 12);
						};

						img.src =
							node.type === IApplicationType.CORPORATE
								? corporateIcon
								: individualIcon;

						ctx.textAlign = 'center';
						ctx.textBaseline = 'middle';
						ctx.fillStyle = '#FFFFFF';
						ctx.fillText(
							label,
							node.x,
							node.y,
							node.val * NODE_RELATIVE_SIZE - 2
						);
					}}
					nodeCanvasObjectMode={() => 'after'}
					linkCanvasObject={({ source, target }, ctx) => {
						if (!source || !target) return;
						if (!isNodeObject(source) || !isNodeObject(target)) return;
						if (!source || !target) return;

						const member = source?.members[target.id];
						if (!member) return;

						const { shares, memberRoles } = member;

						// show the first 2 member roles
						let label = memberRoles
							.slice(0, 2)
							.map((r) => t(`roles.${r}`))
							.join(', ');
						if (memberRoles.length > 2) {
							label = `${label}, etc`;
						}
						if (shares) {
							label = `${label}; ${shares} shares`;
						}
						const fontSize = 4;
						ctx.font = `${fontSize}px Sans-Serif`;

						const x = (source.x + target.x) / 2;
						const y = (source.y + target.y) / 2;

						ctx.textAlign = 'center';
						ctx.textBaseline = 'middle';
						ctx.fillStyle = grey;
						ctx.fillText(label, x, y);
					}}
					linkCanvasObjectMode={() => 'after'}
					{...rest}
				/>
			</div>
		</>
	);
};
