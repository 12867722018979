import { KycProcessApplicantActionRequiredStatuses } from '../../../../../common-ts/src/application/constants';
import { IApplication } from '../../../../../common-ts/src/interface/application';
import { apiClient } from '../../../../../common-ts/src/services/network/api';

export const getApplicationsList = () => {
	return apiClient.get<IApplication[]>('/api/v2/applications', {
		fields: [
			'paths',
			'statusLog',
			'type',
			'template',
			'id',
			'currentStatus',
			'updatedAt',
			'createdAt',
			'attributes',
			'forms',
			'options',
			'questions',
			'members',
		],
	});
};

export const getApplicationById = (id: string) => {
	return apiClient.get<IApplication>(`/api/v2/applications/${id}`);
};

export const updateApplication = (
	id: string,
	// TODO: Replace any with type
	data: any
): Promise<IApplication> => {
	return apiClient.patch<IApplication>(`/api/v2/applications/${id}`, data);
};

// TODO: Replace any with type
export const submitApplication = (id: string, data: any) => {
	return apiClient.post<any>(`/api/v2/applications/${id}/submit`, data);
};

export const getApplicationStatusText = (application: IApplication) => {
	if (!application.currentStatus) {
		return 'applicationStatus.error';
	}
	const statusText = `applicationStatus.${application.currentStatus}`;
	return statusText;
};

// in the front-end the application.currentStatus property will not be ambiguous
// because it is filtered by the API when the application is queried
export const isApplicationEditable = (application: IApplication) => {
	return KycProcessApplicantActionRequiredStatuses.includes(
		application.currentStatus
	);
};
