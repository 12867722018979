import isEmpty from 'lodash/isEmpty';
import valuesIn from 'lodash/valuesIn';

import { IApplication, IAttribute, IQuestion } from '../interface/application';
import { IFormSubmission, IKycDocumentTemplate } from '../interface/form';

interface GetRequirementsValuesReturnType {
	attributes: IAttribute[];
	questions: IQuestion[];
	forms: IFormSubmission[];
}

export const allRequirementsFulfilled = (application: IApplication) => {
	const values = getRequirementsValues(application);
	const missingAttributes = getMissingAttributesOrQuestions(values.attributes);
	const missingQuestions = getMissingAttributesOrQuestions(values.questions);
	const missingForms = getMissingForms(values.forms);

	return (
		missingAttributes.length === 0 &&
		missingQuestions.length === 0 &&
		missingForms.length === 0
	);
};

export const getRequirementsValues = (
	application: IApplication
): GetRequirementsValuesReturnType => {
	return {
		attributes:
			application.attributes instanceof Map
				? [...application.attributes.values()]
				: valuesIn(application.attributes),

		questions:
			application.questions instanceof Map
				? [...application.questions.values()]
				: valuesIn(application.questions),

		forms: application.forms
			? (application.forms as IFormSubmission[]).filter(
					(f: IFormSubmission) =>
						(f.form as IKycDocumentTemplate).active &&
						!(f.form as IKycDocumentTemplate).removed
			  )
			: [],
	};
};

export const getFilledAttributesOrQuestions = (
	requirements: Array<IAttribute | IQuestion> = []
) => {
	return requirements.filter(
		(req) => req.value || req.value?.length > 0 || !isEmpty(req.value)
	);
};

export const getMissingAttributesOrQuestions = (
	requirements: Array<IAttribute | IQuestion> = []
) => {
	return requirements.filter(
		(req) =>
			!req.valid || (!req.optional && (!req.value || req.value.length === 0))
	);
};

export const getMissingForms = (forms: IFormSubmission[] = []) => {
	return forms.filter((form: IFormSubmission) => {
		return !form.valid || (!form.optional && !form.submitted);
	});
};
