import { makeStyles } from '@material-ui/core/styles';

import { accent, grey } from '../../../../ui/src/colors';

export const useStyles = makeStyles({
	root: {
		fontWeight: 700,
		textTransform: 'uppercase',
		color: grey,
	},
	view: {
		margin: '0 0.25rem',
		'&:hover': {
			cursor: 'pointer',
			color: accent,
		},
	},
	selected: {
		color: accent,
	},
});
