import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ErrorToastMessage = ({ closeToast, toastProps }: any) => {
	const { t } = useTranslation();
	return <div>{t('errors.questionErrorGenericToast')}</div>;
};

export const transformErrors = (errors: any[]) => {
	// NOTE - not sure if this is the correct place to display toast but the rjsf module does not
	// seem to have other way
	if (errors.length > 0) {
		toast.warning(<ErrorToastMessage />, {
			autoClose: 5000,
			position: toast.POSITION.TOP_CENTER,
			// prevent duplicates
			toastId: 'question-error',
		});
	}
	return errors.map((error) => {
		switch (error.name) {
			case 'enum':
				// better error message for datazoo consent
				if (error.schemaPath.includes('consent')) {
					error.message = 'errors.datazooConsent';
				}
				break;
			case 'pattern':
				error.message = 'errors.pattern';
				break;
			case 'required':
				error.message = 'errors.required';
				if (error.schemaPath.includes('consent')) {
					error.message = 'errors.datazooConsent';
				}
				break;
			case 'minLength':
				error.message = 'errors.minLength';
				break;
			case 'format':
				error.message = 'errors.format';
				break;
			case 'minItems':
				error.message = 'errors.minItems';
				break;
		}
		return error;
	});
};
