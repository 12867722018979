import { GetApplicationChatCountResponse } from '../../../../common-ts/src/interface/application-chat-count';
import { apiClient } from '../../../../common-ts/src/services/network/api';
import { IMessage } from '../../../../common/src/react/Chat';
import { fullName } from '../../../../common/src/react/helpers';

export const getChatByApplicationId = async (
	applicationId: string,
	// TODO: Find options type
	options = {}
) => {
	// TODO: Replace any with type
	const response = await apiClient.get<any[]>(
		`/api/v2/applications/${applicationId}/chat`,
		undefined,
		options
	);

	const messages: IMessage[] = response.map((message) => {
		return {
			...message,
			user: message.user?._id,
			userName: fullName(message.user as any),
			userRole: message.user?.roles?.length ? 'manager' : 'client',
		};
	});

	return messages;
};

export const getChatCount = async (applicationId: string) => {
	const { count } = await apiClient.get<GetApplicationChatCountResponse>(
		`/api/v2/applications/${applicationId}/chat_count`
	);

	return count;
};

export const sendChatMessage = (
	applicationId: string,
	message: string,
	// TODO: Find options type
	options = {}
) => {
	// TODO: Replace any with type
	return apiClient.post<any>(
		`/api/v2/applications/${applicationId}/chat`,
		{ message },
		options
	);
};
