export enum Feature {
	XmasTree = 'XmasTree',
	ManagerFill = 'ManagerFill',
	MultiScope = 'MultiScope',
	MultiApproval = 'MultiApproval',
	RiskScoring = 'RiskScoring',
	Monitoring = 'Monitoring',
	TokenList = 'TokenList',
	IDScan = 'IDScan',
	AIScan = 'AIScan',
	Datazoo = 'Datazoo',
	WalletScreening = 'WalletScreening',
	Messages = 'Messages',
	Reminders = 'Reminders',
	PasswordlessFlow = 'PasswordlessFlow',
	BrexIO = 'BrexIO',
	Scraper = 'Scraper',
	ComplyAdvantage = 'ComplyAdvantage',
	Languages = 'Languages',
	ControlManagerActions = 'ControlManagerActions',
	TwoFactorAuthRequired = 'TwoFactorAuthRequired',
	DocumentExpiryMonitoring = 'DocumentExpiryMonitoring',
	KycRefresh = 'KycRefresh',
	GeneratePdfReports = 'GeneratePdfReports',
}
