import { KnownAttribute } from './../customSchemas';

// tslint:disable object-literal-sort-keys
export const KycStatuses = {
	missing: 0,
	in_progress: 1,
	approved: 2,
	rejected: 3,
	uploaded: 4,
	invited: 5,
	terms_accepted: 6,
	user_declined: 7,
	cancelled: 8,
	additional_requested: 9,
	corporate_details: 10,
	user_processing_requirement: 11,
	partially_approved: 12,
	send_tokens: 13,
	manager_assigned: 14,
	data_check: 15,
	reopened: 16,
	deleted: 17,
	manager_filled: 18,
	document_order: 19,
	application_label_updated: 20,
	refresh: 21,
	generate_application_report: 22,
	document_expiry_check: 23,
};

export const KycStatusNames = [
	'Waiting for Signature',
	'In Progress',
	'Approved',
	'Rejected',
	'Documentation Ready for Review',
	'Customer Invited',
	'User Accepted KYC Invitation',
	'Customer Declined',
	'Cancelled',
	'Additional Items Requested',
	'Customer Processing',
	'Customer Processing',
	'Partially Approved',
	'Send Tokens',
	'Manager Assigned',
	'Data check and sanctions screening',
	'Process Re-Opened',
	'Deleted',
	'Manager Filled Requirement(s)',
	'Company Document Ordered',
	'Application Label Updated',
	'Process Re-Opened for KYC Refresh',
	'Application Report Generated',
	'Document Expiry Checked',
];

// statuses which could appear at any point in the application
// process and don't really tell you the most recent action or next action
export const KycProcessAmbiguousStatuses = [
	KycStatuses.manager_assigned,
	KycStatuses.send_tokens,
	KycStatuses.data_check,
	KycStatuses.reopened,
	KycStatuses.deleted,
	KycStatuses.manager_filled,
	KycStatuses.document_order,
	KycStatuses.application_label_updated,
	KycStatuses.refresh,
	KycStatuses.generate_application_report,
	KycStatuses.document_expiry_check,
];

export const KycProcessManagerActionRequiredStatuses = [
	KycStatuses.uploaded,
	KycStatuses.partially_approved,
];

export const KycProcessApplicantActionRequiredStatuses = [
	KycStatuses.missing,
	KycStatuses.in_progress,
	KycStatuses.invited,
	KycStatuses.terms_accepted,
	KycStatuses.user_processing_requirement,
	KycStatuses.corporate_details,
	KycStatuses.additional_requested,
];

export const KycProcessOpenStatuses = [
	...KycProcessApplicantActionRequiredStatuses,
	...KycProcessManagerActionRequiredStatuses,
];

export const KycProcessClosedStatuses = [
	KycStatuses.approved,
	KycStatuses.rejected,
	KycStatuses.cancelled,
	KycStatuses.user_declined,
	KycStatuses.deleted,
];

// special status group for the ApplicationSchema isEditableByUser function
export const KycProcessEditableStatuses = [
	KycStatuses.terms_accepted,
	KycStatuses.additional_requested,
	KycStatuses.corporate_details,
	KycStatuses.user_processing_requirement,
	KycStatuses.in_progress,
];

export const KycProcessNonAmbiguousStatuses = Object.values(KycStatuses).filter(
	(s) => !KycProcessAmbiguousStatuses.includes(s)
);

export interface IStatusGroup {
	text: string;
	statuses: number[];
}

export const StatusGroups: { [key: string]: IStatusGroup } = {
	inProgress: {
		text: 'In Progress',
		statuses: KycProcessApplicantActionRequiredStatuses,
	},
	needsAdditionalApproval: {
		text: 'Awaiting Additional Approval(s)',
		statuses: [KycStatuses.partially_approved],
	},
	uploaded: {
		text: 'Ready For Review',
		statuses: KycProcessManagerActionRequiredStatuses,
	},
	approved: { text: 'Approved', statuses: [KycStatuses.approved] },
	rejected: { text: 'Rejected', statuses: [KycStatuses.rejected] },
	declined: { text: 'User Declined', statuses: [KycStatuses.user_declined] },
	cancelled: { text: 'Cancelled', statuses: [KycStatuses.cancelled] },
};

export const getNameForStatus = (status: number): string => {
	const key = Object.keys(StatusGroups).find(
		(group: string) => StatusGroups[group].statuses.indexOf(status) >= 0
	);
	return StatusGroups[key] ? StatusGroups[key].text : '';
};

export const ValidUploadsInternal = [
	'application/pdf',
	'image/jpeg',
	'image/png',
];
export const ValidUploadsIntegrations = [
	'application/pdf',
	'image/jpeg',
	'image/png',
];
export const ValidUploadsAttachments = [
	'application/pdf',
	'image/jpeg',
	'image/png',
	'text/plain',
];
export const ValidUploadsLogo = [
	'image/jpeg',
	'image/png',
	'image/svg+xml',
	'application/xml',
];

export const fileTypeMap: { [key: string]: string } = {
	'application/pdf': 'pdf',
	'image/jpeg': 'jpg',
	'image/jpg': 'jpg',
	'image/png': 'png',
	'image/svg+xml': 'svg',
	'application/xml': 'svg',
};

export const fileTypeByExt: { [key: string]: string } = {
	png: 'image/png',
	jpg: 'image/jpeg',
	pdf: 'application/pdf',
};

export enum FileType {
	PDF_APPLICATION = 'application/pdf',
	IMAGE_JPEG = 'image/jpeg',
	IMAGE_PNG = 'image/png',
}

export const ObjectIdRegex = /^[0-9a-fA-F]{24}$/;

export enum UserRoles {
	UNAUTHORIZED_APPLICANT = 'UNAUTHORIZED_APPLICANT',
	APPLICANT = 'applicant',
	WALLET_APPLICANT = 'wallet_applicant',
	CHECKER = 'checker',
	MANAGER = 'manager',
}

export const AssignableManagerRoles = [UserRoles.MANAGER];

export const ApplicantRoles = [UserRoles.APPLICANT, UserRoles.WALLET_APPLICANT];

export const LegalEntityTypes: {
	[key: string]: { name: string; memberRoles: string[] };
} = {
	ltd: {
		name: 'Company Limited by Shares (LTD)',
		memberRoles: [
			'director_ltd',
			'shareholder',
			'ubo',
			'observer',
			'authorizedSignatory',
			'other',
		],
	},
	llc: {
		name: 'Limited Liability Company (LLC)',
		memberRoles: [
			'manager',
			'member_llc',
			'ubo',
			'observer',
			'authorizedSignatory',
			'other_llc',
		],
	},
	tst: {
		name: 'Trust (TST)',
		memberRoles: [
			'grantor',
			'beneficiary_tst',
			'trustee',
			'protector',
			'ubo',
			'observer',
			'authorizedSignatory',
			'other',
		],
	},
	fnd: {
		name: 'Foundation (FND)',
		memberRoles: [
			'founder',
			'director_fnd',
			'supervisor',
			'beneficiary_fnd',
			'ubo',
			'observer',
			'authorizedSignatory',
			'other',
		],
	},
	llp: {
		name: 'Limited Partnership (LLP)',
		memberRoles: [
			'generalPartner',
			'limitedPartner',
			'ubo',
			'observer',
			'authorizedSignatory',
			'other',
		],
	},
	other: {
		name: 'Other',
		memberRoles: ['member', 'ubo', 'observer', 'authorizedSignatory'],
	},
};

const memberRolesSet = new Set<string>(
	Object.keys(LegalEntityTypes)
		.map((type) => LegalEntityTypes[type].memberRoles)
		.reduce((flattened, curr) => [...flattened, ...curr], [])
);

export const MemberRoles: string[] = Array.from(memberRolesSet);

export const MinimalIndividualAttributes = [
	{
		schemaId: KnownAttribute.Email,
		jsonSchema: {
			description: '',
			title: 'Email',
		},
		uiType: 'attribute',
		weight: 0,
	},
	{
		schemaId: KnownAttribute.FirstName,
		jsonSchema: {
			description: '',
			title: 'First name',
		},
		uiType: 'attribute',
		weight: 0,
	},
	{
		schemaId: KnownAttribute.MiddleName,
		jsonSchema: {
			description: '',
			title: 'Middle name',
		},
		optional: true,
		uiType: 'attribute',
		weight: 0,
	},
	{
		schemaId: KnownAttribute.LastName,
		jsonSchema: {
			description: '',
			title: 'Last name',
		},
		uiType: 'attribute',
		weight: 0,
	},
];

export const ExtraAttributesIndividual = [
	{
		schemaId: KnownAttribute.DateOfBirth,
		jsonSchema: {
			description: '',
			title: 'Date of birth',
		},
		uiType: 'attribute',
		weight: 0,
	},
	{
		schemaId: KnownAttribute.Nationality,
		jsonSchema: {
			description: '',
			title: 'Nationality',
		},
		uiType: 'attribute',
		weight: 0,
	},
	{
		schemaId: KnownAttribute.CountryOfResidency,
		jsonSchema: {
			description: '',
			title: 'Country of residency',
		},
		uiType: 'attribute',
		weight: 0,
	},
];

export const MinimalCorporateAttributes = [
	{
		schemaId: KnownAttribute.Email,
		jsonSchema: {
			description: '',
			title: 'Email',
		},
		uiType: 'attribute',
		weight: 0,
	},
	{
		schemaId: KnownAttribute.CompanyName,
		jsonSchema: {
			description:
				'Please provide the name of your company, as it appears in official documents',
			title: 'Company name',
		},
		uiType: 'attribute',
		weight: 0,
	},
	{
		schemaId: KnownAttribute.LegalEntityType,
		jsonSchema: {
			description: 'Please specify the type of legal entity',
			title: 'Legal Entity Type',
		},
		uiType: 'attribute',
		weight: 0,
	},
];

export const ExtraAttributesCorporate = [
	{
		schemaId: KnownAttribute.LegalJurisdiction,
		jsonSchema: {
			description: '',
			title: 'Jurisdiction',
		},
		uiType: 'attribute',
		weight: 0,
	},
	{
		schemaId: KnownAttribute.BusinessTypes,
		jsonSchema: {
			description: 'Please select the primary business types',
			title: 'Business Types',
		},
		uiType: 'attribute',
		weight: 0,
	},
	{
		schemaId: KnownAttribute.IncorporationDate,
		jsonSchema: {
			description: 'Please select the company incorporation date',
			title: 'Date of Incorporation',
		},
		uiType: 'attribute',
		weight: 0,
	},
];

export const duplicateAttributesAllowed: string[] = [
	KnownAttribute.BankStatement,
	KnownAttribute.ExternalDocument,
	KnownAttribute.TaxCertificate,
	KnownAttribute.UtilityBill,
	KnownAttribute.CertificateOfIncorporation,
	KnownAttribute.RegistrationCertificate,
	KnownAttribute.BirthCertificate,
	KnownAttribute.MemberRegister,
	KnownAttribute.Cv,
	KnownAttribute.ProfessionalReferenceLetter,
];

// TODO - makes the list more flexbile
export const SELKEY_CREDENTIAL_ATTRIBUTES: string[] = [
	KnownAttribute.CountryOfResidency,
	KnownAttribute.DateOfBirth,
	// KnownAttribute.DriversLicense,,
	KnownAttribute.Email,
	KnownAttribute.EthAddress,
	KnownAttribute.FirstName,
	KnownAttribute.LastName,
	// KnownAttribute.NationalId,
	KnownAttribute.Nationality,
	// KnownAttribute.Passport,
	KnownAttribute.PhoneNumber,
	KnownAttribute.PhysicalAddress,
	// KnownAttribute.SelfieId,
];
