import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './index';

interface IResetPasswordForm {
	submitFunc: (email: string) => Promise<any>;
	translationTitle: string;
}

export const ResetPasswordForm = (props: IResetPasswordForm) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [values, setValues] = useState({
		email: '',
		validationError: false,
	});

	const [submitLoading, setSubmitLoading] = useState(false);
	const handleChange =
		(prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
		};

	const resetSubmit = (event: React.SyntheticEvent) => {
		event.preventDefault();
		setValues({ ...values, validationError: false });
		if (values.email.length <= 0) {
			setValues({ ...values, validationError: true });
			return;
		}
		setSubmitLoading(true);

		props
			.submitFunc(values.email)
			.then(() => {
				setValues({ email: '', validationError: false });
				setSubmitLoading(false);
			})
			.catch(() => {
				setSubmitLoading(false);
			});
	};

	return (
		<>
			{submitLoading && <LinearProgress />}
			<form onSubmit={resetSubmit} noValidate>
				<FormControl className={classes.input} fullWidth>
					<InputLabel shrink htmlFor="email">
						{t('reset.email')}
					</InputLabel>
					<Input
						id="email"
						placeholder={t('reset.email')}
						disabled={submitLoading}
						type="email"
						onChange={handleChange('email')}
						value={values.email}
						error={values.validationError}
						fullWidth
						required
					/>
					{values.email.length <= 0 && values.validationError ? (
						<Typography
							variant="subtitle1"
							color="error"
							className={classes.errorMessage}
						>
							{t(`${props.translationTitle}.description`)}
						</Typography>
					) : null}
				</FormControl>
				<Button
					type="submit"
					className={classes.button}
					size="large"
					variant="contained"
					fullWidth
				>
					{t(`${props.translationTitle}.submit`)}
				</Button>
			</form>
		</>
	);
};
