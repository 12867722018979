import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { memberApplicationIsDeletableByApplicant } from '../../../../../../../../common-ts/src/application/memberApplicationIsDeletable';
import { IApplication } from '../../../../../../../../common-ts/src/interface/application';
import {
	fullNameFromApplication,
	getMemberEntryInApplication,
} from '../../../../../../../../common/src/helpers/attributes';
import { MemberTypeIcon } from '../../../../../../../../common/src/react/MemberTypeIcon';
import { getAttribute } from '../../../../../../../../common/src/react/helpers';
import { isApplicationEditable } from '../../../../../../services/api';
import { useStyles } from './SharesRegistryMemberRowItem.style';

export interface Props {
	application: IApplication;
	memberApplication: IApplication;
	editMember: (id: string) => void;
	deleteMember?: (id: string) => Promise<void>;
}

export const SharesRegistryMemberRowItem: React.FC<Props> = ({
	application,
	memberApplication,
	editMember,
	deleteMember,
}) => {
	const styles = useStyles();
	const { t } = useTranslation();

	const memberApplicationEntry = getMemberEntryInApplication(
		application.members,
		memberApplication.id
	);

	const { value: email } = getAttribute(
		memberApplication.attributes,
		'http://platform.selfkey.org/schema/attribute/email.json'
	);

	return (
		<TableRow key={memberApplication.id}>
			<TableCell className={`${styles.mobile} ${styles.mobileIconElement}`}>
				<MemberTypeIcon
					type={memberApplication.type}
					classes={styles.iconType}
				/>
			</TableCell>

			<TableCell>
				<Typography
					variant="body2"
					color="secondary"
					className={`${styles.textOnMobileView} ${styles.mobileOverflowProtection}`}
				>
					{fullNameFromApplication(memberApplication)}
				</Typography>

				<Typography
					variant="subtitle1"
					className={`${styles.overflowProtection} ${styles.subtitle} ${styles.mobileOverflowProtection}`}
					title={email}
				>
					{email}
				</Typography>

				<Typography
					variant="body2"
					color="secondary"
					className={`${styles.mobile} ${styles.mobileOverflowProtection}`}
				>
					{memberApplicationEntry.memberRoles
						.map((r: string) => t(`roles.${r}`))
						.join(', ')}

					{application.type === 'corporate' &&
						application.options.askForMemberOwnership && (
							<> • {memberApplicationEntry.shares}</>
						)}
				</Typography>
			</TableCell>

			<TableCell className={styles.hiddenOnMobile}>
				<Typography
					variant="body2"
					color="secondary"
					style={{ whiteSpace: 'initial' }}
				>
					{memberApplicationEntry.memberRoles
						.map((r: string) => t(`roles.${r}`))
						.join(', ')}
				</Typography>
			</TableCell>

			{application.type === 'corporate' && (
				<>
					<TableCell align="center" className={styles.hiddenOnMobile}>
						<MemberTypeIcon
							type={memberApplication.type}
							classes={styles.iconType}
						/>
					</TableCell>

					{application.options.askForMemberOwnership && (
						<TableCell className={styles.hiddenOnMobile}>
							<Typography variant="body2" color="secondary">
								{memberApplicationEntry.shares}
							</Typography>
						</TableCell>
					)}
				</>
			)}

			<TableCell align="right" className={styles.vertical}>
				{isApplicationEditable(memberApplication) && (
					<>
						<IconButton
							onClick={() => editMember(memberApplication.id)}
							size="small"
							aria-label="delete"
							className={styles.iconButton}
						>
							<EditIcon fontSize="inherit" />
						</IconButton>

						{!!deleteMember &&
							memberApplicationIsDeletableByApplicant(memberApplication) && (
								<IconButton
									onClick={() => deleteMember(memberApplication.id)}
									size="small"
									aria-label="delete"
									className={styles.iconButton}
								>
									<DeleteIcon fontSize="inherit" />
								</IconButton>
							)}
					</>
				)}
			</TableCell>
		</TableRow>
	);
};
