import { Theme, makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Chat from '../../../../../common/src/react/Chat';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import {
	getChatByApplicationId,
	sendChatMessage,
} from '../../../../src/services/api';

const useStyles = makeStyles((_: Theme) => ({
	chat: {
		'@media (max-width: 600px)': {
			height: '450px',
		},
		height: '500px',
	},
	title: {
		marginBottom: '10px',
	},
}));

interface IMessagesProps {
	applicationId: string;
	userId: string;

	onClickBack(): void;
}

const Messages = (props: IMessagesProps) => {
	const classes = useStyles();
	const { userId } = props;
	const { t } = useTranslation();

	const [_, setLoading] = React.useState(false);
	const [messages, setMessages] = React.useState([]);

	const handleSave = async (message: string) => {
		const { applicationId } = props;
		if (!message.trim().length) {
			return;
		}
		setLoading(true);
		await sendChatMessage(applicationId, message);
		setLoading(false);
		handleLoad();
	};

	const handleLoad = async () => {
		const { applicationId } = props;
		setLoading(true);
		const data = await getChatByApplicationId(applicationId);
		setLoading(false);
		setMessages(data);
	};

	React.useEffect(() => {
		handleLoad();
		const interval = setInterval(handleLoad, 5000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<CustomerPortalTheme>
			<div>
				<Typography align="left" variant="h1" className={classes.title}>
					{t('messages.title')}
				</Typography>
				<Divider />
				<Chat
					placeholderText={t('messages.placeholder')}
					className={classes.chat}
					userId={userId}
					messages={messages}
					handleOnSave={handleSave}
					handleEdit={() => null}
				/>
			</div>
		</CustomerPortalTheme>
	);
};

export default Messages;
