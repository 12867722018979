import { IconButton, Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Language as LanguageIcon } from '@material-ui/icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { isStorageAvailable } from '../../../../../common-ts/src';
import {
	LANGUAGE_TRANSLATION,
	Language,
} from '../../../../../common-ts/src/interface/scope';
import CustomerPortalTheme from '../../../../../ui/src/theme/kyc-default-theme';
import { SettingsContext } from '../../../App';
import * as i18nCommon from './../../../../../common/src/i18n';
import { useStyles } from './LanguageSwitcher.style';

const LanguageSwitcher: React.FC = () => {
	const { i18n } = useTranslation();
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const settings = React.useContext(SettingsContext);
	const enabledLanguages = settings.enabledLanguages;

	const navigatorLanguage = navigator.language?.substring(0, 2) as Language;
	const initialLanguage = enabledLanguages.includes(navigatorLanguage)
		? navigatorLanguage
		: enabledLanguages[0] ?? Language.English;

	const changeLanguage = (value: string) => {
		// Change the language in the current project using the i18n instance
		i18n.changeLanguage(value);

		// Change the language in the 'common' project using the default i18n instance
		i18nCommon.default.changeLanguage(value);

		// Store the selected language in localStorage, if available
		if (isStorageAvailable(window.localStorage)) {
			window.localStorage.setItem('language', value);
		}
	};

	React.useEffect(() => {
		// Check if the language is stored in localStorage
		const cachedLanguage = isStorageAvailable(window.localStorage)
			? (window.localStorage.getItem('language') as Language)
			: null;

		// Use the cached language if available, otherwise use the default language
		const userLanguage = enabledLanguages.includes(cachedLanguage)
			? cachedLanguage
			: initialLanguage;

		changeLanguage(userLanguage);
	}, []);

	const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleMenuItemClick = (selectedLanguage: Language) => {
		changeLanguage(selectedLanguage);
		handleMenuClose();
	};

	if (enabledLanguages?.length === 1) {
		return null;
	}

	return (
		<CustomerPortalTheme>
			<div className={classes.container}>
				<IconButton onClick={handleButtonClick} disableRipple>
					<LanguageIcon />
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
				>
					{enabledLanguages.map((language) => {
						return (
							<MenuItem
								onClick={() => handleMenuItemClick(language as Language)}
								className={classes.menuItem}
							>
								{LANGUAGE_TRANSLATION[language as Language]}
							</MenuItem>
						);
					})}
				</Menu>
			</div>
		</CustomerPortalTheme>
	);
};

export default LanguageSwitcher;
