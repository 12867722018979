import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	KnownAttribute,
	MAIN_ATTRIBUTES,
} from '../../../../../common-ts/src/customSchemas';
import {
	IApplication,
	IMember,
} from '../../../../../common-ts/src/interface/application';
import { IDateFormat } from '../../../../../common-ts/src/interface/scope';
import { IUserFields } from '../../../../../common-ts/src/interface/user';
import {
	fullNameFromApplication,
	getAttribute,
} from '../../../../../common/src/helpers/attributes';
import { ValueFormatter } from '../../../../../common/src/react/Attribute/valueFormatter';
import { MemberTypeIcon } from '../../../../../common/src/react/MemberTypeIcon';
import { useStyles } from './ApplicationInformation.style';

export interface Props {
	application: IApplication;
	parentApplication: IApplication;
	dateFormat: IDateFormat;
}

export const ApplicationInformation: React.FC<Props> = ({
	application,
	parentApplication,
	dateFormat,
}) => {
	const { t } = useTranslation();
	const styles = useStyles();
	const [rows, setRows] = React.useState([]);

	let member: IMember = null;

	React.useEffect(() => {
		if (parentApplication) {
			member = parentApplication?.members.find(
				(memb) => memb.application === application.id
			);
		}
		setRows(getRows());
	}, [parentApplication, application]);

	const getRows = () => {
		return [
			{
				title: t('membersTable.name'),
				value: fullNameFromApplication(application),
			},
			{
				title: t('membersTable.contact'),
				value:
					(getAttribute(application.attributes, KnownAttribute.Email)
						?.value as string) ?? (application.owners[0] as IUserFields)?.email,
			},
			{
				title: t('membersTable.type'),
				value: <MemberTypeIcon type={application.type} />,
			},
			parentApplication && {
				title: t('membersTable.roles'),
				value: member?.memberRoles.length
					? member.memberRoles.map((r: string) => t(`roles.${r}`)).join(', ')
					: 'No roles',
			},
			parentApplication &&
				parentApplication?.options?.askForMemberOwnership && {
					title: t('membersTable.shares_owned'),
					value: member?.shares,
				},
			...MAIN_ATTRIBUTES.map((attributeId) => {
				const attribute = getAttribute(application.attributes, attributeId);

				if (
					!attribute?.value ||
					attribute?.schemaId === KnownAttribute.CompanyName
				) {
					return;
				}

				const valueFormatterRequirement = {
					value: attribute.value,
					valid: attribute.valid,
					schemaId: attribute.schemaId,
				};

				return {
					title: attribute.label,
					value: (
						<ValueFormatter
							typographyProps={{ variant: 'subtitle2' }}
							requirement={valueFormatterRequirement}
							dateFormat={dateFormat}
						/>
					),
				};
			}),
		].filter(Boolean);
	};

	return (
		<Table>
			<TableBody>
				{rows.map((row) => (
					<TableRow key={row.title}>
						<TableCell padding="checkbox" className={styles.item}>
							<Typography variant="body2">
								<b>{row.title}</b>
							</Typography>
						</TableCell>

						<TableCell className={styles.item}>
							<Typography variant="body2">{row.value}</Typography>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};
